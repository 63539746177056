import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './en/translation.json'
import translationPT from './pt/translation.json'
import translationEs from './es/translation.json'

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    pt: {
        translation: translationPT,
    },
    es: {
        translation: translationEs,
    },
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    })
