import { giphyDarkestGrey } from '@giphy/colors'
import { useContext } from 'react'
import { Button } from 'shared/components/buttons'
import GridTypeToggle from 'shared/components/grid-type/grid-type'
import SectionHeader from 'shared/components/section-header'
import BulkEditModeContext from 'shared/contexts/bulk-edit-mode'
import ChannelPageContext from 'shared/contexts/channel-page'
import ModalContext from 'shared/contexts/modal'
import { useCanBulkEdit, useCanCurateCollection } from 'shared/hooks/use-permissions'
import styled from 'styled-components'

const Container = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
`

const Items = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
`

const NavButton = styled(Button)`
    height: 32px;
    width: 140px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 4px;
`

type Props = { title?: string; noResults?: boolean }
const Navigation = ({ title = '', noResults = false }: Props) => {
    const { channel, channelType } = useContext(ChannelPageContext)
    const canCurate = useCanCurateCollection()
    const { openBulkEditPanel, openAddGifsToCollectionPanel } = useContext(ModalContext)
    const { isBulkEditMode, toggleBulkEditMode } = useContext(BulkEditModeContext)
    const canBulkEdit = useCanBulkEdit()
    const showAddGifs = (channelType === 'branch_channel' || channelType === 'leaf_channel') && canCurate
    const rootChannelId = channel.ancestors?.[0]?.id
    return (
        <Container>
            <SectionHeader label={title} />
            <Items>
                {showAddGifs && (
                    <NavButton
                        color={giphyDarkestGrey}
                        onClick={() => {
                            openAddGifsToCollectionPanel(channel.id, rootChannelId)
                        }}
                    >
                        Add to Collection
                    </NavButton>
                )}
                {!noResults && canBulkEdit && (
                    <NavButton
                        color={giphyDarkestGrey}
                        onClick={() => {
                            openBulkEditPanel(!isBulkEditMode)
                            toggleBulkEditMode()
                        }}
                    >
                        Bulk Editor
                    </NavButton>
                )}
                <GridTypeToggle />
            </Items>
        </Container>
    )
}

export default Navigation
