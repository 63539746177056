import { giphyBlack } from '@giphy/colors'
import styled from 'styled-components'
import { fontFamily } from 'ui/src/constants'

export const Content = styled.div`
    align-items: center;
    display: flex;
    padding: 30px;
    position: relative;

    span,
    p {
        color: rgba(255, 255, 255, 0.7);
        font-weight: bold;
    }

    p {
        margin: 0;
    }
`

export const Icon = styled.svg`
    flex-shrink: 0;
    -webkit-font-smoothing: antialiased;
    margin-right: 24px;
`

export const Copy = styled.div`
    flex-shrink: 0;
    margin-right: 60px;
`
export const Title = styled.h2`
    margin-bottom: 3px;
    font-family: ${fontFamily.title};
    -webkit-font-smoothing: antialiased;
    font-size: 26px;
`

export const Buttons = styled.div`
    text-align: center;
    width: 100%;

    a {
        align-items: center;
        background: ${giphyBlack};
        border-radius: 2px;
        color: #fff;
        display: flex;
        font-weight: bold;
        height: 36px;
        justify-content: center;
        margin-bottom: 10px;
        width: 100%;
    }

    span {
        cursor: pointer;
    }
`

type Props = {
    className?: string
    onCTAClick: () => void
    onDismiss: () => void
}

const UploadClipsCTACopy = ({ className, onDismiss, onCTAClick }: Props) => (
    <Content className={className}>
        <Icon xmlns="http://www.w3.org/2000/svg" width="45" height="45">
            <path
                d="M33 0c6.627 0 12 5.373 12 12v21c0 6.627-5.373 12-12 12H12C5.373 45 0 39.627 0 33V12C0 5.373 5.373 0 12 0Zm-8.913 12.534a.405.405 0 0 0-.572-.003l-5.152 5.11h-6.708a.405.405 0 0 0-.405.405v9.155c0 .223.181.405.405.405l5.375-.001 6.484 6.473a.405.405 0 0 0 .69-.286V12.819a.405.405 0 0 0-.117-.285Zm6.417 2.76a1.086 1.086 0 0 0-1.476.389 1.061 1.061 0 0 0 .394 1.459c2.319 1.328 3.776 3.774 3.776 6.472 0 2.693-1.451 5.135-3.763 6.465-.515.297-.69.95-.39 1.46.3.51.96.682 1.476.386 2.97-1.709 4.836-4.85 4.836-8.31 0-3.469-1.874-6.615-4.853-8.321ZM27.547 18.1a1.085 1.085 0 0 0-1.438.506 1.063 1.063 0 0 0 .511 1.423 3.2 3.2 0 0 1 1.851 2.895 3.195 3.195 0 0 1-1.635 2.784 1.061 1.061 0 0 0-.402 1.456c.296.512.955.69 1.473.398a5.326 5.326 0 0 0 2.723-4.638 5.334 5.334 0 0 0-3.083-4.824Z"
                fill="#FFF"
                fillRule="evenodd"
            />
        </Icon>
        <Copy>
            <Title>Introducing GIPHY Clips!</Title>
            <p>Be the first to upload your GIFs with sound.</p>
        </Copy>
        <Buttons>
            <a href="/upload" onClick={onCTAClick}>
                Upload Clips
            </a>
            <span onClick={onDismiss}>Dismiss</span>
        </Buttons>
    </Content>
)

export default UploadClipsCTACopy
