import { connect } from 'react-redux'
import { StickerEmbedModal } from 'shared/components/channel-info/sticker-embed-modal'
import * as selectors from 'shared/redux/channel'

const mapStateToProps = ({ channel }, { channelId }) => ({
    id: channelId,
    ...selectors.getChannel(channel, channelId),
})
const StickerEmbedModalContainer = connect(mapStateToProps)(StickerEmbedModal)
export default StickerEmbedModalContainer
