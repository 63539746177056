import { giphyBlue } from '@giphy/colors'
import { Gif } from '@giphy/react-components'
import useCategories from 'shared/hooks/use-categories'
import { grid9 } from 'shared/util/grid'
import styled from 'styled-components'

const List = styled.div`
    margin: 0 20px 0 0;
    h3 {
        text-transform: uppercase;
        font-weight: 700;
    }
    a {
        line-height: 28px;
        display: block;
        :hover {
            color: ${giphyBlue};
        }
    }
`

const Container = styled.div`
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
`

const Title = styled.h1`
    color: #fff;
    font-family: 'nexablack', sans-serif;
    font-size: 36px;
    text-align: left;
    margin-bottom: 16px;
    text-transform: capitalize;
`

const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: ${grid9}px;
    a {
        display: block;
        margin: 0 16px 16px 0;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
    .${Gif.className} {
        img {
            object-fit: cover;
        }
    }
`
const CategoryName = styled.div`
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Overlay = ({ name }: { name: string }) => <CategoryName>{name}</CategoryName>

const Categories = () => {
    const { title, categories, subcategory, items } = useCategories()
    return (
        <Container>
            <List>
                <h3 onClick={() => (location.href = '/categories')}>Categories</h3>
                {categories.map((c) => (
                    <a href={`/categories/${c.name_encoded}`} key={c.name}>
                        {c.name}
                    </a>
                ))}
            </List>
            <div>
                <Title>{title}</Title>
                <Items>
                    {items.map(({ gif, name, name_encoded }) => {
                        // we could easily spa here, but ga would need to be set up
                        const href = subcategory ? `/search/${name_encoded}` : `/categories/${name_encoded}`
                        return gif ? (
                            <a href={href} key={name}>
                                <Gif
                                    gif={gif}
                                    overlay={() => <Overlay name={name} />}
                                    width={248}
                                    height={134}
                                    noLink
                                />
                            </a>
                        ) : null
                    })}
                </Items>
            </div>
        </Container>
    )
}
export default Categories
