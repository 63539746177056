import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import { useContext } from 'react'
import css from './grid-type.css'
import GridTypeContext from 'shared/contexts/grid-type'

const onViewTypeClick = (e, nextGridType, setGridType) => {
    window.history.replaceState({}, nextGridType, `?view=${nextGridType}`)
    e.preventDefault()
    e.stopPropagation()
    setGridType(nextGridType)
}

const GridType = () => {
    const { gridType, setGridType } = useContext(GridTypeContext)
    return (
        <div className={css.iconContainer}>
            <a
                className={`${css.grid} ${css[gridType === 'grid' ? 'iconActive' : 'iconInactive']}`}
                href="?view=grid"
                onClick={(e) => onViewTypeClick(e, 'grid', setGridType)}
            />
            <a
                className={`${css.feed} ${css[gridType === 'feed' ? 'iconActive' : 'iconInactive']}`}
                href="?view=feed"
                onClick={(e) => onViewTypeClick(e, 'feed', setGridType)}
            />
        </div>
    )
}

export default GridType
