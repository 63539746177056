import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import { IChannel } from '@giphy/js-types'
import { isEmpty } from 'lodash'
import ChannelAnalyticsOverview from 'shared/components/channel-analytics/channel-analytics'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import { IProfilePowerUser } from 'types'
import { parse } from 'url'
import MobileBreadcrumbs from './mobile-breadcrumbs'
import NoInfo from './no-info'
import Social from './social'

const DescriptionContainer = styled.div`
    margin-bottom: 16px;
`

export const Bio = styled.p`
    color: ${giphyLightGrey};
    white-space: pre-wrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    margin: 10px 0px;
    text-transform: none;
    ${mobile.css`
        color: ${giphyWhite};
        font-size: 16px;
        clear: both;
        margin: 2px 0 10px;
    `}
`

const Website = styled.a`
    color: ${giphyWhite};
    font-family: interface;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
`

const getSocialLinks = ({
    facebook_url,
    twitter_url,
    instagram_url,
    tumblr_url,
    tiktok_url,
    youtube_url,
}: IProfilePowerUser) => {
    const social = [
        { type: 'facebook', url: facebook_url },
        { type: 'twitter', url: twitter_url },
        { type: 'instagram', url: instagram_url },
        { type: 'tumblr', url: tumblr_url },
        { type: 'tiktok', url: tiktok_url },
        { type: 'youtube', url: youtube_url },
    ]
    // changed this to js native filter bc typescript expects lodash filter to return a number in some cases
    return social.filter((item) => item.url)
}

type Props = {
    isOwner: boolean
    user: IProfilePowerUser
    ancestors: IChannel[]
    isCompact: boolean | undefined
    mobileTitle: string
    shortDisplayName: string
    search: string
    showBreadcrumbs: boolean | undefined
    dashboardLink: boolean
}

const Description = ({
    isOwner,
    user,
    ancestors,
    isCompact,
    mobileTitle,
    shortDisplayName,
    search,
    showBreadcrumbs,
    dashboardLink,
}: Props) => {
    const { primary_site: primarySite = '', about_bio: aboutBio, slug, id } = user
    const showDashboardLink = isOwner && dashboardLink
    const socialLinks = getSocialLinks(user)

    if (isOwner && !isCompact && !primarySite && !aboutBio && isEmpty(socialLinks)) {
        return <NoInfo />
    }

    return showBreadcrumbs ? (
        <>
            <MobileBreadcrumbs
                ancestors={ancestors}
                mobileTitle={mobileTitle}
                shortDisplayName={shortDisplayName}
                slug={slug}
                primarySite={primarySite}
                search={search}
            />
            {aboutBio ? <Bio>{aboutBio}</Bio> : null}
        </>
    ) : (
        <DescriptionContainer>
            <ChannelAnalyticsOverview userId={id} isCompact={isCompact} showDashboardLink={showDashboardLink} />
            {aboutBio ? <Bio>{aboutBio}</Bio> : null}
            {isCompact && (
                <MobileBreadcrumbs
                    ancestors={ancestors}
                    mobileTitle={mobileTitle}
                    shortDisplayName={shortDisplayName}
                    slug={slug}
                    primarySite={primarySite || ''}
                    search={search}
                />
            )}
            {primarySite && !isCompact ? (
                <Website href={primarySite} target="_blank" rel="noopener noreferrer">
                    {parse(primarySite).hostname}
                </Website>
            ) : null}
            {!isEmpty(socialLinks) ? <Social links={socialLinks} isCompact={isCompact} /> : null}
        </DescriptionContainer>
    )
}

export default Description
