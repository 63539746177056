import appendQuery from 'append-query'

import { request, ANALYTICS_URL_LEGACY } from 'shared/api'

const formatDate = (inputDate) => {
    let dd = String(inputDate.getDate()).padStart(2, '0')
    let mm = String(inputDate.getMonth() + 1).padStart(2, '0') // January is 0!
    let yyyy = inputDate.getFullYear()
    return yyyy + '-' + mm + '-' + dd
}

const contentTypes = {
    'GIFs & Stickers': 'gif,sticker',
    GIFs: 'gif',
    Stickers: 'sticker',
    Stories: 'story',
    Clips: 'clip',
}

export type LeaderboardItem = {
    viewCount: number
    contentType: string
    contentId: string
}

export type AggregationData = {
    viewCount: number
    uploadCount: number
}

type TopTermItem = {
    userId?: string
    gifId: string
    term: string
    clickCount: number
    impressionCount: number
    ctr: number
}

export const getLeaderboard = (contentType, dateRange, username) => {
    let startDate = formatDate(dateRange[0])
    let endDate = formatDate(dateRange[1])

    const url = appendQuery(`${ANALYTICS_URL_LEGACY}leaderboard/`, {
        start_dt: startDate,
        end_dt: endDate,
        content_type: contentTypes[contentType],
        limit: 50,
        username: username,
    })

    return request(url) as Promise<LeaderboardItem[]>
}

export const getAggregations = (contentType, dateRange, username) => {
    let startDate = formatDate(dateRange[0])
    let endDate = formatDate(dateRange[1])

    const url = appendQuery(`${ANALYTICS_URL_LEGACY}aggregations/`, {
        start_dt: startDate,
        end_dt: endDate,
        content_type: contentTypes[contentType],
        limit: 50,
        username: username,
    })

    return request(url) as Promise<AggregationData>
}

export const getTopTerms = (contentType, dateRange, content_id, username) => {
    let startDate = formatDate(dateRange[0])
    let endDate = formatDate(dateRange[1])

    const params = {
        start_dt: startDate,
        end_dt: endDate,
        content_type: contentTypes[contentType],
        content_ids: content_id,
        username: username,
    }

    const url = appendQuery(`${ANALYTICS_URL_LEGACY}ctr/`, params, { encodeComponents: false })

    return request(url) as Promise<TopTermItem[]>
}

export const getClipStarts = (contentType, dateRange, content_id, username) => {
    let startDate = formatDate(dateRange[0])
    let endDate = formatDate(dateRange[1])

    const params = {
        start_dt: startDate,
        end_dt: endDate,
        content_type: contentTypes[contentType],
        content_ids: content_id,
        username: username,
    }

    const url = appendQuery(`${ANALYTICS_URL_LEGACY}start_count/`, params, { encodeComponents: false })

    return request(url) as Promise<TopTermItem[]>
}

export const getCsvLink = (contentType, dateRange, username) => {
    let startDate = formatDate(dateRange[0])
    let endDate = formatDate(dateRange[1])

    const url = appendQuery(`${ANALYTICS_URL_LEGACY}csv/`, {
        start_dt: startDate,
        end_dt: endDate,
        content_type: contentTypes[contentType],
        username: username,
    })

    return url as string
}

export const getCsvCtrLink = (contentType, dateRange, username) => {
    if (contentTypes[contentType] === 'clip') {
        return ''
    }

    let startDate = formatDate(dateRange[0])
    let endDate = formatDate(dateRange[1])

    const url = appendQuery(`${ANALYTICS_URL_LEGACY}csv-ctr/`, {
        start_dt: startDate,
        end_dt: endDate,
        content_type: contentTypes[contentType],
        username: username,
    })

    return url as string
}
