export const TrendingIcon = () => (
    <svg width="25" height="20" viewBox="0 0 25 20" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="5.615%" y1="77.472%" x2="100%" y2="26.124%" id="trending">
                <stop stopColor="#3191FF" offset="0%" />
                <stop stopColor="#0CF" offset="100%" />
            </linearGradient>
        </defs>
        <path
            d="M25.333 4.635l-6.45-.032a.47.47 0 00-.471.468l.004 1.575.008.085a.47.47 0 00.462.383h2.94l-7.544 8.101-3.878-3.125a1.119 1.119 0 00-1.631-.009l-7.584 7.73a1 1 0 00-.002 1.4l.288.295a1 1 0 001.431 0L9.652 14.6l3.782 3.042.093.1c.442.442.964.541 1.498.145l8.43-8.998v3.103c0 .25.197.456.446.468l1.407.069a.47.47 0 00.491-.446V5.104a.47.47 0 00-.466-.469z"
            fill="url(#trending)"
            fillRule="nonzero"
            transform="rotate(-5 -21.505 23.157)"
        />
    </svg>
)

export const ArtistsIcon = () => (
    <svg width="21" height="26" viewBox="0 0 21 26" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="15.313%" y1="100%" x2="84.687%" y2="0%" id="artists">
                <stop stopColor="#E646B6" offset="0%"></stop>
                <stop stopColor="#FF6666" offset="100%"></stop>
            </linearGradient>
        </defs>
        <path
            d="M3.9 23.67c-.636-.46-.798-1.112-.485-1.958l2.36-7.256H1.308a1.34 1.34 0 01-1.126-.614c-.244-.379-.244-.998 0-1.377L7.334.613C7.547.233 7.974 0 8.46 0h10.226c.457 0 .883.234 1.126.613.244.38.213.876-.03 1.255l-4.991 7.649h3.201c.548 0 1.004.32 1.217.788.183.467.061.992-.304 1.343L6.25 23.493c-.274.263-1.396.866-2.35.177zm-.88-11.117h3.886c.426 0 1.007-.053 1.285.525.222.324.222.74 0 1.25l-2.094 6.026a.2.2 0 00.33.207l8.8-8.797a.2.2 0 00-.142-.341h-1.61 0c-.457 0-.963.13-1.405-.468-.442-.598-.243-1.174 0-1.554l5.115-7.419a.2.2 0 00-.164-.313l-7.67-.001a.3.3 0 00-.258.146L2.85 12.25a.2.2 0 00.172.303z"
            transform="translate(1 1)"
            fill="url(#artists)"
            fillRule="nonzero"
            stroke="url(#b)"
            strokeWidth=".1"
        />
    </svg>
)

export const ClipsIcon = () => (
    <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="100%" y1="12.4630348%" x2="0%" y2="87.5369652%" id="linearGradient-1">
                <stop stopColor="#00FF99" offset="0%"></stop>
                <stop stopColor="#00CCFF" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="linearGradient-2">
                <stop stopColor="#00FF99" offset="0%"></stop>
                <stop stopColor="#00CCFF" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="🏡-Homepage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Clip-Icon" transform="translate(-275.000000, -737.000000)">
                <g id="Video" transform="translate(274.500000, 736.500000)">
                    <g id="Clips-Icon-Updated" transform="translate(0.500000, 0.500000)">
                        <rect id="Rectangle" fill="#121221" opacity="0" x="0" y="0" width="28" height="28"></rect>
                        <path
                            d="M14.767664,9.14181599 C14.865653,9.24058865 14.9206373,9.37408367 14.9206373,9.51321631 L14.9206373,19.3531441 C14.9206373,19.644388 14.6845375,19.8804878 14.3932936,19.8804878 C14.2535764,19.8804878 14.1195696,19.8250419 14.0206973,19.7263243 L11.046,16.7562481 L8.45234375,16.7571422 C8.16109984,16.7571422 7.925,16.5210424 7.925,16.2297984 L7.925,11.9036065 C7.925,11.6123626 8.16109984,11.3762627 8.45234375,11.3762627 L11.765,11.3762481 L14.0218932,9.13884587 C14.2286523,8.93372713 14.5625453,8.9350569 14.767664,9.14181599 Z M18.3219429,10.1086532 C19.9306724,11.0299103 20.9428571,12.7289223 20.9428571,14.6016892 C20.9428571,16.4706 19.9348523,18.1666374 18.3314041,19.0892943 C18.0531183,19.2494255 17.6962595,19.1561373 17.5343375,18.8809291 C17.3724156,18.6057208 17.4667471,18.2528085 17.7450328,18.0926772 C18.9932239,17.3744426 19.7769176,16.0558241 19.7769176,14.6016892 C19.7769176,13.1445594 18.9899675,11.8236171 17.737676,11.1064782 C17.4590529,10.9469216 17.363977,10.5942046 17.5253178,10.3186629 C17.6866587,10.0431211 18.0433199,9.94909654 18.3219429,10.1086532 Z M16.7254617,11.624252 C17.7337633,12.0981568 18.3903352,13.1050696 18.3903352,14.2289596 C18.3903352,15.2752118 17.8214565,16.2238135 16.9198157,16.7332641 C16.6402613,16.8912195 16.2841577,16.7951504 16.1244359,16.5186876 C15.9647141,16.2422249 16.0618575,15.8900593 16.3414118,15.7321039 C16.8833291,15.4259065 17.2243956,14.8571802 17.2243956,14.2289596 C17.2243956,13.5543057 16.8305288,12.950275 16.224985,12.6656676 C15.9341902,12.5289932 15.8104899,12.1850678 15.9486927,11.8974888 C16.0868956,11.6099098 16.4346669,11.4875775 16.7254617,11.624252 Z"
                            id="Combined-Shape"
                            fill="url(#linearGradient-1)"
                        ></path>
                        <path
                            d="M20.075,0.982142857 L7.925,0.982142857 C4.09056588,0.982142857 0.982142857,4.09056588 0.982142857,7.925 L0.982142857,20.075 C0.982142857,23.9094341 4.09056588,27.0178571 7.925,27.0178571 L20.075,27.0178571 C23.9094341,27.0178571 27.0178571,23.9094341 27.0178571,20.075 L27.0178571,7.925 C27.0178571,4.09056588 23.9094341,0.982142857 20.075,0.982142857 Z M19.9711538,2.91071429 C22.79782,2.91071429 25.0892857,5.20217998 25.0892857,8.02884615 L25.0892857,19.9711538 C25.0892857,22.79782 22.79782,25.0892857 19.9711538,25.0892857 L8.02884615,25.0892857 C5.20217998,25.0892857 2.91071429,22.79782 2.91071429,19.9711538 L2.91071429,8.02884615 C2.91071429,5.20217998 5.20217998,2.91071429 8.02884615,2.91071429 L19.9711538,2.91071429 Z"
                            id="Combined-Shape"
                            fill="url(#linearGradient-2)"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const StoriesIcon = () => (
    <svg width="22" height="25" viewBox="0 0 22 25" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="7.986%" y1="100%" x2="92.014%" y2="0%" id="stories">
                <stop stopColor="#6157FF" offset="0%" />
                <stop stopColor="#93F" offset="100%" />
            </linearGradient>
        </defs>
        <path
            d="M20.25 25a.75.75 0 110 1.5H7.75a.75.75 0 110-1.5h12.5zm2-3a.75.75 0 110 1.5H5.75a.75.75 0 110-1.5h16.5zm-.134-19.5C23.709 2.5 25 3.719 25 5.222v12.556c0 1.503-1.291 2.722-2.884 2.722H5.884C4.291 20.5 3 19.281 3 17.778V5.222C3 3.719 4.291 2.5 5.884 2.5zm-.215 2.2H6.099c-.496 0-.899.371-.899.83v11.94c0 .459.403.83.9.83h15.8c.497 0 .9-.371.9-.83V5.53c0-.459-.403-.83-.9-.83z"
            transform="translate(-3 -2)"
            fill="url(#stories)"
            fillRule="nonzero"
        />
    </svg>
)

export const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 29">
        <defs>
            <linearGradient x1="7.986%" y1="100%" x2="92.014%" y2="0%" id="searchIconGradient">
                <stop stopColor="#00CCFF" offset="0%" />
                <stop stopColor="#00FF99" offset="100%" />
            </linearGradient>
        </defs>
        <path
            d="M10.548 20.41l-7.3 7.79a1.5 1.5 0 01-2.19-2.05l7.348-7.842a11.066 11.066 0 01-2.308-6.79c0-6.069 4.841-11 10.827-11s10.828 4.931 10.828 11c0 6.07-4.842 11-10.828 11-2.385 0-4.589-.783-6.377-2.109zm6.377-.892c4.317 0 7.828-3.575 7.828-8 0-4.424-3.511-8-7.828-8-4.316 0-7.827 3.576-7.827 8 0 4.425 3.51 8 7.827 8z"
            fill="url(#searchIconGradient)"
            fillRule="nonzero"
        />
    </svg>
)
