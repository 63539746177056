import { giphyBlue, giphyDarkestGrey, giphyIndigo, giphyLightGrey, giphyPurple, giphyWhite } from '@giphy/colors'
import { useEffect, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { Button } from 'shared/components/buttons'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import { DimmableArea } from '../../../site/user-dashboard-analytics/app'
import CsvModal from './csv-modal'
import LeaderBoardRow from './leader-board-row'
import { NextCampaignBanner } from '../content-calendar/next-campaign-banner'

const LeaderboardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ShowMoreButton = styled(Button)`
    font-size: 16px;
    width: 420px;
    background: linear-gradient(-135deg, ${giphyIndigo} 0%, ${giphyPurple} 100%);
    ${mobile.css`
        width: 100%;
    `}
    border-radius: 4px;
`

const CsvButton = styled.button`
    display: flex;
    flex-direction: row;
    background: transparent;
    color: ${giphyWhite};
    font-family: interface;
    font-weight: bold;
    font-size: 18px;

    &:hover {
        color: ${giphyBlue};
    }
    i {
        margin-right: 10px;
        margin-top: -2px;
        position: relative;
        color: ${giphyBlue};
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, 0.15);
        line-height: 45px;
        text-align: center;
    }
`
const MoreActionsBar = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    align-items: center;
    justify-content: center;
`

const AddMoreContentButton = styled.a`
    display: block;
    background-image: url('/static/img/dashboard/AddMoreButton@2x.png');
    background-size: cover;
    width: 137px;
    height: 137px;
    display: inline-block;
    margin: 0 auto 20px;
    ${mobile.css`
        width: 100px;
        height: 100px;
    `}
`

const NoDataMessageContainer = styled.div`
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'interface';
    font-weight: bold;
    ${mobile.css`
        margin-top:60px;
    `}
    div {
        color: white;
    }
    span {
        color: ${giphyLightGrey};
    }
`

const DownloadContainer = styled.div`
    border-radius: 4px;
`

// To compensate for the height of the navbar ...
const AnchorTag = styled.div`
    padding-top: 35px;
`

const DownloadTile = styled.div`
    position: absolute;
    display: flex;
    z-index: 1;
    width: 755px;
    padding: 30px 67px 20px 67px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 9px;
    background: linear-gradient(358deg, #121212 -19.56%, ${giphyDarkestGrey} 98.31%);
    box-shadow:
        0px 90px 250px -50px rgba(0, 204, 255, 0.25),
        0px 14px 24px 2px rgba(0, 0, 0, 0.25);
`

const DownloadHeader = styled.h1`
    color: var(--color-grey-9-white, #fff);

    text-align: center;
    font-family: NexaBlack;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 130%; /* 36.4px */
`

const DownloadDescription = styled.div`
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${giphyLightGrey};
        text-align: center;
    }
`

export interface ContentPiece {
    contentId: string
    viewCount: number
}

type Props = {
    onboardingActive?: boolean
    currentStep?: number
    csv: string
    csvCtr: string
    data: ContentPiece[]
    contentType: string
    dateRange: string[]
    datePreset: string
    onCSVDownload?: (label: string) => void
    onCSVError?: (err: Error) => void
    onCSVModalToggle?: (isOpen?: boolean) => void
}

const NoDataMessage = () => {
    return (
        <NoDataMessageContainer>
            <AddMoreContentButton href="/upload" />
            <div>No data available!</div>
            <span>Try adjusting the date range, or upload more!</span>
        </NoDataMessageContainer>
    )
}
const listRows = (props: Props, limit: number) => {
    const { data, contentType, dateRange } = props
    if (data != null && data.length > 0) {
        // Calculate the maximum views displayed so we know how to draw the bar graph in relation to the max
        let maxViews = Math.max.apply(
            Math,
            data.map(function (o) {
                return o.viewCount
            })
        )
        data.sort(function (a, b) {
            return a.viewCount - b.viewCount
        }).reverse()
        // Iterate through all of the pieces of content and create a row on the leaderboard for each.
        return data.slice(0, limit).map((item, index) => {
            return (
                <LeaderBoardRow
                    key={index}
                    contentType={contentType}
                    maxViews={maxViews}
                    index={index}
                    item={item}
                    dateRange={dateRange}
                />
            )
        })
    } else {
        // If no data, show a fallback image and message.
        return <NoDataMessage />
    }
}

const LeaderBoardGraph = (props: Props) => {
    const defaultModalActiveRef = useRef<boolean | null>(false)
    const [limit, setLimit] = useState<number>(5)
    const isMobile = useMedia(mobile.query)
    const [moreContentAvailable, setMoreContentAvailable] = useState<boolean>(true)
    const [isModalActive, setIsModalActive] = useState<boolean>(false)
    const { data, csv, csvCtr, onboardingActive, currentStep, onCSVDownload, onCSVError, onCSVModalToggle } = props
    // Refresh the leaderboard graph when new data is provided.
    useEffect(() => {
        if (data.length <= 5) {
            setMoreContentAvailable(false)
        }

        return () => {}
    }, [data])

    useEffect(() => {
        if (isModalActive !== defaultModalActiveRef.current) {
            onCSVModalToggle?.(isModalActive)
            defaultModalActiveRef.current = null
        }
    }, [isModalActive])

    const increaseLimit = () => {
        if (moreContentAvailable) {
            setLimit(limit + 5)
            // If user tries to show more than 20 pieces of content, prompt them to download the CSV instead, even if more content is available.
            if (limit + 5 > data.length || limit >= 15) {
                setMoreContentAvailable(false)
            }
        }
    }

    return (
        <div>
            {isModalActive && (
                <CsvModal
                    csv={csv}
                    csvCtr={csvCtr}
                    onClose={() => setIsModalActive(false)}
                    onDownload={onCSVDownload}
                    onError={onCSVError}
                />
            )}
            <DimmableArea onboardingActive={onboardingActive} step={3} currentStep={currentStep}>
                <LeaderboardContainer>{listRows(props, limit)}</LeaderboardContainer>
            </DimmableArea>
            {data && data.length > 0 && (
                <div>
                    <MoreActionsBar>
                        <ShowMoreButton
                            disabled={!moreContentAvailable}
                            secondary={!moreContentAvailable}
                            onClick={increaseLimit}
                        >
                            {moreContentAvailable ? 'Load 5 More' : 'Download CSV for full data'}
                        </ShowMoreButton>
                    </MoreActionsBar>
                    <AnchorTag id={'csvAnchor'} />
                    {(csv || csvCtr) && !isMobile ? (
                        <DownloadContainer>
                            <DownloadTile>
                                <DownloadHeader>Download All Time Data</DownloadHeader>
                                <DownloadDescription>
                                    <span>
                                        Export a copy of your channel&apos;s data using our CSV download. Access all
                                        time view counts for every content type and engagement rates for tags on GIFs
                                        and Stickers.
                                    </span>
                                </DownloadDescription>
                                <CsvButton onClick={() => setIsModalActive(true)}>
                                    <i className={'ss-download'} />
                                    <span>Download Full CSV</span>
                                </CsvButton>
                            </DownloadTile>
                            <NextCampaignBanner />
                        </DownloadContainer>
                    ) : null}
                </div>
            )}
        </div>
    )
}

export default LeaderBoardGraph
