import * as colors from '@giphy/colors'
import { useState } from 'react'
import { endsWithWords } from 'shared/util/string'
import styled from 'styled-components'

const IMAGE_TYPES = ['gif', 'gifs', 'sticker', 'stickers']

const SearchForm = styled.form`
    background: ${colors.giphyBlack};
    height: 40px;
    overflow: hidden;
    position: relative;
`

const SearchInput = styled.input`
    background: ${colors.giphyBlack};
    border: 2px solid ${colors.giphyDarkGrey};
    box-sizing: border-box;
    color: ${colors.giphyWhite};
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding-right: 50px;
    width: 100%;
`

const SearchButton = styled.button`
    color: ${colors.giphyWhite};
    font-size: 15px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
`

type Props = {
    user: any
    channel: any
    query: string
    isTagView: boolean
    contentType: string
}

const Search = ({ user, channel, contentType }: Props) => {
    let displayName
    let shortDisplayName
    let searchSlug

    const [inputValue, setInputValue] = useState<string>('')

    if (user) {
        displayName = user.display_name
        shortDisplayName = user.display_name
        searchSlug = user.username
    } else {
        displayName = channel.ancestors[0] ? channel.ancestors[0].display_name : channel.display_name
        shortDisplayName = channel.ancestors[0] ? channel.ancestors[0].short_display_name : channel.short_display_name
        searchSlug = channel.ancestors[0] ? channel.ancestors[0].slug : channel.slug
    }
    const imageType = contentType === 'sticker' ? 'Stickers' : 'GIFs'
    return (
        <>
            <SearchForm action={`/${searchSlug}/search`}>
                <SearchInput
                    name="q"
                    type="text"
                    required={true}
                    placeholder={`Search ${shortDisplayName || displayName} ${
                        endsWithWords(shortDisplayName || displayName, IMAGE_TYPES) ? '' : imageType
                    }`}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue || ''}
                />
                <SearchButton type="submit" className="ss-search" />
            </SearchForm>
        </>
    )
}

export default Search
