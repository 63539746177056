import * as colors from '@giphy/colors'
import styled from 'styled-components'

const Base = styled.a`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 40px;
    margin: 0 10px 10px 0;
    vertical-align: top;
`

export const ProductLink = styled(Base)`
    background: ${colors.primaryCTA};
    color: ${colors.giphyWhite};
    font-weight: bold;
    line-height: 40px;
    padding: 0 16px;
    -webkit-font-smoothing: antialiased;

    &:hover {
        color: ${colors.giphyWhite};
    }
`

export const Apple = styled(Base)`
    background-image: url('/static/img/app_landing/appstore_2x.png');
    width: 130px;
`

export const Android = styled(Base)`
    background-image: url('/static/img/app_landing/googleplay_2x.png');
    width: 124px;
`

export const Vive = styled(Base)`
    background-image: url('/static/img/app_landing/vive.png');
    width: 103px;
`

export const Oculus = styled(Base)`
    background-image: url('/static/img/app_landing/oculus.png');
    width: 103px;
`
