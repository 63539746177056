import { setGADataLayer } from 'analytics'
import { STATUS_MESSAGES } from 'shared/components/message/flash-message'

export type AvatarFormEvent = 'error' | 'open' | 'success'

export function trackGA4AvatarEvent(event: AvatarFormEvent, formError?: string) {
    const data = {
        event: `change_avatar_${event}`,
        options: {
            form: {
                formName: 'change avatar',
            },
        },
    }

    if (formError) {
        data.options.form['formError'] = formError
    }

    setGADataLayer(data)
}

export function getGA4FormError(e: Error | string): string | undefined {
    const statusKeys = Object.keys(STATUS_MESSAGES)
    const errorMessage = (e as Error).message
    const messageOrKey = errorMessage || e
    const statusKey = statusKeys.find((key) => key === messageOrKey)

    switch (statusKey) {
        case 'FILE_TOO_BIG':
            return 'image size too large'
        case 'INVALID_FILE':
            return 'invalid file type'
        default:
            return
    }
}
