import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import { Component } from 'react'
import $ from 'jquery'
import { delay, isEmpty, isEqual } from 'lodash'
import { autobind } from 'core-decorators'
import ScreensaverItem from './screensaver-item'
import ImagePreloader from '../../util/image-preloader'
import css from './screensaver.css'

const $doc = $(document),
    $win = $(window)

/**
 * Channel Screensaver: Controls the screensaver for root
 * channel pages. This is an animation that plays when the
 * user leaves their mouse idle.
 *
 * @class ChannelScreensaver
 * @param {Number} [wait=60000] Time (in milliseconds) to wait after
 * mouse stops moving before showing the screensaver.
 * @param {Array} images List of image urls to animate.
 */
export default class Screensaver extends Component {
    static propTypes = {
        wait: PropTypes.number,
        images: PropTypes.array.isRequired,
    }

    static defaultProps = {
        wait: 60000,
    }

    state = {
        bounds: {},
        isAnimating: false,
        isHiding: false,
    }

    /**
     * Triggered when the mouse is moved.
     * This handler will reset the wait timer and stop any currently
     * running animations.
     *
     */
    @autobind
    __mousemove() {
        const { isAnimating } = this.state
        isAnimating ? this.stopAnimating() : this.startWaiting()
    }

    /**
     * Updates the screensaver bounding box.
     */
    @autobind
    updateBounds() {
        const bounds = {
            x: $win.width(),
            y: $win.height(),
        }
        this.setState({ bounds: bounds })
    }

    /**
     * Restarts the wait timer.
     */
    @autobind
    startWaiting() {
        const { wait } = this.props
        this.stopWaiting()
        this.waitTimer = delay(this.startAnimating, wait)
    }

    /**
     * Stops the wait timer and resets the hiding/animating state.
     */
    stopWaiting() {
        clearTimeout(this.waitTimer)
        this.setState({
            isAnimating: false,
            isHiding: false,
        })
    }

    /**
     * Starts the screensaver animation.
     */
    @autobind
    startAnimating() {
        this.setState({ isAnimating: true })
    }

    /**
     * Stops the screensaver animation and resets the wait timer.
     */
    @autobind
    stopAnimating() {
        clearTimeout(this.waitTimer)
        this.setState({ isHiding: true })

        delay(this.startWaiting, 1000)
    }

    /**
     * Triggered when all the screensaver images are preloaded
     * and ready to go. This will start the wait timer and
     * listen for mouse movement/screen resizes.
     */
    @autobind
    imagesReady() {
        this.updateBounds()
        this.startWaiting()

        // events
        $doc.on('mousemove.screensaver', this.__mousemove)
        $win.on('resize.screensaver', this.updateBounds)
    }

    componentDidMount() {
        const { images } = this.props
        if (!isEmpty(images)) {
            ImagePreloader.load(images).then(this.imagesReady)
        }
    }

    componentWillUnmount() {
        this.stopWaiting()
        $doc.off('.screensaver')
        $win.off('.screensaver')
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isAnimating !== this.state.isAnimating) {
            return true
        }
        if (nextState.isHiding !== this.state.isHiding) {
            return true
        }
        if (!isEqual(nextState.bounds, this.state.bounds)) {
            return true
        }
        return false
    }

    render() {
        const { images } = this.props
        const { bounds, isAnimating, isHiding } = this.state
        const totalItems = bounds.x ? Math.round(bounds.x / 100) : 0
        let className = css.containerEmpty
        if (isAnimating) className = css.container
        if (isHiding) className = css.containerHiding
        return (
            <div className={className}>
                {isAnimating
                    ? Array.apply(0, Array(totalItems)).map((item, index) => (
                          <ScreensaverItem key={index} bounds={bounds} images={images} isHiding={isHiding} />
                      ))
                    : null}
            </div>
        )
    }
}
