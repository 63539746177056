import { StoryResult, IStory } from 'shared/types/story'
import { PublicAPIError } from 'shared/api/errors'

export const storyPaginator = (fetchStories: (cursor: string) => Promise<StoryResult>) => {
    const stories: IStory[] = []
    // for deduping
    const storyIds: (string | number)[] = []
    let cursor
    let isDoneFetching = false
    return async () => {
        if (isDoneFetching) {
            return stories
        }
        const result = await fetchStories(cursor)
        const { pagination, data: newStories } = result
        if (!pagination) {
            throw new PublicAPIError('Error fetching story paginator (Method: storyPaginator)')
        }
        cursor = pagination.next_cursor
        newStories.forEach((story) => {
            const { story_id } = story
            if (!storyIds.includes(story_id)) {
                // add gifs and gifIds
                stories.push(story)
                storyIds.push(story_id)
            }
        })
        isDoneFetching = !cursor
        return [...stories]
    }
}
