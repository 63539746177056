import styled from 'styled-components'
import { getBetaEnvData } from 'shared/api'
import { useState, useMemo } from 'react'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { mobile } from 'shared/util/media-query'
import { liveDate } from 'shared/util/date'
import { useSessionStorage } from 'react-use'

const gifs = [
    'https://media.giphy.com/media/IyTG5Si67UEsE/giphy.gif',
    'https://media.giphy.com/media/S5JSwmQYHOGMo/giphy.gif',
    'https://media.giphy.com/media/7pJ8YSjtZavZu/giphy.gif',
    'https://media.giphy.com/media/XzHpp5jvHbQZrVDYeM/giphy.gif',
]

type Props = {
    mobile?: boolean
}

const BetaNotice = ({ mobile }: Props) => {
    // @ts-ignore
    const showModal = !!window?.BUILD_DISPLAY_ENABLED
    const [betaInfo, setBetaInfo] = useState<any | null>(null)
    useAsyncEffect(async () => {
        if (showModal) {
            const data = await getBetaEnvData()
            setBetaInfo(data)
        }
    }, [showModal])
    const randomGiphy = Math.floor(Math.random() * gifs.length)
    const [gif] = useSessionStorage(`BETA_RANDOM_GIF`, gifs[randomGiphy])

    const data = useMemo(() => {
        if (!betaInfo) {
            return []
        }
        return [
            'Giphy BETA',
            betaInfo?.branch,
            liveDate(betaInfo?.build_time * 1000, 'MM-DD-YYYY ppp'),
            betaInfo?.user,
            betaInfo?.desc,
            betaInfo?.version,
        ].filter((value) => !!value)
    }, [betaInfo])

    if (!showModal) {
        return null
    }

    if (mobile) {
        return (
            <MarqueeContainer>
                <Image src={gif} />
                <span>{data.join('  /  ')}</span>
            </MarqueeContainer>
        )
    }
    return (
        <Container>
            <Image src={gif} />
            {data.map((value) => (
                <p key={value}>{value}</p>
            ))}
        </Container>
    )
}

export default BetaNotice

const MarqueeContainer = styled.div`
    display: flex;
    justify-components: center;
    align-items: center;
    height: 40px;
    font-family: PTMono;
    font-size: 15px;
    font-weight: bold;
`

const Image = styled.img`
    width: 60px;
    height: auto;
    margin-bottom: 14px;
    ${mobile.css`
        height: 24px;
        width: auto;
        margin-bottom: 0;
        margin-right: 10px;
    `}
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    max-width: 225px;
    z-index: 2147483647;
    bottom: 0;
    left: 0;
    text-overflow: ellipsis;
    font-family: PTMono;
    font-size: 17px;
    margin-left: 25px;
    margin-bottom: 25px;
    p {
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
