import { getCreator, getUser, getUserChannel, setGADataLayer } from 'analytics'
import { DatePresetType } from './app'
import { getPageOptionsBasedOnPath } from 'analytics/src/utils/page'
import { IProfileUser } from 'types'

type ContentType = 'clip' | 'gif' | 'sticker' | 'story'
type ContentTypeEvent = 'change' | 'close' | 'error' | 'open'
type DownloadCSVEvent = 'close' | 'error' | 'open' | 'success'
type TimeRange = 'week' | 'month' | 'all' | 'custom'

export function getGA4RangeFromDatePreset(preset: DatePresetType): TimeRange {
    switch (preset) {
        case 'ALL_TIME':
            return 'all'
        case 'MONTH':
            return 'month'
        case 'WEEK':
            return 'week'
        default:
            return 'custom'
    }
}

export function getGA4ContentTypeFromString(type: string): ContentType {
    const formatted = type.trim().toUpperCase()

    switch (formatted) {
        case 'CLIPS':
            return 'clip'
        case 'STICKERS':
            return 'sticker'
        case 'STORIES':
            return 'story'
        default:
            return 'gif'
    }
}

export function getGA4ContentTypesFromString(types: string): ContentType[] {
    const formattedTypes = types.split('&').map(getGA4ContentTypeFromString)

    return formattedTypes.filter((t, i) => formattedTypes.indexOf(t) === i)
}

export function trackGA4ContentTypeEvent(event: ContentTypeEvent, contentTypes: ContentType[]) {
    const data = {
        event: `content_type_${event}`,
        options: {
            form: {
                formName: 'content type',
            },
            content: {
                contentType: contentTypes.join(','),
            },
        },
    }

    setGADataLayer(data)
}

export function trackGA4TimeRangeEvent(range: TimeRange, contentTypes: ContentType[]) {
    setGADataLayer({
        event: 'time_range_change',
        options: {
            content: {
                contentType: contentTypes.join(','),
                timeRange: `${range}`,
            },
        },
    })
}

export function trackGA4InsightToggleEvent(toggleTitle: string) {
    setGADataLayer({
        event: 'search_insights_toggle',
        options: {
            creator: {
                ...getCreator(),
                toggleTitle,
            },
        },
    })
}

export function trackGA4InsightTagEvent(tagName: string, contentType?: ContentType) {
    const data = {
        event: 'search_insights_tag',
        options: {
            creator: {
                ...getCreator(),
                tagName,
            },
        },
    }

    if (contentType) {
        data.options['content'] = {
            contentType,
        }
    }

    setGADataLayer(data)
}

export function trackGA4DownloadCSVEvent(event: DownloadCSVEvent, documentType?: string) {
    const data = { event: `download_csv_${event}` }

    if (documentType) {
        data['options'] = {
            creator: {
                ...getCreator(),
                documentType,
            },
        }
    }

    setGADataLayer(data)
}

export function trackGA4DashboardNavEvent(event: string, user: IProfileUser) {
    const data = {
        event: `dashboard_nav_${event}_click`,
        page: getPageOptionsBasedOnPath('/dashboard'),
        creator: getCreator(),
        channel: getUserChannel(user),
        user: getUser(),
    }

    setGADataLayer(data)
}

export function trackGA4DashboardEvent(event: string) {
    setGADataLayer({ event })
}
