import { giphyBlack, giphyBlue, giphyRed } from '@giphy/colors'
import { useContext } from 'react'
import { editChannel } from 'shared/api/channels'
import { Button } from 'shared/components/buttons'
import ChannelPageContext from 'shared/contexts/channel-page'
import MessageContext from 'shared/contexts/message'
import ModalContext from 'shared/contexts/modal'
import RefreshDataContext from 'shared/contexts/refresh-data'
import UserContext from 'shared/contexts/user'
import { useCanCurateCollection } from 'shared/hooks/use-permissions'
import styled from 'styled-components'

const Container = styled.div<{ color: string }>`
    width: 100%;
    display: flex;
    height: 68px;
    background: ${(props) => props.color};
    color: ${giphyBlack};
    padding: 20px;
    margin-bottom: 20px;
    align-items: center;
    .ss-info,
    .ss-lock {
        font-size: 32px;
        height: 33px;
    }
    ${Button} {
        min-width: 177px;
    }
`

const InnerContainer = styled.div`
    flex: 1;
    margin-left: 20px;
`
const Title = styled.div`
    font-size: 15px;
    font-weight: bold;
`

const Description = styled.div`
    font-size: 14px;
`
export const PrivateCollection = () => {
    const { channel, isOwner } = useContext(ChannelPageContext)
    const { refreshChannel } = useContext(RefreshDataContext)
    const { sendMessage } = useContext(MessageContext)
    const makePublic = async () => {
        try {
            const result = await editChannel({ ...channel, is_private: false })
            refreshChannel({ ...channel, ...result, user: channel.user })
            sendMessage(`${channel.display_name} is now public!`, 'success')
        } catch (error) {
            sendMessage(`There was an error updating ${channel.display_name}`, 'error')
        }
    }
    let isRootChannelPrivacy = !channel.parent || channel.ancestors[0]?.is_private
    if (channel.parent && channel.is_private) {
        // collectoin banner since this collection is private
        isRootChannelPrivacy = false
    }
    const description = isOwner
        ? isRootChannelPrivacy
            ? 'To make it public change your privacy settings'
            : 'To make it public change your privacy settings in “Edit Collection” or click the big button to the right.'
        : 'To make it shareable the owner must switch it to "Public"'
    return (
        <Container color={giphyRed}>
            <div className="ss-lock"></div>
            <InnerContainer>
                <Title>
                    {isOwner ? `Your` : `This`} {isRootChannelPrivacy ? 'channel' : 'collection'} is private
                </Title>
                <Description>{description}</Description>
            </InnerContainer>
            {isOwner && (
                <Button
                    color={giphyBlack}
                    onClick={
                        isRootChannelPrivacy
                            ? () => {
                                  location.href = '/settings'
                              }
                            : makePublic
                    }
                >
                    {isRootChannelPrivacy ? 'Settings' : 'Make Public'}
                </Button>
            )}
        </Container>
    )
}
export const EmptyCollection = () => {
    const { channel } = useContext(ChannelPageContext)
    const { user } = useContext(UserContext)
    const { openAddGifsToCollectionPanel } = useContext(ModalContext)
    const canAddGifs = useCanCurateCollection()
    return canAddGifs ? (
        <Container color={giphyBlue}>
            <div className="ss-info"></div>
            <InnerContainer>
                <Title>This {channel.parent ? 'collection' : 'channel'} is empty</Title>
                <Description>You should add at least a few GIFs to it</Description>
            </InnerContainer>
            <Button color={giphyBlack} onClick={() => openAddGifsToCollectionPanel(channel.id, user.channelId)}>
                Add Gifs
            </Button>
        </Container>
    ) : null
}
