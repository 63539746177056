import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PillsSlider from '../pills-slider'
import React from 'react'
import { Title, Copy, Content } from './common-styles'
import VideoPlayOnHover from 'shared/components/video-inline-play'
import { useWindowSize } from 'react-use'
import { ambarbecutie, hbo, richiebrowntown, samsung } from './clips-assets'

const Container = styled.div`
    padding: 10px 0px 0px 10px;
    z-index: 2;
    width: 900px;
    height: 426px;

    background: #121212;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 800px;
        height: 320px;
    }
`

const Emoji = styled.img`
    position: relative;
    height: 174px;
    top: -47px;
`

const Icon = styled.img`
    width: 25px;
    height: 25px;
    margin-right: 9px;
`
const StickerIcon = styled(Icon)`
    width: 28px;
    height: 28px;
`

const ClipIcon = styled(Icon)`
    width: 28px;
    height: 28px;
`

const GifIcon = styled(Icon)`
    width: 28px;
    height: 28px;
`

const TypeName = styled.h1`
    width: 97px;
    height: 38px;

    font-family: 'nexablack';
    font-style: normal;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    display: flex;
    align-items: flex-end;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

const TypeDescription = styled.h4`
    width: 283px;
    height: 25px;
    margin: 8px 0px;

    /* P – Large/Bold */

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    /* identical to box height, or 147% */

    display: flex;
    align-items: flex-end;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

const TypeDetails = styled.span`
    width: 300px;
    height: 100px;

    /* P – Large/Regular */

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    /* or 147% */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        font-size: 15px;
    }
`
const TypeLink = styled.a`
    display: inline-block;
    width: 300px;
    height: 100px;

    /* P – Large/Regular */

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    /* or 147% */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    color: #00ccff;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        font-size: 15px;
    }
`

const ContentBlock = styled.div`
    display: inline-block;
    width: 39%;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 53%;
    }
`
const GalleryBlock = styled.div`
    display: inline-block;
    width: 55%;
    float: right;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 47%;
    }
`

const AssetContainer = styled.div`
    display: inline-block;
    height: 188px;
    width: 226px;
    margin: 5px;
    background-image: linear-gradient(
            45deg,
            rgb(34, 34, 34) 25%,
            transparent 25%,
            transparent 75%,
            rgb(34, 34, 34) 75%,
            rgb(34, 34, 34) 100%
        ),
        linear-gradient(
            45deg,
            rgb(34, 34, 34) 25%,
            transparent 25%,
            transparent 75%,
            rgb(34, 34, 34) 75%,
            rgb(34, 34, 34) 100%
        );
    background-position: 0px 0px, 19px 19px;
    background-size: 38px 38px;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 168px;
        height: 140px;
    }
`

const Asset = styled.img`
    margin: 5px;
    display: inline-block;
    height: 188px;
    width: 226px;
    border-radius: 4px;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 168px;
        height: 140px;
    }
`
const Sticker = styled.img`
    display: inline-block;
    height: 188px;
    width: 226px;
    border-radius: 4px;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 168px;
        height: 140px;
    }
`

const Video = styled.div`
    margin: 5px;
    display: inline-block;
    height: 188px;
    width: 226px;
    border-radius: 4px;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 168px;
        height: 140px;
    }
`

const StyledPillsSlider = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        margin-top: 20px;
    }
`

const GifDescription = () => {
    return (
        <div style={{ padding: '35px' }}>
            <TypeName>
                <GifIcon src="/static/img/content-type-icons/gif.png" />
                GIFs
            </TypeName>
            <TypeDescription>A short looping animated image</TypeDescription>
            <TypeDetails>
                Great GIFs are between 3-6 seconds long. They are playful, communicate emotion, and are useful in
                conversation.
            </TypeDetails>
            <TypeLink
                href="https://support.giphy.com/hc/en-us/articles/360019914771-GIF-Creation-Best-Practices"
                target="_blank"
            >
                See more best practices
            </TypeLink>
        </div>
    )
}

const StickersDescription = () => {
    return (
        <div style={{ padding: '35px' }}>
            <TypeName>
                <StickerIcon src="/static/img/content-type-icons/sticker.png" />
                Stickers
            </TypeName>
            <TypeDescription>A GIF with a transparent background</TypeDescription>
            <TypeDetails>
                Great stickers are simple and eye-catching, with sharp and bold text at small sizes. Effects and
                accessories are useful for layering in messaging scenarios.
            </TypeDetails>
            <TypeLink
                href="https://support.giphy.com/hc/en-us/articles/360019683472-Sticker-Best-Practices"
                target="_blank"
            >
                See more best practices
            </TypeLink>
        </div>
    )
}

const ClipsDescription = () => {
    return (
        <div style={{ padding: '35px' }}>
            <TypeName>
                <ClipIcon src="/static/img/content-type-icons/clip.png" />
                Clips
            </TypeName>
            <TypeDescription>A short looping video with sound</TypeDescription>
            <TypeDetails>
                Clips are between 5 - 30 seconds. When creating video Clips, focus on short, shareable iconic moments,
                actions or highlights that people would want to send to each other.
            </TypeDetails>
            <TypeLink
                href="https://support.giphy.com/hc/en-us/articles/360045679212-GIPHY-Clips-Best-Practices"
                target="_blank"
            >
                See more best practices
            </TypeLink>
        </div>
    )
}

const GifAssets = () => {
    return (
        <>
            <Asset src="https://media2.giphy.com/media/J1j6wzyprIjN0dFZoc/200w.gif" />
            <Asset src="https://media2.giphy.com/media/uqNMBVCICHVTbG2m9L/200w.gif" />
            <Asset src="https://media2.giphy.com/media/KVhdX2JiPr0JRK0mDj/200w.gif" />
            <Asset src="https://media2.giphy.com/media/4SrjDgHTdYJcy9eU1u/200w.gif" />
        </>
    )
}

const StickersAssets = () => {
    return (
        <>
            <AssetContainer>
                <Sticker src="https://media2.giphy.com/media/O8CSTLyHv4HgJwyRLn/200w.gif" />
            </AssetContainer>
            <AssetContainer>
                <Sticker src="https://media2.giphy.com/media/UrD6rzKm97gSM09EPZ/200w.gif" />
            </AssetContainer>
            <AssetContainer>
                <Sticker src="https://media2.giphy.com/media/aGOMiVZHBcRVFVYe5A/200w.gif" />
            </AssetContainer>
            <AssetContainer>
                <Sticker src="https://media2.giphy.com/media/TvNa6lOfIXu7uUGQ4F/200w.gif" />
            </AssetContainer>
        </>
    )
}

const ClipsAssets = () => {
    const { width, height } = useWindowSize()
    let clipsWidth = 226
    let clipsHeight = 188
    if (width < 1300 || height < 880) {
        clipsWidth = 168
        clipsHeight = 140
    }
    return (
        <>
            <Video>
                <VideoPlayOnHover gif={samsung} width={clipsWidth} height={clipsHeight} />
            </Video>
            <Video>
                <VideoPlayOnHover gif={hbo} width={clipsWidth} height={clipsHeight} />
            </Video>
            <Video>
                <VideoPlayOnHover gif={ambarbecutie} width={clipsWidth} height={clipsHeight} />
            </Video>
            <Video>
                <VideoPlayOnHover gif={richiebrowntown} width={clipsWidth} height={clipsHeight} />
            </Video>
        </>
    )
}

const contentTypes = [
    {
        name: 'GIFs',
        description: GifDescription,
        assets: GifAssets,
        gradient: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%);',
    },
    {
        name: 'Stickers',
        description: StickersDescription,
        assets: StickersAssets,
        gradient: 'linear-gradient(240.25deg, #00FF99 7.79%, #00CCFF 92.21%);',
    },
    {
        name: 'Clips',
        description: ClipsDescription,
        assets: ClipsAssets,
        gradient: 'linear-gradient(232.84deg, #9933FF 0%, #6157FF 100%);',
    },
]

export const ExpandYourReach = () => {
    const [type, setType] = useState(0)
    const [animatedOpacity, setAnimatedOpacity] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setAnimatedOpacity(100)
        }, 200)
    }, [])

    const { description, assets } = contentTypes[type]
    return (
        <>
            <Emoji src="https://media2.giphy.com/media/3ohzdIC9neHjucxQB2/200w.gif" />
            <Content style={{ opacity: animatedOpacity }}>
                <Title>Expand your reach</Title>
                <Copy>
                    We have three different formats of short form content: GIFs, Stickers & Clips. Each format has their
                    own unique use cases and best practices.
                </Copy>
            </Content>
            <Container>
                <ContentBlock>
                    <StyledPillsSlider>
                        <PillsSlider
                            allOptions={contentTypes}
                            currentValue={contentTypes[type].name}
                            setValue={setType}
                        />
                    </StyledPillsSlider>
                    {React.createElement(description)}
                </ContentBlock>
                <GalleryBlock>{React.createElement(assets)}</GalleryBlock>
            </Container>
        </>
    )
}
