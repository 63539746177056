'use client'

import { Result } from '@giphy/js-fetch-api'
import { useCallback, useEffect, useRef, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
interface EndpointResult<T> {
    results: T[]
    next: string | null
}
export interface GenericResult<T> extends Result {
    data: T[]
}

// the channel endpoint works with offset, so just ignore the next and use offset
export const convertToPagination = <T>(result: EndpointResult<T>, offset: number) => {
    const { results: data = [] } = result
    const count = data.length
    return {
        data,
        pagination: {
            count,
            total_count: Infinity,
            offset,
        },
        meta: {
            msg: '',
            response_id: '',
            status: 200,
        },
    }
}

/**
 * fetch gifs by tracking gifs in state
 * allowing use of offset based on gifs.length and also knowing when
 * fetching is done regardless if channel api or public api
 */
const useFetchData = <T>({
    initialData = [],
    fetchData,
    triggerFetch = false,
    checkDone,
}: {
    initialData?: T[]
    fetchData: (offset: number) => Promise<GenericResult<T>>
    triggerFetch?: boolean
    // optional checkDone function to determine if we're done fetching
    checkDone?: (data?: GenericResult<T>) => boolean
}) => {
    // put everything in a ref so doFetch can retain referential equality
    const gifs = useRef<T[]>(initialData)
    const [, updateState] = useState<any>()
    const forceUpdate = useCallback(() => updateState({}), [])
    const doneFetching = useRef<boolean>(!!checkDone?.())
    const isFetching = useRef(false)
    const fg = useRef(fetchData)
    const doFetch = useCallback(async () => {
        if (doneFetching.current || isFetching.current) {
            return
        }
        isFetching.current = true
        let result = await fg.current.apply(fg, [gifs.current.length])
        // check if we're using the channel endpoint with results instead of data
        const channelEndpoint = result as unknown as EndpointResult<T>
        if (channelEndpoint.results) {
            result = convertToPagination<T>(channelEndpoint, gifs.current.length)
        }
        const { data, pagination } = result
        const allGifs = [...gifs.current, ...data]
        // if we use the public api we won't have to make an extra request
        const regularAPIDone = allGifs.length === pagination.total_count
        // we will have had to make an extra request to get an empty response
        const channelAPIDone = !data.length

        doneFetching.current = regularAPIDone || channelAPIDone || !!checkDone?.(result)
        gifs.current = [...allGifs]
        isFetching.current = false
        // updating refs won't trigger a render
        forceUpdate()
    }, [])

    // check if we're still in view after fetching some content
    useDebounce(
        () => {
            if (triggerFetch) doFetch()
        },
        100,
        [gifs.current.length]
    )
    // optional triggerFetch property will trigger a fetch
    useEffect(() => {
        if (triggerFetch) {
            doFetch()
        }
    }, [triggerFetch, doFetch])

    return { doneFetching: doneFetching.current, doFetch, gifs: gifs.current, isFetching: isFetching.current }
}

export default useFetchData
