import { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { omit } from 'lodash'

@withRouter
/**
 * GD is global data
 * We want to take the window.GD and put it into our browser location state
 */
class GDBootstrapper extends PureComponent {
    componentWillMount() {
        const {
            initData = {},
            location: { pathname, search = '' },
            history: { replace },
        } = this.props
        // gifs is too big for firefox pushState, and we don't need it
        // since it goes in redux
        const initDataNoReduxStuff = omit(initData, ['gifs'])
        // replace the current location with this new location that has our GD
        replace(`${pathname}${search}`, initDataNoReduxStuff)
    }

    render() {
        return this.props.children
    }
}

export default GDBootstrapper
