import { PureComponent } from 'react'
import { connect } from 'react-redux'
import log from 'shared/util/log'
import withLocationState from 'shared/components/with-location-state'
import { message } from 'shared/redux/message'

@withLocationState(['formErrors', 'form'])
@connect(undefined, { message })
class FormErrors extends PureComponent {
    componentDidMount() {
        const { formErrors, message, form } = this.props

        if (formErrors) {
            log.warn(`form errors:`, formErrors)
            const [firstKey] = Object.keys(formErrors)
            if (firstKey) {
                const [error] = formErrors[firstKey]
                if (error) {
                    message(error.message, 'error')
                }
            }
        }

        if (form && form.errors) {
            log.warn(`form errors:`, form.errors)
            const [firstKey] = Object.keys(form.errors)
            if (firstKey) {
                const [error] = form.errors[firstKey]
                if (error) {
                    message(error.message, 'error')
                }
            }
        }
    }
    render() {
        return null
    }
}

export default FormErrors
