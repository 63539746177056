import styled from 'styled-components'
import { Button } from 'shared/components/buttons'
import OnboardingProgressBar from './progress-bar'

const Wave = styled.svg`
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 1296px;
    @media (max-width: 1300px), (max-height: 880px) {
        width: 1064px;
        // bottom: -13px;
    }
`

export const Nav = styled.button`
    background: none;
    line-height: inherit;
    height: auto;
    width: auto;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    i {
        font-size: 12px;
    }
`

export const NavText = styled.span`
    font-size: 18px;
    font-weight: bold;
    opacity: 0.7;
`

export const NextButton = styled(Button)`
    display: inline-block;
    margin: 0px 20px 0px 0px;
    position: relative;
    bottom: 30px;
    right: 35px;
    height: 45px;

    i {
        margin-top: 2px;
        margin-left: 14px;
    }
    background: rgba(232, 230, 255, 0.9);
    border-radius: 4px;
    color: #5d5fef;
    z-index: 1000;

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    transition: all 0.2s ease-in;
    &:hover {
        background: rgba(255, 255, 255, 0.9);
        color: #5d5fef;
    }
`

export const SkipButton = styled(Button)`
    position: absolute;
    width: 96px;
    height: 45px;
    left: 39px;
    bottom: 25px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border-radius: 4px;
    color: rgba(232, 230, 255, 0.9);
    z-index: 1000;

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    transition: all 0.2s ease-in;
    &:hover {
        color: rgba(255, 255, 255, 1);
    }
`

export const PrevButton = styled(Button)`
    display: inline-block;
    margin: 0 20px;
    position: relative;
    bottom: 30px;
    right: 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border-radius: 4px;
    color: rgba(232, 230, 255, 0.9);
    z-index: 12;

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    transition: all 0.2s ease-in;
    &:hover {
        color: rgba(255, 255, 255, 1);
    }
`

const StyledProgressBar = styled(OnboardingProgressBar)`
    position: absolute;
    bottom: 0;
    z-index: 1;
`

const NextBlock = styled.div`
    position: absolute;
    // width: 96px;
    // height: 45px;
    right: -25px;
    bottom: 0px;
    z-index: 1000;
`

export const Footer = ({ step, setStep, setShowSkipModal, endOnboarding }) => {
    const START_STEP = 1
    const END_STEP = 6
    return (
        <>
            <Wave height="273" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 273V39.9578C195.118 1.34087 295.864 12.1352 442.84 39.9578C589.816 67.7803 600.4 73.4794 811.076 73.4794C1021.75 73.4794 1222.14 24.4931 1296 0V273H0Z"
                    fill="url(#paint0_linear_274_4127)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_274_4127"
                        x1="-1.1115e-05"
                        y1="214.515"
                        x2="209.714"
                        y2="-345.736"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#6157FF" />
                        <stop offset="1" stopColor="#E646B6" />
                    </linearGradient>
                </defs>
            </Wave>
            <NextBlock>
                {step > START_STEP && <PrevButton onClick={() => setStep(step - 1)}>Previous</PrevButton>}
                {step === END_STEP && (
                    <NextButton onClick={() => endOnboarding()}>
                        Go to your channel
                        <i className="ss-navigateright" />
                    </NextButton>
                )}
                {step < END_STEP && (
                    <NextButton onClick={() => setStep(step + 1)}>
                        Next
                        <i className="ss-navigateright" />
                    </NextButton>
                )}
            </NextBlock>
            {step < END_STEP && <SkipButton onClick={() => setShowSkipModal(true)}>Skip</SkipButton>}
            {step == END_STEP && <SkipButton onClick={endOnboarding}>Skip</SkipButton>}
            <StyledProgressBar progress={step / END_STEP} />
        </>
    )
}
