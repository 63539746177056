import styled from 'styled-components'
import { Copy, Emoji, Title, Content } from './common-styles'

const Video = styled.video`
    height: 431px;
    width: 1296px;
    // object-fit: cover;
    z-index: 2;

    @media screen and (max-width:  1300px), (max-height: 880px)  {
        object-fit: contain;
        width: 1063px;
        height: 295px;
        margin-top: 20px;
    }
}
`

export const WelcomeScreen = () => {
    return (
        <>
            <Emoji src="https://media2.giphy.com/media/S3nZ8V9uemShxiWX8g/200w.gif" />
            <Content style={{ opacity: 100 }}>
                <Title>Congratulations!</Title>
                <Copy>
                    Welcome to our upgraded community! Your content is now discoverable in our search and trending
                    feeds, which get billions of views across the world’s biggest messaging and social apps. Let’s walk
                    you through the resources and tools that you now have access to.
                </Copy>
                <Video autoPlay loop muted playsInline>
                    <source src="/static/img/upgraded_onboarding/welcome.mp4" type="video/mp4"></source>
                </Video>
            </Content>
        </>
    )
}
