import { giphyCharcoal, giphyLightGrey, giphyWhite } from '@giphy/colors'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mobile.css`
        flex-direction: row;
        &:nth-of-type(1) {
            padding-right: 15px;
        }
    `}
`

const Statistic = styled.h1<{ isPublic: boolean }>`
    color: ${(props) => (!props.isPublic ? `${giphyCharcoal}` : `${giphyWhite}`)};
    font-family: Nexablack;
    font-size: 22px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    -webkit-font-smoothing: antialiased;
    ${mobile.css`
        flex-direction: row;
        display: flex;
        font-size: 16px;

    `}
`

const AnalyticsSubheader = styled.p`
    color: ${giphyLightGrey};
    font-family: interface;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
    margin-top: 0px;
    -webkit-font-smoothing: antialiased;
    ${mobile.css`
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        padding-left: 0.4em;
        display: flex;
    `}
`

type Props = {
    stat: string
    title: string
    isPublic: boolean
}

const ChannelStatistics = ({ stat, title, isPublic }: Props) => {
    return (
        <Container>
            <Statistic isPublic={isPublic}>{stat}</Statistic>
            <AnalyticsSubheader>{title}</AnalyticsSubheader>
        </Container>
    )
}

export default ChannelStatistics
