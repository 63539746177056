import { GifsResult } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { Gif, Loader } from '@giphy/react-components'
import { useEffect, useState } from 'react'
import Observer from 'shared/components/observer'
import styled from 'styled-components'

const GifContainer = styled.div`
    margin-bottom: 30px;
    width: 480px;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

type Props = {
    className?: string
    fetchGifs: (offset: number) => Promise<GifsResult>
}

const Feed = ({ className, fetchGifs }: Props) => {
    const [gifs, setGifs] = useState<IGif[]>([])
    const doFetch = async (offset: number) => {
        const { data } = await fetchGifs(offset)
        setGifs(data)
    }
    useEffect(() => {
        doFetch(0)
    }, [])
    return (
        <Container className={className}>
            {gifs.map((gif) => (
                <GifContainer key={gif.id}>
                    <Gif gif={gif} width={480} />
                </GifContainer>
            ))}
            {gifs.length > 0 && (
                <Observer onVisible={() => doFetch(gifs.length - 1)} key={gifs.length}>
                    <Loader />
                </Observer>
            )}
        </Container>
    )
}

export default Feed
