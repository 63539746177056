import { giphyBlue, giphyGreen } from '@giphy/colors'
import { useContext } from 'react'
import { AccountsManagerContext } from 'shared/contexts/accounts-manager'
import UserContext from 'shared/contexts/user'
import routes from 'shared/routes'
import isMobile from 'shared/util/is-mobile'
import styled, { css } from 'styled-components'
import { DropdownButton, SelectList } from 'ui'

const StyledDropdownButton = styled(DropdownButton)`
    position: relative;
    width: 215px;

    ${() =>
        isMobile() &&
        css`
            width: 68px;
            margin-left: 10px;
        `}

    button {
        padding: 0;
    }
`

const Label = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`

const LabelImage = styled.img`
    height: 34px;
    width: 34px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    margin-right: 10px;
    object-fit: cover;
`

const StyledSelectList = styled(SelectList)`
    overflow-y: scroll;
    max-height: 300px;

    ul {
        padding: 15px;
    }

    li {
        &:not(:first-child) {
            margin-top: 10px;
        }
    }
`

const ListItem = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`

const ListImage = styled.img`
    height: 28px;
    width: 28px;
    border-radius: 2px;
    margin-right: 10px;
    object-fit: cover;
`

const DashboardAccessPicker = () => {
    const { dashboard_channel_access, dashboard_user, channel_access } = useContext(AccountsManagerContext)
    const { user } = useContext(UserContext)

    if (dashboard_channel_access && dashboard_channel_access?.length > 0) {
        const selectedIndex = dashboard_channel_access.findIndex(
            (access) => access.channel.id === dashboard_user?.channel_id
        )

        const mainUser = isMobile()
            ? [
                  <>
                      <ListItem>
                          <ListImage src={user.avatarURL} />
                          {user.displayName || user.username}
                      </ListItem>
                  </>,
              ]
            : [
                  <>
                      <ListItem>
                          <ListImage src={channel_access?.user.avatar_url} />
                          {channel_access?.user.display_name || channel_access?.user.username}{' '}
                      </ListItem>
                  </>,
              ]

        const dashboardUsers = dashboard_channel_access.map((access) => (
            <>
                <ListItem>
                    <ListImage src={access.channel.avatar_url} />
                    <span>{access.channel.display_name}</span>
                </ListItem>
            </>
        ))

        return (
            <StyledDropdownButton
                label={
                    <>
                        <Label>
                            <LabelImage src={dashboard_user?.avatar_url} />
                            <span>{!isMobile() && (dashboard_user?.display_name || dashboard_user?.username)}</span>
                        </Label>
                    </>
                }
                iconButtonOptions={{
                    iconColor: giphyBlue,
                }}
            >
                <StyledSelectList
                    defaultIndex={selectedIndex + 1}
                    options={[...mainUser, ...dashboardUsers]}
                    onChange={(index) => {
                        if (index === 0) {
                            window.location.href = routes.DASHBOARD.path
                        } else {
                            const dashboard_channel = dashboard_channel_access[index - 1]
                            window.location.href = dashboard_channel.dashboard_url
                        }
                    }}
                    iconColor={giphyGreen}
                />
            </StyledDropdownButton>
        )
    }

    return <></>
}

export default DashboardAccessPicker
