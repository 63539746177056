import { Button as BrandButton } from 'shared/components/buttons'
import BrandTooltip from 'shared/components/ui/tooltip'
import { useContext } from 'react'
import { useLocalStorage } from 'react-use'
import styled from 'styled-components'
import ChannelPageContext from 'shared/contexts/channel-page'
import ModalContext from 'shared/contexts/modal'
import UserContext from 'shared/contexts/user'
import { Copy } from './sc'

const Button = styled(BrandButton)`
    position: relative;
`

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    ${Copy} {
        margin-bottom: 12px;
        text-align: center;
    }
`

const Tooltip = styled(BrandTooltip)``

const IntroContainer = styled.div`
    font-weight: normal;
    div {
        font-weight: bold;
    }
`

type Props = { isEdit?: boolean }
export const CollectionCTAButton = ({ isEdit }: Props) => {
    const [hasSeen, setHasSeen] = useLocalStorage('has-seen-collection-cabinet-tooltip', false)
    const { channel } = useContext(ChannelPageContext)
    const { user } = useContext(UserContext)
    const { openNewCollectionPanel, openCollectionCabinet } = useContext(ModalContext)
    const copy = isEdit ? `Edit Collections` : `Create New Collection`
    const IntroCopy = () => (
        <IntroContainer>
            <div>✨Introducing Collections✨</div>Organize your channel into collections. <br />
            Click “{copy}” to get started!
        </IntroContainer>
    )

    const DoIt = () => (
        <Button
            onClick={() => {
                setHasSeen(true)
                if (isEdit) {
                    openCollectionCabinet(channel.id)
                } else {
                    openNewCollectionPanel({ parentChannel: channel, userId: user.id, redirectOnCreate: true })
                }
            }}
        >
            {copy}
        </Button>
    )
    return hasSeen ? (
        <DoIt />
    ) : (
        <Tooltip label={<IntroCopy />} maxWidth={400} offsetY={6}>
            <DoIt />
        </Tooltip>
    )
}

const CreateNewCollection = () => {
    return (
        <Container>
            <Copy>
                You don&apos;t have any collections yet!
                <br />
                Make your first by clicking the button below.
            </Copy>
            <CollectionCTAButton />
        </Container>
    )
}

export default CreateNewCollection
