import styled from 'styled-components'

const PlanningBanner = styled.div`
    margin-top: 20px;
    padding-top: 100px;
    position: relative;
`

const Figures = styled.img`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: red;
        width: 100%;
        height: 8em;
        border-radius: 12px;
    }
`

const Heading = styled.h2`
    text-align: center;
    font-family: NexaBlack;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 130%;

    position: absolute;
    top: 60%;
    left: 37%;

    background: -webkit-linear-gradient(53deg, #6157ff 0%, #0cf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const Subheading = styled.h3`
    overflow: hidden;
    color: #5c5c5c;
    text-align: center;
    text-overflow: ellipsis;

    position: absolute;
    width: 618px;
    top: 64.5%;
    left: 11.6%;

    font-family: InterFace;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
`

export const NextCampaignBanner = () => {
    return (
        <PlanningBanner>
            <Figures src="/static/img/content_calendar/campain-banner.svg" width="770" height="483" />
            <Heading>Content Planner</Heading>
            <Subheading>
                Create GIFs, Stickers and Clips with purpose using our Content Planner. Connect with users by uploading
                for high impact keywords and trending events on GIPHY throughout the year.
            </Subheading>
        </PlanningBanner>
    )
}
