import * as colors from '@giphy/colors'
import { capitalize, isEmpty } from 'lodash'
import qs from 'query-string'
import { useContext, useEffect } from 'react'
import { UsernameBadge } from 'shared/components/users'
import UserContext from 'shared/contexts/user'
import { desktop, mobile } from 'shared/util/media-query'
import resizeAvatar from 'shared/util/resize-avatar'
import { removeWords } from 'shared/util/string'
import styled from 'styled-components'
import ChannelListLiveHeader from '../channel-live-logo/live-header'
import Description from './channel-description'
import Search from './search'
import { StickerEmbedButton } from './sticker-embed-modal'
import Tags from './tags'

const IMAGE_TYPES = ['gif', 'gifs', 'sticker', 'stickers']

const Container = styled.div`
    color: ${colors.giphyWhite};
    padding-right: 40px;
    text-align: left;
    display: flex;
    flex-direction: column;
    ${mobile.css`
        align-items: flex-start;
        margin-left: 5px;
    `};
`

const Header = styled.div<{ hasBanner: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    position: relative;
    min-height: 65px;
    min-height: ${(props) => (props.hasBanner ? `53px` : `65px`)};
    ${desktop.css`
        width: 100%;
    `};
`

const Avatar = styled.div<{ hasBanner: boolean }>`
    height: 208px;
    width: 208px;
    margin-bottom: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: relative;
    ${mobile.css`
        height: 80px;
        width: 80px;
        margin: 0px 14px 10px 0px;
    `}
`

const ActionsDiv = styled.div<{ isFreelance: boolean }>`
    display: flex;
    flex-flow: column nowrap;
    border-top: 2px solid ${colors.giphyDarkGrey};
    margin-bottom: 30px;
    margin-top: ${(props) => (props.isFreelance ? `30px` : `0px`)};
`

const TitleSmall = styled.h2<{ hasBanner?: boolean }>`
    line-height: 28px;
    margin: 0 0 2px;
    padding-top: ${(props) => (props.hasBanner ? `0px` : `10px`)};
    font-size: 26px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

const LiveMobileTitleDiv = styled.div`
    margin-bottom: auto;
    svg {
        height: 18px;
        margin-bottom: -5px;
        position: relative;
        top: -5px;
        width: 64px;
    }
`

const HireMeButton = styled.button`
    background: linear-gradient(45deg, ${colors.giphyIndigo} 0%, ${colors.giphyPurple} 100%);
    border-radius: 1px;
    box-shadow: 0px 10px 80px 0px rgb(0, 0, 0);
    height: 36px;
    width: 208px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin: 0px 0px 0px 10px;
        line-height: 36px;
    }
    *:first-child {
        // 'ss-mail' icon
        height: 36px;
    }
    &:hover {
        filter: brightness(1.1);
    }
`

const Actions = ({ contentType, isFreelance }) => (
    <ActionsDiv isFreelance={isFreelance}>{contentType === 'sticker' ? <StickerEmbedButton /> : null}</ActionsDiv>
)

const LiveMobileTitle = ({ displayName }) => (
    <LiveMobileTitleDiv>
        <ChannelListLiveHeader isMobile={true} />
        <TitleSmall>{removeWords(displayName, IMAGE_TYPES)}</TitleSmall>
    </LiveMobileTitleDiv>
)

const handleHireButtonClick = (showModal, artistUsername, isLoggedIn) => {
    // If user is not logged in, bounce them to the login page with url parameters bringing them back here on complete.
    if (!isLoggedIn) {
        document.location.href = 'login?hire=true&next=/' + artistUsername + '?hire=true'
    } else {
        showModal()
    }
}

type ChannelInfoProps = {
    isOwner: boolean
    channel: any
    search: string
    hideDescription?: boolean
    isCompact?: boolean
    isTagView: boolean
    title?: string // optionally override channel title stuff
    children?: any
    showModal?: () => void
}

const Info = ({
    isOwner,
    channel,
    search,
    hideDescription,
    isCompact,
    isTagView,
    title,
    children,
    showModal,
}: ChannelInfoProps) => {
    const {
        tags,
        user,
        is_live: isLive,
        banner_image,
        ancestors,
        display_name: channelDisplayName,
        short_display_name: shortDisplayName,
        content_type: contentType,
    } = channel
    const { user: loggedInUser } = useContext(UserContext)
    const { loggedIn } = loggedInUser
    const dashboardLink = loggedInUser?.permissions?.dashboard_analytics ?? false
    const { avatar, username, is_freelance: isFreelance } = user
    const canSearch = user && user.user_type !== 'user'
    const mobileTitle = title || shortDisplayName || channelDisplayName || username
    const showBreadcrumbs = hideDescription && isCompact

    useEffect(() => {
        const hireParam = qs.parse(location.search)
        // If the hire parameter is true and the user is logged in, open the for hire modal. This is used when a user is redirected back from the login page after clicking 'for hire'.
        if (hireParam?.hire === 'true') {
            handleHireButtonClick(showModal, username, loggedIn)
        }
    }, [])

    return (
        <Container>
            <Header hasBanner={!!banner_image}>
                {avatar ? (
                    <a href={`${channel.user.profile_url}`}>
                        <Avatar
                            hasBanner={!!banner_image}
                            style={{
                                backgroundImage: `url(${resizeAvatar(avatar, isCompact ? 80 : 170)})`,
                            }}
                        />
                    </a>
                ) : null}
                <div>
                    {isCompact &&
                        (isLive ? (
                            <LiveMobileTitle displayName={mobileTitle} />
                        ) : (
                            <TitleSmall hasBanner={!!banner_image}>
                                {search
                                    ? `${removeWords(capitalize(search), IMAGE_TYPES)} ${
                                          contentType === 'sticker' ? 'Stickers' : 'GIFs'
                                      }`
                                    : mobileTitle}
                            </TitleSmall>
                        ))}
                    {isCompact && <UsernameBadge user={user} />}
                </div>
            </Header>
            {!hideDescription ? (
                <Description
                    isOwner={isOwner}
                    ancestors={ancestors}
                    mobileTitle={mobileTitle}
                    user={user}
                    isCompact={isCompact}
                    shortDisplayName={shortDisplayName}
                    search={search}
                    showBreadcrumbs={showBreadcrumbs}
                    dashboardLink={dashboardLink}
                />
            ) : null}
            {!isCompact ? (
                <div>
                    {/* Note: All users can now see the 'for hire' button. */}
                    {isFreelance && (
                        <HireMeButton onClick={() => handleHireButtonClick(showModal, username, loggedIn)}>
                            <div className="ss-mail" />
                            <p>Hire Me</p>
                        </HireMeButton>
                    )}
                    <Actions contentType={contentType} isFreelance={isFreelance} />
                    {canSearch && (
                        <Search
                            user={user}
                            channel={channel}
                            query={search}
                            isTagView={isTagView}
                            contentType={contentType}
                        />
                    )}
                </div>
            ) : null}
            {!isEmpty(tags) ? (
                <Tags channel={channel} search={search} isCompact={isCompact} isTagView={isTagView} />
            ) : null}
            {children}
        </Container>
    )
}

export default Info
