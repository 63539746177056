import { useContext, useState } from 'react'
import Info from 'shared/components/channel-info/info'
import Screensaver from 'shared/components/screensaver/screensaver'
import { VideoPreviewChannelGridByUsername } from 'shared/components/video-preview-grid'
import ChannelPageContext from 'shared/contexts/channel-page'
import { getUrl } from 'shared/util/gif-extract'
import { grid3 } from 'shared/util/grid'
import { WhenInView } from 'shared/util/in-view'
import ChannelBanner from './channel-banner'
import ForHireModal from './for-hire-modal'
import BulkEditLauncher from './gifs/bulk-edit-launcher'
import ChannelHeader from './header'
import { Container, Content, ContentContainer, Sidebar } from './sc'

const Clips = ({ deepRefreshKey }) => {
    const { isOwner, channel, isTagView, searchQuery: query } = useContext(ChannelPageContext)
    const { screensaver_gif, user } = channel
    const [isModalActive, setIsModalActive] = useState<boolean>(false)
    const screensaverUrl = getUrl(screensaver_gif, 'fixed_height') || ''
    // This isn't a channel anymore, but we're using root channel data to render the page
    // and it's parent is the root channel
    channel.ancestors = [channel]
    const showModal = () => {
        // Set (or unset) locked grid scrolling
        if (isModalActive) {
            document.body.style.overflow = ''
        } else {
            document.body.style.overflow = 'hidden'
        }
        setIsModalActive(!isModalActive)
    }
    return (
        <Container>
            <BulkEditLauncher />
            <ChannelBanner />
            <ContentContainer>
                {isModalActive ? <ForHireModal user={user} deactivateModal={showModal} /> : null}
                <Sidebar>
                    <Info
                        channel={channel}
                        isOwner={isOwner}
                        search={query}
                        isTagView={isTagView}
                        showModal={showModal}
                    ></Info>
                </Sidebar>
                <Content>
                    <ChannelHeader overrideTitle="Clips" />
                    <div key={deepRefreshKey}>
                        <WhenInView dontUnmount>
                            <VideoPreviewChannelGridByUsername previewWidth={grid3} hideAttribution />
                        </WhenInView>
                    </div>
                </Content>
                {screensaverUrl ? <Screensaver images={[screensaverUrl]} /> : null}
            </ContentContainer>
        </Container>
    )
}
export default Clips
