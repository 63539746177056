import styled, { keyframes } from 'styled-components'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { Button } from 'shared/components/buttons'
import Modal from 'shared/components/modal/modal'
import useLocalStorage from 'react-use/lib/useLocalStorage'

const ModalWindow = styled.div`
    width: 613px;
    height: auto;
    background-color: #212121;
    box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.3);
    border-radius: 8px;
    padding: 40px;
    text-align: center;
    position: relative;
`

const Inner = styled.div`
    height: auto;
    display: block;
    font-family: 'interface';
    h1 {
        font-family: 'nexaregular';
        font-size: 28px;
        text-align: center;

        span {
            font-family: 'nexablack';
            font-weight: 900;
        }
    }
    p {
        color: ##d8d8d8;
        margin-bottom: 28px;
        width: 533px;
        text-align: center;
        color: #d8d8d8;
        line-height: 22px;
    }
`

const CloseButton = styled.div`
    width: 16px;
    height: 16px;
    background-size: 100%;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background-image: url('/static/img/thick-close-btn.png');
`

const CelebrationImage = styled.div`
    width: 79px;
    height: 79px;
    margin: auto;
    display: block;
    background-image: url('/static/img/dashboard/celebrate.png');

    span {
        font-weight: bold;
    }
`
const StageIndicatorLabel = styled.span`
    color: #a6a6a6;
    font-family: 'InterFace';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 24px;
    display: block;
    span {
        font-weight: bold;
    }
`

const GetStartedButton = styled(Button)`
    background: linear-gradient(232.84deg, #9933ff 0%, #6157ff 100%);
    border-radius: 4px;
    font-size: 17px;
    width: 159px;
    height: 45px;
    line-height: 45px;
`

const InfoModule = styled.div<{ leftPos: number }>`
    width: 252px;
    min-height: 350px;
    position: fixed;
    left: ${(props) => props.leftPos - 252 - 45}px;
    top: 125px;
    background: #212121;
    box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
`

const ModuleContent = styled.div`
    h1 {
        font-family: 'InterFace';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 25px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        color: #d8d8d8;
    }
`

const NextButton = styled(GetStartedButton)`
    width: 100%;
    margin-top: 20px;
`

const gradientSlide = keyframes`
    0% {background-position: 0%;}
    100% {background-position: -300%;}
    `

const NewPill = styled.span`
    background: linear-gradient(to right, rgb(49, 145, 255), rgb(0, 255, 153), rgb(49, 145, 255)) 0% 100% / 300%;
    border-radius: 4px;
    color: black;
    font-weight: 800;
    padding: 5px;
    font-size: 12px;
    animation: ${gradientSlide} 5s linear 0s infinite;
    position: relative;
    top: -2px;
    left: 2px;
`

type Props = {
    isOnboardingActive?: boolean
    setOnboardingActive?: Dispatch<SetStateAction<boolean>>
    setOnboardingStep: Dispatch<SetStateAction<number>>
}

const NewUserOnboarding = ({ isOnboardingActive, setOnboardingActive, setOnboardingStep }: Props) => {
    const [isActive, setIsActive] = useState<boolean>(false)
    const [step, setStep] = useState<number>(1)
    const [leftPosition, setLeftPosition] = useState<number>(0)
    const [hasSeenOnboarding, setHasSeenOnboarding] = useLocalStorage<boolean>('has-seen-dashboard-onboarding', false)
    const lastStep = 6

    useEffect(() => {
        if (isOnboardingActive) {
            // Temporarily prevent the user from scrolling down the dashboard while the intro modal is displayed.
            document.body.style.overflow = 'hidden'
            setIsActive(true)
        }
    }, [isOnboardingActive])

    const beginOnboarding = () => {
        let anchorTarget = document.getElementById('graphAnchor')
        // Get the x position of the elements being highlighted, so we can display the onboarding information modules beside them
        let lp = anchorTarget?.getBoundingClientRect().left
        if (lp != undefined) {
            setLeftPosition(lp)
        }
        scrollToNextStep(2, 'graphAnchor', 115)
    }

    const scrollToNextStep = (whichStep: number, anchorTag: string, offset: number) => {
        let anchorTarget = document.getElementById(anchorTag)
        if (anchorTarget) {
            scrollIntoViewWithOffset(anchorTag, offset)
            setStep(whichStep)
            setOnboardingStep(whichStep)
        }
    }

    const scrollIntoViewWithOffset = (selector, offset) => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.getElementById(selector)!.getBoundingClientRect()!.top -
                document.body.getBoundingClientRect().top -
                offset,
        })
    }

    const skipOnboarding = () => {
        exitOnboarding()
    }

    const completeOnboarding = () => {
        exitOnboarding()
    }

    const exitOnboarding = () => {
        // Allow scrolling again
        document.body.style.overflow = 'visible'
        setStep(-1)
        setOnboardingStep(-1)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        setIsActive(false)
        // mark in a user's browser they've already gone through onboarding
        if (!hasSeenOnboarding) {
            setHasSeenOnboarding(true)
        }
        if (setOnboardingActive != undefined) {
            setOnboardingActive(false)
        }
    }

    return (
        <div>
            {isActive && (
                <>
                    {step == 1 && (
                        <>
                            <Modal>
                                <ModalWindow>
                                    <CloseButton onClick={skipOnboarding} />
                                    <Inner>
                                        <CelebrationImage />
                                        <StageIndicatorLabel>
                                            {step} <span>OF</span> {lastStep}
                                        </StageIndicatorLabel>
                                        <h1>
                                            Welcome to the <span>GIPHY</span> Dashboard
                                        </h1>
                                        <p>
                                            Measure your views and engagement using GIPHY&apos;s analytics dashboard.
                                            Keep up with emerging trends and optimize your content based on tailored
                                            insights.
                                        </p>
                                        <GetStartedButton onClick={beginOnboarding}>Get Started!</GetStartedButton>
                                    </Inner>
                                </ModalWindow>
                            </Modal>
                        </>
                    )}
                    <InfoModule leftPos={leftPosition}>
                        <CloseButton onClick={skipOnboarding} />
                        {step == 2 && (
                            <>
                                <>
                                    <StageIndicatorLabel>
                                        {step} <span>OF</span> {lastStep}
                                    </StageIndicatorLabel>
                                </>
                                <ModuleContent>
                                    <h1>Stats at a Glance</h1>
                                    <p>
                                        Here you’ll find a snapshot of your GIPHY content performance. Drill into your
                                        data by filtering by date and content type.
                                    </p>
                                    <NextButton onClick={() => scrollToNextStep(3, 'topGifsAndStickersAnchor', 85)}>
                                        Next
                                    </NextButton>
                                </ModuleContent>
                            </>
                        )}
                        {step == 3 && (
                            <>
                                <>
                                    <StageIndicatorLabel>
                                        {step} <span>OF</span> {lastStep}
                                    </StageIndicatorLabel>
                                </>
                                <ModuleContent>
                                    <h1>Top Content</h1>
                                    <p>
                                        Discover your channel&apos;s most viewed content in one place and see which tags
                                        are driving engagement. Use these high performers as a guide and double down on
                                        what has been successful in the past.
                                    </p>
                                    <NextButton onClick={() => scrollToNextStep(4, 'csvAnchor', 70)}>Next</NextButton>
                                </ModuleContent>
                            </>
                        )}
                        {step == 4 && (
                            <>
                                <>
                                    <StageIndicatorLabel>
                                        {step} <span>OF</span> {lastStep}
                                    </StageIndicatorLabel>
                                </>
                                <ModuleContent>
                                    <h1>In-Depth Reporting </h1>
                                    <p>
                                        See detailed analytics using our downloadable CSV, including view counts and
                                        engagement rates per tag. Spot trends and optimize your tagging to maximize
                                        views and engagement.
                                    </p>
                                    <NextButton onClick={() => scrollToNextStep(5, 'contentCalendarAnchor', 120)}>
                                        Next
                                    </NextButton>
                                </ModuleContent>
                            </>
                        )}
                        {step == 5 && (
                            <>
                                <>
                                    <StageIndicatorLabel>
                                        {step} <span>OF</span> {lastStep}
                                    </StageIndicatorLabel>
                                </>
                                <ModuleContent>
                                    <h1>
                                        Content Calendar <NewPill>NEW</NewPill>
                                    </h1>
                                    <p>
                                        This calendar has been designed to help content creators take advantage of high
                                        traffic moments on GIPHY throughout the year. Optimize your creation to target
                                        the top moments users are searching for.
                                    </p>
                                    <NextButton onClick={() => scrollToNextStep(6, 'searchInsightsAnchor', 130)}>
                                        Next
                                    </NextButton>
                                </ModuleContent>
                            </>
                        )}
                        {step == 6 && (
                            <>
                                <>
                                    <StageIndicatorLabel>
                                        {step} <span>OF</span> {lastStep}
                                    </StageIndicatorLabel>
                                </>
                                <ModuleContent>
                                    <h1>
                                        Take Your Content to the Next Level <NewPill>NEW</NewPill>
                                    </h1>
                                    <p>
                                        Create a content strategy based on what users are searching for in real-time on
                                        GIPHY. Uncover trending topics, leverage content gaps, and take advantage of
                                        tailored insights for your content.
                                    </p>
                                    <NextButton onClick={completeOnboarding}>Done</NextButton>
                                </ModuleContent>
                            </>
                        )}
                    </InfoModule>
                </>
            )}
        </div>
    )
}

export default NewUserOnboarding
