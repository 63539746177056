import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    border-radius: inherit;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    height: 12px;
`

const Progress = styled.div`
    background: #fff;
    height: 100%;
    overflow: hidden;
    transform: translate3d(-100%, 0, 0);
    transition: transform 1s ease-out;
    width: 100%;
`

type Props = {
    className?: string
    children?: ReactNode
    progress: number
}

const OnboardingProgressBar = ({ className, children, progress }: Props) => {
    const [displayProgress, setDisplayProgress] = useState<number>(0)

    useEffect(() => {
        const delay = setTimeout(() => {
            setDisplayProgress(progress)
        }, 100)

        return () => {
            clearTimeout(delay)
        }
    }, [progress])

    return (
        <Container className={className}>
            <Progress style={{ transform: `translate3d(${(displayProgress - 1) * 100}%, 0, 0)` }}></Progress>
            {children}
        </Container>
    )
}

export default OnboardingProgressBar
