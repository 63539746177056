import appendQuery from 'append-query'
import { getResults } from '.'
import { IGif } from '@giphy/js-types'

export type CalEvent = {
    summary: string
    eventType: string
    start: { date: string }
    end: { date: string }
    sequence: number
    etag: string
    id: string
    iCalUID: string
    recurringEventId: string
    status: string
    created: string
    updated: string
    htmlLink?: string
    tags: string[]
    isLive: boolean
    isFeatured: boolean
    asset: IGif
}

export type CalendarResponse = {
    description: string
    items: CalEvent[]
    summary: string
    timeZone: string
    updated: string
}

export const getContentCalendarEvents = (timeMin: string, timeMax: string): Promise<CalendarResponse> => {
    const requestUrl = appendQuery(`/api/v3/contentcalendar`, {
        timeMin,
        timeMax,
        maxResults: 250,
    })

    return getResults(requestUrl, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}
