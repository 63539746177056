import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import css from './live-header.css'

const { bool } = PropTypes

const LiveHeader = ({ isMobile }) => (
    <svg className={isMobile ? css.svgMobile : css.svgDesktop} viewBox="0 0 118 35" xmlns="http://www.w3.org/2000/svg">
        <g fill="#0F9" fillRule="evenodd">
            <path d="M0 15v18h13v-4.156H4.858V15" />
            <path d="M15 33h4V21h-4" />
            <path d="M25.993 33L20 21h5.296l2.9 7.13L30.982 21H36l-5.798 12" />
            <path d="M44.622 25.98c-.053-.664-.262-1.187-.627-1.57-.365-.384-.806-.575-1.32-.575-.535 0-1.002.196-1.403.587-.4.392-.636.912-.707 1.56h4.057zm-4.084 2.376c.035.75.29 1.332.76 1.75.472.416 1.073.625 1.803.625.588 0 1.09-.1 1.51-.306.417-.204.804-.52 1.16-.945l2.856 2.145c-.284.34-.61.65-.974.932-.365.28-.783.532-1.255.753-.47.222-1.014.392-1.628.51-.615.12-1.305.18-2.07.18-.925 0-1.793-.136-2.602-.41-.81-.27-1.518-.675-2.123-1.212-.605-.536-1.085-1.21-1.44-2.017-.357-.808-.535-1.75-.535-2.822 0-1.038.17-1.962.507-2.77.338-.81.8-1.495 1.388-2.057.588-.56 1.286-.986 2.096-1.276.81-.29 1.687-.434 2.63-.434 1.94 0 3.484.6 4.632 1.8C48.4 24 48.982 25.854 49 28.357h-8.462z" />
            <path d="M15 15.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5-2.5 1.12-2.5 2.5" />
            <path
                className={css.barSmall}
                d="M22 16c0-2.757-2.243-5-5-5-.552 0-1-.448-1-1s.448-1 1-1c3.86 0 7 3.14 7 7 0 .552-.448 1-1 1s-1-.448-1-1z"
            />
            <path
                className={css.barMedium}
                d="M26.884 17c-.617 0-1.117-.5-1.117-1.116 0-4.77-3.88-8.65-8.65-8.65-.617 0-1.117-.5-1.117-1.118C16 5.5 16.5 5 17.116 5 23.118 5 28 9.882 28 15.884 28 16.5 27.5 17 26.884 17"
            />
            <path
                className={css.barLarge}
                d="M31.663 17c-.74 0-1.337-.6-1.337-1.337 0-7.162-5.827-12.99-12.99-12.99C16.6 2.674 16 2.077 16 1.338 16 .6 16.6 0 17.337 0 25.974 0 33 7.026 33 15.663 33 16.4 32.4 17 31.663 17"
            />
            <path d="M73.56 19.432c-2.208-2.232-4.272-2.712-6.6-2.712-6.312 0-9.192 4.512-9.192 8.904s2.52 8.832 9.168 8.832c3.24 0 6.168-1.152 7.224-3.288v-7.464h-8.208v3.72H70.2v2.16c-.96.696-2.352.864-3.24.864-3.384 0-4.512-2.664-4.512-4.824 0-3.288 1.776-4.92 4.512-4.92 1.128 0 2.616.312 3.84 1.464l2.76-2.736zM81.576 34V17.2h-4.728V34h4.728zm8.112 0v-5.784h7.68v-3.888h-7.68v-3.144h8.184V17.2H84.984V34h4.704z" />
        </g>
    </svg>
)

LiveHeader.propTypes = {
    isMobile: bool,
}

export default LiveHeader
