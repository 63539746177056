import { useHistory } from 'react-router-dom'

type Props = {}
const ExposeRouter = ({}: Props) => {
    const history = useHistory()
    // @ts-ignore
    window.reactRouter = history
    return null
}
export default ExposeRouter
