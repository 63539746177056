import { lazy } from 'react'
import { Helmet } from 'react-helmet'
import { CurationContextManager } from './components/curation-context'

const CurationStation = lazy(() => import(/* webpackChunkName: "curationStation" */ './components/curation-station'))

const CurationStationRoute = (props) => (
    <>
        <Helmet>
            <title>GIPHY | Curation Station</title>
        </Helmet>
        <CurationContextManager>
            <CurationStation {...props} />
        </CurationContextManager>
    </>
)

export default CurationStationRoute
