import { createContext, useContext, useEffect, useState } from 'react'
import { Modal } from '../modal'
import { validateLegacyStatus } from '../settings/email-verification-message/email-verification-message.api'
import InvalidEmailFlow from './invalid-email-flow'
import SuccessEmailChange from './success-change'
import UpdatingEmail from './updating-email'
import ValidEmailFlow from './valid-email-flow'
import { LoggedInUser } from 'types'
import UserContext from 'shared/contexts/user'

export enum States {
    VALID_EMAIL = 'VALID_EMAIL',
    INVALID_EMAIL = 'INVALID_EMAIL',
    UPDATING_EMAIL = 'UPDATING_EMAIL',
    SUCCESS_EMAIL_CHANGE = 'SUCCESS_EMAIL_CHANGE',
}

type PendingLegacyEmailConfirmation = {
    user: number
    email: string
    status: number
    create_datetime: Date | null
    update_datetime: Date | null
    email_send_datetime: Date | null
    confirmation_datetime: Date | null
    email_resends: number
    can_send_email: boolean
}

type PendingEmailChangeRequest = {
    can_send_email: boolean
    create_datetime: Date | null
    email: string
    id: number
    is_email_deliverable: boolean
    is_email_valid: boolean
    is_jailed: boolean
}

type LegacyUser = Partial<LoggedInUser> & {
    is_email_deliverable: boolean
    email: string
    pending_legacy_email_confirmation: PendingLegacyEmailConfirmation | null
    pending_email_change_request: PendingEmailChangeRequest | null
}

type ValidationUserContextType = {
    user: LegacyUser
    changeState: (state: States) => void
    newEmail: string
    setNewEmail: (email: string) => void
}

export const ValidationUserContext: React.Context<ValidationUserContextType> = createContext({
    user: {} as LegacyUser,
    newEmail: '',
    changeState: (_state: States) => {},
    setNewEmail: (_email: string) => {},
})

const getInitialState = (user: LegacyUser): States => {
    const emailRequest = user.pending_email_change_request

    if (emailRequest && emailRequest.email !== user.email) {
        return States.SUCCESS_EMAIL_CHANGE
    }
    if (user.is_email_deliverable) {
        return States.VALID_EMAIL
    }

    return States.INVALID_EMAIL
}

const EmailValidationModal = () => {
    const { user } = useContext(UserContext)
    const [validationData, setValidationData] = useState<LegacyUser | null>(null)
    const [state, setState] = useState<States>(States.VALID_EMAIL)
    const [newEmail, setNewEmail] = useState('')

    useEffect(() => {
        const isExternalRedirect = document.referrer.toLowerCase().indexOf('giphy') === -1
        if (user && user.isJailed && !isExternalRedirect) {
            validateLegacyStatus().then((res: LegacyUser) => {
                const initialState = getInitialState(res)
                setNewEmail(res.pending_email_change_request?.email || '')
                setValidationData(res)
                setState(initialState)
            })
        }
    }, [user])

    if (!validationData) {
        return null
    }
    let currentScreen: JSX.Element = <></>
    switch (state) {
        case States.VALID_EMAIL:
            currentScreen = <ValidEmailFlow />
            break
        case States.INVALID_EMAIL:
            currentScreen = <InvalidEmailFlow />
            break
        case States.UPDATING_EMAIL:
            currentScreen = <UpdatingEmail />
            break
        case States.SUCCESS_EMAIL_CHANGE:
            currentScreen = <SuccessEmailChange />
            break
    }
    return (
        <ValidationUserContext.Provider value={{ user: validationData, changeState: setState, newEmail, setNewEmail }}>
            <Modal>{currentScreen}</Modal>
        </ValidationUserContext.Provider>
    )
}

export default EmailValidationModal
