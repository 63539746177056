import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import ApplyForm from 'shared/components/form/apply-form'
import { Switch, Route } from 'react-router'
import {
    Done,
    InvalidEmail,
    NeedMoreGifs,
    Pending,
    Rejected,
} from 'shared/components/form/apply/application-states.tsx'

const Container = styled.div`
    margin-top: 10px;
    padding: 20px 0;
`
const Apply = () => (
    <Container>
        <Helmet>
            <title>Create an Artist or Brand Channel | GIPHY</title>
        </Helmet>
        <Switch>
            <Route path="/apply/more-gifs" component={NeedMoreGifs} />
            <Route path="/apply/invalid-email" component={InvalidEmail} />
            <Route path="/apply/pending" component={Pending} />
            <Route path="/apply/incomplete" component={Pending} />
            <Route path="/apply/ineligible" component={Rejected} />
            <Route path="/apply/done" component={Done} />
            <Route component={ApplyForm} />
        </Switch>
    </Container>
)

export default Apply
