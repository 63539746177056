import { IUser } from '@giphy/js-types'
import Header from 'modules/header/components/header'
import Favorites from 'modules/search/containers/favorites'
import { Suspense, useContext, useEffect, useRef } from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import DidomiUI from 'ui/src/components/didomi/didomi'
import ArSceneLandingPage from 'shared/components/ar-scene-landing-page'
import BetaNoticeBanner from 'shared/components/beta-env/banner'
import UIbeta from 'shared/components/beta-env/notice'
import entryPoint from 'shared/components/entry-point-hoc'
import GDBootstrapper from 'shared/components/gd-bootstrapper'
import ReportOnSPA from 'shared/components/router/report-on-spa'
import UpgradedUserOnboardingModal from 'shared/components/user-onboarding/upgraded-user-onboarding'
import EmailValidationModal from 'shared/components/validation-popup/modal'
import ContextManagers from 'shared/context-managers'
import { ChannelAccess } from 'shared/contexts/accounts-manager'
import 'shared/locale'
import { getDefaultStore } from 'shared/redux/init-store'
import routes from 'shared/routes'
import { FlashMessageContext } from '../shared/components/flash-message/flash-message'
import GA4 from '../shared/components/ga4'
import About from './about/route'
import AppsLandingRoute from './apps-landing/components/route'
import ArtistsRoute from './artists/route'
import Categories from './categories'
import Channel from './channel/components/app'
import CurationRoute from './curation-station/route'
import DashboardPage from './dashboard/dashboard'
import Error403 from './error-pages/403'
import Error404 from './error-pages/404'
import Error503 from './error-pages/503'
import GifDetailRoute from './gif-detail/components/route'
import Homebase from './homebase/route'
import SiteContainer from './site-container'
import ChannelStoriesRoute from './stories/route'
import StoryRoute from './story/components/route'
import TeamPage from './team/team'
import TransparencyReport from './transparency-reports'
import TrendingRoute from './trending/route'
import Apply from './user/apply'
import EmailJail from './user/email-jail'
import Settings from './user/settings'
import TakedownAppeal from './user/takedown-appeal'
import VideosRoute, { VideoDetailRoute } from './videos/route'
import EditGif from 'mobile/pages/edit-gif'
import ExposeRouter from 'shared/components/expose-router'

const store = getDefaultStore({})

type User = IUser & {
    channel_access?: ChannelAccess
}

type Props = {
    id: string | number
    pageData: any
    showSearch?: boolean
    showBrowse?: boolean
    searchTerm?: boolean
    user?: User
    contextId?: string
}

const BackendMessages = ({ pageData }) => {
    const { showMessage } = useContext(FlashMessageContext)

    useEffect(() => {
        if (pageData?.flash_msg) {
            showMessage({ slug: pageData.flash_msg })
        }
    }, [pageData])

    return null
}

const App = (props: any) => {
    const { pageData = {}, user, showSearch, showBrowse, searchTerm, contextId }: Props = props

    const is404 = useRef(pageData.status_code === 404)
    const is403 = useRef(pageData.status_code === 403)
    const isMaintenance = useRef(pageData.status_code === 503)
    // if we have is404 in the template on first render
    if (is404.current) {
        // then the current pathname is a 404
        routes.ERROR_404.path = location.pathname
    }
    if (is403.current) {
        // then the current pathname is a 404
        routes.ERROR_403.path = location.pathname
    }
    if (isMaintenance.current) {
        routes.ERROR_503.path = location.pathname
    }
    // page load context id for channel pages choosing, root, branch or leaf
    const channelType = useRef(contextId)
    if (channelType.current) {
        // there's no SPA but we're close...
        // we'd handle it with location state with an id when we history.push
        routes.CHANNEL.path = location.pathname
    }

    return (
        <Provider store={store}>
            <SiteContainer>
                <Suspense fallback={null}>
                    <BrowserRouter>
                        <GDBootstrapper initData={pageData}>
                            <ContextManagers
                                channel_access={user?.channel_access}
                                dashboard_channel_access={pageData.dashboard_channel_access}
                                dashboard_user={pageData.dashboard_user}
                            >
                                {!pageData.hideHeader && (
                                    <Header
                                        hideLogo={pageData.hideLogo}
                                        adsEnabled={pageData.ads_enabled === true}
                                        {...{ user, showSearch, showBrowse, searchTerm }}
                                    />
                                )}
                                <ExposeRouter />
                                <BackendMessages pageData={pageData} />
                                <DidomiUI />
                                <GA4 />
                                <ReportOnSPA />
                                <UIbeta />
                                <BetaNoticeBanner />
                                <EmailValidationModal />
                                <UpgradedUserOnboardingModal />
                                <Switch>
                                    <Route {...routes.CHANNEL} render={() => <Channel {...props} />} />
                                    <Route {...routes.ERROR_404} exact component={Error404} />
                                    <Route {...routes.ERROR_403} exact component={Error403} />
                                    <Route {...routes.ERROR_503} exact component={Error503} />
                                    <Route {...routes.TAKEDOWN_APPEAL} component={TakedownAppeal} />
                                    <Route {...routes.APPLY} component={Apply} />
                                    <Route {...routes.DASHBOARD} component={DashboardPage} />
                                    <Route {...routes.ABOUT} exact component={About} />
                                    <Route {...routes.EMAIL_JAIL} component={EmailJail} />
                                    <Route {...routes.CHANNEL_STORIES} component={ChannelStoriesRoute} />
                                    <Route {...routes.ARTISTS} component={ArtistsRoute} />
                                    <Route {...routes.STORY} component={StoryRoute} />
                                    <Route {...routes.GIF_DETAIL} component={GifDetailRoute} />
                                    <Route {...routes.CLIPS} component={VideosRoute} />
                                    <Route {...routes.TRENDING_CLIPS} component={VideosRoute} />
                                    <Route {...routes.CLIPS_DETAIL} component={VideoDetailRoute} />
                                    <Route {...routes.TRENDING} component={TrendingRoute} />
                                    <Route {...routes.APPS} component={AppsLandingRoute} />
                                    <Route {...routes.SETTINGS} component={Settings} />
                                    <Route {...routes.HOMEBASE} component={Homebase} />
                                    <Route {...routes.EDIT_GIFS} component={CurationRoute} />
                                    <Route {...routes.TEAM} component={TeamPage} />
                                    <Route {...routes.CATEGORIES} component={Categories} />
                                    <Route {...routes.FAVORITES} component={Favorites} />
                                    <Route {...routes.AR_SCENE_LANDING_PAGE} component={ArSceneLandingPage} />
                                    <Route {...routes.TRANSPARENCY} component={TransparencyReport} />
                                    <Route {...routes.EDIT_GIF} component={EditGif} />
                                    <Route exact component={Error404} />
                                </Switch>
                            </ContextManagers>
                        </GDBootstrapper>
                    </BrowserRouter>
                </Suspense>
            </SiteContainer>
        </Provider>
    )
}

export default entryPoint(store)(App)
