import { isEmpty } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { fetchAllChannelChildren, fetchChannel } from 'shared/api/channels'
import StickerEmbedModalContainer from 'shared/containers/sticker-embed-modal'
import ChannelPageContext from 'shared/contexts/channel-page'
import RefreshDataContext from 'shared/contexts/refresh-data'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { IChannel } from 'shared/types/channel'
import Channel from './channel'
import Clips from './clips'

const App = ({ query, isOwner, contextId: channelType, id: channelId, isClipsPage }) => {
    const [channel, setChannel] = useState<IChannel>()
    const [deepRefreshKey, setDeepRefreshKey] = useState(0)
    const isTagView = channelType === 'tagged_channel'
    const isSearch = !isEmpty(query)
    useAsyncEffect(async () => {
        setChannel(await fetchChannel(channelId))
    }, [])
    useAsyncEffect(async () => {
        if (!isClipsPage && channel?.has_children && channel?.children?.length === 0) {
            const children = await fetchAllChannelChildren(channelId)
            if (children.length > 0) {
                setChannel({ ...channel, children })
            }
        }
    }, [channel, isClipsPage])
    const { onRefreshChannel } = useContext(RefreshDataContext)
    useEffect(() => {
        if (channel) {
            const off = onRefreshChannel((refreshedChannel, deepRefresh) => {
                if (refreshedChannel.id === channel.id) {
                    // used to indicate to grids that they gotta refresh (can circle back on this, not the best approach)
                    setDeepRefreshKey(deepRefresh ? deepRefreshKey + 1 : deepRefreshKey)
                    setChannel(refreshedChannel)
                }
            })
            return off
        }
    }, [channel])
    return channel ? (
        <ChannelPageContext.Provider
            value={{
                isOwner,
                channel,
                channelType,
                isTagView,
                isSearch,
                searchQuery: query,
            }}
        >
            <StickerEmbedModalContainer channelId={parseInt(channelId)} />
            {isClipsPage ? <Clips deepRefreshKey={deepRefreshKey} /> : <Channel deepRefreshKey={deepRefreshKey} />}
        </ChannelPageContext.Provider>
    ) : null
}

export default App
