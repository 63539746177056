import NoUploads from 'shared/components/channel-info/no-uploads'
import { grid5, grid9 } from 'shared/util/grid'
import styled from 'styled-components'

const Container = styled.div`
    padding-top: 10px;
`

const Shrug = styled.div`
    background: url('/static/img/shrug.gif') no-repeat center center;
    background-size: contain;
    height: 300px;
    margin: 0 auto;
    width: ${grid5}px;
`

type Props = {
    isOwner?: boolean
}

const NoChannelGifResults = ({ isOwner }: Props) => (
    <Container>{isOwner ? <NoUploads width={grid9} height={300} /> : <Shrug />}</Container>
)

export default NoChannelGifResults
