import { SyntheticEvent, useContext, useState, useEffect } from 'react'
import { editChannel, removeGif } from 'shared/api/channels'
import Portal from 'shared/components/portal'
import gifSkeleton from 'shared/components/gif-skeleton'
import ChannelPageContext from 'shared/contexts/channel-page'
import MessageContext from 'shared/contexts/message'
import RefreshDataContext from 'shared/contexts/refresh-data'
import { useCanManageCollection } from 'shared/hooks/use-permissions'
import { Overlay, EllipsisContainer, EllipsisIcon, StarIcon, Menu, MenuItem, MenuIcon, Arrow } from './sc'
import { useRef } from 'react'

const NOT_HOVERING_TIMER_TO_CLOSE = 1500
const ElipisisMenuOverlay = ({ gif, isHovered }) => {
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [top, setTop] = useState<number>(0)
    const [left, setLeft] = useState<number>(0)
    const timeoutToClose = useRef<null | ReturnType<typeof setTimeout>>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const { channel } = useContext(ChannelPageContext)
    const { sendMessage } = useContext(MessageContext)
    const { refreshChannel } = useContext(RefreshDataContext)
    const canManage = useCanManageCollection()

    const calculateMenuTop = () => {
        const clientRect = containerRef?.current?.getBoundingClientRect()

        if (clientRect) {
            setLeft(clientRect.left - 40)
            setTop(clientRect.top + window.pageYOffset - 120)
        }
    }

    useEffect(() => {
        calculateMenuTop()

        window.addEventListener('scroll', calculateMenuTop, true)

        return () => window.removeEventListener('scroll', calculateMenuTop, true)
    }, [containerRef])

    const isStarred = channel.featured_gif?.id === gif.id

    const onClickFeaturedGif = async (e: SyntheticEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        try {
            // @ts-ignore featured_gif is usually a gif, but not when passed to edit
            await editChannel({ ...channel, featured_gif: isStarred ? '' : gif.id })
            sendMessage(`Success! Featured GIF updated`, 'success')
        } catch (error) {
            sendMessage(`Error updating featured GIF :(`, 'error')
        }
        refreshChannel({ ...channel, featured_gif: isStarred ? gifSkeleton() : gif })
    }

    const onClickRemoveGif = async (e: SyntheticEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        try {
            // @ts-ignore featured_gif is usually a gif, but not when passed to edit
            await removeGif({ gif: gif.id, channel: channel.id })
            sendMessage(`Successfully deleted`, 'success')
        } catch (error) {
            sendMessage(
                `Unable to delete - content is in a sub-collection.
            To delete the content, please visit the sub-collection that it is part of.`,
                'error'
            )
        }
        refreshChannel({ ...channel, featured_gif: isStarred ? gifSkeleton() : gif })
    }

    const handleClose = () => {
        timeoutToClose.current = setTimeout(() => {
            setShowMenu(false)
        }, NOT_HOVERING_TIMER_TO_CLOSE)
    }

    // don't show featured gif start on root channels
    // only show them on collection pages
    // and abort if can't manage of course
    if (!channel || !channel.parent || !canManage) {
        return null
    }

    return (
        <Overlay>
            <EllipsisContainer ref={containerRef}>
                {isHovered && !isStarred && (
                    <EllipsisIcon
                        className="ss-ellipsis"
                        onMouseEnter={() => setShowMenu(true)}
                        onMouseLeave={handleClose}
                    />
                )}
                {isHovered && isStarred && <StarIcon className="ss-star" onClick={(e) => onClickFeaturedGif(e)} />}
                {showMenu && containerRef.current && top > 0 && left > 0 && (
                    <Portal>
                        <Menu
                            top={top}
                            left={left}
                            onMouseLeave={() => {
                                setShowMenu(false)
                            }}
                            onMouseEnter={() => timeoutToClose.current && clearTimeout(timeoutToClose.current)}
                        >
                            <MenuItem onClick={(e) => onClickFeaturedGif(e)}>
                                <MenuIcon>
                                    <div className="ss-star" />
                                </MenuIcon>
                                <span>{isStarred ? 'Unmake Featured GIF' : 'Make Featured GIF'}</span>
                            </MenuItem>
                            <MenuItem onClick={(e) => onClickRemoveGif(e)}>
                                <MenuIcon>
                                    <div className="ss-trash" />
                                </MenuIcon>
                                <span>Remove from Collection</span>
                            </MenuItem>
                            <Arrow className="ss-dropdown" />
                        </Menu>
                    </Portal>
                )}
            </EllipsisContainer>
        </Overlay>
    )
}

export default ElipisisMenuOverlay
