import { useContext, useState } from 'react'
import { editUserById } from 'shared/api'
import styled from 'styled-components'
import isEmail from 'validator/lib/isEmail'
import { AdaptedFormInput } from '../form/fields'
import { States, ValidationUserContext } from './modal'
import { Container, Emoji, Title, Button, Message, Email, Footer, PrimaryButton } from './styled'

const StyledAdaptedFormInput = styled(AdaptedFormInput)`
    width: 355px;
`

const InvalidEmailFlow = () => {
    const { user, changeState, setNewEmail, newEmail } = useContext(ValidationUserContext)
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string | null>(null)

    const logout = () => {
        window.location.href = '/logout'
    }

    const onValidateAccount = () => {
        if (email === user.email) {
            setError('Uh-oh. Please enter a new email address.')
            return
        }
        if (!isEmail(email)) {
            setError("Uh-oh. We don't recognize that email address.")
            return
        }

        editUserById(user.id, { email, platform: 'web', source: 2 }, true)
            .then(() => {
                setNewEmail(email)
                changeState(States.SUCCESS_EMAIL_CHANGE)
            })
            .catch(() => {
                setNewEmail(email)
                changeState(States.INVALID_EMAIL)
            })
    }

    return (
        <Container>
            <Emoji src="https://media2.giphy.com/media/USUIWSteF8DJoc5Snd/200w.gif" />
            <Title>Oops!</Title>
            <Message>
                {`Looks like `}
                <Email>{newEmail || user.email}</Email>
                {` isn't valid. Please`}
                {`\nenter another email adddress to validate your account.`}
            </Message>
            <StyledAdaptedFormInput
                errorTooltip
                errorMessage={error}
                borderRadius={2}
                inputProps={{
                    placeholder: 'Email address',
                    type: 'email',
                    value: email,
                    onChange: (e) => setEmail(e.target.value),
                }}
            />
            <PrimaryButton onClick={onValidateAccount}>Validate My Account</PrimaryButton>
            <Button onClick={logout}>Log out</Button>
            <Footer>
                {`Need help? Please reach out to `}
                <Email>support@giphy.com</Email>
            </Footer>
        </Container>
    )
}

export default InvalidEmailFlow
