import { sample } from 'lodash'
import { GlobalStyle, Container, Message, Button } from './sc'

export const Error403 = () => {
    const errorGifs = [
        'img/error_pages/text/dang.gif',
        'img/error_pages/text/oops.gif',
        'img/error_pages/text/woof.gif',
    ]
    const errorBgs = [
        'img/error_pages/bg/bg_blocks.gif',
        'img/error_pages/bg/bg_cupcakes_small.gif',
        'img/error_pages/bg/bg_pizza.gif',
        'img/error_pages/bg/bg_shine_small.gif',
        'img/error_pages/bg/bg_tacos.gif',
    ]
    const errorText = sample(errorGifs)
    const errorBG = sample(errorBgs)

    return (
        <>
            <GlobalStyle errorBG={errorBG!} />
            <Container>
                <img src={(window as any).STATIC_URL + errorText} />
                <Message>
                    <p>
                        An internal error occurred. Please <a href={location.href}>refresh</a> the page.
                    </p>
                </Message>
                <Button href="/" is403={true}>
                    Continue to Our Homepage
                </Button>
            </Container>
        </>
    )
}

export default Error403
