import { giphyBlue, giphyGreen } from '@giphy/colors'
import isMobile from 'shared/util/is-mobile'
import styled, { css } from 'styled-components'
import { DropdownButton, SelectList } from 'ui'

const StyledDropdownButton = styled(DropdownButton)`
    position: relative;
    width: 215px;

    ${() =>
        isMobile() &&
        css`
            width: 140px;
        `}
`

const values: string[] = ['GIFs & Stickers', 'Clips', 'GIFs', 'Stickers']

type Props = {
    onChange: (type: string) => void
    onToggle?: (isOpen: boolean) => void
    selectedContentType: string
}

const ContentTypePicker = ({ onChange = () => {}, onToggle, selectedContentType }: Props) => {
    const selectedValue = values.findIndex((value) => value === selectedContentType)

    return (
        <StyledDropdownButton
            label={'Content Type'}
            iconButtonOptions={{
                iconColor: giphyBlue,
            }}
            onToggle={onToggle}
        >
            <SelectList
                defaultIndex={selectedValue}
                options={values}
                onChange={(index) => {
                    onChange(values[index])
                }}
                iconColor={giphyGreen}
            />
        </StyledDropdownButton>
    )
}

export default ContentTypePicker
