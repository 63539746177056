import { giphyLightGrey } from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { singularContentTypes } from '../../../site/user-dashboard-analytics/app'
import { ContentPiece } from './leader-board-graph'
import { getClipStarts, getTopTerms } from './networking'

const Container = styled.div`
    span {
        color: #00baee; //NOTE: Color does not exist in brand repo
        text-transform: capitalize;
    }
    z-index: 2;
    div {
        color: ${giphyLightGrey};
    }
`

type Props = {
    item: ContentPiece
    contentType: string
    dateRange: string[]
    user: IUser
}

const Insight = ({ item, contentType, dateRange, user }: Props) => {
    const [topTerm, setTopTerm] = useState<string>('')
    const [ctr, setCtr] = useState<number>(0)
    const [starts, setStarts] = useState<number>(0)
    const [allowedToFetch, setAllowedToFetch] = useState<boolean>(true)

    useEffect(() => {
        // Connect to the api for top terms
        if (allowedToFetch) {
            if (contentType === 'Clips') {
                chooseClipStart(item, contentType, dateRange, user)
            } else {
                chooseATopTerm(item, contentType, dateRange, user, true)
            }
        }

        return () => {
            setAllowedToFetch(false)
        }
    }, [dateRange, item])

    const chooseClipStart = async (item, contentType, dateRange, user) => {
        const data = await getClipStarts(contentType, dateRange, item.contentId, user.username)
        let starts = data['startData'][0]['startCount']
        setStarts(starts)
    }

    const chooseATopTerm = async (item, contentType, dateRange, user, returnTopTerm) => {
        const data = await getTopTerms(contentType, dateRange, item.contentId, user.username)
        // remove any terms that start with 'giphy'
        let filteredData = data['ctrData'].filter(function (item) {
            return !item.term.toLowerCase().startsWith('giphy')
        })
        // Were top terms returned at all?
        if (filteredData.length > 0) {
            if (returnTopTerm) {
                // sort filteredData by ctr
                filteredData.sort(function (a, b) {
                    return b.ctr - a.ctr
                })
                // If the 'returnTopTerm' flag is set, just set the top term and count.
                setTopTerm(filteredData[0].term)
                setCtr(filteredData[0].ctr)
            } else {
                // Otherwise, randomize the results
                const randomTermObject = filteredData[Math.floor(Math.random() * filteredData.length)]
                setTopTerm(randomTermObject.term)
                setCtr(randomTermObject.ctr)
            }
        }
    }

    return (
        <Container>
            {contentType === 'Clips' && starts !== null && starts !== 0 && (
                <div>
                    This clip started playing <span>{starts.toLocaleString()}</span> times.
                </div>
            )}
            {topTerm && ctr !== null && ctr !== undefined && contentType !== 'Stories' && (
                <div>
                    Your {contentType !== 'GIFs & Stickers' ? singularContentTypes[contentType] : 'content'} was engaged
                    with the most when users searched for <span>{topTerm}</span>
                </div>
            )}
        </Container>
    )
}

export default Insight
