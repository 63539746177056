import styled from 'styled-components'
import { Modal } from '../modal'

export const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 38px 50px 15px;
    width: 489px;
    background: rgba(8, 8, 8, 0.9);
    border-radius: 8px;
`

export const Emoji = styled.img`
    width: 200px;
    height: 200px;
    bottom: 200px;
    position: relative;
    z-index: 1;
`

export const Title = styled.h2`
    padding-top: 50px;
    font-family: 'nexablack', sans-serif;
    font-style: normal;
    font-size: 28px;
`

export const Message = styled.p`
    color: white;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    margin-bottom: 40px;
    white-space: pre-line;
`

export const PrimaryButton = styled.button`
    width: 355px;
    height: 41px;
    color: #ffffff;
    background: linear-gradient(88.24deg, #9933ff -20.09%, #6157ff 98.56%);
    border-radius: 2px;
    font-weight: 700;
    font-size: 17px;
    margin-top: 20px;
`

export const Button = styled.button`
    width: 355px;
    height: 41px;
    color: #d8d8d8;
    background: #212121;
    border-radius: 2px;
    font-weight: 700;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 20px;
`

export const SkipOnboardingModal = ({ show, setShowSkipModal, setShowOnboarding, endOnboarding }) => {
    if (!show) {
        return null
    }
    return (
        <Modal>
            <Emoji src="https://media2.giphy.com/media/tEuZQV7AGrrv035n3c/200w.gif" />
            <Container>
                <Title>Are you sure?</Title>
                <Message>
                    We want you to make the most of being an upgraded member of GIPHY. Are you sure you don’t want to
                    learn more about how you can use our platform?
                </Message>
                <PrimaryButton
                    onClick={() => {
                        setShowOnboarding(false)
                        setShowSkipModal(false)
                        endOnboarding(true)
                    }}
                >
                    Yes, Close and Exit
                </PrimaryButton>
                <Button onClick={() => setShowSkipModal(false)}>Go Back</Button>
            </Container>
        </Modal>
    )
}
