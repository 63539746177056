import styled from 'styled-components'
import { ColoredStrip, CloseButton } from './form-elements'
import LogoShapesAnimation from 'ui/src/components/logo-shapes-animation'
import { Button } from 'shared/components/buttons'

const Container = styled.div`
    position: fixed;
    top: 60px; // design specifies 170px but that places the modal quite low
    left: 50%;
    width: 663px;
    height: 718px;
    padding: 40px 40px 20px;
    transform: translateX(-50%);
    z-index: 999;
    background: rgb(18, 18, 18); //nTODO: replace this witha giphy color
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`

const Inner = styled.div`
    text-align: center;
    width: 560px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    canvas {
        z-index: -1;
    }
`

const ConfirmationText = styled.div`
    padding-bottom: 60px;
    h2 {
        color: rgb(255, 255, 255);
        font-family: interface;
        font-size: 24px;
        font-weight: bold;
        line-height: 31px;
    }
    p {
        width: 500px;
        text-align: center;
        color: rgb(166, 166, 166); //nTODO: Giphy color
        font-family: interface;
        font-weight: bold;
        line-height: 26px;
        margin: 10px auto 20px auto;
    }

    button {
        width: 177px;
    }
`

const PeaceImage = styled.img`
    width: 150px;
    margin: auto;
`

type Props = {
    deactivateModal: () => void
    preferredName: string
}

const SuccessContainer = ({ deactivateModal, preferredName }: Props) => (
    <Container>
        <ColoredStrip />
        <CloseButton onClick={deactivateModal}></CloseButton>
        <Inner>
            <LogoShapesAnimation width={663} height={718} colors={['#0e0e0e', '#0e0e0e']} speed={2} />
            <ConfirmationText>
                <PeaceImage src="/static/img/apply/peace.gif" />
                <h2>Your inquiry has been emailed to {preferredName}</h2>
                <p>
                    If {preferredName} is interested in your project they will contact you through the email address you
                    provided.
                </p>
                <Button onClick={deactivateModal}>Close</Button>
            </ConfirmationText>
        </Inner>
    </Container>
)

export default SuccessContainer
