import PropTypes from 'prop-types'
import css from './breadcrumbs.css'

const Breadcrumbs = ({ breadcrumbs, url, shortDisplayName, displayName, casing = `capitalize` }) =>
    breadcrumbs.length ? (
        <div>
            {breadcrumbs.map(({ display_name: displayName, short_display_name: shortDisplayName, url }, index) => (
                <a key={index} className={css.breadcrumb} style={{ textTransform: casing }} href={url}>
                    <h2>{shortDisplayName || displayName}</h2>
                </a>
            ))}
        </div>
    ) : (
        <a className={css.breadcrumbSingle} style={{ textTransform: casing }} href={url}>
            <h2>{shortDisplayName || displayName}</h2>
        </a>
    )

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
    url: PropTypes.string,
    shortDisplayName: PropTypes.string,
    displayName: PropTypes.string,
}

export default Breadcrumbs
