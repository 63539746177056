import styled from 'styled-components'

const Header = styled.div`
    padding: 16px 0;

    &:after {
        content: '';
        clear: both;
        display: block;
        width: 100%;
    }

    & h4 {
        font-size: 17px;
        font-weight: bold;
        font-family: interface;
    }
`

type Props = {
    title: string
}

const GridHeader = ({ title }: Props) => (
    <Header>
        <h4>{title}</h4>
    </Header>
)

export default GridHeader
