import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import channelProps from '../../prop-types/channel'
import css from './tags.css'

const isTagActive = (tag = '', search = '', isTagView) => isTagView && tag.toUpperCase() === search.toUpperCase()

const Tags = ({ channel: { tags, slug, parent, ancestors }, search, isCompact, isTagView }) => (
    <div className={!isCompact ? css.tagContainer : css.tagContainerSmall}>
        {isCompact ? null : (
            <div>
                <div className={css.header} />
                <h2 className={css.tagListTitle}>Trending Now</h2>
            </div>
        )}
        <ul className={isCompact ? css.tagListSmall : css.tagList}>
            {tags.map((item, index) => (
                <li className={isCompact ? css.tagListItemWrapper : null} key={index}>
                    <a
                        className={isCompact ? css.tagListItemSmall : css.tagListItem}
                        data-active={isTagActive(item.tag, search, isTagView)}
                        href={`/${parent ? ancestors[0].slug : slug}/tagged/${encodeURIComponent(
                            item.tag.replace(/\s/g, '-')
                        )}`}
                    >{`#${item.tag}`}</a>
                </li>
            ))}
        </ul>
    </div>
)

Tags.propTypes = {
    channel: channelProps,
    search: PropTypes.string,
    isCompact: PropTypes.bool,
    isTagView: PropTypes.bool,
}

export default Tags
