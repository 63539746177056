import * as colors from '@giphy/colors'
import download from 'downloadjs'
import { useEffect, useRef } from 'react'
import { useClickAway, useKeyPress } from 'react-use'
import styled from 'styled-components'
import { request } from '../../api'
import { Modal } from '../modal'
import AnimatedDownloadButton from './animated-download-button'
import Loader from 'shared/components/loaders/dots'
import { useState } from 'react'

const LoaderWrapper = styled.div`
    height: 80px;
    position: relative;
    top: -20px;
    width: 100%;
`

const Container = styled.div`
    position: absolute;
    top: 170px;
    left: 50%;
    width: 620px;
    height: auto;
    padding: 55px;
    transform: translateX(-50%);
    background-color: ${colors.giphyDarkestGrey};

    & p {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        margin: 0;
        line-height: 25px;
    }
`

const Links = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
`

const DisclaimerContainer = styled.div`
    color: ${colors.giphyLightGrey};
    margin-top: 35px;
    flex-direction: column;
    text-align: center;
`

const Highlight = styled.span`
    color: ${colors.giphyWhite};
    font-weight: bold;
`

type Props = {
    csv: string
    csvCtr: string
    onClose: () => void
    onDownload?: (label: string) => void
    onError?: (err: Error) => void
}

const CsvModal = ({ csv, csvCtr, onClose, onDownload, onError }: Props) => {
    const [isEscPressed] = useKeyPress((event: KeyboardEvent) => event.keyCode === 27)
    const [isDownLoading, setIsDownloadLoading] = useState(false)

    useEffect(() => {
        if (isEscPressed) {
            onClose()
        }
    }, [isEscPressed])
    const ref = useRef(null)
    useClickAway(ref, () => {
        onClose()
    })

    const downloadCSV = async (csvUrl, label) => {
        try {
            setIsDownloadLoading(true)
            const response = await request(csvUrl, {}, null, false)
            const fileName = response.headers.get('content-disposition').split('"')[1]
            download(await response.blob(), fileName, 'text/csv')

            onDownload?.(label)
        } catch (err) {
            onError?.(err)
        } finally {
            setIsDownloadLoading(false)
        }
    }

    return (
        <Modal>
            <Container ref={ref}>
                {(isDownLoading && (
                    <>
                        <p>{`Creating your CSV`}</p>
                        <DisclaimerContainer>
                            We are creating a CSV report of your data. Please remain on this screen and{' '}
                            <Highlight>do not refresh or navigate away</Highlight>. You will be redirected back to the
                            dashboard momentarily.
                        </DisclaimerContainer>
                        <>
                            <LoaderWrapper>
                                <Loader isAbsolute />
                            </LoaderWrapper>
                        </>
                    </>
                )) || (
                    <>
                        <p>
                            {`Select the data you'd like to download for your ${csvCtr ? 'GIFs' : 'Clips'}.`}
                            {csvCtr &&
                                ` Engagement Rate on GIPHY shows the percentage of times a user engages with your GIFs in search!`}
                        </p>
                        <Links>
                            <AnimatedDownloadButton
                                title={'View Count CSV'}
                                clickCallback={() => downloadCSV(csv, 'Views')}
                            />
                            {csvCtr && (
                                <AnimatedDownloadButton
                                    title={'Engagement Rate CSV'}
                                    clickCallback={() => downloadCSV(csvCtr, 'Engagement Rate')}
                                />
                            )}
                        </Links>
                        <DisclaimerContainer>
                            Processing large CSV’s can take a bit. Please be patient!
                        </DisclaimerContainer>
                    </>
                )}
            </Container>
        </Modal>
    )
}

export default CsvModal
