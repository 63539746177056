import { Helmet } from 'react-helmet'
import { fontCss } from './components/container'
import FormErrors from './components/form-errors'
import Form from './components/takedown-appeal'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    display: flex;
    ${fontCss};
    flex-wrap: wrap;
    justify-content: center;
`

const TakedownAppeal = () => (
    <Container>
        <Helmet>
            <title>Content Takedown Appeal | GIPHY</title>
        </Helmet>
        <Form />
        <FormErrors />
    </Container>
)

export default TakedownAppeal
