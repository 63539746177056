import * as colors from '@giphy/colors'
import { giphyLightGrey } from '@giphy/colors'
import { Button } from 'shared/components/buttons'
import Fields, { Label } from 'shared/components/form/fields'
import { CTAButton as BrandCTAButton } from 'shared/components/form/form-components'
import SharedButtonGroup from 'shared/components/form/inputs/button-group'
import Input from 'shared/components/form/ui/input'
import { Title } from 'shared/components/text-elements'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'

const labelWidth = 125
const labelMargin = 10
const leftColumn = labelWidth + labelMargin

export const FormContainer = styled.div`
    background: ${colors.giphyDarkestGrey};
    padding: 35px 90px 35px 55px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${mobile.css`
        background: transparent;
        padding: 10px;
    `}

    ${Label} {
        flex-shrink: 0;
        height: 40px;
        justify-content: flex-start;
        margin-right: ${labelMargin}px;
        text-align: left;
        width: ${labelWidth}px;

        ${mobile.css`
            color: ${giphyLightGrey};
            font-weight: bold;
            height: auto;
            line-height: 2;
            margin-right: 0;
            width: 100%;
            -webkit-font-smoothing: antialiased;
        `};
    }
`

// the other `input` fields have a global style :/,
// but nothing for textarea, so here it is
export const Textarea = styled(Input)`
    margin: 0;
    height: 100px;
    textarea {
        width: 100%;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: #000;
        padding: 10px;
        outline: none;
    }
`

export const FieldSection = styled(Fields)`
    margin-bottom: 20px;

    ${mobile.css`
        margin-bottom: 12px;
    `}
`

export const FooterTip = styled.div`
    color: ${colors.giphyLightGrey};
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 30px;
    margin-left: ${leftColumn}px;

    ${mobile.css`
        margin: 6px 0 20px;
    `}
`

export const Header = styled(Title)`
    font-size: 36px;
    margin-bottom: 40px;
    text-align: left;

    ${mobile.css`
        font-size: 22px;
        margin-bottom: 20px;
    `}
`

export const CTAButton = styled(BrandCTAButton)`
    width: calc(100% - ${leftColumn}px);
    margin-left: ${leftColumn}px;

    ${mobile.css`
        margin-left: 0;
        width: 100%;
    `}
`

export const ButtonGroups = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    ${Button} {
        height: 40px;
    }
    /* prettier-ignore */
    ${Button}:nth-child(4) {
        background: ${colors.giphyBlack};
        &:hover {
            background:${colors.giphyGreen}
        }
    }
    ${mobile.css`
        flex-wrap: wrap;
    `}
`

export const ButtonGroup = styled(SharedButtonGroup)`
    width: 100%;
`
