import { useContext, useEffect, useState } from 'react'
import { Loader } from '../preloader-animation/preloader-animation'
import { Loader as DotsLoader } from 'ui'
import DaysBuilder, { lastCalDate } from './days-builder'
import {
    addDays,
    addMonths,
    endOfMonth,
    format,
    isSameDay,
    isSameMonth,
    startOfMonth,
    subDays,
    subMonths,
} from 'date-fns'
import { CalEvent, getContentCalendarEvents } from 'shared/api/calendar'
import { MappedDay, getDateKey, streamlineCalEvents } from './streamline'
import {
    Asset,
    CalendarContainer,
    Title,
    Header,
    DayView,
    WeekDay,
    WeekAndMonth,
    Month,
    DayContolButtons,
    Arrow,
    Events,
    NoEvents,
    EventTile,
    Description,
    EventTitle,
    EventDate,
    Divider,
    PanelHolder,
    LeftPanel,
    RightPanel,
    Info,
    AnimatedContainer,
    AvatarImage,
    RightPanelContainer,
    LeftPanelContainer,
} from './styles'
import { trackGA4ContentCalendarEvent } from './ga4'
import UserContext from 'shared/contexts/user'
import { webFetch } from 'shared/api'
import { GifOverlayProps } from '@giphy/react-components'
import { IGif } from '@giphy/js-types'

const GifOverlay = ({ isHovered, gif }: GifOverlayProps) => {
    return isHovered && gif?.user?.avatar_url ? <AvatarImage src={gif.user.avatar_url} /> : <></>
}

const EventTileComponent = ({ event, getEventDate }) => {
    const { user } = useContext(UserContext)
    const [gif, setGif] = useState<IGif>()

    useEffect(() => {
        const fetchGif = async () => {
            const { data } = await webFetch.gif(event.assetId)
            setGif(data)
        }
        if (event.assetId) {
            fetchGif()
        }
    }, [])

    return (
        <EventTile key={event.id}>
            {event.assetId && gif && (
                <Asset
                    gif={gif}
                    width={90}
                    onGifClick={() => trackGA4ContentCalendarEvent('featured_gif', user)}
                    overlay={GifOverlay}
                />
            )}
            <Description isAsset={!!event.assetId}>
                <EventTitle>{event.summary}</EventTitle>
                <EventDate>{getEventDate(event)}</EventDate>
            </Description>
        </EventTile>
    )
}

const EventsList = ({
    selectedDate,
    setSelectedDate,
    setActiveDate,
    daysCache,
    eventSize,
}: {
    selectedDate: Date
    setSelectedDate: (arg0: Date) => void
    setActiveDate: (arg0: Date) => void
    daysCache: Map<string, MappedDay>
    eventSize: number
}) => {
    const { user } = useContext(UserContext)
    const [animationStatus, setAnimationStatus] = useState('fadeIn')
    const [proxyDate, setProxyDate] = useState(new Date())
    const [isLastDay, setIsLastDay] = useState(false)

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined
        if (animationStatus === 'fadeOut') {
            timeout = setTimeout(() => {
                setSelectedDate(proxyDate)
                setAnimationStatus('fadeIn')

                if (!isSameMonth(selectedDate, proxyDate)) {
                    if (proxyDate < selectedDate) {
                        setActiveDate(subMonths(selectedDate, 1))
                    } else {
                        setActiveDate(addMonths(selectedDate, 1))
                    }
                }
            }, 250)
        }
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationStatus])

    useEffect(() => {
        setIsLastDay(isSameDay(selectedDate, lastCalDate))
    }, [selectedDate])

    const changeDayWrapper = (newDate: Date) => {
        setProxyDate(newDate)
        setAnimationStatus('fadeOut')
    }

    const nextDay = () => {
        trackGA4ContentCalendarEvent('next_day', user)
        changeDayWrapper(addDays(selectedDate, 1))
        setAnimationStatus('fadeOut')
    }

    const prevDay = () => {
        if (isLastDay) return
        trackGA4ContentCalendarEvent('previous_day', user)
        changeDayWrapper(subDays(selectedDate, 1))
        setAnimationStatus('fadeOut')
    }

    const hasNoEvents = (day: MappedDay) => {
        return (
            day?.allDayEvents?.length === 0 &&
            day?.singleDayEvents?.length === 0 &&
            day?.multiDayEvents?.length === 0 &&
            day?.ongoingEvents?.length === 0
        )
    }

    const currentDay = daysCache.get(getDateKey(selectedDate))

    const getDayLabel = (event: CalEvent) => {
        const startDt = new Date(event.start.date)
        const startDay = new Date(startDt.valueOf() + startDt.getTimezoneOffset() * 60 * 1000)
        return format(startDay, 'MMMM D, YYYY')
    }

    const getDaysLabel = (event: CalEvent) => {
        const firstDayOfMonth = startOfMonth(selectedDate)
        const lastDayOfMonth = endOfMonth(selectedDate)
        const startDt = new Date(event.start.date)
        const endDt = new Date(event.end.date)
        const startDay = new Date(startDt.valueOf() + startDt.getTimezoneOffset() * 60 * 1000)
        const endDay = new Date(endDt.valueOf() + endDt.getTimezoneOffset() * 60 * 1000)

        if (startDay < firstDayOfMonth || endDay > lastDayOfMonth) {
            return `${format(startDay, 'MMMM D')} - ${format(endDay, 'MMMM D, YYYY')}`
        }
        return `${format(startDay, 'MMMM D')} - ${format(endDay, 'D, YYYY')}`
    }

    const isOngoingEventsPresent =
        (currentDay?.allDayEvents && currentDay.allDayEvents.length > 0) ||
        (currentDay?.ongoingEvents && currentDay.ongoingEvents.length)

    return (
        <>
            <Header>
                <DayView>{format(selectedDate, 'DD')}</DayView>
                <WeekAndMonth>
                    <WeekDay>{format(selectedDate, 'dddd')}</WeekDay>
                    <Month>{format(selectedDate, 'MMMM')}</Month>
                </WeekAndMonth>
                <DayContolButtons>
                    <Arrow className="ss-icon ss-navigateleft" onClick={prevDay} disabled={isLastDay} />
                    <Arrow className="ss-icon ss-navigateright" onClick={nextDay} />
                </DayContolButtons>
            </Header>
            <AnimatedContainer fadeStatus={animationStatus}>
                <Events onScroll={() => trackGA4ContentCalendarEvent('view_more', user)}>
                    {!currentDay && (
                        <NoEvents>
                            <DotsLoader type="blocks"></DotsLoader>
                        </NoEvents>
                    )}
                    {currentDay && eventSize && hasNoEvents(currentDay) && (
                        <NoEvents>
                            Any day can be a holiday! We recommend creating content for trend worthy moments like the
                            days of the week, seasonal weather patterns, and the zodiac signs!
                        </NoEvents>
                    )}
                    {currentDay?.singleDayEvents?.map((event: CalEvent) => (
                        <EventTileComponent key={event.id} event={event} getEventDate={getDayLabel} />
                    ))}
                    {currentDay?.multiDayEvents?.map((event: CalEvent) => (
                        <EventTileComponent key={event.id} event={event} getEventDate={getDaysLabel} />
                    ))}
                    {isOngoingEventsPresent ? <Divider>Ongoing</Divider> : <></>}
                    {currentDay?.ongoingEvents?.map((event: CalEvent) => (
                        <EventTileComponent key={event.id} event={event} getEventDate={getDaysLabel} />
                    ))}
                    {currentDay?.allDayEvents?.map((event: CalEvent) => (
                        <EventTileComponent key={event.id} event={event} getEventDate={getDayLabel} />
                    ))}
                </Events>
            </AnimatedContainer>
        </>
    )
}

const Calendar = ({ setLoader }) => {
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [activeDate, setActiveDate] = useState(new Date())

    const [daysCache, setDaysCache] = useState<Map<string, MappedDay>>(new Map())
    const [eventSize, setEventSize] = useState<number>(0)
    const [minFetchedDate, setMinFetchedDate] = useState<Date>(new Date())
    const [maxFetchedDate, setMaxFetchedDate] = useState<Date>(new Date())

    useEffect(() => {
        if (addMonths(minFetchedDate, 1) < activeDate && activeDate < subMonths(maxFetchedDate, 1)) {
            return
        }

        const monthBackwards = 2
        const monthUpwards = 2
        let timeMin = subMonths(startOfMonth(activeDate), 1)
        let timeMax = addMonths(endOfMonth(activeDate), 1)

        if (timeMin < minFetchedDate) {
            timeMin = startOfMonth(subMonths(timeMin, monthBackwards))
            timeMax = minFetchedDate
        }
        if (timeMax > maxFetchedDate) {
            timeMin = maxFetchedDate
            timeMax = endOfMonth(addMonths(timeMax, monthUpwards))
        }
        if (isSameDay(minFetchedDate, maxFetchedDate)) {
            timeMin = subMonths(startOfMonth(activeDate), 1)
            timeMax = addMonths(endOfMonth(activeDate), 1)
        }

        const formattedTimeMin = getDateKey(timeMin)
        const formattedTimeMax = getDateKey(timeMax)

        getContentCalendarEvents(formattedTimeMin, formattedTimeMax).then((res) => {
            const eventsCache = streamlineCalEvents(res.items, formattedTimeMin, formattedTimeMax, daysCache)
            setDaysCache(eventsCache)
            setEventSize(eventsCache.size)
            setLoader(false)
            setMinFetchedDate(timeMin < minFetchedDate ? timeMin : minFetchedDate)
            setMaxFetchedDate(timeMax > maxFetchedDate ? timeMax : maxFetchedDate)
        })
    }, [activeDate.getMonth()])

    return (
        <PanelHolder>
            <LeftPanelContainer>
                <LeftPanel>
                    <DaysBuilder
                        selectedDate={selectedDate}
                        activeDate={activeDate}
                        daysWithEvents={daysCache.get(getDateKey(activeDate))?.daysWithEvents}
                        setSelectedDate={setSelectedDate}
                        setActiveDate={setActiveDate}
                    />
                </LeftPanel>
            </LeftPanelContainer>
            <RightPanelContainer>
                <RightPanel>
                    <EventsList
                        daysCache={daysCache}
                        selectedDate={selectedDate}
                        eventSize={eventSize}
                        setActiveDate={setActiveDate}
                        setSelectedDate={setSelectedDate}
                    />
                </RightPanel>
            </RightPanelContainer>
        </PanelHolder>
    )
}

const ContentCalendar = ({}) => {
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        setIsFetching(true)
    }, [])

    return (
        <CalendarContainer>
            <Title>Trending Events Calendar</Title>
            <Info>
                Check out the events that trend on GIPHY throughout the year! Upload and tag relevant content for top
                moments to maximize your reach. Have questions?{' '}
                <a href={'https://support.giphy.com/hc/en-us/articles/360059071691-2023-Content-Calendar'}>
                    Learn more here.
                </a>
            </Info>
            {isFetching ? <Loader /> : <Calendar setLoader={setIsFetching} />}
        </CalendarContainer>
    )
}

export default ContentCalendar
