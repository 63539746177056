import { useContext, useEffect } from 'react'
import BulkEditModeContext from 'shared/contexts/bulk-edit-mode'
import ModalContext from 'shared/contexts/modal'
const BulkEditLauncher = () => {
    const { isBulkEditMode } = useContext(BulkEditModeContext)
    const { openBulkEditPanel } = useContext(ModalContext)
    useEffect(() => {
        if (isBulkEditMode) {
            openBulkEditPanel(isBulkEditMode)
        }
    }, [])
    return null
}
export default BulkEditLauncher
