import { isEmpty } from 'lodash'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { fetchChannelGifs } from 'shared/api/channels'
import ChannelPageContext from 'shared/contexts/channel-page'
import RefreshDataContext from 'shared/contexts/refresh-data'
import styled, { keyframes } from 'styled-components'
import { convertToImagingService } from '../../../shared/util/url'
import { EmptyCollection, PrivateCollection } from './banners'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const Container = styled.a`
    margin-bottom: 30px;
    display: block;
    width: 100%;
    animation: ${fadeIn} 0.333s ease-in forwards;
    height: 160px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`

type EnsureChannelProps = {
    children?: React.ReactNode
}

const EnsureChannel = ({ children }: EnsureChannelProps) => {
    const { channel } = useContext(ChannelPageContext)
    return channel && !isEmpty(channel) ? <>{children}</> : null
}

const ChannelBanner = () => {
    const { channel, channelType } = useContext(ChannelPageContext)
    // a channel will have no gifs only if it and its subchannels have no gifs
    const [channelHasGifs, setChannelHasGifs] = useState<boolean | undefined>()
    // const [channelHasClips, setChannelHasClips] = useState<boolean | undefined>()
    const { ancestors = [], is_private, id } = channel
    const channelWithBanner = [...ancestors, channel].reverse().find((c) => c.banner_image)
    const hasClips = channel.has_clips_feed
    const checkForChildren = async () => {
        // this is for checking if a channel has gifs
        if (channelType !== 'tagged_channel' && channelType !== 'search_channel') {
            const { data } = await fetchChannelGifs(id)
            setChannelHasGifs(data.length > 0 || hasClips)
        }
    }
    useEffect(() => {
        checkForChildren()
    }, [])
    const { onRefreshChannel } = useContext(RefreshDataContext)
    useEffect(() => {
        const off = onRefreshChannel(checkForChildren)
        return off
    }, [channel])

    const Banner = () =>
        channelWithBanner && channelWithBanner.banner_image ? (
            <Container
                style={{
                    backgroundImage: `url(${convertToImagingService(channelWithBanner.banner_image)})`,
                }}
            ></Container>
        ) : null
    return (
        <>
            {channelHasGifs === false && <EmptyCollection />}
            {(is_private || ancestors[0]?.is_private) && <PrivateCollection />}
            <Banner />
        </>
    )
}

export default () => (
    <EnsureChannel>
        <ChannelBanner />
    </EnsureChannel>
)
