import { giphyRed } from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { deleteUser } from 'shared/api'
import { ResponseError } from 'shared/api/errors'
import { Label } from 'shared/components/form/fields'
import Modal from 'shared/components/form/settings/delete-user-modal'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { SendMessageAction, message } from 'shared/redux/message'
import styled, { css } from 'styled-components'
import { IProfileUser } from 'types'
import { trackGA4DeleteAccountEvent } from './ga4'
import { CTAButton, FormContainer, ButtonGroups as SharedButtonGroups } from './sc'

type Props = {
    user: IUser
    className?: string
    message: SendMessageAction
}

const ButtonGroups = styled(SharedButtonGroups)`
    margin-bottom: 0;
`

const LaunchDelete = styled(CTAButton)`
    background-color: ${giphyRed};
    margin-left: 0;
`

const DeleteUserModal = styled(Modal)<{ userGone: boolean }>`
    & * {
        transition: 1s filter linear;
        filter: grayscale(${(props) => (props.userGone ? 100 : 0)}%);
        ${(props) =>
            props.userGone &&
            css`
                pointer-events: none;
            `}
    }
    transition: 1s background-color linear;
    ${(props) =>
        props.userGone &&
        css`
            background-color: black;
        `}
`

export type DeleteHandler = (error?: Error) => void

const deleteConfirmationTimeout = 5000
type useDeleteUserAPI = {
    deleteUser: (string) => void
    isDeleting: boolean
}
function useDeleteUser(id: number, cb: DeleteHandler): useDeleteUserAPI {
    const [isDeleting, setSave] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    useAsyncEffect(async () => {
        if (isDeleting) {
            try {
                await deleteUser(id, password)
                cb()
            } catch (error) {
                console.error(error)
                cb(error)
            }
            setSave(false)
        }
    }, [isDeleting])
    return {
        deleteUser: (password) => {
            setPassword(password)
            setSave(true)
        },
        isDeleting,
    }
}

const Messages = {
    password: `Oops, that's not the correct password! Please try again. Or don't...`,
    seeYa: `Your account is in the process of being deleted. Hate to see you leave 😢`,
}
const DeleteAccount = ({ message, user, className = '' }: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [userGone, setUserGone] = useState<boolean>(false)
    const deleteHandler: DeleteHandler = (error) => {
        if (error) {
            if (error as ResponseError) {
                const { details } = error as ResponseError
                if (details.password) {
                    message(Messages.password, 'error')
                } else {
                    // ResponseError.details has keys, but I don't what they are,
                    // and they can be anything so just show a default
                    message(`Error deleting, please try again`, 'error')
                }
            } else {
                message(`Error deleting, please try again`, 'error')
            }

            trackGA4DeleteAccountEvent('error')
        } else {
            message(Messages.seeYa, 'success', undefined, deleteConfirmationTimeout)
            setUserGone(true)
            trackGA4DeleteAccountEvent('success')
        }
    }
    useEffect(() => {
        let redirectInFive
        if (userGone) {
            redirectInFive = setTimeout(() => (location.href = '/'), deleteConfirmationTimeout)
        }
        return () => clearTimeout(redirectInFive)
    }, [userGone])

    const { deleteUser, isDeleting } = useDeleteUser(user.id, deleteHandler)
    return (
        <FormContainer className={className}>
            {showModal && (
                <DeleteUserModal
                    isDeleting={isDeleting}
                    userGone={userGone}
                    hasSocialAuth={(user as IProfileUser).has_social_auth}
                    onClose={() => {
                        // don't let the user close the model if they successfully deleted
                        if (!userGone) {
                            setShowModal(false)
                            trackGA4DeleteAccountEvent('cancel')
                        }
                    }}
                    onDelete={(password: string) => deleteUser(password)}
                />
            )}
            <ButtonGroups>
                <Label label="Channel Privacy" />
                <LaunchDelete
                    onClick={() => {
                        setShowModal(true)
                        trackGA4DeleteAccountEvent('open')
                    }}
                    disabled={isDeleting}
                >
                    Delete Your Account
                </LaunchDelete>
            </ButtonGroups>
        </FormContainer>
    )
}

export default compose(connect(undefined, { message }))(DeleteAccount)
