import styled, { css } from 'styled-components'
import PillsSlider from '../pills-slider'
import { useEffect, useState } from 'react'
import { Content, Emoji, Title, Copy, SupportLink } from './common-styles'
// import { smallScreenSize } from '../upgraded-user-onboarding'

const SeasonedWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 44px;
`

const Seasoned = styled.a`
    margin-top: 40px;
    z-index: 2;
`
const Seasons = styled.div`
    position: relative;
    top: 50px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 1300px), (max-height: 880px) {
        top: -47px;
    }
`
const SmallEmoji = styled.img<{ gradient: string | null }>`
    width: 130px;
    height: 130px;
    border-radius: 50%;
    flex: none;
    order: 1;
    flex-grow: 0;

    ${({ gradient }) =>
        !!gradient &&
        css`
            background: ${gradient};
        `};
`
const SmallTitle = styled.span`
    width: 130px;
    height: 22px;
    justify-content: center;

    /* P – Small/Bold */

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 147% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

const SeasonedComponent = ({ name, asset, bg, href }) => {
    return (
        <Seasoned href={href} target="_blank">
            <SmallEmoji src={asset} gradient={bg} />
            <SmallTitle>{name}</SmallTitle>
        </Seasoned>
    )
}

const seasons = [
    {
        name: 'Spring',
        gradient: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%)',
        events: [
            {
                name: 'Easter',
                asset: 'https://media2.giphy.com/media/dchiy5hMO1LgexS8Cz/200w.gif',
                bg: 'linear-gradient(240.25deg, #00FF99 7.79%, #00CCFF 92.21%);',
                href: 'https://giphy.com/search/easter',
            },
            {
                name: 'Cinco De Mayo',
                asset: 'https://media2.giphy.com/media/cOhwFnQTQSL4BLGw42/200w.gif',
                bg: 'linear-gradient(60.66deg, #FF6666 -4.93%, #FFF35C 115.46%);',
                href: 'https://giphy.com/search/cinco-de-mayo',
            },
            {
                name: 'Mother’s Day',
                asset: 'https://media2.giphy.com/media/ugL1IdPHVl6htAkQzP/200w.gif',
                bg: 'linear-gradient(240.25deg, #FF6666 7.79%, #E646B6 92.21%);',
                href: "https://giphy.com/search/mother's-day",
            },
            {
                name: 'Gemini',
                asset: 'https://media2.giphy.com/media/VzrLEbaKUyupFacJiy/200w.gif',
                bg: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%);',
                href: 'https://giphy.com/search/gemini',
            },
            {
                name: 'Pride',
                asset: 'https://media2.giphy.com/media/YXtxNubDDwvw2Jf0Ir/200w.gif',
                bg: 'linear-gradient(232.84deg, #9933FF 0%, #6157FF 100%);',
                href: 'https://giphy.com/search/pride',
            },
        ],
    },
    {
        name: 'Summer',
        gradient: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%)',
        events: [
            {
                name: 'Juneteeth',
                asset: 'https://media2.giphy.com/media/wWnEdCXzwkQQcFApZX/200w.gif',
                bg: 'linear-gradient(240.25deg, #00FF99 7.79%, #00CCFF 92.21%);',
                href: 'https://giphy.com/search/juneteeth',
            },
            {
                name: '4th of July',
                asset: 'https://media2.giphy.com/media/NDt7FNhlJK0XnduLnp/200w.gif',
                bg: 'linear-gradient(60.66deg, #FF6666 -4.93%, #FFF35C 115.46%);',
                href: 'https://giphy.com/search/4th-of-july',
            },
            {
                name: 'International Cat Day',
                asset: 'https://media2.giphy.com/media/FZAmJToAKSsQOoRMxy/200w.gif',
                bg: 'linear-gradient(240.25deg, #FF6666 7.79%, #E646B6 92.21%);',
                href: 'https://giphy.com/search/international-cat-day',
            },
            {
                name: 'National Ice Cream Day',
                asset: 'https://media2.giphy.com/media/xUPGcheURV7DHb5rtC/200w.gif',
                bg: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%);',
                href: 'https://giphy.com/search/national-ice-cream-day',
            },
            {
                name: 'Labor Day',
                asset: 'https://media2.giphy.com/media/Y0PcgyzjZwOTlz8P2P/200w.gif',
                bg: 'linear-gradient(232.84deg, #9933FF 0%, #6157FF 100%);',
                href: 'https://giphy.com/search/labor-day',
            },
        ],
    },
    {
        name: 'Autumn',
        gradient: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%)',
        events: [
            {
                name: 'First Day of Fall',
                asset: 'https://media2.giphy.com/media/2CmD2VNiZUJwgQoEgM/200w.gif',
                bg: 'linear-gradient(240.25deg, #00FF99 7.79%, #00CCFF 92.21%);',
                href: 'https://giphy.com/search/first-day-of-fall',
            },
            {
                name: 'National Taco Day',
                asset: 'https://media2.giphy.com/media/xUPGcnkuXfoEfhfXdS/200w.gif',
                bg: 'linear-gradient(60.66deg, #FF6666 -4.93%, #FFF35C 115.46%);',
                href: 'https://giphy.com/search/national-taco-day',
            },
            {
                name: 'Halloween',
                asset: 'https://media2.giphy.com/media/tHXcnD7LM9wdfj93rb/200w.gif',
                bg: 'linear-gradient(240.25deg, #FF6666 7.79%, #E646B6 92.21%);',
                href: 'https://giphy.com/search/halloween',
            },
            {
                name: 'DiWali',
                asset: 'https://media2.giphy.com/media/8moGxSidI8BqniwguG/200w.gif',
                bg: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%);',
                href: 'https://giphy.com/search/diwali',
            },
            {
                name: 'Thanksgiving',
                asset: 'https://media2.giphy.com/media/dgax93y6499AfH01PI/200w.gif',
                bg: 'linear-gradient(232.84deg, #9933FF 0%, #6157FF 100%);',
                href: 'https://giphy.com/search/thanksgiving',
            },
        ],
    },
    {
        name: 'Winter',
        gradient: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%)',
        events: [
            {
                name: 'Holidays',
                asset: 'https://media2.giphy.com/media/jmeuptoHVe6f7pBd6T/200w.gif',
                bg: 'linear-gradient(240.25deg, #00FF99 7.79%, #00CCFF 92.21%);',
                href: 'https://giphy.com/search/holidays',
            },
            {
                name: 'Groundhog Day',
                asset: 'https://media2.giphy.com/media/zyJlnE1T7sDsNdoxJA/200w.gif',
                bg: 'linear-gradient(60.66deg, #FF6666 -4.93%, #FFF35C 115.46%);',
                href: 'https://giphy.com/search/groundhog-day',
            },
            {
                name: 'Superbowl',
                asset: 'https://media2.giphy.com/media/qnX99ZUEl7F7FJuxmZ/200w.gif',
                bg: 'linear-gradient(240.25deg, #FF6666 7.79%, #E646B6 92.21%);',
                href: 'https://giphy.com/search/superbowl',
            },
            {
                name: 'Black History Month',
                asset: 'https://media2.giphy.com/media/KAfQaNzAsNrx845Joh/200w.gif',
                bg: 'linear-gradient(52.84deg, #6157FF 0%, #00CCFF 100%);',
                href: 'https://giphy.com/search/black-history-month',
            },
            {
                name: "Valentine's Day",
                asset: 'https://media2.giphy.com/media/YondZW6AMjgTEHevF0/200w.gif',
                bg: 'linear-gradient(232.84deg, #9933FF 0%, #6157FF 100%);',
                href: "https://giphy.com/search/valentine's-day",
            },
        ],
    },
]

export const ContentStrategy = () => {
    const [season, setSeason] = useState(0)
    const [animatedOpacity, setAnimatedOpacity] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setAnimatedOpacity(100)
        }, 200)
    }, [])

    return (
        <>
            <Emoji src="https://media2.giphy.com/media/L4ZI0w4waQ2vSkMgU9/200w.gif" />
            <Content style={{ opacity: animatedOpacity }}>
                <Title>Level up your content strategy!</Title>
                <Copy>
                    Create content that aligns with what our users are searching for. Our event calendar helps you take
                    advantage of high traffic holidays on GIPHY. We recommend that you pick moments that feel authentic
                    to your brand or portfolio. Any day can be a holiday! We also recommend creating content for the
                    days of the week, seasonal weather trends, and the zodiac signs.
                </Copy>
                <SupportLink
                    href="https://support.giphy.com/hc/en-us/articles/360059071691-2023-Content-Calendar"
                    target="_blank"
                >
                    See our full calendar here
                </SupportLink>
                <Seasons>
                    <PillsSlider allOptions={seasons} currentValue={seasons[season].name} setValue={setSeason} />
                    <SeasonedWrapper>
                        {seasons[season]?.events.map((event) => (
                            <SeasonedComponent name={event.name} asset={event.asset} bg={event.bg} href={event.href} />
                        ))}
                    </SeasonedWrapper>
                </Seasons>
            </Content>
        </>
    )
}
