import GridHeader from 'shared/components/gif-grid/header'
import Grid from 'shared/components/grid'
import { webFetch } from 'shared/api'
import { grid12 } from 'shared/util/grid'

const Trending = () => {
    const fetchGifs = (offset: number) => webFetch.trending({ offset, rating: 'pg-13' })
    return (
        <>
            <GridHeader title={'Trending GIFs'} />
            <Grid fetchGifs={fetchGifs} width={grid12} columns={4} />
        </>
    )
}

export default Trending
