import styled from 'styled-components'
import SelectDropdown from 'shared/components/form/ui/select-dropdown'
import DatePicker from 'shared/components/form/ui/date-picker'

type CalendarIconProps = {
    active: boolean
}

const CalendarIcon = styled.i<CalendarIconProps>`
    margin-right: -10px;
    margin-left: -10px;
    font-size: 20px;
    color: ${(props) => (props.active ? 'white' : 'inherit')};
`

const Container = styled.div``

type Props = {
    onDateUpdate: (start: string | Date, end: string | Date) => void
    active: boolean
}

const DateRangeFilter = ({ active, onDateUpdate = () => {} }: Props) => (
    <Container>
        <SelectDropdown themeId={'icon'} label={<CalendarIcon active={active} className="ss-calendar" />}>
            <DatePicker defaultRange={[new Date(), new Date()]} maxDate={new Date()} onUpdate={onDateUpdate} />
        </SelectDropdown>
    </Container>
)

export default DateRangeFilter
