import styled from 'styled-components'
import { mobile } from 'shared/util/media-query'

const HeroVideo = styled.video`
    width: 100%;
    object-fit: cover;
    height: 200px;
    margin: 15px 0;
    ${mobile.css` 
        height: 65px;
        margin: 5px 0;
    `}
`

const BannerContainer = styled.div`
    position: relative;
`

const DashboardTitle = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: 'nexaregular', 'nexabook';
    font-size: 51px;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.5);
    ${mobile.css` 
        font-size: 20px;
    `}
`

const GIPHYTitle = styled.span`
    font-family: 'nexablack';
`

const DashboardBanner = () => {
    return (
        <BannerContainer>
            <DashboardTitle>
                <GIPHYTitle>GIPHY</GIPHYTitle> Dashboard
            </DashboardTitle>
            <HeroVideo autoPlay loop muted playsInline>
                <source src="/static/img/dashboard/dashboardbanner.mp4" type="video/mp4" />
            </HeroVideo>
        </BannerContainer>
    )
}

export default DashboardBanner
