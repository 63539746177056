exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);

// module
exports.push([module.id, ".O0e3MqAEJc7CL1_YLFovX {\n\tcolor: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyGrey"] + ";\n\tdisplay: flex;\n\tfloat: left;\n}\n\n.O0e3MqAEJc7CL1_YLFovX:after {\n\t\tcontent: ' / ';\n\t\tpadding: 0 4px;\n\t}\n\n.O0e3MqAEJc7CL1_YLFovX:hover {\n\t\tcolor: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n\t}\n\n.O0e3MqAEJc7CL1_YLFovX:hover:after {\n\t\t\tcolor: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyGrey"] + ";\n\t\t}\n\n/*remove trailing slash*/\n\n.O0e3MqAEJc7CL1_YLFovX:last-child:after {\n\t\tcontent: '';\n\t}\n\n.NyRh2V5pdgZZM8PQAuMp3 {\n\tcolor: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n}\n\n.NyRh2V5pdgZZM8PQAuMp3:hover {\n\t\tcolor: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n\t}\n", ""]);

// exports
exports.locals = {
	"colors": "'shared/css/colors.css'",
	"giphyGrey": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyGrey"] + "",
	"giphyWhite": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + "",
	"breadcrumb": "O0e3MqAEJc7CL1_YLFovX",
	"breadcrumbSingle": "NyRh2V5pdgZZM8PQAuMp3"
};