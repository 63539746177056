import { giphyAqua, giphyIndigo, giphyLightBlue, giphyPink } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { fill, isEmpty, uniqueId } from 'lodash'
import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react'
import { fetchChannel, fetchChannelGifs } from 'shared/api/channels'
import SharedCarousel from 'shared/components/carousel/carousel'
import gifSkeleton from 'shared/components/gif-skeleton'
import Observer from 'shared/components/observer'
import ChannelPageContext from 'shared/contexts/channel-page'
import ModalContext from 'shared/contexts/modal'
import RefreshDataContext from 'shared/contexts/refresh-data'
import { useCanCurateCollection } from 'shared/hooks/use-permissions'
import { IChannel } from 'shared/types/channel'
import styled from 'styled-components'
import ChannelCarouselItem from './carousel-item'

const MAX_CAROUSEL_ITEMS = 3
const colors = [giphyAqua, giphyPink, giphyIndigo, giphyLightBlue]

const emptyGifPlaceholder = () =>
    gifSkeleton({ label: ``, url: ' ' }, { src: 'https://media.giphy.com/media/L3oPamhY5bxT24LbJB/giphy.gif' })

const createGif = ({ featured_gif, url, short_display_name, display_name }): IGif => ({
    ...(featured_gif || emptyGifPlaceholder()),
    label: short_display_name || display_name,
    url,
})

const Carousel = styled(SharedCarousel)`
    overflow: hidden;
`

type Props = {
    index: number
    channel: IChannel
    className?: string
}

export const CarouselSection = ({ index, className, channel: initialChannel }: Props) => {
    const { openNewCollectionPanel, openAddGifsToCollectionPanel } = useContext(ModalContext)
    const { channel: rootChannel } = useContext(ChannelPageContext)
    const { onRefreshChannel, offRefreshChannel } = useContext(RefreshDataContext)
    const [isVisible, setIsVisible] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState(colors[index % colors.length])
    const [channel, setChannel] = useState<IChannel>(initialChannel)
    const [channelGifs, setChannelGifs] = useState<IGif[]>([])
    const canCurate = useCanCurateCollection()
    const channelRefresh = useCallback(
        async (updatedChannel) => {
            if (updatedChannel.id !== channel.id) {
                return
            }
            setChannel(updatedChannel)
            if (!hasChildren) {
                const { data: gifs } = await fetchChannelGifs(id)
                onGifs(gifs)
            }
        },
        [channel.id]
    )
    const {
        has_children: hasChildren,
        id,
        display_name: title,
        is_private: isPrivate,
        url: titleUrl,
        user,
        children,
    } = channel
    // gifs that have been fetched, may be overwritten
    let gifs = [...channelGifs]
    const onGifs = (gifs: IGif[]) => {
        if (gifs.length === 0) {
            setBackgroundColor('transparent')
        } else {
            setChannelGifs(gifs)
        }
    }

    useEffect(() => {
        onRefreshChannel(channelRefresh)
        return () => offRefreshChannel(channelRefresh)
    }, [channel.id])

    const onVisible = async (isVisible) => {
        if (isVisible && usingPlaceholderGifs) {
            setIsVisible(true)
            if (hasChildren) {
                const channel = await fetchChannel(id, { fetch_children: true })
                setChannel(channel)
            } else {
                const { data: gifs } = await fetchChannelGifs(id)
                onGifs(gifs)
            }
        }
    }

    let usingPlaceholderGifs = false
    const isNotLeaf = hasChildren && !isEmpty(children)
    if (isNotLeaf) {
        // get the channels that have been fetched
        gifs = children.map(createGif)
        // if no gifs, create placeholder gifs
    } else if (isEmpty(gifs)) {
        // branch channel carousel items have labels, leaf items don't
        // @ts-ignore
        gifs = fill(Array(3), gifSkeleton({ label: hasChildren ? ' ' : '', url: ' ' })).map((gif) => ({
            ...gif,
            id: uniqueId(`placeholder`),
        }))
        usingPlaceholderGifs = true
    }
    const events = {}

    if (canCurate) {
        let addGif: IGif | undefined
        if (isNotLeaf) {
            addGif = gifSkeleton(
                { label: `Create New Collection`, url: ' ' },
                { src: 'https://media.giphy.com/media/fTytsfKCVyb6lB75jM/giphy.gif' }
            )
            events[addGif.id] = (_gif: IGif, e: SyntheticEvent) => {
                e.preventDefault()
                openNewCollectionPanel({ userId: user.id, parentChannel: channel, redirectOnCreate: true })
            }
        } else {
            addGif = gifSkeleton(
                { label: ``, url: ' ' },
                { src: 'https://media.giphy.com/media/fTytsfKCVyb6lB75jM/giphy.gif' }
            )
            events[addGif.id] = (_gif: IGif, e: SyntheticEvent) => {
                e.preventDefault()
                openAddGifsToCollectionPanel(channel.id, rootChannel.id)
            }
        }
        gifs = [addGif, ...gifs]
    }
    return (
        <Observer onVisibleChange={onVisible} fireOnce key={id} style={{ marginTop: 30 }}>
            <Carousel
                className={className}
                channelId={id}
                maxBlocks={MAX_CAROUSEL_ITEMS}
                {...{ isVisible, title, titleUrl, isPrivate }}
            >
                {gifs.map((gif, index) => (
                    <ChannelCarouselItem
                        key={index}
                        gif={gif}
                        onGifClick={events[gif.id]}
                        backgroundColor={backgroundColor}
                        itemIndex={{ index, max: MAX_CAROUSEL_ITEMS }}
                    />
                ))}
            </Carousel>
        </Observer>
    )
}

const ItemGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const GifWithLabel = styled(ChannelCarouselItem)<{ columns: number }>`
    margin-bottom: 16px;
    ${(props) => `&:nth-child(${props.columns}n)`} {
        margin-right: 0;
    }
`
export const BranchGrid = ({ channels = [] }: { channels: IChannel[] }) => {
    const gifs = channels.map(createGif)
    return (
        <ItemGrid>
            {gifs.map((gif, index) => (
                <GifWithLabel
                    columns={3}
                    key={index}
                    gif={gif}
                    onGifClick={undefined}
                    backgroundColor={colors[0]}
                    itemIndex={{ index, max: 100 }}
                />
            ))}
        </ItemGrid>
    )
}

const PaginationCarousels = ({ channels = [] }: { channels: IChannel[] }) => {
    return (
        <>
            {channels.map((channel, index) => (
                <CarouselSection key={channel.id} {...{ channel, index }} />
            ))}
        </>
    )
}

export default PaginationCarousels
