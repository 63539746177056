import { createContext } from 'react'
import { IUser } from '@giphy/js-types'

interface DashboardUser extends IUser {
    permissions?: any
    avatar: string
    channel: string
    channel_id: number
    is_public: boolean
    is_staff: boolean
}

export type DashboardUserContextProps = {
    user: DashboardUser
}

const DashboardUserContext = createContext({} as DashboardUserContextProps)

export default DashboardUserContext
