import { giphyIndigo, giphyPurple } from '@giphy/colors'
import { useContext } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import UserContext from 'shared/contexts/user'
import styled from 'styled-components'
import AnimationComponent from './animation'
import Copy from './copy'

const ClipsCTA = styled.div`
    background: linear-gradient(45deg, ${giphyIndigo}, ${giphyPurple});
    border-radius: 8px;
    position: relative;
    width: 100%;
`

const Animation = styled(AnimationComponent)`
    left: 0;
    position: absolute;
    top: 0;
`

type Props = {
    className?: string
    gaCategory: string
}

const UploadClipsCTA = ({ className }: Props) => {
    const { user } = useContext(UserContext)
    const [isDimissed, setIsDimissed] = useLocalStorage('has-seen-clips-cta', false)

    const onCTAClick = () => {}

    const onDismiss = () => {
        setIsDimissed(true)
    }

    if (isDimissed || !user.permissions.upload_videos) {
        return null
    }

    return (
        <ClipsCTA className={className}>
            <Animation />
            <Copy {...{ onCTAClick, onDismiss }} />
        </ClipsCTA>
    )
}

export default UploadClipsCTA
