import styled from 'styled-components'

export const Emoji = styled.img`
    position: relative;
    width: 174px;
    height: 174px;
    top: -47px;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        width: 146px;
        height: 146px;
    }
`
export const Title = styled.h1`
    position: relative;
    top: 36px
    width: 880px;
    height: 36px;

    /* H1/Regular */

    font-family: 'nexablack', sans-serif;
    font-style: normal;
    font-size: 36px;
    line-height: 36px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
`
export const Copy = styled.h3`
    width: 880px;

    /* H3/Light */

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    /* or 136% */

    text-align: center;

    /* ↪ Grey/08 Almost White */

    color: #d8d8d8;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        font-size: 20px;
        line-height: 26px;
    }
`

export const SupportLink = styled.a`
    width: 880px;
    height: 90px;
    font-family: 'InterFace';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;

    text-align: center;
    color: #00ccff;
`

export const Content = styled.div`
    top: -27px;
    position: relative;
    opacity: 0;
    // visibility:hidden;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;*/
    transition: all 1s ease 0s;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
`
