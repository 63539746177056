import * as colors from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import { setGADataLayer } from 'analytics'
import cookie from 'cookie'
import { useContext, useEffect, useRef, useState } from 'react'
import { Button } from 'shared/components/buttons'
import { DarkFields } from 'shared/components/form/apply-form'
import { FIELDS, FieldContainer as FieldsFieldContainer } from 'shared/components/form/fields'
import DatePicker from 'shared/components/form/ui/date-picker'
import SelectDropdown from 'shared/components/form/ui/select-dropdown'
import SelectList from 'shared/components/form/ui/select-list'
import MessageContext from 'shared/contexts/message'
import UserContext from 'shared/contexts/user'
import resizeAvatar from 'shared/util/resize-avatar'
import { getErrorFields } from 'utils/src/api'
import styled, { css } from 'styled-components'
import { CloseButton, ColoredStrip } from './form-elements'
import NumberInput from './number-input'
import SuccessContainer from './success-container'
import { BudgetTooltipContent, ModalTooltip, ProjectTypeTooltipContent, TooltipContainer } from './tooltips'

// TODO: Consider moving this to a shared location.
export const Checkbox = styled.div<{ checked: boolean }>`
    background: ${colors.giphyDarkGrey};
    border-radius: 3px;
    height: 24px;
    width: 24px;
    margin: 0 7px;
    cursor: pointer;

    ${(props) =>
        props.checked &&
        css`
            background-image: url('/static/img/check-mark.png');
            background-size: 100%;
            background-size: 64%;
            background-repeat: no-repeat;
            background-position: center;
        `}
`

const Disclaimer = styled.p`
    color: ${colors.giphyLightGrey};
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.3px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
`
const SubmitButton = styled(Button)<{ disabled: boolean }>`
    width: 100%;
    font-size: 16px;
    line-height: 48px;
    height: auto;
    border-radius: 2px;
    ${({ disabled }) => !disabled && 'background: linear-gradient(45deg, rgb(0, 204, 255) 0%, rgb(97, 87, 255) 100%);'}
    &:hover {
        filter: brightness(1.1);
    }
`

const EmailContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${FieldsFieldContainer} {
        margin-top: 0px;
    }
`
const ForHireFormContainer = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`
const ForHireForm = styled.form`
    position: absolute;
    top: 60px; // design specifies 170px but that places the modal quite low
    left: 50%;
    width: 663px;
    height: auto;
    padding: 40px 40px 20px;
    background-color: green;
    transform: translateX(-50%);
    z-index: 999;
    background: ${colors.giphyBlack};
`
const Avatar = styled.img`
    height: 80px;
    width: 80px;
    margin-right: 20px;
`

const EmailField = styled(DarkFields)`
    width: 100%;
    font-weight: bold;

    input {
        background: ${colors.giphyDarkGrey};
        border-radius: 4px;
    }

    // Selects the error message icon tooltip. Not sure how else to tweak the position of this.
    & > div > div > div > input + div {
        top: 4px;
    }
`
const FieldRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 42px;
`

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &:nth-child(2) {
        margin-left: 30px;
    }
`
const DropdownFieldContainer = styled(FieldContainer)<{ fixedWidth?: number; selected?: any }>`
    ${({ fixedWidth }) => fixedWidth && 'width:' + fixedWidth + 'px;'}
    max-width: 586px;

    // Label
    & > div > div:first-child > span {
        color: ${({ selected }) => (selected?.length > 0 ? 'white' : colors.giphyLightGrey)};
        line-height: 42px;
        font-size: 16px;
    }
`

const StyledDropdown = styled(SelectDropdown)`
    margin-top: 2px;

    // Input, Arrow
    & > div,
    & > div > div {
        height: 42px;
    }
`

export const FieldExplanation = styled.span`
    color: ${colors.giphyLightGrey};
    font-size: 16px;
    align-items: center;
`
const RowLabel = styled.div`
    color: white;
    font-size: 18px;
    margin: 8px 0px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    div {
        flex-direction: row;
    }
    span {
        display: flex;
    }
`
const MessagingHeader = styled.div`
    display: flex;
    flex-direction: row;
    color: ${colors.giphyLightestGrey};
    font-family: interface;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 40px;
`

const MessagingText = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
`

const Username = styled.span`
    color: white;
    font-family: nexablack;
    font-size: 34px;
    line-height: 42px;
    margin-top: 3px;
    font-weight: normal;
`

type Props = {
    user: IUser
    deactivateModal: () => void
}

const ForHireModal = ({ user, deactivateModal }: Props) => {
    const formRef = useRef<HTMLFormElement | null>(null)

    const { sendMessage } = useContext(MessageContext)
    const { user: loggedInUser } = useContext(UserContext)

    // TODO: Implement backend response errors:
    // const [responseErrors, setResponseErrors] = useState<any>(null)
    const { username, display_name } = user
    const preferredName = display_name || username

    // Optional form fields:
    const [numOfGifs, setNumberOfGifs] = useState<number | ''>(0)
    const [numOfStickers, setNumberOfStickers] = useState<number | ''>(0)
    const [numOfVideos, setNumberOfVideos] = useState<number | ''>(0)
    const [flexibleProject, setFlexibleProject] = useState<boolean>(false)

    // Mandatory fields:
    const [typeOfProject, setTypeOfProject] = useState<string | null>(null)
    const [projectTimeline, setProjectTimeline] = useState<any[] | null>(null)
    const [projectBudget, setProjectBudget] = useState<string | null>(null)
    const [emailValid, setEmailValid] = useState<boolean | null>(null)

    // Flag to determine whether form is ready to submit
    const [readyForSubmit, setReadyForSubmit] = useState<boolean>(false)
    // Flag to determine whether or not form has already been submitted successfully
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

    // TODO: Turn on and off body scrolling when active
    // useEffect(() => {
    //     document.body.style.overflow = 'hidden'
    //     return () => {
    //         document.body.style.overflow = ''
    //     }
    // }, [])

    useEffect(() => {
        checkFormReadiness()
    }, [
        typeOfProject,
        projectTimeline,
        projectBudget,
        flexibleProject,
        numOfGifs,
        numOfVideos,
        numOfStickers,
        numOfVideos,
        emailValid,
    ])
    const dateRangeUpdated = (start, end) => {
        setProjectTimeline([start, end])
    }

    const onContactChange = (isValid) => {
        setEmailValid(isValid)
    }

    const checkFormReadiness = () => {
        if (
            projectTimeline &&
            projectBudget &&
            projectBudget !== '' &&
            typeOfProject &&
            typeOfProject.length > 0 &&
            (flexibleProject || numOfGifs !== 0 || numOfStickers !== 0 || numOfVideos !== 0) &&
            emailValid
        ) {
            setReadyForSubmit(true)
        } else {
            setReadyForSubmit(false)
        }
    }

    const getBudgetMin = (budgetString: string) => {
        if (budgetString === '+$50,000') return '50000'
        let newString = budgetString.replace(/\$/g, '').replace(/\,/g, '')
        let minPrice = newString.split('-')[0].trim()
        return minPrice
    }

    const getBudgetMax = (budgetString: string) => {
        if (budgetString === '+$50,000') return '100000'
        let newString = budgetString.replace(/\$/g, '').replace(/\,/g, '')
        let maxPrice = newString.split('-')[1].trim()
        return maxPrice
    }

    const formatDateObj = (dateObj: Date) => {
        var date = new Date(dateObj)
        var dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
        return dateString
    }

    const submitForm = async (event) => {
        let payload = {}
        const { csrftoken } = cookie.parse(document.cookie)
        event.stopPropagation()
        event.preventDefault()
        if (formRef.current == null) {
            return
        }
        const formData = new FormData(formRef.current)

        formData.set(`project_type`, typeOfProject || 'None')

        if (projectTimeline![0] > projectTimeline![1]) {
            formData.set(`project_start_date`, formatDateObj(projectTimeline![1]))
            formData.set(
                `project_end_date`,
                projectTimeline![1] != null ? formatDateObj(projectTimeline![0]) : formatDateObj(projectTimeline![0])
            )
        } else {
            formData.set(`project_start_date`, formatDateObj(projectTimeline![0]))
            formData.set(
                `project_end_date`,
                projectTimeline![1] != null ? formatDateObj(projectTimeline![1]) : formatDateObj(projectTimeline![0])
            )
        }

        formData.set(`project_budget_min`, getBudgetMin(projectBudget!))
        formData.set(`project_budget_max`, getBudgetMax(projectBudget!))
        formData.set(`project_gif_count`, numOfGifs === '' ? (0).toString() : numOfGifs.toString())
        formData.set(`project_sticker_count`, numOfStickers === '' ? (0).toString() : numOfStickers.toString())
        formData.set(`project_video_count`, numOfVideos === '' ? (0).toString() : numOfVideos.toString())
        formData.set(`project_flexible`, flexibleProject.toString())

        formData.forEach((value, key) => (payload[key] = value))
        try {
            const response = await fetch(`/api/v1/users/${user.id}/freelance-email/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
                body: JSON.stringify(payload),
            })

            if (response.ok) {
                setFormSubmitted(true)
                setGADataLayer({
                    event: 'hire_form_success',
                    options: {
                        form: {
                            formName: 'hire me',
                        },
                    },
                })
            } else {
                window.scrollTo(0, 0)
                const errorFields = getErrorFields(await response.json())
                setGADataLayer({
                    event: 'hire_form_error',
                    options: {
                        form: {
                            formName: 'hire me',
                            formError: errorFields.join(','),
                        },
                    },
                })
                sendMessage('Oops! Something went wrong. Please try again later.', 'error')
            }
        } catch (error) {
            window.scrollTo(0, 0)
            sendMessage('Oops! Something went wrong. Please try again later.', 'error')
            setGADataLayer({
                event: 'hire_form_error',
                options: {
                    form: {
                        formName: 'hire me',
                        formError: 'uncaught exception',
                    },
                },
            })
        }
    }

    const formatDate = (date) => {
        return date.toDateString().split(' ').slice(1).join(' ')
    }

    const generateTimelineString = () => {
        if (projectTimeline != null) {
            let start = formatDate(projectTimeline[0])
            if (projectTimeline[1] != null) {
                return projectTimeline[0] > projectTimeline[1]
                    ? `${formatDate(projectTimeline[1])} - ${start}`
                    : `${start} - ${formatDate(projectTimeline[1])}`
            }
            return start
        }
    }

    const changeNumber = (val: string, whichType: string) => {
        // Only allow user to enter 0-9
        const re = /^$|^[0-9\b]{0,2}$/
        let formattedVal = val

        if (formattedVal === '') {
            switch (whichType) {
                case 'gifs':
                    setNumberOfGifs('')
                    break
                case 'stickers':
                    setNumberOfStickers('')
                    break
                case 'videos':
                    setNumberOfVideos('')
                    break
            }
        } else if (re.test(formattedVal) && formattedVal !== '') {
            switch (whichType) {
                case 'gifs':
                    setNumberOfGifs(parseInt(formattedVal))
                    break
                case 'stickers':
                    setNumberOfStickers(parseInt(formattedVal))
                    break
                case 'videos':
                    setNumberOfVideos(parseInt(formattedVal))
                    break
            }
        }
    }

    return (
        <>
            <ForHireFormContainer>
                {!formSubmitted ? (
                    <ForHireForm method="POST" onSubmit={submitForm} ref={formRef}>
                        <ColoredStrip />
                        <CloseButton onClick={deactivateModal}></CloseButton>
                        <MessagingHeader>
                            <Avatar src={resizeAvatar(user.avatar_url, 80)} />
                            <MessagingText>
                                <span>Work Inquiry for</span>
                                <Username>{preferredName}</Username>
                            </MessagingText>
                        </MessagingHeader>
                        <RowLabel>
                            I&apos;m Looking for:
                            <FieldExplanation>
                                <Checkbox
                                    checked={flexibleProject}
                                    onClick={() => setFlexibleProject(!flexibleProject)}
                                />
                                Uncertain Amount/Flexible
                            </FieldExplanation>
                        </RowLabel>
                        <FieldRow>
                            <NumberInput onChange={changeNumber} contentType={'gifs'} numberVal={numOfGifs}>
                                <label>GIFs</label>
                            </NumberInput>
                            <NumberInput onChange={changeNumber} contentType={'stickers'} numberVal={numOfStickers}>
                                <label>Animated Stickers</label>
                            </NumberInput>
                            <NumberInput onChange={changeNumber} contentType={'videos'} numberVal={numOfVideos}>
                                <label>
                                    Clips <span>(with sound)</span>
                                </label>
                            </NumberInput>
                        </FieldRow>

                        <FieldRow>
                            <DropdownFieldContainer selected={typeOfProject}>
                                <RowLabel>
                                    <span>
                                        What type of Project is this?
                                        <TooltipContainer>
                                            <ModalTooltip maxWidth={390} icon={'ss-info'}>
                                                <ProjectTypeTooltipContent />
                                            </ModalTooltip>
                                        </TooltipContainer>
                                    </span>
                                </RowLabel>
                                <SelectList
                                    onChange={(option) => setTypeOfProject(option)}
                                    options={[
                                        'Advertising Campaign',
                                        'Social Media Campaign',
                                        'Editorial',
                                        'Personal Promotion',
                                        'TV/Broadcast/Film',
                                        'Music Video',
                                        'Product Design',
                                        'Social Good',
                                    ]}
                                    placeholder="Choose which type(s)"
                                    themeId={'dark'}
                                    width={100}
                                    height={'42px'}
                                    allowMultiple={true}
                                />
                            </DropdownFieldContainer>
                        </FieldRow>

                        <FieldRow>
                            <DropdownFieldContainer fixedWidth={286} selected={projectTimeline}>
                                <RowLabel>Project Timeline</RowLabel>
                                <StyledDropdown
                                    themeId={'dark'}
                                    label={projectTimeline ? generateTimelineString() : 'Select Date Range'}
                                >
                                    <DatePicker
                                        defaultRange={
                                            projectTimeline
                                                ? [
                                                      projectTimeline[0],
                                                      projectTimeline[1] ? projectTimeline[1] : projectTimeline[0],
                                                  ]
                                                : [new Date(), new Date()]
                                        }
                                        onUpdate={dateRangeUpdated}
                                        minDate={new Date()}
                                    ></DatePicker>
                                </StyledDropdown>
                            </DropdownFieldContainer>
                            <DropdownFieldContainer fixedWidth={280} selected={projectBudget}>
                                <RowLabel>
                                    <span>
                                        Project Budget
                                        <TooltipContainer>
                                            <ModalTooltip icon={'ss-info'}>
                                                <BudgetTooltipContent />
                                            </ModalTooltip>
                                        </TooltipContainer>
                                    </span>
                                </RowLabel>
                                <SelectList
                                    onChange={(option) => setProjectBudget(option)}
                                    options={[
                                        '$500 - $2,000',
                                        '$2,000 - $5,000',
                                        '$5,000 - $10,000',
                                        '$10,000 - $25,000',
                                        '$25,000 - $50,000',
                                        '+$50,000',
                                    ]}
                                    placeholder="Select Budget"
                                    themeId={'dark'}
                                    width={100}
                                    height={'42px'}
                                    allowMultiple={false}
                                />
                            </DropdownFieldContainer>
                        </FieldRow>
                        <FieldRow>
                            <EmailContainer>
                                <RowLabel>
                                    Contact Email<FieldExplanation>Any responses will be sent here</FieldExplanation>
                                </RowLabel>

                                <EmailField
                                    noTooltip
                                    showLabel={false}
                                    onValidChange={onContactChange}
                                    showFields={[FIELDS.email]}
                                    user={loggedInUser}
                                    responseErrors={null}
                                    ackResponseError={() => {}}
                                    aggroErrorMessages={true}
                                />
                            </EmailContainer>
                        </FieldRow>
                        <SubmitButton disabled={!readyForSubmit} secondary={!readyForSubmit}>
                            Send Inquiry
                        </SubmitButton>
                        <Disclaimer>
                            Clicking “Send Inquiry” will send an email to this Artist containing the information you
                            entered, including your email address. Please ensure you are comfortable sharing the
                            information entered.
                        </Disclaimer>
                    </ForHireForm>
                ) : (
                    <SuccessContainer deactivateModal={deactivateModal} preferredName={preferredName} />
                )}
            </ForHireFormContainer>
        </>
    )
}

export default ForHireModal
