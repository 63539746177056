import { giphyLightGrey } from '@giphy/colors'
import subMonths from 'date-fns/sub_months'
import subWeeks from 'date-fns/sub_weeks'
import { useMedia } from 'react-use'
import { mobile } from 'shared/util/media-query'
import styled, { css } from 'styled-components'
import DateRangeFilter from './date-range-filter'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

type PresetProps = {
    active: boolean
}

const DateRangePreset = styled.div<PresetProps>`
    border-bottom: 0.08em solid transparent;
    padding-bottom: 0.08em;
    font-weight: bold;
    color: ${giphyLightGrey};
    margin-right: 1em;
    height: 25px;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            color: white;
            border-color: #00ccff;
        `}
`

type Props = {
    onDateUpdate: (preset: string, range: Array<string | Date> | undefined) => void
    currentPreset?: string
    canViewDatePicker: boolean | undefined
}

const DateRangeModule = ({ currentPreset, onDateUpdate = () => {}, canViewDatePicker }: Props) => {
    const isMobile = useMedia(mobile.query)
    const selectPreset = (whichPreset: string, dateRange?: Array<string | Date> | undefined) => {
        const now = new Date()
        switch (whichPreset) {
            case 'CUSTOM':
                return onDateUpdate(whichPreset, dateRange)
            case 'WEEK':
                return onDateUpdate(whichPreset, [subWeeks(now, 1), now])
            case 'MONTH':
                return onDateUpdate(whichPreset, [subMonths(now, 1), now])
            case 'ALL_TIME':
                return onDateUpdate(whichPreset, [new Date(2013, 1, 1), now])
        }
    }

    return (
        <Container>
            <DateRangePreset active={currentPreset === 'WEEK'} onClick={() => selectPreset('WEEK')}>
                {isMobile ? '1W' : 'Last 7 Days'}
            </DateRangePreset>
            <DateRangePreset active={currentPreset === 'MONTH'} onClick={() => selectPreset('MONTH')}>
                {isMobile ? '1M' : 'Last 30 Days'}
            </DateRangePreset>
            <DateRangePreset active={currentPreset === 'ALL_TIME'} onClick={() => selectPreset('ALL_TIME')}>
                All Time
            </DateRangePreset>
            {!isMobile && canViewDatePicker && (
                <DateRangeFilter
                    active={currentPreset === 'CUSTOM'}
                    onDateUpdate={(start, end) => selectPreset('CUSTOM', [start, end])}
                />
            )}
        </Container>
    )
}

export default DateRangeModule
