import { giphyBlue } from '@giphy/colors'
import appendQuery from 'append-query'
import { Button } from 'shared/components/buttons'
import isMobile from 'shared/util/is-mobile'
import { desktop, mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import Video from '../../../../shared/components/background-video'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Nexablack;
    margin: auto;
    margin-top: 8px;
    ${desktop.css`
        align-items: center;
    `};
    ${mobile.css`
        max-width: 620px;
    `};
`

const BackgroundVideo = styled(Video)`
    height: 1015px;
    opacity: 0.23;
    ${mobile.css`
        height: 800px;
    `}
`

const Title = styled.div`
    font-size: 42px;
    font-family: Nexablack;
    margin-bottom: 15px;
    ${mobile.css`
        font-size: 26px;
        font-weight: normal;
    `};
`

const Copy = styled.div<{ maxWidth?: number; fontSize?: number }>`
    font-size: 18px;
    font-family: interface;
    font-weight: bold;
    line-height: 26px;
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : `620px`)};
    & a {
        color: ${giphyBlue};
    }
    & span {
        font-style: italic;
    }
    ${mobile.css`
        margin: auto;
        max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : `345px`)};
        font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `16px`)};
        font-weight: normal;
    `};
`

const MascotContainer = styled.div<{ state: string; marginBottom?: number }>`
    background: ${(props) => `${formatStickerUrl(props.state)}`};
    background-size: contain;
    height: 429px;
    width: 536px;
    margin-top: 20px;
    margin-bottom: 35px;
    ${mobile.css`
        max-width: 355px;
        height: 365px;
        margin-top: 0px;
        margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : `0px`)};
    `};
`

const ButtonDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 0px;
    div {
        &:first-child {
            margin-bottom: ${(props) => (props.isMobile ? `0px` : `30px`)};
            margin-top: ${(props) => (props.isMobile ? `20px` : `0px`)};
        }
    }
    ${mobile.css`
        flex-direction: column-reverse;
    `};
`

const BackButton = styled(Button)<{ moreGifs?: boolean; isMobile?: boolean }>`
    width: 400px;
    height: 36px;
    font-size: 16px;
    font-weight: bold;
    font-family: interface;
    margin-bottom: ${(props) =>
        props.moreGifs
            ? props.isMobile
                ? `20px !important` /* !important overrides margin: auto below */
                : `30px`
            : `0px`};
    ${mobile.css`
        width: 315px;
        margin: auto;
    `};
`

const formatStickerUrl = (state: string) => `url(/static/img/apply/${state}.gif) center no-repeat`

export const Done = () => (
    <Container>
        <BackgroundVideo />
        <Title>Congrats!</Title>
        <Copy>Your Application Has Been Successfully Submitted.</Copy>
        <MascotContainer state={'done'} />
        <ButtonDiv isMobile={isMobile()}>
            <Copy fontSize={14} maxWidth={!isMobile() ? 658 : 318}>
                We receive a high volume of applications every day, so this can take a while. In the meantime - live
                your life and we’ll notify you via email if your status changes.
            </Copy>
            <BackButton onClick={() => (location.href = '/')} isMobile={isMobile()}>
                Back to GIPHY
            </BackButton>
        </ButtonDiv>
    </Container>
)

export const NeedMoreGifs = () => (
    <Container>
        <BackgroundVideo />
        <Title>Upload Some GIFs!</Title>
        <Copy maxWidth={800}>
            In order to be considered for a Brand or Artist account, you must have <span>at least 5 GIFs</span> on your
            channel. Check out our{' '}
            <a
                href="https://support.giphy.com/hc/en-us/articles/360020433711-Tips-For-Submitting-An-Application-"
                target="_blank"
                rel="noopener noreferrer"
            >
                Guidelines
            </a>{' '}
            for applying{!isMobile() ? ` for an upgraded account.` : `.`}
        </Copy>
        <MascotContainer state={'more-gifs'} />
        <BackButton
            onClick={() => (location.href = appendQuery('/upload', { next: '/apply' }))}
            isMobile={isMobile()}
            moreGifs={true}
        >
            Go Upload Some GIFs!
        </BackButton>
        <Copy maxWidth={350}>
            <a
                href="https://support.giphy.com/hc/en-us/sections/360003006231-GIPHY-For-Brands-and-Artists-"
                target="_blank"
                rel="noopener noreferrer"
            >
                Read more
            </a>{' '}
            about Brand & Artist accounts on GIPHY to see if you qualify.
        </Copy>
    </Container>
)

export const InvalidEmail = () => (
    <Container>
        <BackgroundVideo />
        <Title>Email address required</Title>
        <Copy maxWidth={!isMobile() ? 658 : 318}>
            You must have a valid email address in order to submit an application. Please validate your email on the{' '}
            <a href="/settings">Settings page</a> to continue.
        </Copy>
        <MascotContainer state={'more-gifs'} />
        <BackButton onClick={() => (location.href = '/settings')} isMobile={isMobile()}>
            Go to Settings!
        </BackButton>
    </Container>
)

export const Pending = () => (
    <Container>
        <BackgroundVideo />
        <Title>Hello again...</Title>
        <Copy>Your application to upgrade to an artist or brand channel has been received. </Copy>
        <MascotContainer state={'pending'} marginBottom={35} />
        <ButtonDiv isMobile={isMobile()}>
            <Copy fontSize={14} maxWidth={!isMobile() ? 658 : 318}>
                We receive a high volume of applications every day, so this can take a while. In the meantime - live
                your life and we’ll notify you if you’ve been approved!
            </Copy>
            <BackButton onClick={() => (location.href = '/')} isMobile={isMobile()}>
                Back to GIPHY
            </BackButton>
        </ButtonDiv>
    </Container>
)

export const Rejected = () => (
    <Container>
        <BackgroundVideo />
        <Title>The Results Are In</Title>
        <Copy>Your application is ineligible </Copy>
        <MascotContainer state={'rejected'} marginBottom={20} />
        <ButtonDiv isMobile={isMobile()}>
            <Copy fontSize={14} maxWidth={!isMobile() ? 658 : 318}>
                We have reviewed your GIPHY application and determined that your channel is not eligible for an upgrade.
                Our team individually reviews every application and makes decisions based on the information provided
                and GIPHY&apos;s policies. If you love GIFs as much as us, we hope you&apos;ll continue to make and
                share them. Your current account still gives you access to many awesome features including unlimited
                uploads, favorites, renditions and much more.
            </Copy>
            <BackButton onClick={() => (location.href = '/')} isMobile={isMobile()}>
                Back to GIPHY
            </BackButton>
        </ButtonDiv>
    </Container>
)
