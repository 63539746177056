import PropTypes from 'prop-types'
import { Component } from 'react'
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys'
import 'd3-transition'
import { scaleLinear, scaleTime } from 'd3-scale'
import { select } from 'd3-selection'
import { extent, max } from 'd3-array'
import { line } from 'd3-shape'
import { axisBottom, axisLeft } from 'd3-axis'
import { timeFormat, timeParse } from 'd3-time-format'
import { easeQuadOut } from 'd3-ease'
import { format } from 'd3-format'
import { Loader } from 'shared/components/preloader-animation'
import { singularContentTypes } from '../../../site/user-dashboard-analytics/app'
import css from './line-chart.css'
import { giphyIndigo, giphyPink } from 'shared/css/colors'
import { getDashboard } from './networking'

@onlyUpdateForKeys(['activeTimeSeries', 'dateRange', 'contentType'])
export default class GraphAnalytics extends Component {
    static propTypes = {
        activeTimeSeries: PropTypes.string.isRequired,
        isMobile: PropTypes.bool,
        contentType: PropTypes.string,
        dateRange: PropTypes.array,
        onLoad: PropTypes.func,
    }
    state = {
        isFetching: false,
        data: {},
        contentType: 'GIFs & Stickers',
    }
    parseTime = timeParse('%Y-%m-%d')
    monthDayFormat = timeFormat('%m/%d')
    monthDayYearFormat = timeFormat('%x')
    monthYearFormat = timeFormat('%m/%Y')
    monthYearMobileFormat = timeFormat('%m/%y')

    numberFormat = format(',')
    dotRadius = 10
    dotInnerRadius = 4
    bubbleHeight = 22

    getDashboard() {
        const {
            user: { username },
            contentType,
            dateRange,
        } = this.props

        Promise.resolve(() => this.setState({ isFetching: true }))
            .then(() => getDashboard(contentType, dateRange, username))
            .then((data) => this.processData(data))
            .then((data) => this.setStateData(data))
            .then(() => this.setState({ isFetching: false }))
            .then(() => this.updateChart())
            .catch(() => this.setState({ isFetching: false }))
            .finally(() => {
                this.props.onLoad && this.props.onLoad()
            })
    }

    setStateData(newData) {
        return new Promise((resolve) => {
            this.setState({ data: { ...this.state.data, [this.props.activeTimeSeries]: newData } }, resolve)
        })
    }

    processData(data) {
        const parsedData = data.map(({ viewCount, date }) => ({
            viewCount: Math.max(0, viewCount),
            date: this.parseTime(date),
        }))
        return parsedData.slice().sort(({ date: dateA }, { date: dateB }) => dateA - dateB)
    }
    getTimeSeries(activeTimeSeries) {
        return this.state.data[activeTimeSeries] || []
    }

    componentDidUpdate({ contentType, dateRange, activeTimeSeries }) {
        const { isFetching } = this.state
        if (this.props.contentType !== contentType) {
            this.setState({ contentType: this.props.contentType })
            this.getDashboard()
        }
        if (isFetching) {
            return
        }
        const newTimeFrame = this.props.dateRange !== dateRange
        const newTimeSeries = this.props.activeTimeSeries !== activeTimeSeries
        if (newTimeFrame || newTimeSeries) {
            return this.getDashboard()
        }
    }

    componentDidMount() {
        this.getDashboard()
        this.toolTip = select('body').append('div').classed(css.toolTip, true).style('opacity', 0)
        this.dimensions = this.container.getBoundingClientRect()
        let leftMargin = this.props.isMobile ? 50 : 50
        this.margin = { top: 20, right: 10, bottom: 40, left: leftMargin }
        this.width = this.dimensions.width - this.margin.left - this.margin.right
        this.height = this.dimensions.height - this.margin.top - this.margin.bottom
        this.x = scaleTime().range([0, this.width])
        this.y = scaleLinear().range([this.height, 0])
        this.valueLine = line()
            .x(({ date }) => this.x(date))
            .y(({ viewCount }) => this.y(viewCount))

        const { xAxis, yAxis } = this.getAxes(this.x, this.y, this.width)
        this.xAxis = xAxis
        this.yAxis = yAxis
        this.createChart()
    }
    componentWillUnmount() {
        this.toolTip.remove()
    }
    addGradient(defs) {
        const gradient = defs.append('linearGradient').attr('id', 'svgGradient').attr('x1', '0%').attr('x2', '100%')

        gradient
            .append('stop')
            .attr('class', 'start')
            .attr('offset', '0%')
            .attr('stop-color', giphyIndigo)
            .attr('stop-opacity', 1)

        gradient
            .append('stop')
            .attr('class', 'end')
            .attr('offset', '100%')
            .attr('stop-color', giphyPink)
            .attr('stop-opacity', 1)
    }
    getAxes(xScale, yScale, width) {
        const { isMobile, activeTimeSeries } = this.props
        let numberOfTicks = isMobile ? 3 : 5
        var xAxisPadding = this.props.isMobile ? 10 : 20
        let xAxis = axisBottom(xScale)
            // increase number of ticks dynamically....
            .ticks(numberOfTicks)
            .tickPadding(xAxisPadding)
            // format should change based on the scale
            .tickFormat(this.determineDateFormat(activeTimeSeries))
        let yAxis = axisLeft(yScale).tickSize(width).ticks(numberOfTicks, 's').tickPadding(10)
        return { xAxis, yAxis }
    }
    customXAxis(g, xAxis) {
        g.transition().duration(750).call(xAxis)
        g.select('.domain').remove()
        g.select('line').remove()
        g.select('path').remove()
    }
    customYAxis(g, yAxis) {
        g.transition().duration(750).call(yAxis)
        // X-axis line is removed
        g.select('.domain').remove()
        g.selectAll('.tick:not(:first-of-type) line').attr('stroke-array', '2,2')
        // removes the y axis ticks
        // g.selectAll('.tick text').remove()
    }
    drawLine(data, g, line, toolTip) {
        const { activeTimeSeries, dateRange } = this.props
        const timeseries = [{ data, type: activeTimeSeries }]
        const lineSelection = g.selectAll('.line').data(timeseries)
        var parent = this

        lineSelection
            .enter()
            .append('path')
            // this describes the gradient line connecting data points
            .classed('line', true)
            .attr('d', ({ data }) => line(data))
            .attr('fill', 'none')
            .attr('stroke', 'url(#svgGradient)')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            // Changing stroke width dynamically based on time series
            .attr('stroke-width', function () {
                return parent.getStrokeWidth(dateRange)
            })
            .attr('stroke-dashoffset', function () {
                return this.getTotalLength()
            })
            .attr('stroke-dasharray', function () {
                return 99999 // arbitrarily high number
            })
            .transition()
            .delay(400)
            .duration(750)
            .ease(easeQuadOut)
            .attr('stroke-dashoffset', 0)

        lineSelection
            .transition()
            .duration(750)
            .ease(easeQuadOut)
            .attr('d', ({ data }) => line(data))
            .attr('stroke-width', function () {
                return parent.getStrokeWidth(dateRange)
            })
            .attr('stroke-dasharray', function () {
                return 99999 // arbitrarily high number
            })
            .attr('stroke-dashoffset', 0)

        lineSelection
            .exit()
            .transition()
            .duration(400)
            .ease(easeQuadOut)
            .attr('stroke-width', function () {
                return parent.getStrokeWidth(dateRange)
            })
            .attr('stroke-dashoffset', function () {
                return this.getTotalLength()
            })
            .remove()

        const dataPointSelection = g.selectAll('.data-point').data(data, () => activeTimeSeries)
        const dataPointsEnterSet = dataPointSelection
            .enter()
            .append('g')
            .classed('data-point', true)
            .style('opacity', 0)
            .on('mouseenter', this.showToolTip(toolTip))
            .on('mouseleave', this.hideToolTip(toolTip))
        dataPointSelection.exit().remove()

        // dots
        dataPointsEnterSet.append('circle').classed(css.dot, true)
        const dotSelection = g.selectAll(`.${css.dot}`)

        dotSelection
            .data(data, () => activeTimeSeries)
            .attr('r', this.dotRadius)
            .attr('cx', ({ date }) => this.x(date))
            .attr('cy', ({ viewCount }) => this.y(viewCount))

        dotSelection
            .attr('r', this.dotRadius)
            .attr('cx', ({ date }) => this.x(date))
            .attr('cy', ({ viewCount }) => this.y(viewCount))

        // orbits
        dataPointsEnterSet.append('circle').classed(css.dotOrbit, true)
        const orbitSelection = g.selectAll(`.${css.dotOrbit}`)

        orbitSelection
            .data(data, () => activeTimeSeries)
            .attr('r', 0)
            .attr('cx', ({ date }) => this.x(date))
            .attr('cy', ({ viewCount }) => this.y(viewCount))

        orbitSelection
            .attr('r', 0)
            .attr('cx', ({ date }) => this.x(date))
            .attr('cy', ({ viewCount }) => this.y(viewCount))

        // dots inner
        dataPointsEnterSet.append('circle').classed(css.dotInner, true)
        const dotsInnerSelection = g.selectAll(`.${css.dotInner}`)

        dotsInnerSelection
            .data(data, () => activeTimeSeries)
            .attr('r', this.dotInnerRadius)
            .attr('cx', ({ date }) => this.x(date))
            .attr('cy', ({ viewCount }) => this.y(viewCount))

        dotsInnerSelection
            .attr('r', this.dotInnerRadius)
            .attr('cx', ({ date }) => this.x(date))
            .attr('cy', ({ viewCount }) => this.y(viewCount))
    }
    roundedNearestOrderofMagnitude(value) {
        // Edge case where no data exists
        if (value < 1) {
            value = 10
        }
        const numOrderOfMagnitude = Math.floor(Math.log10(value))
        const orderOfMagnitude = Math.pow(10, numOrderOfMagnitude)
        return Math.ceil(value / orderOfMagnitude) * orderOfMagnitude
    }
    addAxes(g) {
        g.append('g').classed(css.xAxis, true).attr('transform', `translate(0, ${this.height})`)
        g.append('g').classed(css.yAxis, true).attr('transform', `translate(${this.width}, 0)`)
    }

    getStrokeWidth(dateRange) {
        var lengthInMonths = this.getDateRangeLengthInMonths(dateRange)
        if (lengthInMonths > 9) {
            return (2).toString()
        } else {
            return (6).toString()
        }
    }
    getDateRangeLengthInMonths(dateRange) {
        var rangeLength = Math.abs(dateRange[1].getTime() - dateRange[0].getTime())
        var lengthInDays = rangeLength / 1000 / 60 / 60 / 24
        return lengthInDays / 30
    }

    updateAxesScale(activeTimeSeries) {
        var numberOfTicks = this.props.isMobile ? 3 : 5
        switch (activeTimeSeries) {
            case 'ALL_TIME':
                if (this.props.dateRange) {
                    var rangeLength = Math.abs(this.props.dateRange[1].getTime() - this.props.dateRange[0].getTime())
                    var lengthInDays = rangeLength / 1000 / 60 / 60 / 24
                    var lengthInMonths = lengthInDays / 30

                    if (numberOfTicks > lengthInDays) {
                        numberOfTicks = Math.floor(lengthInDays)
                    }

                    if (lengthInMonths > 6) {
                        if (this.props.isMobile) {
                            this.xAxis.ticks(numberOfTicks).tickFormat(this.monthYearMobileFormat)
                        } else {
                            this.xAxis.ticks(numberOfTicks).tickFormat(this.monthYearFormat)
                        }
                    } else {
                        this.xAxis.ticks(numberOfTicks).tickFormat(this.monthDayFormat)
                    }
                }
                break
            case 'MONTH':
                this.xAxis.ticks(numberOfTicks).tickFormat(this.monthDayFormat)
                break
            case 'WEEK':
                this.xAxis.ticks(numberOfTicks).tickFormat(this.monthDayFormat)
                break
            case 'CUSTOM':
                // Evaluate custom date range. If greater than 6 months, use monthYear - otherwise use monthDayYear.
                if (this.props.dateRange) {
                    rangeLength = Math.abs(this.props.dateRange[1].getTime() - this.props.dateRange[0].getTime())
                    lengthInDays = rangeLength / 1000 / 60 / 60 / 24
                    lengthInMonths = lengthInDays / 30

                    if (numberOfTicks > lengthInDays) {
                        numberOfTicks = Math.floor(lengthInDays)
                    }

                    if (lengthInMonths > 6) {
                        this.xAxis.ticks(numberOfTicks).tickFormat(this.monthYearFormat)
                    } else {
                        this.xAxis.ticks(numberOfTicks).tickFormat(this.monthDayFormat)
                    }
                }
                break
            default:
                this.xAxis.ticks(numberOfTicks).tickFormat(this.monthDayYearFormat)
                break
        }
    }
    updateAxes(data, g) {
        const { activeTimeSeries, dateRange } = this.props
        let dataToDraw = data
        if (dataToDraw.length === 0) {
            dataToDraw[0] = { date: dateRange[0], viewCount: 0 }
            dataToDraw[1] = { date: dateRange[1], viewCount: 0.01 }
        }
        this.x.domain(extent(dataToDraw, ({ date }) => date))
        // Update axis ticks and format based on time series
        this.updateAxesScale(activeTimeSeries)
        this.y.domain([0, this.roundedNearestOrderofMagnitude(max(dataToDraw, ({ viewCount }) => viewCount))])
        g.select(`.${css.xAxis}`).call((g) => this.customXAxis(g, this.xAxis))
        g.select(`.${css.yAxis}`).call((g) => this.customYAxis(g, this.yAxis))
    }
    createChart() {
        const { activeTimeSeries, dateRange } = this.props
        const data = this.getTimeSeries(activeTimeSeries)

        this.svg.attr('width', this.dimensions.width).attr('height', this.dimensions.height)
        const defs = this.svg.append('defs')
        const g = this.svg.append('g').attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)

        this.addGradient(defs)
        this.addAxes(g)
        this.updateAxes(data, g)
        let dataToDraw = data
        if (dataToDraw.length === 0) {
            dataToDraw[0] = { date: dateRange[0], viewCount: 0 }
            dataToDraw[1] = { date: dateRange[1], viewCount: 0 }
        }

        this.drawLine(dataToDraw, g, this.valueLine, this.toolTip, 10)
    }
    updateChart() {
        const { activeTimeSeries, dateRange } = this.props
        const data = this.getTimeSeries(activeTimeSeries)
        const g = this.svg.select('g')
        this.updateAxes(data, g)
        let dataToDraw = data
        if (dataToDraw.length === 0) {
            dataToDraw[0] = { date: dateRange[0], viewCount: 0 }
            dataToDraw[1] = { date: dateRange[1], viewCount: 0 }
        }

        this.drawLine(dataToDraw, g, this.valueLine, this.toolTip, 6)
    }

    determineDateFormat(activeTimeSeries) {
        switch (activeTimeSeries) {
            case 'ALL_TIME':
                return this.monthYearFormat
            case 'MONTH':
                return this.monthDayFormat
            case 'WEEK':
                return this.monthDayFormat
            case 'CUSTOM':
                return this.monthDayYearFormat
            default:
                return this.monthDayYearFormat
        }
    }

    showToolTip(toolTip) {
        const { dotRadius, bubbleHeight, monthDayYearFormat, numberFormat } = this
        // For the tooltips, always display month day year in tooltip. If this is mobile, don't display anything.
        if (this.props.isMobile) {
            return
        }
        const dateFormat = monthDayYearFormat
        const { contentType } = this.state
        const viewNoun = contentType === 'Stories' ? ' Reads' : ' Views'
        return function ({ date, viewCount }) {
            const { scrollX, scrollY } = window
            const { left, top } = this.getBoundingClientRect()
            const g = select(this)
            const dot = g.select(`.${css.dotOrbit}`)
            const toolTipY = () => top + scrollY - toolTip.node().clientHeight - bubbleHeight
            const toolTipX = () => left + scrollX - toolTip.node().clientWidth / 2 + dotRadius
            const formattedDate = dateFormat(date)
            // change style of tooltip here here
            const template = `
                <p class="${css.toolTipTitle}">${formattedDate}</p>
                <p class="${css.toolTipViews}">${numberFormat(Math.floor(viewCount))} ${viewNoun}</p>
            `
            g.transition().duration(250).style('opacity', 1)
            dot.transition().duration(200).attr('r', dotRadius)
            toolTip
                .html(template)
                .style('left', `${toolTipX()}px`)
                .style('top', `${toolTipY()}px`)
                .style('visibility', 'visible')
                .transition()
                .duration(200)
                .style('opacity', 1)
        }
    }
    hideToolTip(toolTip) {
        return function () {
            const g = select(this)
            const dot = g.select(`.${css.dotOrbit}`)
            g.transition().duration(500).style('opacity', 0)
            dot.transition().duration(500).attr('r', 0)
            toolTip
                .transition()
                .duration(500)
                .style('opacity', 0)
                .on('end', function () {
                    select(this).style('visibility', 'hidden')
                })
        }
    }
    render() {
        const { isFetching, contentType } = this.state
        const viewNoun = contentType === 'Stories' ? ' Reads' : ' Views'
        return (
            <div className={css.graph}>
                <Loader isFetching={isFetching} />
                <h1 className={css.lineTitle}>
                    Total {singularContentTypes[contentType]} {viewNoun}
                </h1>
                <div className={css.chartContainer} ref={(el) => (this.container = el)}>
                    <svg className={css.chart} ref={(el) => (this.svg = select(el))} />
                </div>
            </div>
        )
    }
}
