import styled, { keyframes } from 'styled-components'
import { giphyLightestGrey } from '@giphy/react-giphy-brand/src/css/colors'
import { Gif } from '@giphy/react-components'

export const CalendarContainer = styled.div`
    margin-bottom: 40px;

    h1 {
        font-size: 24px;
        font-family: NexaBlack;
        margin-bottom: 10px;
        text-align: left;
    }
`

export const Title = styled.h1`
    position: relative;
    display: flex;
`

export const Info = styled.div`
    font-family: InterFace;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    width: 768px;
    margin: 0px 0px 30px 0px;
    color: ${giphyLightestGrey};
    a {
        background: linear-gradient(53deg, #6157ff 0%, #0cf 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: InterFace;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-decoration-line: underline;
    }
`

export const LeftPanel = styled.div`
    width: 385px;
    height: 450px;
    flex-shrink: 0;
    border-radius: 12px 0px 0px 12px;
    background: linear-gradient(180deg, #2e2e2e 0%, #212121 100%);
`

export const LeftPanelContainer = styled.div`
    flex: 1;
`

export const RightPanelContainer = styled.div`
    flex: 1;
`

export const RightPanel = styled.div`
    width: 385px;
    height: 480px;
    flex-shrink: 0;
    background: linear-gradient(45deg, #5d5fef, #00ccff);
    border-radius: 12px;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.4);

    filter: drop-shadow(-10.845070838928223px 2.1690142154693604px 86.76056671142578px rgba(0, 0, 0, 0.2));

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(0, 204, 255, 0), rgba(0, 143, 179, 0.4) 90%);
        width: 100%;
        height: 4em;
        border-radius: 12px;
    }
`

export const PanelHolder = styled.div`
    display: flex;
    align-items: center;
`

export const Header = styled.div`
    display: flex;
    margin: 30px;
    position: absolute;
    width: 325px;
`

export const DayView = styled.div`
    color: var(--color-grey-9-white, #fff);
    font-family: NexaBlack;
    font-size: 60px;
    font-style: normal;
    line-height: 100%;
    letter-spacing: -2.4px;
    left: 0px;
    position: relative;
    text-align: left;
`

export const Month = styled.div`
    color: var(--color-grey-9-white, #fff);

    /* P – Large/Regular */
    font-family: InterFace;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 147.059% */
    top: 28px;
    margin-left: 10px;
    position: absolute;
`
export const WeekDay = styled.div`
    color: var(--color-grey-9-white, #fff);

    /* H3/Regular */
    font-family: InterFace;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 136.364% */
    margin-left: 10px;
    margin-top: 5px;
`

export const WeekAndMonth = styled.div`
    flex: 1;
    position: relative;
`

export const DayContolButtons = styled.div`
    flex: 1;
    display: flex;
    position: absolute;
    gap: 10px;
    right: 0px;
`

export const Arrow = styled.div<{ disabled?: boolean }>`
    font-size: 26px;
    margin-top: 10px;
    flex: 1;
    color: #fff;
    cursor: pointer;
    ${({ disabled }) => disabled && `opacity: 0.3; cursor: default`}
`

export const Events = styled.div`
    width: 385px;
    height: 480px;
    position: absolute;
    margin-top: 98px;
    height: 370px;
    overflow: scroll;
`

export const NoEvents = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-grey-9-white, #fff);
    text-align: center;
    height: 100%;
    opacity: 0.6;

    /* P – Small/Regular */
    font-family: InterFace;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
`

export const EventTile = styled.div`
    border-radius: 8px;
    background: rgba(217, 217, 217, 0.3);
    width: 333px;
    flex-shrink: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    position: relative;
    margin-top: 12px;
    min-height: 70px;
    height: auto;
`
export const EventTitle = styled.span`
    display: inline-block;
    width: 203px;
    height: auto;
    color: var(--color-grey-9-white, #fff);

    /* P – Small/Bold */
    font-family: InterFace;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    vertical-align: middle;
`
export const EventDate = styled.div`
    color: var(--color-grey-9-white, #fff);

    /* V - Tiny/Regular */
    font-family: InterFace;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
    vertical-align: middle;
`
export const Description = styled.div<{ isAsset: boolean }>`
    top: 15px;
    position: relative;
    margin-left: 10px;
    display: inline-block;
    ${({ isAsset }) => isAsset && `margin-left: 100px;`}
`

// i'm sorry, important just to override the inline styles from giphy lib
export const Asset = styled(Gif)`
    width: 90px;
    object-fit: fill;
    display: inline-block;
    position: absolute;

    border-radius: 8px 0px 0px 8px !important;
    height: 100% !important;
`

export const Divider = styled.div`
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-family: InterFace;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 12px;
    width: 343px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    &:after,
    &:before {
        flex: 1;
        content: '';
        padding: 0.5px;
        background-color: #fff;
        margin: 5px;
        opacity: 0.25;
        border-radius: 1px 0px 0px 1px;
    }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const AnimatedContainer = styled.div<{ fadeStatus?: string }>`
    animation: ${(props) => (props.fadeStatus === 'fadeIn' ? fadeIn : fadeOut)} 0.25s ease-in-out;
`

export const CalArrow = styled.div<{ disabled?: boolean }>`
    font-size: 18px;
    margin-top: 20px;
    flex: 1;
    color: #fff;
    cursor: pointer;
    ${({ disabled }) => disabled && `opacity: 0.3; cursor: default`}
`

export const Container = styled.div`
    font-family: sans-serif;
    text-align: center;
    color: #212121;
`

export const DaysHeader = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    position: relative;
    padding-top: 15px;
    padding-bottom: 10px;
`

export const CurrentMonth = styled.h2`
    flex: 1;
    margin-left: 24px;
    margin-right: 2px;
    margin-top: 12px;
    color: var(--color-grey-9-white, #fff);
    text-align: right;

    /* H2/Regular */
    font-family: NexaBlack;
    font-size: 28px;
    font-style: normal;
    line-height: 130%; /* 36.4px */
`

export const CurrentYear = styled.h2`
    flex: 1;
    margin-right: 24px;
    margin-left: 2px;
    color: #4e4e4e;
    margin-top: 14px;

    /* H2/Light */
    font-family: NexaRegular;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 36.4px */
`

export const WeekContainer = styled.h2`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding-left: 10px;
    padding-right: 10px;
`

export const DatesContainer = styled(WeekContainer)`
    magin-top: 20px;
`

export const Day = styled.div<{ isInactive: boolean; isSelected: boolean; isHasEvent: boolean }>`
    color: var(--color-grey-9-white, #fff);
    text-align: center;

    /* P – Large/Bold */
    font-family: InterFace;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 147.059% */

    margin: 12px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &:hover {
        color: white;
        border-radius: 50%;
        padding: 24px;
        margin: 1px 0px 0px 1px;
        position: relative;
        background: #2e2e2e;
        ${({ isHasEvent, isInactive }) =>
            isHasEvent &&
            !isInactive &&
            `&::after {
                    content: '';
                    position: absolute;
                    bottom: 12px;
                    height: 2px;
                    border-radius: 5px;
                    width: 40%;
                    background: linear-gradient(45deg, #5d5fef, #00ccff);;
                }`}
    }

    ${({ isInactive, isHasEvent, isSelected }) => {
        if (isSelected) {
            return `
                color: white; 
                background: linear-gradient(45deg, #5d5fef, #00ccff); 
                border-radius: 50%;
                padding: 24px;
                margin: 1px 0px 0px 1px;
                &:hover {
                    background: linear-gradient(45deg, #5d5fef, #00ccff); 
                }
                position: relative;
                ${
                    isHasEvent &&
                    `&::after {
                    content: '';
                    position: absolute;
                    bottom: 12px;
                    height: 2px;
                    border-radius: 5px;
                    width: 40%;
                    background: white;
                }`
                }
                
            `
        }
        if (isInactive) {
            return `
            color: #4E4E4E; 
            cursor: default;                
            &:hover {
                color: #4E4E4E; 
            }
            `
        }
        if (isHasEvent) {
            return 'border-radius: 0%; background: linear-gradient(to right, #5d5fef, #00ccff) no-repeat; background-size: 80% 2px; background-position: center bottom;'
        }
    }}
`

export const WeekNames = styled.div`
    text-align: center;

    margin: 12px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #4e4e4e;

    text-align: center;
    font-family: InterFace;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.859px; /* 159.061% */
    text-transform: uppercase;
    cursor: default;
`

export const AvatarImage = styled.img`
    width: 24px;
    height: 24px;

    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
`
