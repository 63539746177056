import { giphyLightGrey, giphyLightestGrey, giphyWhite } from '@giphy/colors'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { fetchDashboardTrendingSearches } from 'shared/api/index'
import { SearchIcon, TrendingIcon } from 'shared/components/homepage-icons'
import Observer from 'shared/components/observer'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import styled from 'styled-components'
import AnalyticsInfoTooltip from '../../site/user-dashboard-analytics/analytics-info-tooltip'
import { DashboardUser } from '../contexts/accounts-manager'

const Icon = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 9px;
`
const StickerIcon = styled(Icon)`
    background-image: url('/static/img/content-type-icons/sticker.png');
`

const ClipIcon = styled(Icon)`
    background-image: url('/static/img/content-type-icons/clip.png');
    background-size: 100%;
`

const GifIcon = styled(Icon)`
    background-image: url('/static/img/content-type-icons/gif.png');
    width: 28px;
    height: 28px;
`

const TrendingSearchesContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #181818;
    color: ${giphyLightGrey};
    border-radius: 12px;
    flex-flow: row wrap;
    padding-top: 40px;
    padding-bottom: 40px;
`

const Title = styled.h1`
    position: relative;
    display: flex;
    color: #fff;

    font-family: NexaBlack;
    font-size: 28px;
    font-style: normal;
    line-height: 130%;
`

const InfoBlock = styled.div`
    font-family: InterFace;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    width: 768px;
    margin: 0px 0px 30px 0px;
    color: ${giphyLightestGrey};
    & a {
        color: #3191ff;
        font-family: InterFace;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-decoration-line: underline;
    }
`

const Info = styled.div`
    color: #d8d8d8;
    text-align: center;

    font-family: InterFace;
    font-size: 17px;
    font-style: normal;
    line-height: 25px;
    font-weight: 700;

    letter-spacing: 0px;
    width: 558px;
    margin: 30px auto;
    & a {
        color: ${giphyLightGrey};
        text-decoration: underline;
    }
`

const InfoBold = styled(Info)`
    font-weight: bold;
`

const TagsObserver = styled(Observer)`
    width: 100%;
`

const TagsContainer = styled.div`
    display: flex;
    width: 75%;
    margin: auto;
    margin-top: 7px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
`

const Tag = styled.div`
    color: ${giphyWhite};
    max-width: 211px;
    height: 50px;
    background: linear-gradient(-180deg, rgb(40, 40, 40) 0%, rgb(33, 33, 32) 100%);
    border-radius: 25px;
    font-size: 17px;
    font-family: InterFace;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 50px;
    text-align: center;
    & svg {
        height: 15px;
        width: 20px;
    }
    cursor: pointer;
    user-select: none;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 18px;
    margin-top: 10px;
    margin-right: 4px;
    margin-left: 4px;
    justify-self: center;
`

const ToggleContainer = styled.div`
    height: 42px;
    margin-top: 25px;
    background: #181818;
    border-radius: 21px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(233px, 1fr));
`

const TooltipIcon = styled(AnalyticsInfoTooltip)`
    display: inline-block;
    & strong {
        font-weight: bold;
    }
`

type ToggleProps = {
    toggle: boolean
}

const TogglePill = styled.div<ToggleProps>`
    width: auto;
    height: 42px;
    border-radius: 22.5px;
    cursor: pointer;
    text-align: center;
    ${(props) => {
        if (props.toggle) {
            return `
                background: rgba(0, 0, 0, 0.3)
                linear-gradient(45deg, rgb(0, 204, 255) 0%, rgb(0, 255, 153) 100%);
                .ss-info {
                    color: ${giphyWhite} !important;
                }
            `
        }
    }}
`

const ToggleTitle = styled.div`
    margin: 0;
    color: ${giphyWhite};
    font-family: interface;
    font-weight: 600;
    font-size: 16px;
    line-height: 42px;
    text-transform: none;
    display: inline;
`

const ContentTypeHeader = styled.div`
    text-align: center;
    margin-bottom: 0px;
    margin-top: 32px;
    font-weight: 700;
    color: white;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

type TrendingSearch = {
    url: string
    query: string
}

type TrendingSearches = {
    data: {
        trendingSearches: TrendingSearch[]
        tailoredForYou: TrendingSearch[]
        contentGaps: TrendingSearch[]
    }
}

const uploadingLink = (
    <a href="/upload" target="_blank">
        Uploading
    </a>
)

const taggingLink = (
    <a
        href="https://support.giphy.com/hc/en-us/articles/360026405951-How-to-Tag-Your-Content"
        target="_blank"
        rel="noopener noreferrer"
    >
        Tagging
    </a>
)

const AnchorTag = styled.div``

const ToggleCopy = {
    'tailored-for-you': (
        <>
            You already have uploaded highly engaging content for these terms. Upload these content types for more
            reach!
        </>
    ),
    'trending-searches': (
        <>
            Maximize your impact by {uploadingLink} and {taggingLink} content for these search terms!
        </>
    ),
    'content-gaps': (
        <>Content Gaps are highly searched terms on GIPHY that are in need of more of the content types below.</>
    ),
}

const ProcessData = (data: any[]): TrendingSearch[][] => {
    let gifData: TrendingSearch[] = []
    let stickerData: TrendingSearch[] = []
    let clipData: TrendingSearch[] = []

    // Iterate through data and build buckets for each content type.
    data.map((item) => {
        if (item?.media_types?.includes('Video')) {
            clipData.push(item)
        }
        if (item?.media_types?.includes('Sticker')) {
            stickerData.push(item)
        }
        if (item?.media_types?.includes('GIF')) {
            gifData.push(item)
        }
    })

    return [gifData, stickerData, clipData]
}

type DisplayTagsProps = {
    trendingData: any
    toggle: string
    user: DashboardUser
    onClick?: (query: string, contentType?: string) => void
}

const DisplayTags = ({ trendingData, toggle, user, onClick }: DisplayTagsProps) => {
    let data: TrendingSearch[] = []
    let dataByContentType: TrendingSearch[][] = []
    let clipData: TrendingSearch[] = []
    let stickerData: TrendingSearch[] = []
    let gifData: TrendingSearch[] = []

    let supplementaryCopy: any = ToggleCopy[toggle]

    if (toggle === 'trending-searches') {
        data = trendingData.data.trendingSearches
        // NOTE: trending searches is not broken out by content type
    } else if (toggle === 'tailored-for-you') {
        dataByContentType = ProcessData(trendingData.data.tailoredForYou)
        clipData = dataByContentType[2]
        stickerData = dataByContentType[1]
        gifData = dataByContentType[0]
    } else if (toggle === 'content-gaps') {
        dataByContentType = ProcessData(trendingData.data.contentGaps)
        clipData = dataByContentType[2]
        stickerData = dataByContentType[1]
        gifData = dataByContentType[0]
    }

    if (toggle === 'trending-searches') {
        return (
            <>
                <InfoBold>{supplementaryCopy}</InfoBold>
                <TagsContainer>
                    {data.map((item) => {
                        return (
                            <Tag
                                key={item.url}
                                {...{
                                    as: 'a',
                                    href: item.url,
                                    target: '_blank',
                                    rel: 'noopener noreferrer',
                                    onClick: () => {
                                        onClick?.(item.query)
                                    },
                                }}
                            >
                                {toggle === 'trending-searches' ? <TrendingIcon /> : <SearchIcon />} {item.query}
                            </Tag>
                        )
                    })}
                </TagsContainer>
            </>
        )
    } else {
        return (
            <>
                <Info>{supplementaryCopy}</Info>
                {clipData.length > 0 && (
                    <>
                        <ContentTypeHeader>
                            <ClipIcon />
                            Clips
                        </ContentTypeHeader>
                        <TagsContainer>
                            {clipData.map((item) => {
                                let itemUrl
                                // Append channel search query param if toggle is set to 'tailored for you'
                                toggle === 'tailored-for-you'
                                    ? (itemUrl = item.url + '?channel=' + user.username)
                                    : (itemUrl = item.url)
                                return (
                                    <Tag
                                        key={itemUrl}
                                        {...{
                                            as: 'a',
                                            href: itemUrl,
                                            target: '_blank',
                                            rel: 'noopener noreferrer',
                                            onClick: () => {
                                                onClick?.(item.query, 'clips')
                                            },
                                        }}
                                    >
                                        {toggle === 'trending-searches' ? <TrendingIcon /> : <SearchIcon />}{' '}
                                        {item.query}
                                    </Tag>
                                )
                            })}
                        </TagsContainer>
                    </>
                )}
                {stickerData.length > 0 && (
                    <>
                        <ContentTypeHeader>
                            <StickerIcon />
                            Stickers
                        </ContentTypeHeader>
                        <TagsContainer>
                            {stickerData.map((item) => {
                                let itemUrl
                                // Append channel search query param if toggle is set to 'tailored for you'
                                toggle === 'tailored-for-you'
                                    ? (itemUrl = item.url + '?channel=' + user.username)
                                    : (itemUrl = item.url)
                                return (
                                    <Tag
                                        key={item.url}
                                        {...{
                                            as: 'a',
                                            href: itemUrl,
                                            target: '_blank',
                                            rel: 'noopener noreferrer',
                                            onClick: () => {
                                                onClick?.(item.query, 'stickers')
                                            },
                                        }}
                                    >
                                        {toggle === 'trending-searches' ? <TrendingIcon /> : <SearchIcon />}{' '}
                                        {item.query}
                                    </Tag>
                                )
                            })}
                        </TagsContainer>
                    </>
                )}
                {gifData.length > 0 && (
                    <>
                        <ContentTypeHeader>
                            <GifIcon />
                            GIFs
                        </ContentTypeHeader>
                        <TagsContainer>
                            {gifData.map((item) => {
                                let itemUrl
                                // Append channel search query param if toggle is set to 'tailored for you'
                                toggle === 'tailored-for-you'
                                    ? (itemUrl = item.url + '?channel=' + user.username)
                                    : (itemUrl = item.url)
                                return (
                                    <Tag
                                        key={item.url}
                                        {...{
                                            as: 'a',
                                            href: itemUrl,
                                            target: '_blank',
                                            rel: 'noopener noreferrer',
                                            onClick: () => {
                                                onClick?.(item.query, 'gifs')
                                            },
                                        }}
                                    >
                                        {toggle === 'trending-searches' ? <TrendingIcon /> : <SearchIcon />}{' '}
                                        {item.query}
                                    </Tag>
                                )
                            })}
                        </TagsContainer>
                    </>
                )}
            </>
        )
    }
}

type DashboardTrendingProps = {
    user: DashboardUser
    isDashboardLoaded: boolean
    onCalendarClick?: () => void
    onTagClick?: (query: string, contentType?: string) => void
    onToggle?: (label: string) => void
}

const DashboardTrendingSearches = ({ user, isDashboardLoaded, onToggle, onTagClick }: DashboardTrendingProps) => {
    const contentRef = useRef<HTMLDivElement>(null)
    const sourceBanner = useMemo(() => {
        const urlParams = new URLSearchParams(location.search)
        return urlParams.get('source')
    }, [location.search])

    const [toggle, setToggle] = useState<string>('trending-searches')
    const [trendingData, setTrendingData] = useState<TrendingSearches>()
    const [hasTailoredForYou, setHasTailoredForYou] = useState<boolean>(false)

    useAsyncEffect(async () => {
        const response = await fetchDashboardTrendingSearches(user.username)
        setTrendingData(response)
        if (response.data.tailoredForYou.length > 0) {
            setToggle('tailored-for-you')
            setHasTailoredForYou(true)
        }
    }, [])

    useEffect(() => {
        if (!isDashboardLoaded || !sourceBanner || !trendingData) {
            return
        }

        contentRef.current?.scrollIntoView()
    }, [isDashboardLoaded, sourceBanner, trendingData])

    const handleToggle = (t: string) => {
        setToggle(t)
        onToggle?.(t)
    }

    let tailoredTooltipText = (
        <Fragment>
            <strong>Tailored for You</strong> terms are high performing tags based on your existing content. Expand your
            reach across more content types like Stickers and Clips
        </Fragment>
    )
    let trendingTooltipText = (
        <Fragment>
            <strong>Trending Searches</strong> are the most popular search terms on GIPHY refreshed daily.
        </Fragment>
    )
    let gapsTooltipText = (
        <Fragment>
            <strong>Content Gaps</strong> are highly searched terms on GIPHY in need of more results. Be one of the
            first to fill these gaps with relevant content from your channel.
        </Fragment>
    )

    if (trendingData) {
        return (
            <div ref={contentRef}>
                <AnchorTag id={'searchInsightsAnchor'} />
                <Title>Search Insights 🔥</Title>
                <InfoBlock>
                    Take a data driven approach to your content creation by aligning with top performing keywords
                    highlighted in GIPHY&#39;s Search Insights dashboard.
                </InfoBlock>
                <ToggleContainer>
                    {hasTailoredForYou && (
                        <TogglePill
                            toggle={toggle === 'tailored-for-you'}
                            onClick={() => handleToggle('tailored-for-you')}
                        >
                            <ToggleTitle>Tailored for You</ToggleTitle>
                            <TooltipIcon tooltip={tailoredTooltipText} />
                        </TogglePill>
                    )}
                    <TogglePill
                        toggle={toggle === 'trending-searches'}
                        onClick={() => handleToggle('trending-searches')}
                    >
                        <ToggleTitle>Trending Searches</ToggleTitle>
                        <TooltipIcon tooltip={trendingTooltipText} />
                    </TogglePill>
                    <TogglePill toggle={toggle === 'content-gaps'} onClick={() => handleToggle('content-gaps')}>
                        <ToggleTitle>Content Gaps</ToggleTitle>
                        <TooltipIcon tooltip={gapsTooltipText} />
                    </TogglePill>
                </ToggleContainer>
                <TrendingSearchesContainer>
                    <TagsObserver>
                        <DisplayTags trendingData={trendingData} toggle={toggle} user={user} onClick={onTagClick} />
                    </TagsObserver>
                </TrendingSearchesContainer>
            </div>
        )
    }
    return <div></div>
}

export default DashboardTrendingSearches
