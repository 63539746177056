import { IGif } from '@giphy/js-types'
import { Loader as BrandLoader } from '@giphy/react-components'
import { useCallback, useContext, useEffect } from 'react'
import { useKeyPress } from 'react-use'
import { getChannelGifsByUsername } from 'shared/api/channels'
import BulkEditor from 'shared/components/bulk-editor'
import Observer from 'shared/components/observer'
import VideoPreviewPlayInline from 'shared/components/video-inline-play'
import BulkEditModeContext from 'shared/contexts/bulk-edit-mode'
import { useBulkEditGifClick } from 'shared/hooks/use-bulk-edit'
import { useSameObjectRef } from 'shared/hooks/use-same-ref'
import styled from 'styled-components'
import useFetchData from 'ui/src/hooks/use-fetch-data'
import ChannelPageContext from '../contexts/channel-page'
import GifContext from '../contexts/gif'
import { GridOverlayContext } from './grid-overlay'
const StyledVideoPreview = styled(VideoPreviewPlayInline)`
    margin-bottom: 30px;
`

export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    justify-content: space-between;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
`

export const Loader = styled(BrandLoader)`
    margin-bottom: 50px;
`

// spaces grid to align items to the left
const PreviewSpacer = styled.div<{ previewWidth: number }>`
    height: 1px;
    width: ${(props) => props.previewWidth}px;
`

type Props = {
    gifs?: IGif[]
    previewWidth?: number
    hideAttribution?: boolean
}

type GifId = string | number

const VideoPreviewGrid = ({ gifs = [], previewWidth = 336, hideAttribution = false }: Props) => {
    const standaloneSelectionMode = false
    const {
        isBulkEditMode,
        setBulkEditGifIds: setSelectedGifs,
        bulkEditGifIds: selectedGifs,
    } = useContext(BulkEditModeContext)
    const iSelectionMode = !!(isBulkEditMode || standaloneSelectionMode)
    const [isEscPressed] = useKeyPress((event: KeyboardEvent) => event.keyCode === 27)
    const gifIds: GifId[] = gifs.map((g) => g.id)

    const onGifClick = useBulkEditGifClick({ gifs: gifIds, iSelectionMode, standaloneSelectionMode })

    useEffect(() => {
        setSelectedGifs([])
    }, [isEscPressed, setSelectedGifs])

    useEffect(() => {
        return () => setSelectedGifs([])
    }, [setSelectedGifs])

    const gridOverlayValue = useSameObjectRef({
        onGifClick,
        selectedGifs,
        iSelectionMode,
    })

    return (
        <GridOverlayContext.Provider value={gridOverlayValue}>
            <Grid>
                {gifs.map((gif, key) => (
                    <StyledVideoPreview
                        key={gif.id}
                        gif={gif}
                        width={previewWidth}
                        gridPosition={key + 1}
                        showClipTitle={true}
                        showAttribution={!hideAttribution}
                    />
                ))}
                <PreviewSpacer previewWidth={previewWidth} />
            </Grid>
        </GridOverlayContext.Provider>
    )
}

export const VideoPreviewChannelGridByUsername = ({ previewWidth, hideAttribution = false }: Props) => {
    const { channel } = useContext(ChannelPageContext)
    const { receivedGifs } = useContext(GifContext)
    const fetchGifs = useCallback(
        async (offset: number) => getChannelGifsByUsername(channel.user.username, { offset, limit: 25 }),
        [channel.user.username]
    )
    const { doneFetching, doFetch, gifs } = useFetchData<IGif>({ fetchData: fetchGifs })
    useEffect(() => {
        receivedGifs({ gifs })
    }, [gifs])
    return (
        <>
            {gifs.length !== 0 && (
                <VideoPreviewGrid gifs={gifs} previewWidth={previewWidth} hideAttribution={hideAttribution} />
            )}
            {!doneFetching && (
                <Observer onVisible={doFetch} key={gifs.length}>
                    <Loader />
                </Observer>
            )}
            <BulkEditor />
        </>
    )
}

export default VideoPreviewGrid
