import PropTypes from 'prop-types'
import { Loader } from 'shared/components/preloader-animation'
import css from './sticker-embed-modal.css'
const defaultSticker = 'https://media.giphy.com/media/3o6gE51uXycrKW6D84/giphy.gif'

const embedScript = (channelId) =>
    `;(function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; window.giphyStickerPackId = ${channelId}; js = d.createElement(s); js.id = id; js.src ='https://scripts.giphy.com/sticker-embed/latest/bundle.js'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'giphy-sticker-pack'))`

const injectEmbed = (channelId) => new Function(embedScript(channelId))() // eslint-disable-line
const EmbedCode = ({ channelId }) => {
    let textarea

    const autoSelect = () => {
        textarea.focus()
        textarea.select()
    }
    return (
        <div className={css.embedCode}>
            <h1 className={css.bodyCopy}>Pack Embed Code</h1>
            <textarea
                className={css.code}
                readOnly
                value={`<script>${embedScript(channelId)}</script>`}
                ref={(el) => (textarea = el)}
                onClick={autoSelect}
                onCopy={() => global.showSuccess(`Copied embed code to clipboard!`)}
            />
        </div>
    )
}

EmbedCode.propTypes = {
    channelId: PropTypes.number.isRequired,
}
export const StickerEmbedModal = ({ id, display_name, featured_gif, isFetching }) => (
    <div id="embed" className={css.modal}>
        {isFetching ? (
            <Loader />
        ) : (
            <div className={css.embedModal}>
                <h1 className={css.title}>{display_name} Pack Embed</h1>
                <a className={css.exit} href="#" />
                <div className={css.container}>
                    <div className={css.column}>
                        <p className={css.bodyCopy}>
                            Embed this Sticker Pack on your website to turn it into a Sticker Party. Just copy and paste
                            our javascript embed code onto your site or post.
                        </p>
                        <p className={css.bodyCopy}>
                            Want to embed a different pack? Check out all our Sticker Packs
                            <a className={css.link} href={'/stickers'}>
                                {' '}
                                here
                            </a>
                            .
                        </p>
                    </div>
                    <div className={css.checkerBoardColumn}>
                        <img
                            className={css.featuredSticker}
                            src={featured_gif ? featured_gif.images.original.url : defaultSticker}
                        />
                    </div>
                </div>
                <EmbedCode channelId={id} />
            </div>
        )}
    </div>
)

StickerEmbedModal.propTypes = {
    id: PropTypes.number,
    display_name: PropTypes.string,
    history: PropTypes.object,
    featured_gif: PropTypes.object,
    isFetching: PropTypes.bool,
}

export const StickerEmbedButton = () => (
    <div>
        <a className={css.button} href="#embed">
            <i className={css.embedButton} />
            <span>Embed Sticker Pack</span>
        </a>
    </div>
)
