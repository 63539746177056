import * as colors from '@giphy/colors'
import { IChannel } from '@giphy/js-types'
import Breadcrumbs from 'shared/components/breadcrumbs/breadcrumbs'
import styled from 'styled-components'
import { parse } from 'url'

const BreadcrumbsDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    a {
        margin-bottom: 6px;
    }
`

const MobileWebsiteLink = styled.a`
    color: ${colors.giphyLightGrey};
    display: inline-block;
`

type Props = {
    ancestors: IChannel[]
    mobileTitle: string
    shortDisplayName: string
    search: string
    primarySite: string
    slug: string
}

const MobileBreadcrumbs = ({
    ancestors,
    ancestors: [ancestor],
    mobileTitle,
    shortDisplayName,
    search,
    primarySite,
    slug,
}: Props) =>
    ancestor ? ( // branch & leaf pages
        <BreadcrumbsDiv>
            <Breadcrumbs
                breadcrumbs={ancestors}
                displayName={mobileTitle}
                shortDisplayName={shortDisplayName}
                casing={`capitalize`}
            />
        </BreadcrumbsDiv>
    ) : search || primarySite ? ( // search or root channel
        <BreadcrumbsDiv>
            <MobileWebsiteLink
                style={{ textTransform: `none` }}
                href={search ? `/${slug}` : primarySite}
                rel="noopener noreferrer"
                target="_blank"
            >
                {search ? mobileTitle : parse(primarySite).hostname}
            </MobileWebsiteLink>
        </BreadcrumbsDiv>
    ) : null

export default MobileBreadcrumbs
