import { useContext, useState } from 'react'
import { useChannelWithNormalGifFetch } from 'shared/api/channels'
import EllipsisMenuOverlay from 'shared/components/collections/ellipsis-menu-overlay'
import Grid from 'shared/components/grid'
import ChannelPageContext from 'shared/contexts/channel-page'
import GridTypeContext from 'shared/contexts/grid-type'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { grid9 } from 'shared/util/grid'
import { getAllTheCopy } from 'shared/util/string'
import styled from 'styled-components'
import Feed from '../feed'
import Navigation from './navigation'
import NoResults from './no-results'

const Container = styled.div`
    margin-top: 30px;
`

type Props = {
    isOwner?: boolean
    onNoResultsUpdate?: (noResults: boolean) => void
    gridWidth?: number
}

const ChannelGrid = ({ isOwner, onNoResultsUpdate, gridWidth = grid9 }: Props) => {
    const { channel, channelType, searchQuery } = useContext(ChannelPageContext)
    const [noResults, showNoResults] = useState<boolean | undefined>(undefined)
    const { id, display_name: displayName, has_children: hasChildren, content_type: contentType } = channel
    const { gridType } = useContext(GridTypeContext)
    const isFeaturedTag = channelType === 'tagged_channel'
    const hasClips = channel.has_clips_feed

    let fetchOptions
    if (channelType === 'tagged_channel' || channelType === 'search_channel') {
        fetchOptions = { term: searchQuery, isFeaturedTag }
    }
    const fetchGifs = useChannelWithNormalGifFetch(id, fetchOptions)
    // fetches are cached in memory so no need to worry about the extra fetch
    const testForGifs = useChannelWithNormalGifFetch(id, fetchOptions)
    useAsyncEffect(async () => {
        const result = await testForGifs(0)
        showNoResults(result.data.length === 0 && !hasClips)
        onNoResultsUpdate && onNoResultsUpdate(result.data.length === 0 && !hasClips)
    }, [])
    const title = searchQuery || isFeaturedTag || !hasChildren ? '' : getAllTheCopy(displayName, contentType)
    if (noResults === undefined) {
        return null
    }

    return (
        <Container>
            <Navigation title={title} noResults={noResults} />
            {gridType === 'feed' ? (
                <Feed fetchGifs={fetchGifs} />
            ) : (
                <Grid Overlay={EllipsisMenuOverlay} fetchGifs={fetchGifs} width={gridWidth} />
            )}
            {noResults && <NoResults isOwner={isOwner} />}
        </Container>
    )
}

export default ChannelGrid
