import 'shared/trackjs'
import { createRoot } from 'react-dom/client'
import App from './app'

const Giphy = (global.Giphy = global.Giphy || {})
Giphy.renderDesktop = (el, data) => {
    const root = createRoot(el)

    root.render(<App {...data} />)

    if (module.hot) {
        module.hot.accept('./app', () => {
            const NextApp = require('./app').default
            root.render(<NextApp {...data} />)
        })
    }
}
