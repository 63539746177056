import * as colors from '@giphy/colors'
import { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react'
import Fields, { FIELDS, FieldUpdate, Label } from 'shared/components/form/fields'
import BlockEnterSubmit from 'shared/components/form/inputs/block-enter-submit'
import Test from 'shared/components/test'
import { useSaveForm } from 'shared/hooks/use-save-form'
import { AckResponseErrorHandler, SaveHandler } from 'shared/types/settings'
import styled from 'styled-components'
import { IProfilePowerUser } from 'types'
import { CY_USER_SETTINGS__SAVE_BUTTON } from 'ui'
import { trackGA4SettingsEvent } from './ga4'
import { ButtonGroups, CTAButton, FieldSection, FooterTip, FormContainer, Header } from './sc'

const ChannelContainer = styled(ButtonGroups)`
    margin-bottom: 12px;
    text-align: left;
    font-size: 14px;
`

const Channel = styled.a`
    font-size: 12px;
    color: ${colors.giphyLightGrey};
    cursor: pointer;
    &:hover {
        color: ${colors.giphyWhite};
    }
`

const ArtistBrandUsername = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Username = styled.div``

type Props = {
    csrftoken?: string
    user: IProfilePowerUser
    className?: string
    saveHandler: SaveHandler
    ackResponseError: AckResponseErrorHandler
    responseErrors: object
}

const SettingsForm = ({ user, className, saveHandler, ackResponseError, responseErrors }: Props) => {
    const { user_type } = user
    const isBrandOrArtist = user_type === 'partner' || user_type === 'artist'
    const isFocusedRef = useRef<boolean>(false)

    const onSave = (err?: Error) => {
        saveHandler(err)
        trackGA4SettingsEvent('account', err ? 'error' : 'success')
        isFocusedRef.current = false
    }

    const onError = (field: string) => {
        ackResponseError(field)
    }

    const onFocus = () => {
        if (isFocusedRef.current) return

        trackGA4SettingsEvent('account', 'open')
        isFocusedRef.current = true
    }

    const onBlur = (e: SyntheticEvent<HTMLFormElement, FocusEvent>) => {
        if (!e.nativeEvent.relatedTarget && isFocusedRef.current) {
            trackGA4SettingsEvent('account', 'cancel')
            isFocusedRef.current = false
        }
    }

    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false)
    const [form1Valid, setForm1Valid] = useState<boolean>(isBrandOrArtist)
    const [form2Valid, setForm2Valid] = useState<boolean>(false)
    const { formRef, save, isSaving, response } = useSaveForm(user.id, onSave)
    useEffect(() => setSubmitEnabled(form1Valid && form2Valid), [form1Valid, form2Valid])
    const { username, profile_url } = user
    let customFieldProps: FieldUpdate[] = []
    if (!isBrandOrArtist) {
        customFieldProps = [[FIELDS.displayName, { optional: true }]]
    }

    const [changingEmail, setChangingEmail] = useState(false)

    const saveCallback = () => {
        setChangingEmail(false)
        save()
    }

    const dimButton = !submitEnabled || isSaving
    const isEditableUsername = !isBrandOrArtist && !user.is_community
    return (
        <form
            className={className}
            ref={formRef}
            onFocus={onFocus}
            onBlur={onBlur}
            onSubmit={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
        >
            <FormContainer>
                <Header>Account Settings</Header>
                {!isEditableUsername ? (
                    <ChannelContainer>
                        <Label label="Username" />
                        <ArtistBrandUsername>
                            <Username>{`@${username}`}</Username>
                            <Channel>{profile_url}</Channel>
                        </ArtistBrandUsername>
                    </ChannelContainer>
                ) : (
                    <Fragment>
                        <Fields
                            user={user}
                            onValidChange={setForm1Valid}
                            showFields={[FIELDS.username]}
                            showLabel
                            noTooltip
                            ackResponseError={onError}
                            responseErrors={responseErrors}
                            aggroErrorMessages={true}
                        />
                        <FooterTip>
                            <Channel>{profile_url}</Channel>
                        </FooterTip>
                    </Fragment>
                )}
                <Fragment>
                    <FieldSection
                        user={user}
                        onValidChange={setForm2Valid}
                        showLabel
                        noTooltip
                        noOptional
                        showFields={
                            isBrandOrArtist
                                ? [FIELDS.displayName, FIELDS.email, FIELDS.contact]
                                : [FIELDS.displayName, FIELDS.email]
                        }
                        customFieldProps={customFieldProps}
                        ackResponseError={onError}
                        responseErrors={responseErrors}
                        aggroErrorMessages={true}
                        saveCallback={saveCallback}
                        changingEmail={changingEmail}
                        setChangingEmail={setChangingEmail}
                        isSaving={isSaving}
                        settingsPage={true}
                        saveResponse={response}
                    />
                </Fragment>
                <BlockEnterSubmit />
                <Test id={CY_USER_SETTINGS__SAVE_BUTTON}>
                    <CTAButton onClick={saveCallback} color={dimButton ? colors.giphyDarkGrey : colors.primaryCTA}>
                        {isSaving ? `Saving...` : `Save`}
                    </CTAButton>
                </Test>
                <input type="hidden" name="platform" value="web" />
                <input type="hidden" name="source" value="1" />
            </FormContainer>
        </form>
    )
}

export default SettingsForm
