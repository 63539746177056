import { useContext, useState } from 'react'
import GiphyAppAd from 'shared/components/ads/giphy-app'
import Info from 'shared/components/channel-info/info'
import Screensaver from 'shared/components/screensaver/screensaver'
import UploadClipsCTAComponent from 'shared/components/upload-clips-cta'
import SharedVideoPaginationCarousel from 'shared/components/video-preview-thumbnail/pagination-carousel'
import ChannelPageContext from 'shared/contexts/channel-page'
import { getUrl } from 'shared/util/gif-extract'
import { grid9 } from 'shared/util/grid'
import { WhenInView } from 'shared/util/in-view'
import styled from 'styled-components'
import ChannelBanner from './channel-banner'
import PaginationCarousels, { BranchGrid } from './children/pagination-carousels'
import ForHireModal from './for-hire-modal'
import BulkEditLauncher from './gifs/bulk-edit-launcher'
import ChannelGrid from './gifs/grid'
import ChannelHeader from './header'
import { Container, Content, ContentContainer, Sidebar } from './sc'
import Stories from './stories'

const VideoPaginationCarousel = styled(SharedVideoPaginationCarousel)`
    margin-top: 30px;
`

const UploadClipsCTA = styled(UploadClipsCTAComponent)`
    margin-bottom: 30px;
`

const RootChannel = ({ deepRefreshKey }) => {
    const { isSearch, isOwner, channel, isTagView, searchQuery: query } = useContext(ChannelPageContext)
    const { parent, screensaver_gif, has_children, user, has_clips_feed: hasVideo, children: channelChildren } = channel
    const [noResults, setNoResults] = useState<boolean>(false)
    const [isModalActive, setIsModalActive] = useState<boolean>(false)
    const username = user ? user.username : ''
    const isChild = !!parent
    const screensaverUrl = getUrl(screensaver_gif, 'fixed_height') || ''
    const isBranch = isChild && has_children
    const onNoGifsUpdate = (noResults: boolean = false) => {
        setNoResults(noResults)
    }
    const showModal = () => {
        // Set (or unset) locked grid scrolling
        if (isModalActive) {
            document.body.style.overflow = ''
        } else {
            document.body.style.overflow = 'hidden'
        }
        setIsModalActive(!isModalActive)
    }
    return (
        <Container>
            <BulkEditLauncher />
            <ChannelBanner />
            <ContentContainer>
                {isModalActive ? <ForHireModal user={user} deactivateModal={showModal} /> : null}
                <Sidebar>
                    <Info
                        channel={channel}
                        isOwner={isOwner}
                        search={query}
                        isTagView={isTagView}
                        showModal={showModal}
                    >
                        {isOwner && noResults && <GiphyAppAd />}
                    </Info>
                </Sidebar>
                <Content>
                    <ChannelHeader />
                    {!hasVideo && isOwner && <UploadClipsCTA gaCategory="channel" />}
                    {username && !isChild && !isSearch && (
                        <Stories isOwner={isOwner} username={username} title={'Stories'} />
                    )}
                    <div key={deepRefreshKey}>
                        {!isSearch &&
                            !isTagView &&
                            (isBranch ? (
                                <BranchGrid channels={channelChildren} />
                            ) : (
                                <PaginationCarousels channels={channelChildren} />
                            ))}
                        {hasVideo && (
                            <VideoPaginationCarousel
                                username={username}
                                title={`Clips`}
                                titleUrl={`/${username}/clips`}
                                width={grid9}
                                hideAttribution
                            />
                        )}
                        <WhenInView dontUnmount>
                            <ChannelGrid isOwner={isOwner} onNoResultsUpdate={onNoGifsUpdate} />
                        </WhenInView>
                    </div>
                </Content>
                {screensaverUrl ? <Screensaver images={[screensaverUrl]} /> : null}
            </ContentContainer>
        </Container>
    )
}
export default RootChannel
