import { giphyDarkestGrey } from '@giphy/colors'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ExperiencesContext from 'shared/contexts/experiences'
import UserContext from 'shared/contexts/user'
import styled from 'styled-components'
import { Modal } from '../modal'
import { Footer } from './footer'
import { ContentStrategy } from './screens/content-strategy'
import { ExpandYourReach } from './screens/expand-reach'
import { NiceWork } from './screens/nice-work'
import { TaggingScreen } from './screens/tagging'
import { Tools } from './screens/tools'
import { WelcomeScreen } from './screens/welcome'
import { SkipOnboardingModal } from './skip-modal'
import { getExperiences, updateExperience } from './user-onboarding.api'

const MODAL_TOP = 90
const MODAL_MARGIN_LEFT = -12
const MODAL_HEIGHT = 820
const MODAL_WIDTH = 1296
const UPGRADED_ONBOARDING = 'upgraded_onboarding'

export const smallScreenSize = `(max-width:  1300px), (max-height: 880px) `
const Container = styled.div<{ height?: number; top?: number; marginLeft?: number; width?: number }>`
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${giphyDarkestGrey};
    padding-left: 10px;
    padding-right: 10px;
    width: 1296px;
    margin-left: ${(props) => `${props.marginLeft}px` || 'auto'};
    height: 820px;
    align-items: center;

    @media screen and ${smallScreenSize} {
        width: 1064px;
        height: 670px;
    }
`

type UpgradedOnboarding = {
    experience_id: string
    status: number
}

const STATUS_PENDING = 0
const STATUS_COMPLETED = 2

const UpgradedUserOnboardingModal = () => {
    const { user } = useContext(UserContext)
    const { experiences, disabled } = useContext(ExperiencesContext)

    const { hash, pathname } = useLocation()
    const [step, setStep] = useState(1)
    const [showSkipModal, setShowSkipModal] = useState(false)
    const [showOnboarding, setShowOnboarding] = useState(false)
    const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false)
    const disabledForPath =
        ['/dashboard', '/supercut', '/create', '/upload'].findIndex((p) => pathname.includes(p)) > -1

    useEffect(() => {
        if (hash === '#upgraded-onboarding') {
            getExperiences().then((experiences) => {
                const upgradedOnboarding = Object.values(experiences).find(
                    (experience: any) => experience.experience_id === UPGRADED_ONBOARDING
                ) as UpgradedOnboarding
                if (!upgradedOnboarding) {
                    return
                }

                setIsOnboardingCompleted(upgradedOnboarding.status === STATUS_COMPLETED)
                setShowOnboarding(!!upgradedOnboarding)
            })
        }
    }, [hash])

    useEffect(() => {
        if (disabled || disabledForPath || !user?.isUpgraded) {
            return
        }
        const upgradedOnboarding = Object.values(experiences).find(
            (experience: any) => experience.experience_id === UPGRADED_ONBOARDING
        )
        if (!upgradedOnboarding) {
            return
        }

        setShowOnboarding(upgradedOnboarding.status === STATUS_PENDING)
    }, [experiences])

    useEffect(() => {
        if (showOnboarding) {
            const detectKey = (e) => {
                if (e.keyCode == '39') {
                    setStep((prevStep) => (prevStep < 6 ? prevStep + 1 : prevStep))
                }
                if (e.keyCode == '37') {
                    setStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep))
                }
            }
            document.onkeydown = detectKey
        }
        return () => {
            document.onkeydown = null
        }
    }, [showOnboarding])

    useEffect(() => {
        if (step == 6 && !isOnboardingCompleted) {
            updateExperience(UPGRADED_ONBOARDING, STATUS_COMPLETED)
        }
    }, [step])

    const endOnboarding = (skippedOnboarding: boolean = false) => {
        setShowOnboarding(false)
        if (skippedOnboarding && !isOnboardingCompleted) {
            updateExperience(UPGRADED_ONBOARDING, STATUS_COMPLETED)
        }
        window.location.href = `/${user.username}`
    }

    if (showSkipModal) {
        return (
            <SkipOnboardingModal
                show={showSkipModal}
                setShowSkipModal={setShowSkipModal}
                setShowOnboarding={setShowOnboarding}
                endOnboarding={endOnboarding}
            />
        )
    }
    return (
        <>
            {showOnboarding && (
                <Modal>
                    <Container top={MODAL_TOP} height={MODAL_HEIGHT} marginLeft={MODAL_MARGIN_LEFT} width={MODAL_WIDTH}>
                        {step === 1 && <WelcomeScreen />}
                        {step === 2 && <TaggingScreen />}
                        {step === 3 && <ContentStrategy />}
                        {step === 4 && <Tools userVerification={user.isVerified} />}
                        {step === 5 && <ExpandYourReach />}
                        {step === 6 && <NiceWork />}
                        <Footer
                            step={step}
                            setStep={setStep}
                            setShowSkipModal={setShowSkipModal}
                            endOnboarding={endOnboarding}
                        />
                    </Container>
                </Modal>
            )}
        </>
    )
}

export default UpgradedUserOnboardingModal
