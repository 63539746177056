import { giphyBlue, giphyDarkestGrey, giphyGreen } from '@giphy/colors'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    background: ${giphyDarkestGrey};
    border-radius: 4px;
    overflow: hidden;
    align-items: center;
    height: 55px;
    margin-top: 30px;
`

const GradientContainer = styled.div`
    flex-shrink: 0;
    height: 100%;
    background: linear-gradient(45deg, ${giphyGreen} 0%, ${giphyBlue} 100%);
    width: 7px;
`

// const LearnMoreLinkContainer = styled.a`
//     margin-left: auto;
//     padding: 12px 25px;
//     font-weight: bold;
//     font-size: 15px;
// `

const LearnMoreTextContainer = styled.div`
    padding: 12px 25px;
    width: 500px;
    span {
        background: linear-gradient(45deg, ${giphyGreen} 0%, ${giphyBlue} 100%);
        font-weight: bold;
        color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
`

// const RightArrow = styled.div`
//     display: inline-block;
//     vertical-align: middle;
//     color: white;
//     margin-left: 10px;
//     margin-top: -4px;
//     height: 15px;
//     width: 10px;
// `

const DisclaimerBanner = () => (
    <Container>
        <GradientContainer />
        <LearnMoreTextContainer>
            <span>Clip Views</span> on GIPHY are only available after 4/30/2021.
        </LearnMoreTextContainer>
        {/* <LearnMoreLinkContainer */}
        {/*     href="https://support.giphy.com/hc/en-us/articles/360042381152-Analytics-Dashboard-FAQ" */}
        {/*     target='_blank' */}
        {/*     rel='noopener noreferrer' */}
        {/* > */}
        {/*     Learn More */}
        {/*     <RightArrow className="ss-icon ss-navigateright" /> */}
        {/* </LearnMoreLinkContainer> */}
    </Container>
)

export default DisclaimerBanner
