import { giphyDarkestGrey, giphyLightGrey } from '@giphy/colors'
import { Button } from 'shared/components/buttons'
import { Subheader } from 'shared/components/text-elements'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
`

const Title = styled(Subheader)`
    color: ${giphyLightGrey};
    margin-bottom: 6px;
`

const AppImage = styled.img`
    display: block;
    height: auto;
    margin-bottom: 6px;
    width: 100%;
`

const LearnMore = styled(Button)`
    background: ${giphyDarkestGrey};
    display: block;
`

const GiphyAppAd = () => (
    <Container>
        <Title>Try our app</Title>
        <AppImage src="/static/img/download-app.gif" alt="Download the GIPHY app" />
        <LearnMore as="a" href="/apps">
            Learn More
        </LearnMore>
    </Container>
)

export default GiphyAppAd
