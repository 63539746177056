import * as colors from '@giphy/colors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { ResponseError } from 'shared/api/errors'
import { STATUS_MESSAGES, STATUS_STYLE } from 'shared/components/message/flash-message'
import { message } from 'shared/redux/message'
import styled, { css } from 'styled-components'
import { IProfileUser } from 'types'
import AvatarUpload from './avatar-upload'
import { getGA4FormError, trackGA4AvatarEvent } from './ga4'

const replaceChannel = 'channel'
const items = [
    { icon: 'ss-barchart', label: 'Dashboard', href: '/dashboard' },
    { icon: 'ss-settings', label: 'Settings', href: '/settings' },
    { icon: 'ss-lock', label: 'Password', href: '/password/change' },
    { icon: 'ss-heart', label: 'Favorites', href: '/favorites' },
    { icon: 'ss-like', label: 'How to GIPHY', href: '#upgraded-onboarding', forUpgraded: true },
    { icon: 'ss-help', label: 'Support', href: 'https://support.giphy.com' },
    { icon: 'ss-action', label: 'Go to Channel', href: replaceChannel },
]

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const activeCss = css`
    color: ${colors.giphyWhite};
    background-color: ${colors.giphyBlue};
`
const NavItem = styled.a<{ isActive: boolean }>(
    ({ isActive }) => css`
        color: ${colors.giphyBlue};
        &:hover {
            ${activeCss};
        }
        ${isActive && activeCss};
        display: flex;
        align-items: center;
        padding: 10px;
        i {
            margin-right: 10px;
        }
    `
)

type Props = {
    location: any
    className?: string
    user: IProfileUser
    message: (message: string, type: string) => void
}

const getMessage = (e: Error | string): string => {
    const errorMessage = (e as Error).message
    const messageOrKey = errorMessage || e
    const keyMessage = STATUS_MESSAGES[messageOrKey]
    if (!keyMessage && (e as ResponseError)) {
        const details = (e as ResponseError).details
        if (details) {
            const { detail } = details
            return detail
        }
    }
    return keyMessage || `Error uploading avatar: ${messageOrKey}`
}

const SideNav = ({ user, location: { pathname }, className, message }: Props) => {
    const onAvatarError = (e: Error | string) => {
        message(getMessage(e), STATUS_STYLE.ERROR)
        trackGA4AvatarEvent('error', getGA4FormError(e))
    }
    const onAvatarSuccess = (copy: string) => {
        message(copy, STATUS_STYLE.SUCCESS)
        trackGA4AvatarEvent('success')
    }
    const onAvatarChangeClick = () => {
        trackGA4AvatarEvent('open')
    }
    return (
        <Container className={className}>
            <AvatarUpload
                onAvatarError={onAvatarError}
                onAvatarSuccess={onAvatarSuccess}
                onAvatarChangeClick={onAvatarChangeClick}
                user={user}
            />
            {items
                .filter(({ forUpgraded }) => !forUpgraded || forUpgraded == user.is_upgraded)
                .map(({ href, label, icon }) => {
                    if (href === replaceChannel) {
                        href = '/channel/' + user.username
                    }
                    return (
                        <NavItem href={href} key={label} isActive={pathname === href}>
                            <i className={icon} />
                            {label}
                        </NavItem>
                    )
                })}
        </Container>
    )
}

export default compose(connect(undefined, { message }), withRouter)(SideNav)
