import styled from 'styled-components'
import IconTooltip from 'shared/components/ui/icon-tooltip'

export const TooltipContent = styled.div`
    font-weight: normal;
    font-size: 15px;
    font-family: interface;
    line-height: 23px;
    width: 350px;

    p {
        text-align: center;
        margin-top: 0;
        padding-top: 0;
    }

    a {
        width: 100%;
        display: block;
        text-align: center;
        color: black;
        text-decoration: underline;
        font-weight: bold;

        &:hover {
            opacity: 0.7;
        }
    }
`
export const BudgetTooltipContent = () => (
    <TooltipContent>
        <p>Rates tend to vary! Consider the scope of work and a fair day rate to help calculate this.</p>
        <a
            target="_blank"
            rel="noreferrer"
            href="https://support.giphy.com/hc/en-us/articles/360022661172-Hire-A-GIF-Artist-"
        >
            Learn More about GIF Commissions
        </a>
    </TooltipContent>
)

export const ProjectTypeTooltipContent = () => (
    <TooltipContent>
        <p>
            Help us get a better sense of what you&apos;re working on & where the work will live. Select any that might
            apply.
        </p>
        <a
            target="_blank"
            rel="noreferrer"
            href="https://support.giphy.com/hc/en-us/articles/360022661172-Hire-A-GIF-Artist-"
        >
            Learn More about GIF Commissions
        </a>
    </TooltipContent>
)

export const TooltipContainer = styled.div`
    & > div {
        height: 21px;

        & > div:before {
            color: white;
            top: -6px;
            left: -12px;
            position: relative;
        }
    }
`
export const ModalTooltip = styled(IconTooltip)`
    display: inline-block;
    height: 21px;
    margin-left: 9px;
    cursor: pointer;
`
