import { Button as BrandButton } from 'shared/components/buttons'
import { useContext } from 'react'
import { CollectionCTAButton } from 'shared/components/collections/create-new-collection'
import ChannelPageContext from 'shared/contexts/channel-page'
import ModalContext from 'shared/contexts/modal'
import { useCanManageCollection } from 'shared/hooks/use-permissions'
import styled from 'styled-components'

const Button = styled(BrandButton)`
    margin-left: 10px;
    min-width: auto;
    flex-shrink: 0;
`

const HeaderCollectionButtons = () => {
    const { channel, channelType } = useContext(ChannelPageContext)
    const { openEditCollectionPanel } = useContext(ModalContext)
    const canManage = useCanManageCollection()
    const isRoot = channel.parent === null
    if (!canManage || channelType === 'video_channel') {
        return null
    }
    if (isRoot) {
        return <CollectionCTAButton isEdit={channel.has_children} />
    }
    return <Button onClick={() => openEditCollectionPanel(channel)}>Edit Collection</Button>
}

export default HeaderCollectionButtons
