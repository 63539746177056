import styled from 'styled-components'
import { BackArrowIcon } from 'site/creation-tools/components/icons'

const BackButtonContainer = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: auto;

    span {
        padding: 0 8px;
    }
`

const BackLabel = styled.span`
    font-weight: 700;
    font-size: 17px;
    padding: 0;
`

export const BackButton = ({ onClick }) => (
    <BackButtonContainer onClick={onClick}>
        <BackArrowIcon />
        <BackLabel>Back</BackLabel>
    </BackButtonContainer>
)
