import { setGADataLayer } from 'analytics'

export type SettingsFormType = 'account' | 'channel'
export type SettingsFormEvent = 'cancel' | 'error' | 'open' | 'success'

export function trackGA4SettingsEvent(type: SettingsFormType, event: SettingsFormEvent) {
    setGADataLayer({
        event: `${type}_settings_${event}`,
        options: {
            form: {
                formName: `${type} settings`,
            },
        },
    })
}

export function trackGA4DeleteAccountEvent(event: SettingsFormEvent) {
    setGADataLayer({
        event: `delete_account_${event}`,
        options: {
            form: {
                formName: `delete account`,
            },
        },
    })
}
