import styled from 'styled-components'
import { useState } from 'react'
import { useSessionStorage } from 'react-use'
import { mobile } from 'shared/util/media-query'

const BetaNotice = () => {
    const [hasSeen, setHasSeen] = useSessionStorage(`BETA_BANNER_SEEN`, false)
    // @ts-ignore
    const [showModal, setShowModal] = useState(!!window?.BUILD_DISPLAY_ENABLED && !hasSeen)

    if (!showModal) {
        return null
    }
    return (
        <Background>
            <Modal>
                <MainTitle>Hey, CAUTION, WARNING, ALERT!</MainTitle>
                <Body>
                    You have entered a <span>GIPHY Beta Environment</span>. Beta uses <span>Production Data</span>,
                    meaning anything you upload or edit will <span>also happen on GIPHY.com</span>. Proceed with
                    Caution, because you beta believe anything you mess up will be held against you.
                </Body>
                <ButtonContainer>
                    <a href={'https://giphy.com/'}>
                        <LeftButton>Take me to GIPHY.com</LeftButton>
                    </a>
                    <RightButton
                        onClick={() => {
                            setShowModal(false)
                            setHasSeen(true)
                        }}
                    >
                        Continue to BETA.GIPHY.com
                    </RightButton>
                </ButtonContainer>
                <LearnMore
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://giphypedia.atlassian.net/wiki/spaces/WF/pages/1626603855/Using+Web+Beta+Environments"
                >
                    Learn More
                </LearnMore>
            </Modal>
        </Background>
    )
}

export default BetaNotice

const ButtonContainer = styled.div`
    display: grid;
    margin: auto;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    max-width: 560px;
    width: 100%;
    ${mobile.css`
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        grid-row-gap: 10px;
    `}
`
const ModalButton = styled.button`
    height: 36px;
    width: 275px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'interface', sans-serif;
    color: white;
    line-height: 36px;
    padding: 0;
    &:hover {
        filter: brightness(110%) !important;
        margin: auto;
    }
`

const LeftButton = styled(ModalButton)`
    background: #ff6666;
`
const RightButton = styled(ModalButton)`
    background: #6157ff;
`

const MainTitle = styled.h1`
    color: rgb(255, 255, 255);
    font-family: 'nexablack';
    font-size: 22px;
    letter-spacing: 0px;
    text-align: center;
`

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2147483648;
    ${mobile.css`
        width: 100vw;
        height: 100vh;
        overflow: auto;
    `}
`

const Modal = styled.div`
    box-sizing: border-box;
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background: rgb(33, 33, 33);
    border-radius: 4px;
    max-width: 620px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    &::before {
        content: '';
        background-image: url(https://media.giphy.com/media/3oEhmYyk8pWIXQo5Z6/giphy.gif);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 274px;
        height: 166px;
        display: block;
        position: absolute;
        top: -123px;
        left: 173px;
        ${mobile.css`
            left: calc(50% - 45vw);
            width: 90vw;
        `}
    }
    ${mobile.css`
        max-width: 90vw;
        transform: unset;
        top: 140px;
    `}
`

const Body = styled.p`
    color: rgb(255, 255, 255);
    max-width: 530px;
    width: 100%;
    line-height: 24px;
    font-family: 'interface', sans-serif;
    font-size: 17px;
    margin: auto;
    margin-top: 20px;
    text-align: center;
    span {
        color: #ff6666;
        &:last-child {
            font-style: italic;
        }
    }
`

const LearnMore = styled.a`
    font-size: 15px;
    color: #d8d8d8;
    font-family: 'interface', sans-serif;
    letter-spacing: 0px;
    margin-top: 25px;
    margin-bottom: 0px;
    text-align: center;
    text-decoration: underline;
    display: block;
`
