import styled from 'styled-components'
import { FOUR_O_FOUR_API_KEY } from 'shared/api'
import Grid from 'shared/components/grid'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { Container, Message, Arrow, Footer, Button } from './sc'
import Video from '../../shared/components/background-video'

const BackgroundVideo = styled(Video)`
    height: 1015px;
    opacity: 0.23;
    position: fixed;
`

const Emoji = styled.img`
    height: 300px;
    width: 300px;
    margin-top: 30px;
`

const fourOHFourFetch = new GiphyFetch(FOUR_O_FOUR_API_KEY)

const Error404 = () => {
    const fetchGifs = (offset: number) => fourOHFourFetch.trending({ offset })

    return (
        <>
            <BackgroundVideo />
            <Container>
                <Emoji src={'/static/img/error_pages/crying-cowbow-emoji.gif'} />
                <Message>
                    <h4>Oops! There’s nothing here.</h4>
                    <h4>For GIFs that DO exist, here&apos;s our trending feed...</h4>
                </Message>
                <Arrow className="ss-icon ss-navigatedown" />
                <Grid columns={4} fetchGifs={fetchGifs} />
                <Footer>
                    <Button href="/">Continue to Our Homepage</Button>
                </Footer>
            </Container>
        </>
    )
}

export default Error404
