import { giphyLightGrey } from '@giphy/colors'
import { useMedia } from 'react-use'
import { Title } from 'shared/components/text-elements'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import AnalyticsInfoTooltip from '../analytics-info-tooltip'

const LargeDashboardStatistic = styled(Title)`
    font-size: 68px;
    text-align: left;
    line-height: 1;

    ${mobile.css`
        font-size: 33px;
    `}
`

const SubheaderContainer = styled.div`
    position: relative;
    display: flex;
`

const Subheader = styled.p`
    text-align: left;
    margin: 0;
    color: ${giphyLightGrey};
    font-family: interface;
    font-weight: 600;
    text-transform: none;
    font-size: 18px;

    ${mobile.css`
        font-size: 14px;
    `}
`

type Props = {
    stat: string
    title: string
    toolTipText: string
    helpLink?: string
}

const ShowStatistics = ({ stat, title, toolTipText, helpLink }: Props) => {
    const isMobile = useMedia(mobile.query)
    return (
        <LargeDashboardStatistic>
            {stat}
            <SubheaderContainer>
                <Subheader>{title}</Subheader>
                {!isMobile && <AnalyticsInfoTooltip tooltip={toolTipText} helpLink={helpLink} />}
            </SubheaderContainer>
        </LargeDashboardStatistic>
    )
}

export default ShowStatistics
