import { PureComponent } from 'react'
import { connect } from 'react-redux'
import EllipsisDropdown from 'shared/components/ui/ellipsis-dropdown'
import { giphyIndigo, giphyMediumLightGrey } from 'shared/css/colors.css'
import * as selectors from 'shared/redux/reducers'
import styled from 'styled-components'
import { withStorybookContext } from './storybook/context'
import DeleteStory from './delete-story'

const EllipsisContainer = styled.div`
    position: absolute;
    right: 5px;
    z-index: 1;
    top: 5px;
    cursor: pointer;
`

const StoryEditDeleteContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 8px;
`

export const StoryEditItemBase = styled.div`
    color: ${giphyMediumLightGrey};
    cursor: pointer;
    display: block;
    margin: 0px;

    &:first-child {
        padding-top: 0;
    }

    &:hover {
        color: ${giphyIndigo};
    }
`

@connect((state) => ({
    user: selectors.getUser(state),
}))
@withStorybookContext
class Intro extends PureComponent {
    static defaultProps = {
        showPrivacyOption: false,
    }
    onStoryEditItemBaseClick = () => {
        const { story, onPrivacyClick } = this.props
        // grabbing onPrivacyClick from Storybook context
        onPrivacyClick(story)
    }
    render() {
        const {
            story: { story_id, is_private: isPrivate },
            isOpenDown,
        } = this.props
        return (
            <EllipsisContainer>
                <EllipsisDropdown extendDirectionUp={!isOpenDown} isHover={true}>
                    <StoryEditDeleteContainer>
                        <StoryEditItemBase onClick={this.onStoryEditItemBaseClick}>
                            {isPrivate ? 'Make Story Public' : 'Make Story Private'}
                        </StoryEditItemBase>
                        <DeleteStory id={story_id} />
                    </StoryEditDeleteContainer>
                </EllipsisDropdown>
            </EllipsisContainer>
        )
    }
}

export default Intro
