import { IGif } from '@giphy/js-types'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { getChannelGifsByUsername } from 'shared/api/channels'
import SharedCarousel from 'shared/components/carousel/carousel'
import Observer from 'shared/components/observer'
import VideoPreviewPlayInline from 'shared/components/video-inline-play'
import styled from 'styled-components'
import useFetchData from 'ui/src/hooks/use-fetch-data'
import GifContext from '../../contexts/gif'
import createPlaceholderGif from '../gif-skeleton'

const MAX_CAROUSEL_ITEMS = 3
const MARGIN = 10

const getItemWidth = (width: number) => {
    const margin = (MAX_CAROUSEL_ITEMS - 1) * MARGIN
    return Math.floor((width - margin) / MAX_CAROUSEL_ITEMS)
}

const VideoPreview = styled(VideoPreviewPlayInline)`
    display: inline-block;
    margin-bottom: ${MARGIN}px;
    margin-right: ${MARGIN}px;
    &:nth-${`child(${MAX_CAROUSEL_ITEMS}n)`} {
        margin-right: 0;
    }
`

const Carousel = styled(SharedCarousel)`
    margin-bottom: 0;
`

type Props = {
    title: string
    titleUrl: string
    width: number
    className?: string
    hideAttribution?: boolean
    username: string
    onClick?: () => void
}

const VideoPaginationCarousel = ({
    username,
    title,
    titleUrl,
    width,
    className = '',
    hideAttribution = false,
    onClick,
}: Props) => {
    const { receivedGifs } = useContext(GifContext)
    const placeholder = useRef(createPlaceholderGif({ title: '' }))
    const fetchGifs = useCallback(
        async (offset: number) => getChannelGifsByUsername(username, { offset, limit: 25 }),
        [username]
    )
    const { doFetch, gifs } = useFetchData<IGif>({ fetchData: fetchGifs })
    useEffect(() => {
        receivedGifs({ gifs })
    }, [gifs])
    const itemWidth = getItemWidth(width)
    const onNavigate = async ({ currentIndex, totalPages }) => {
        if (totalPages === currentIndex + 1) {
            doFetch()
        }
    }
    return (
        <Observer onVisible={doFetch} fireOnce>
            <Carousel
                maxBlocks={MAX_CAROUSEL_ITEMS}
                title={title}
                titleUrl={titleUrl}
                className={className}
                onNavigate={onNavigate}
            >
                {(gifs.length > 0 ? gifs : [placeholder.current]).map((gif: IGif, key) => (
                    <VideoPreview
                        key={gif.id}
                        gif={gif}
                        width={itemWidth}
                        showAttribution={!hideAttribution}
                        showClipTitle={true}
                        gridPosition={key + 1}
                        onClick={() => onClick?.()}
                    />
                ))}
            </Carousel>
        </Observer>
    )
}

export default VideoPaginationCarousel
