import { IUser } from '@giphy/js-types'
import { useState } from 'react'
import useAsyncEffect from './use-async-effect'

export function useUserProfile(onError?: (error: Error) => void) {
    const [userProfile, setUserProfile] = useState<IUser | undefined>(undefined)
    useAsyncEffect(async () => {
        let response
        try {
            response = await fetch('/api/v1/users/profile/')
        } catch (error) {
            console.error(error)
            onError && onError(error)
            return
        }
        const user = await response.json()
        const birthdate = user.birthdate
        if (birthdate) {
            let [y, m, d] = birthdate.split('-')
            user.birthdate = `${m}/${d}/${y}`
        }
        user.avatar_url = user.avatar
        setUserProfile(user)
    }, [])
    return userProfile
}
