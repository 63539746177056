import { getCreator, getUser, getUserChannel, setGADataLayer } from 'analytics'
import { getPageOptionsBasedOnPath } from 'analytics/src/utils/page'
import { LoggedInUser } from 'types'

export function trackGA4ContentCalendarEvent(event: string, user: LoggedInUser) {
    const channelData = { channel: user.username, channel_id: user.channelId }
    const data = {
        event: `content_calendar_${event}_click`,
        page: getPageOptionsBasedOnPath('/dashboard'),
        creator: getCreator(),
        channel: getUserChannel(channelData),
        user: getUser(),
    }

    setGADataLayer(data)
}
