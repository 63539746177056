import * as colors from '@giphy/colors'
import { giphyLightGrey } from '@giphy/colors'
import * as React from 'react'
import IconTooltip from 'shared/components/ui/icon-tooltip'
import styled from 'styled-components'

const ToolTip = styled.span`
    position: relative;
    display: flex;
    margin-top: -7px;
`

const ToolTipInnerds = styled.div`
    width: 300px;
    text-align: center;
    font-family: interface;
    font-weight: normal
    font-size: 15px;

    p:last-child {
        margin: 0px;
    }
`

const LearnMore = styled.p`
    text-align: center;

    a {
        color: ${colors.giphyIndigo};
        text-decoration: underline;
        text-transform: uppercase;
        font-family: interface;
        font-size: 12px;
        font-weight: bold;
    }
`

type InfoTooltipProps = {
    tooltip: React.ReactNode
    helpLink?: string
    className?: string
}

const AnalyticsInfoTooltip = ({ tooltip, helpLink, className }: InfoTooltipProps) => (
    <ToolTip className={className}>
        <IconTooltip icon={'ss-info'} color={giphyLightGrey}>
            <ToolTipInnerds>
                <p>{tooltip}</p>
                {helpLink ? (
                    <LearnMore>
                        <a href={helpLink} target="_blank" rel="noreferrer">
                            Learn More
                        </a>
                    </LearnMore>
                ) : null}
            </ToolTipInnerds>
        </IconTooltip>
    </ToolTip>
)

export default AnalyticsInfoTooltip
