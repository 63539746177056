import * as colors from '@giphy/colors'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { resendEmailChangeValidation } from '../settings/email-verification-message/email-verification-message.api'
import { States, ValidationUserContext } from './modal'
import { Button, Container, Email, Emoji, Footer, Message, PrimaryButton, Title } from './styled'

const ResendLink = styled.p`
    color: white;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.11px;
    margin-bottom: 0px;
`

const HighlightedLink = styled.span`
    color: ${colors.giphyBlue};
    font-weight: bold;
    cursor: pointer;
`

const SuccessEmailChange = () => {
    const { user, changeState, newEmail } = useContext(ValidationUserContext)
    const [resendEmail, setResendEmail] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => setResendEmail(true), 600000) // 10 minutes
        const emailRequest = user && user.pending_email_change_request
        if (emailRequest) {
            setResendEmail(emailRequest.can_send_email)
        }

        return () => {
            clearInterval(interval)
        }
    }, [])
    const logout = () => {
        window.location.href = '/logout'
    }

    return (
        <Container>
            <Emoji src="https://media2.giphy.com/media/LOnt6uqjD9OexmQJRB/200w.gif" />
            <Title>{resendEmail ? 'Thanks!' : 'Please validate your email'}</Title>
            <Message>
                {`Check your email for steps to change your email address from `}
                <Email>{user.email}</Email>
                {` to `}
                <Email>{newEmail}</Email>
            </Message>
            {resendEmail && <PrimaryButton onClick={resendEmailChangeValidation}>Resend Email</PrimaryButton>}
            <ResendLink>
                {`Want to update your email? Enter a new email address `}
                <HighlightedLink onClick={() => changeState(States.UPDATING_EMAIL)}>here</HighlightedLink>.
            </ResendLink>
            <Button onClick={logout}>Log out</Button>
            <Footer>
                {`Need help? Please reach out to `}
                <Email>support@giphy.com</Email>
            </Footer>
        </Container>
    )
}

export default SuccessEmailChange
