import EmptyStatePlaceholder from 'shared/components/empty-state-placeholder'
import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 30px;
`

const SettingsLink = styled.a`
    color: #fff;
    display: inline-block;
    font-size: 14px;
    margin-top: 6px;
    text-decoration: none;
`

const SettingsLinkIcon = styled.i`
    display: inline-block;
    font-size: 11px;
    opacity: 0.5;
    left: 2px;
    position: relative;
    top: 1px;
`

const NoInfo = () => (
    <Container>
        <EmptyStatePlaceholder height={135} width={208} colors={['#211717', '#211E16']}>
            <div>Personalize your profile!</div>
            <SettingsLink href="/settings">
                Open Settings <SettingsLinkIcon className="ss-navigateright" />
            </SettingsLink>
        </EmptyStatePlaceholder>
    </Container>
)

export default NoInfo
