import { giphyDarkestGrey, giphyLightGrey, giphyYellow } from '@giphy/colors'
import styled, { keyframes } from 'styled-components'

export const MENU_WIDTH = 260

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const Overlay = styled.div`
    display: flex;
    margin: 10px;
    position: absolute;
    top: 0;
    left: 0;
`

export const EllipsisContainer = styled.div`
    width: 5px;
    height: 20px;
    position: relative;
    display: flex;
    justify-items: center;
    cursor: pointer;
    padding-top: 3px;
`

export const EllipsisIcon = styled.i`
    font-size: 23px;
    transform: translateZ(0) rotate(90deg);
`

export const StarIcon = styled.i`
    font-size: 18px;
    margin-top: -5px;
    color: ${giphyYellow};
`

export const Menu = styled.div<{ top?: number; left?: number }>`
    width: ${MENU_WIDTH}px;
    background-color: ${giphyDarkestGrey};
    position: absolute;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
    padding: 7px 0px 7px 0px;
    animation: ${fadeIn} 0.5s ease;
    z-index: 998;
    border-bottom: transparent 20px;
`

export const MenuIcon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const MenuItem = styled.div`
    font-size: 17px;
    font-weight: bold;
    display: flex;
    justify-items: center;
    align-items: center;
    color: ${giphyLightGrey};
    cursor: pointer;
    padding: 7px 10px 7px 18px;
    span {
        margin-left: 15px;
    }
    &:hover {
        color: #fff;
        svg * {
            fill: #fff;
        }
    }
`

export const Arrow = styled.div`
    position: absolute;
    left: calc(50% - 8px);
    font-size: 22px;
    color: ${giphyDarkestGrey};
    animation: ${fadeIn} 0.5s ease;
`
