import * as colors from '@giphy/colors'
import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 38px 50px 15px;
    width: 489px;
    background: rgba(8, 8, 8, 0.9);
    border-radius: 8px;
`

export const Emoji = styled.img`
    width: 98px;
    height: 98px;
`

export const Title = styled.h2`
    font-family: 'nexablack', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
`

export const Button = styled.button`
    width: 355px;
    height: 41px;
    color: #d8d8d8;
    background: #212121;
    border-radius: 2px;
    font-weight: 700;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 20px;
`

export const Message = styled.p`
    color: white;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    margin-bottom: 40px;
    white-space: pre-line;
`

export const Email = styled.span`
    color: ${colors.giphyBlue};
    font-weight: bold;
`

export const Footer = styled.p`
    color: white;
    padding: 30px 30px 50px;
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.11px;
    margin-bottom: 0px;
`

export const PrimaryButton = styled.button`
    width: 355px;
    height: 41px;
    color: #ffffff;
    background: linear-gradient(88.24deg, #9933ff -20.09%, #6157ff 98.56%);
    border-radius: 2px;
    font-weight: 700;
    font-size: 17px;
    margin-top: 20px;
`
