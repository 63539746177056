import { giphyGreen } from '@giphy/colors'
import styled, { keyframes } from 'styled-components'

const stripeAnimation = keyframes`
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-60px, 0, 0); }
`

const Progress = styled.div`
    background: ${giphyGreen};
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
`

const Stripes = styled.div`
    animation: ${stripeAnimation} 0.7s linear 0s infinite;
    background: repeating-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.05) 10px,
        rgba(0, 0, 0, 0) 10px,
        rgba(0, 0, 0, 0) 20px
    );
    box-sizing: content-box;
    height: 100%;
    left: 0;
    padding-right: 60px;
    position: absolute;
    top: 0;
    width: 100%;
`

const DownloadAnimation = () => {
    return (
        <Progress>
            <Stripes />
        </Progress>
    )
}

export default DownloadAnimation
