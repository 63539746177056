import * as colors from '@giphy/colors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { ResponseError } from 'shared/api/errors'
import AvatarUpload from 'shared/components/form/settings/avatar-upload'
import { getGA4FormError, trackGA4AvatarEvent } from 'shared/components/form/settings/ga4'
import { STATUS_MESSAGES, STATUS_STYLE } from 'shared/components/message/flash-message'
import { message } from 'shared/redux/message'
import styled, { css } from 'styled-components'
import { IProfileUser } from 'types'
import { trackGA4DashboardNavEvent } from './ga4'

const dashboardItems = [
    {
        iconUrl: '/static/img/dashboard/dashboardMenuItem.svg',
        label: 'My Dashboard',
        anchor: '',
        offset: 0,
        trackLabel: 'user_dashboard',
    },
    { icon: 'ss-view', label: 'Total Views', anchor: 'graphAnchor', offset: 200, trackLabel: 'total_views' },
    {
        icon: 'ss-barchart',
        label: 'Top Content',
        anchor: 'topGifsAndStickersAnchor',
        offset: 185,
        trackLabel: 'leaderboard',
    },
    { icon: 'ss-download', label: 'CSV Download', anchor: 'csvAnchor', offset: 0, trackLabel: 'download_data' },
    {
        icon: 'ss-calendar',
        label: 'Events Calendar',
        anchor: 'contentCalendarAnchor',
        offset: 180,
        trackLabel: 'content_calendar',
    },
    {
        icon: 'ss-search',
        label: 'Search Insights',
        anchor: 'searchInsightsAnchor',
        offset: 108,
        trackLabel: 'search_insights',
    },
]

const helpItems = [
    {
        icon: 'ss-like',
        label: 'How to GIPHY',
        href: '#upgraded-onboarding',
        forUpgraded: true,
        trackLabel: 'onboarding',
    },
    { icon: 'ss-help', label: 'Support', href: 'https://support.giphy.com', trackLabel: 'support' },
]

const Container = styled.div`
    display: unset;
`
const activeCss = css`
    color: ${colors.giphyWhite};
    font-weight: 700;
    i,
    img {
        color: #00ccff;
    }
`
const NavItem = styled.a<{ isActive: boolean }>(
    ({ isActive }) => css`
        padding: 5px 0;
        display: flex;
        align-items: center;
        i,
        img {
            color: #a6a6a6;
            margin-right: 15px;
        }
        color: #5c5c5c;
        font-family: InterFace;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;

        &:hover {
            ${activeCss};
            cursor: pointer;
        }

        ${isActive && activeCss};
    `
)

const MenuContainer = styled.div`
    margin-top: 20px;
    margin-left: 15px;
`

const Divider = styled.hr`
    width: 129px;
    border-top: 2px solid #212121;
    margin: 10px 0;
`

const StickyContainer = styled.div`
    position: sticky;
    top: 6rem;
    align-self: start;
`

type Props = {
    location: any
    className?: string
    user: IProfileUser
    message: (message: string, type: string) => void
}

const getMessage = (e: Error | string): string => {
    const errorMessage = (e as Error).message
    const messageOrKey = errorMessage || e
    const keyMessage = STATUS_MESSAGES[messageOrKey]
    if (!keyMessage && (e as ResponseError)) {
        const details = (e as ResponseError).details
        if (details) {
            const { detail } = details
            return detail
        }
    }
    return keyMessage || `Error uploading avatar: ${messageOrKey}`
}

const AnalyticsSideNav = ({ user, location: { pathname }, className, message }: Props) => {
    const onAvatarError = (e: Error | string) => {
        message(getMessage(e), STATUS_STYLE.ERROR)
        trackGA4AvatarEvent('error', getGA4FormError(e))
    }
    const onAvatarSuccess = (copy: string) => {
        message(copy, STATUS_STYLE.SUCCESS)
        trackGA4AvatarEvent('success')
    }

    const scrollToDashboardItem = (anchor, offset) => {
        const top = anchor
            ? document.getElementById(anchor)!.getBoundingClientRect()!.top -
              document.body.getBoundingClientRect().top -
              offset
            : 0
        window.scrollTo({
            behavior: 'smooth',
            top,
        })
    }
    return (
        <Container className={className}>
            <AvatarUpload onAvatarError={onAvatarError} onAvatarSuccess={onAvatarSuccess} user={user} />
            <StickyContainer>
                <MenuContainer>
                    {dashboardItems.map(({ anchor, label, icon, iconUrl, trackLabel }) => {
                        return (
                            <NavItem
                                key={label}
                                isActive={label === 'My Dashboard'}
                                onClick={() => {
                                    scrollToDashboardItem(anchor, 0)
                                    trackGA4DashboardNavEvent(trackLabel, user)
                                }}
                            >
                                {icon ? <i className={icon} /> : <img src={iconUrl} width={17} />}
                                {label}
                            </NavItem>
                        )
                    })}
                    <Divider />
                    {helpItems
                        .filter(({ forUpgraded }) => !forUpgraded || forUpgraded == user.is_upgraded)
                        .map(({ href, label, icon, trackLabel }) => {
                            return (
                                <NavItem
                                    href={href}
                                    key={label}
                                    isActive={pathname === href}
                                    onClick={() => {
                                        trackGA4DashboardNavEvent(trackLabel, user)
                                    }}
                                >
                                    <i className={icon} />
                                    {label}
                                </NavItem>
                            )
                        })}
                </MenuContainer>
            </StickyContainer>
        </Container>
    )
}

export default compose(connect(undefined, { message }), withRouter)(AnalyticsSideNav)
