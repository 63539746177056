import { grid12, grid3, grid9, gridSpacing } from 'shared/util/grid'
import styled from 'styled-components'

export const Container = styled.div`
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    width: ${grid12}px;
`

export const ContentContainer = styled.div`
    display: flex;
`

export const Sidebar = styled.div`
    margin-right: ${gridSpacing}px;
    width: ${grid3}px;
`

export const Content = styled.div`
    width: ${grid9}px;
`
