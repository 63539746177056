import styled from 'styled-components'
import { Content, Emoji, Title, Copy } from './common-styles'
import { useEffect, useState } from 'react'

const Image = styled.img`
    margin-top: 75px;
    width: 380px;
    z-index: 2;
    @media screen and (max-width: 1300px), (max-height: 880px) {
        margin-top: 0px;
    }
`

const InlineLinks = styled.a`
    color: #00ccff;
    text-decoration: underline;
`

export const NiceWork = () => {
    const [animatedOpacity, setAnimatedOpacity] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setAnimatedOpacity(100)
        }, 200)
    }, [])
    return (
        <>
            <Emoji src="https://media2.giphy.com/media/U1sneBtWxluPUlEOiF/200w.gif" />
            <Content style={{ opacity: animatedOpacity }}>
                <Title>Nice work!</Title>
                <Copy>
                    You&#39;re now a GIPHY expert! You can now{' '}
                    <InlineLinks href="/upload" target="_blank">
                        upload content
                    </InlineLinks>{' '}
                    and distribute it on GIPHY and everywhere we&#39;re integrated! For any additional questions, please
                    refer to our{' '}
                    <InlineLinks href="https://support.giphy.com/" target="_blank">
                        Support Center
                    </InlineLinks>
                    . Happy GIF&#39;ing!
                </Copy>
            </Content>
            <Image src="https://media2.giphy.com/media/igbomc6YEwCS566BJO/giphy.gif" />
        </>
    )
}
