import { giphyDarkGrey } from '@giphy/colors'
import { mobile } from 'shared/util/media-query'
import roundWithSuffix from 'shared/util/round-with-suffix'
import styled from 'styled-components'
import { singularContentTypes } from './app'
import ShowStatistics from './components/show-statistics'

const DashboardOverviewContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 70px 0px 50px 0px;

    ${mobile.css`
        padding: 35px 0px 0px 0px;
    `}
`

const GreyLineSeperator = styled.div`
    height: 62px;
    border-left: solid 1px ${giphyDarkGrey};
    margin-left: 68px;
    margin-right: 68px;
    display: flex;
    justify-content: center;
    margin-top: 0.45em;

    ${mobile.css`
        display: flex;
        margin-left: 2em;
        margin-right: 2em;
        margin-top: 0em;
    `}
`

type Props = {
    contentType: string
    viewCount: number
    uploadCount: number
}

export const UserAnalyticsSummary = ({ contentType, viewCount, uploadCount }: Props) => {
    const viewCountRounded = roundWithSuffix(viewCount)
    const uploadCountRounded = roundWithSuffix(uploadCount)
    // Stories views are referred to as 'reads' to indicate that users must click into that content to register a count.
    const viewNoun = contentType === 'Stories' ? ' Reads' : ' Views'
    return (
        <DashboardOverviewContainer>
            <ShowStatistics
                stat={uploadCountRounded}
                title={singularContentTypes[contentType] + ' Uploads'}
                toolTipText={`This channel's total uploads, including content that may have been deleted.`}
            />
            <GreyLineSeperator />
            <ShowStatistics
                stat={viewCountRounded}
                title={singularContentTypes[contentType] + viewNoun}
                toolTipText={`This channel's total ${viewNoun.toLowerCase()} across all uploads. ${viewNoun} are updated daily.`}
                helpLink={'https://support.giphy.com/hc/en-us/articles/360042381152-Analytics-Dashboard-FAQ'}
            />
        </DashboardOverviewContainer>
    )
}

export default UserAnalyticsSummary
