import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { SubheaderSmall } from 'shared/components/text-elements'
import { getOpacity } from '../children/carousel-item'
import Carousel from 'shared/components/carousel/carousel'
import css from '../children/carousel-item.css'
import { fetchUserStories } from 'shared/redux/fetch-stories'
import { getStoriesByUsername, getAutoPlay, getUser } from 'shared/redux/reducers'
import { withStorageContext } from 'react-storage-context'
import { withStorybookContext } from 'shared/components/story/storybook/context'
import StoryItem from 'shared/components/story/story-item'
import { storyPaginator } from 'shared/redux/fetch-story-paginator'

const MAX_CAROUSEL_ITEMS = 4

const Container = styled.div`
    margin-bottom: 25px;
    margin-top: 15px;
`

const StorybookFooter = styled(SubheaderSmall)`
    color: rgba(255, 255, 255, 0.75);
    line-height: initial;
    margin-bottom: 1px;
    margin-top: 7px;
    user-select: none;
    text-transform: capitalize;

    i {
        display: inline-block;
        font-size: 10px;
        margin-left: 4px;
        vertical-align: middle;
    }
`

const CustomStoryItem = styled(StoryItem)`
    &&& {
        width: 182px;
        height: 227px;
    }
`

const CarouselItem = styled.div``

@withStorybookContext
@withStorageContext
@connect(
    (state, { username, isOwner, local: { watchedStories = [] } }) => ({
        stories: getStoriesByUsername(state, username, isOwner ? [] : watchedStories),
        autoPlay: getAutoPlay(state),
        user: getUser(state),
    }),
    { fetchUserStories }
)
class Stories extends PureComponent {
    static propTypes = {
        isOwner: PropTypes.bool,
        username: PropTypes.string.isRequired,
        fetchUserStories: PropTypes.func.isRequired,
    }
    static defaultProps = {
        stories: [],
        title: 'Stories',
    }
    fetchStories = async (cursor) => {
        const { fetchUserStories, username, user, isOwner } = this.props
        // case insensitive request for user stories
        return fetchUserStories(username, (user && user.isStaff) || isOwner, true, MAX_CAROUSEL_ITEMS + 1, cursor)
    }
    paginator = storyPaginator(this.fetchStories)
    componentDidMount() {
        this.paginator()
    }

    onNavigate = async ({ currentIndex, totalPages }) => {
        if (totalPages === currentIndex + 1) {
            this.paginator()
        }
    }

    render() {
        const { isOwner, stories = [], title, autoPlay } = this.props
        const storiesList = stories
        // no router here in channels
        const titleUrl = `${location.pathname}/stories`
        return storiesList.length ? (
            <Container>
                <Carousel
                    title={title}
                    titleUrl={titleUrl}
                    isVisible={true}
                    maxBlocks={MAX_CAROUSEL_ITEMS}
                    onNavigate={this.onNavigate}
                    totalCount={stories.length}
                >
                    {storiesList.map((story, index) => {
                        const { isCreateCard } = story
                        return (
                            <CustomStoryItem
                                key={index}
                                width={182}
                                height={227}
                                rendition={isCreateCard ? 'placeholder' : null}
                                story={story}
                                autoPlay={isCreateCard || autoPlay}
                                useSlug
                                showEditMenu={!isCreateCard}
                                Wrapper={CarouselItem}
                                showPrivacyOption={isOwner}
                                className={css.itemWrapper}
                                containerStyles={{ opacity: getOpacity(index, MAX_CAROUSEL_ITEMS) }}
                            >
                                {isCreateCard && (
                                    <StorybookFooter>
                                        Open Storybook
                                        <i className="ss-navigateright" />
                                    </StorybookFooter>
                                )}
                            </CustomStoryItem>
                        )
                    })}
                </Carousel>
            </Container>
        ) : null
    }
}

export default Stories
