import appendQuery from 'append-query'
import { request, ANALYTICS_URL_LEGACY, WEB_API_KEY } from '../../api'

export const getDashboard = (contentType, dateRange, username) => {
    const contentTypes = {
        'GIFs & Stickers': 'gif,sticker',
        GIFs: 'gif',
        Stickers: 'sticker',
        Stories: 'story',
        Clips: 'clip',
    }

    const formatDate = (inputDate) => {
        let dd = String(inputDate.getDate()).padStart(2, '0')
        let mm = String(inputDate.getMonth() + 1).padStart(2, '0') // January is 0!
        let yyyy = inputDate.getFullYear()
        return yyyy + '-' + mm + '-' + dd
    }

    let startDate = formatDate(dateRange[0])
    let endDate = formatDate(dateRange[1])

    const url = appendQuery(`${ANALYTICS_URL_LEGACY}dashboard/`, {
        api_key: WEB_API_KEY,
        start_dt: startDate,
        end_dt: endDate,
        content_type: contentTypes[contentType],
        username: username,
    })

    return request(url)
}
