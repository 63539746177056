import * as colors from '@giphy/colors'
import { useContext, useEffect, useMemo, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import styled from 'styled-components'
import {
    validateLegacy,
    validateLegacyEmailCode,
} from '../settings/email-verification-message/email-verification-message.api'
import { States, ValidationUserContext } from './modal'
import { Button, Container, Email, Emoji, Footer, Message, PrimaryButton, Title } from './styled'

const Error = styled.p`
    color: ${colors.giphyRed};
    margin: 0;
    font-size: 15px;
    font-weight: normal;
    font-style: italic;
    text-align: center;
    letter-spacing: 0.11px;
`

const HighlightedLink = styled.span`
    color: ${colors.giphyBlue};
    font-weight: bold;
    cursor: pointer;
`

const ResendLink = styled.p`
    color: white;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.11px;
    margin-top: 30px;
    margin-bottom: 0px;
`

const StyledReactCodeInput = styled(ReactCodeInput)<{ error: boolean }>`
    input {
        width: 52px;
        height: 52px;
        margin: 8px;
        border-radius: 4px;
        background: ${colors.giphyDarkCharcoal};
        font-size: 48px;
        text-align: center;
        color: ${colors.giphyAqua};
        font-size: 25px;
        font-family: NexaBlack;
        text-align: center;
        letter-spacing: 0.38px;
        line-height: 35px;
        border-width: 0;
        outline-width: 0;
        box-sizing: border-box;

        ${({ error }) => {
            return error
                ? `
      border-width: 2px;
      border-style: solid;
      border-color: ${colors.giphyRed};
      color: ${colors.giphyRed};  
    `
                : ``
        }}

        &:focus {
            border-width: 2px;
            border-style: solid;
            border-color: ${colors.giphyGreen};
        }
    }
`
const LEGACY_SOURCE_JAIL = 2

const ValidEmailFlow = () => {
    const { user, changeState } = useContext(ValidationUserContext)
    const [error, setError] = useState(false)
    const [resend, setResend] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => setResend(true), 600000) // 10 minutes
        const emailConfirmation = user && user.pending_legacy_email_confirmation
        if (emailConfirmation) {
            setResend(emailConfirmation.can_send_email)
        }

        return () => {
            clearInterval(interval)
        }
    }, [user])

    const submitCode = (code: string) => {
        const submit = async () => {
            try {
                await validateLegacyEmailCode(code)
                window.location.reload()
            } catch (err) {
                if (err.response && err.response.status === 410) {
                    window.location.reload()
                } else {
                    setError(true)
                }
            }
        }
        submit()
    }

    const onCodeChange = (code: string) => {
        if (code.length === 4) {
            submitCode(code)
        }
    }

    const input = useMemo(() => {
        // workaround for poor TS typing within the library
        // @ts-ignore
        return <StyledReactCodeInput fields={4} type="text" error={error} onChange={onCodeChange} />
    }, [error])

    const logout = () => {
        window.location.href = '/logout'
    }
    return (
        <Container>
            <Emoji src="https://media2.giphy.com/media/LOnt6uqjD9OexmQJRB/200w.gif" />
            <Title>Validate Your Account</Title>
            <Message>
                {`We just sent a code to `}
                <Email>{user.email}</Email>
                {`\nPlease paste the code below or click the link within
                        the email to complete validation. `}
            </Message>
            {input}
            {error && <Error>Invalid Code</Error>}
            <ResendLink>
                {`Want to update your email? Enter a new email address `}
                <HighlightedLink onClick={() => changeState(States.UPDATING_EMAIL)}>here</HighlightedLink>.
            </ResendLink>
            {resend && <PrimaryButton onClick={() => validateLegacy(LEGACY_SOURCE_JAIL)}>Resend Email</PrimaryButton>}
            <Button onClick={logout}>Log out</Button>
            <Footer>
                {`Need help? Please reach out to `}
                <Email>support@giphy.com</Email>
            </Footer>
        </Container>
    )
}

export default ValidEmailFlow
