import { giphyBlue, giphyIndigo } from '@giphy/colors'
import styled, { createGlobalStyle } from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 50px;
`

export const Message = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: bold;
    align-items: center;
    line-height: 15px;
    text-align: center;
    width: 400px;
    margin: 50px 0 20px;
    & a {
        color: ${giphyBlue};
    }
    & p {
        margin-bottom: 4px;
    }
    & h4 {
        font-size: 17px;
        margin-bottom: 7px;
    }
`

export const Footer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: fixed;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    bottom: 0;
    width: 100vw;
    height: 30vh;
    pointer-events: none;
`

export const Button = styled.a<{ is403?: boolean }>`
    background: ${giphyIndigo};
    width: 300px;
    color: white;
    line-height: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
    margin-top: ${(props) => (props.is403 ? `20px` : `0px`)};
    pointer-events: auto;
`

export const Arrow = styled.div`
    color: white;
    margin-bottom: 15px;
`

export const GlobalStyle = createGlobalStyle<{ errorBG: string }>`
  body {
    background-color: transparent;
  }

  html {
    background-image: ${(props) => `url(${(window as any).STATIC_URL}${props.errorBG})`};
    background-size: 100%;
  }
`
