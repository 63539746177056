import { giphyLightGrey } from '@giphy/colors'
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import { LeaderBoard } from 'shared/components/leader-board'
import { getAggregations } from 'shared/components/leader-board/networking'
import { GraphAnalytics } from 'shared/components/line-chart'
import { Loader } from 'shared/components/preloader-animation'
import log from 'shared/util/log'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import DashboardTrendingSearches from '../../shared/components/dashboard-trending-searches'
import MoreQuestions from '../../shared/components/more-questions'
import DashboardUserContext from '../dashboard/context'
import ContentTypePicker from './content-type-picker'
import DashboardAccessPicker from './dashboard-access-picker'
import DateRangeModule from './date-range-module'
import DisclaimerBanner from './disclaimer-banner'
import ErrorState from './error-state'
import {
    getGA4ContentTypeFromString,
    getGA4ContentTypesFromString,
    getGA4RangeFromDatePreset,
    trackGA4ContentTypeEvent,
    trackGA4DownloadCSVEvent,
    trackGA4InsightTagEvent,
    trackGA4InsightToggleEvent,
    trackGA4TimeRangeEvent,
} from './ga4'
import NewUserOnboarding from './new-user-onboarding'
import UserAnalyticsSummary from './user-analytics-summary'
import { ContentCalendar } from 'shared/components/content-calendar'

const UITray = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

const CustomRangeText = styled.div`
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: white;
    margin-top: 2px;
    span {
        color: ${giphyLightGrey};
        margin-right: 5px;
    }
`
const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 20px;
    ${mobile.css`
       padding: 0 10px;
    `}
`

const AnchorTag = styled.div``

export const DimmableArea = styled.div<{ step: number; currentStep?: number; onboardingActive: any }>`
    opacity: ${(props) => (props.step !== props.currentStep && props.onboardingActive ? 0.2 : 1.0)};
`

export const singularContentTypes = {
    'GIFs & Stickers': 'GIF & Sticker',
    GIFs: 'GIF',
    Stickers: 'Sticker',
    Stories: 'Story',
    Clips: 'Clip',
}

type GranularPermissions = { canViewContentPicker?: boolean; canViewDatePicker?: boolean; canViewInsights?: boolean }
export type DatePresetType = 'WEEK' | 'MONTH' | 'ALL_TIME' | 'CUSTOM'
type Props = {
    children?: ReactNode
    setOnboardingActive?: Dispatch<SetStateAction<boolean>>
    isOnboarding?: boolean
}

const App = ({ children, setOnboardingActive, isOnboarding }: Props) => {
    const isMobile = useMedia(mobile.query)
    const { user } = useContext(DashboardUserContext)
    const [isLoading, setIsLoading] = useState(true)
    const [totalCounts, setTotalCounts] = useState({
        uploadCount: 0,
        viewCount: 0,
    })
    const [activeErrorState, setActiveErrorState] = useState(false)
    const [onboardingStep, setOnboardingStep] = useState<number>(0)
    const [contentType, setContentType] = useState('GIFs & Stickers')
    const [datePreset, setDatePreset] = useState<DatePresetType>('ALL_TIME')
    const [dateRange, setDateRange] = useState([new Date(2013, 1, 1), new Date()])
    const [granularPermissions, setGranularPermissions] = useState<GranularPermissions>({
        canViewContentPicker: false,
        canViewDatePicker: false,
    })
    const [isGraphLoaded, setIsGraphLoaded] = useState<boolean>(false)
    const [isLeaderBoardLoaded, setIsLeaderBoardLoaded] = useState<boolean>(false)
    const fetchUserSummary = async (dateRange) => {
        try {
            const { uploadCount = 0, viewCount = 0 } = await getAggregations(contentType, dateRange, user.username)
            setTotalCounts({ uploadCount, viewCount })
            setIsLoading(false)
        } catch (e) {
            log.error(e)
            setIsLoading(false)
            setActiveErrorState(true)
        }
    }

    useEffect(() => {
        const {
            view_contenttype_picker_dashboard: canViewContentPicker,
            view_datetype_picker_dashboard: canViewDatePicker,
            view_top_insights_dashboard: canViewInsights,
        } = user.permissions

        // Set granular permissions for extended Analytics features
        setGranularPermissions({ canViewContentPicker, canViewDatePicker, canViewInsights })
        fetchUserSummary(dateRange)
    }, [user])

    useEffect(() => {
        fetchUserSummary(dateRange)
    }, [contentType, dateRange])

    const contentTypeToggled = (isOpen) => {
        const gaContentTypes = getGA4ContentTypesFromString(contentType)
        trackGA4ContentTypeEvent(isOpen ? 'open' : 'close', gaContentTypes)
    }

    const contentTypeUpdated = (newContentType) => {
        // sometimes it is possible for a user to uncheck a previous option in the select list and to have zero options selected. In this case, default back to GIFs.
        let safeNewContentType
        if (newContentType === null) {
            safeNewContentType = 'GIFs & Stickers'
        } else {
            safeNewContentType = newContentType
        }
        setContentType(safeNewContentType)

        const gaContentTypes = getGA4ContentTypesFromString(newContentType)
        trackGA4ContentTypeEvent('change', gaContentTypes)
    }

    const dateRangeUpdated = (datePreset, dateRange) => {
        // Make sure user has chosen two dates and not just one.
        if (dateRange.length === 2 && dateRange[0] != null && dateRange[1] != null && dateRange[0] !== dateRange[1]) {
            // Make sure the date range is in the right order! Sometimes a user can select a range 'backwards'
            dateRange.sort(function (a, b) {
                return new Date(a).getTime() - new Date(b).getTime()
            })
            // Make sure the 'later' date range is not after today!
            if (new Date(dateRange[1]) > new Date()) {
                dateRange[1] = new Date()
            }

            // If the earlier date is after today, just return nothing.
            if (new Date(dateRange[0]) > new Date()) {
                return
            }

            setDateRange(dateRange)
            setDatePreset(datePreset)

            const gaContentTypes = getGA4ContentTypesFromString(contentType)
            const gaRange = getGA4RangeFromDatePreset(datePreset)
            trackGA4TimeRangeEvent(gaRange, gaContentTypes)
        }
    }

    const insightsTagClicked = (query: string, tagType?: string) => {
        const gaTagType = tagType ? getGA4ContentTypeFromString(tagType) : undefined
        trackGA4InsightTagEvent(query, gaTagType)
    }

    const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }

    if (isLoading) {
        return <Loader />
    }

    if (activeErrorState) {
        // An error was thrown fetching gifs
        return <ErrorState isError={true} />
    }

    const { canViewContentPicker, canViewDatePicker, canViewInsights } = granularPermissions
    const { viewCount, uploadCount } = totalCounts
    return (
        <div>
            <NewUserOnboarding
                isOnboardingActive={isOnboarding}
                setOnboardingActive={setOnboardingActive}
                setOnboardingStep={setOnboardingStep}
            />
            <DashboardContainer>
                <AnchorTag id={'graphAnchor'} />
                <UITray id={'dashboard__uitray'}>
                    <DateRangeModule
                        currentPreset={datePreset}
                        canViewDatePicker={canViewDatePicker}
                        onDateUpdate={dateRangeUpdated}
                    />
                    {canViewContentPicker && (
                        <ContentTypePicker
                            onChange={contentTypeUpdated}
                            onToggle={contentTypeToggled}
                            selectedContentType={contentType}
                        />
                    )}
                    <DashboardAccessPicker />
                </UITray>
                {datePreset === 'CUSTOM' && (
                    <CustomRangeText>
                        <span>Custom Range:</span>
                        {dateRange[0].toLocaleDateString('en-us', dateOptions)} -{' '}
                        {dateRange[1].toLocaleDateString('en-us', dateOptions)}
                    </CustomRangeText>
                )}
                <UserAnalyticsSummary viewCount={viewCount} uploadCount={uploadCount} contentType={contentType} />
                {children}
                <div>
                    <DimmableArea onboardingActive={isOnboarding} step={2} currentStep={onboardingStep}>
                        <GraphAnalytics
                            contentType={contentType}
                            isMobile={isMobile}
                            user={user}
                            activeTimeSeries={datePreset}
                            dateRange={dateRange}
                            onLoad={() => setIsGraphLoaded(true)}
                        />
                    </DimmableArea>
                    {singularContentTypes[contentType] === 'Clip' ? <DisclaimerBanner /> : null}
                    <AnchorTag id={'topGifsAndStickersAnchor'} />
                    <LeaderBoard
                        onboardingActive={isOnboarding}
                        currentStep={onboardingStep}
                        dateRange={dateRange}
                        contentType={contentType}
                        datePreset={datePreset}
                        onLoad={() => setIsLeaderBoardLoaded(true)}
                        onCSVDownload={(label) =>
                            trackGA4DownloadCSVEvent('success', `${label.toLocaleLowerCase()} csv`)
                        }
                        onCSVError={() => trackGA4DownloadCSVEvent('error')}
                        onCSVModalToggle={(isOpen) => trackGA4DownloadCSVEvent(isOpen ? 'open' : 'close')}
                    />
                </div>
                {!isMobile && (
                    <>
                        <AnchorTag id={'contentCalendarAnchor'} />
                        <DimmableArea onboardingActive={isOnboarding} step={5} currentStep={onboardingStep}>
                            <ContentCalendar />
                        </DimmableArea>
                        <DimmableArea onboardingActive={isOnboarding} step={6} currentStep={onboardingStep}>
                            <DashboardTrendingSearches
                                user={user}
                                isDashboardLoaded={isGraphLoaded && isLeaderBoardLoaded}
                                onTagClick={insightsTagClicked}
                                onToggle={(label) => trackGA4InsightToggleEvent(label.split('-').join(' '))}
                            />
                        </DimmableArea>
                    </>
                )}
                {canViewInsights && (
                    <DimmableArea onboardingActive={isOnboarding} step={0} currentStep={onboardingStep}>
                        <MoreQuestions
                            title={'Have More Questions?'}
                            description={
                                'For further questions, troubleshooting, or inquires about more data, visit our Support Article!'
                            }
                            helpLink={`https://support.giphy.com/hc/en-us/articles/360042381152-Analytics-Dashboard-FAQ`}
                        />
                    </DimmableArea>
                )}
            </DashboardContainer>
        </div>
    )
}

export default App
