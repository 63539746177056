import { giphyBlack, giphyCharcoal, giphyDarkestGrey, giphyLightGrey } from '@giphy/colors'
import { Gif } from '@giphy/react-components'
import { StoryPreview } from '@giphy/react-giphy-brand'
import { useContext, useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import { getStoryById } from 'shared/api'
import { getGifDetailPermissions } from 'shared/api/detail'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import DashboardUserContext from '../../../site/dashboard/context'
import Insight from './insight'
import { ContentPiece } from './leader-board-graph'

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 120px;
    margin: 5px 0px;
    color: ${giphyLightGrey}
    background-color: ${giphyDarkestGrey};
    font-family: interface;
    font-weight: bold;
    position: relative;

    ${mobile.css`
        height: 68px;
        margin: 3px 0px;
    `}
`

const ThumbnailContainer = styled.div`
    background-color: ${giphyBlack};
    img {
        object-fit: cover;
    }
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
`
const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;
    width: 100%;
    text-align: left;
    background-color: ${giphyDarkestGrey};
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
`

const ContentRank = styled.span`
    position: absolute;
    left: -1.92em;
    top: 50%;
    transform: translateY(-50%);
    font-size: 28px;
    font-family: NexaBlack;
    color: ${giphyCharcoal};
    width: 1.5em;
    text-align: right;
`

const BackgroundGradient = styled.div`
    z-index: 1;
    background: linear-gradient(-90deg, rgb(51, 51, 51) 0%, rgb(47, 47, 47) 13%, rgb(33, 33, 33) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: all 2s ease-in-out;
    transition-delay: 1s;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
`

const ViewCount = styled.div`
    z-index: 2;
    position: relative;
    display: flex;
    span {
        font-family: NexaBlack;
        font-size: 28px;
        color: white;
        font-weight: normal;
        margin-right: 0.18em;

        ${mobile.css`
            font-size: 15px;
        `}
    }
    p {
        color: ${giphyLightGrey};
        margin-top: 13px;

        ${mobile.css`
            margin-top: 0px;
        `}
    }
`

const Placeholder = styled.div`
    width: 120px;
    height: 120px;
    ${mobile.css`
        width: 68px;
        height: 68px;
    `}
    background-image: linear-gradient(45deg, #222 25%, transparent 25%, transparent 75%, #222 75%, #222 100%),
        linear-gradient(45deg, #222 25%, transparent 25%, transparent 75%, #222 75%, #222 100%);
    background-position: 0 0, 19px 19px;
    background-size: 38px 38px;
`

type RowProps = {
    item: ContentPiece
    index: number
    maxViews: number
    contentType: string
    dateRange: string[]
}

const LeaderBoardRow = ({ index, item, maxViews, contentType, dateRange }: RowProps) => {
    const isMobile = useMedia(mobile.query)
    const { user } = useContext(DashboardUserContext)
    const { view_top_insights_dashboard: canViewInsights } = user.permissions
    const [content, setContent] = useState<any | null>(null) // I tried valiantly to make this a union type between IStory and IGif but couldn't get the typeguard to work. Open to suggestions.
    const [allowedToFetch, setAllowedToFetch] = useState(true)
    const [barWidth, setBarWidth] = useState(0)
    const viewNoun = contentType === 'Stories' ? ' Reads' : ' Views'

    // Refresh the row if there's a new piece of content.
    useEffect(() => {
        if (item && contentType && allowedToFetch) {
            GetContentInfo(item.contentId, contentType, user)
            // Set the background gradient bar as a percentage of the top performing piece of content.
        }
        return () => {
            // prevent async requests from continuing on unmount
            setAllowedToFetch(false)
        }
    }, [item])

    useEffect(() => {
        setBarWidth((item.viewCount / maxViews) * 100)
    }, [content])

    const RenderThumbnail = (isMobile: boolean) => {
        if (content != null) {
            const gifSize = isMobile ? 68 : 120
            // If the piece of content is a story....
            if (content.hasOwnProperty('story_id')) {
                return (
                    <StoryPreview
                        height={gifSize}
                        hideCascade
                        hideAvatar
                        showUsername={false}
                        hideIcons
                        numberOfLines={2}
                        onClick={null}
                        story={content}
                        width={gifSize * 1.75}
                    />
                )
            } else {
                // If it's not a story (non-gif), it's a gif or sticker...
                return <Gif gif={content} hideAttribution width={gifSize} height={gifSize} />
            }
        } else {
            return <Placeholder />
        }
    }

    const GetContentInfo = async (id, contentType, user) => {
        try {
            if (contentType === 'Stories') {
                const { data } = await getStoryById(id, user.token)
                setContent(data)
            } else {
                const { gif } = await getGifDetailPermissions(id)
                setContent(gif)
            }
        } catch (error) {
            console.warn('Error fetching content')
            console.error(error)
        }
    }

    return (
        <Row>
            {!isMobile && <ContentRank>{index + 1}</ContentRank>}
            <ThumbnailContainer>{RenderThumbnail(isMobile)}</ThumbnailContainer>
            <InfoContainer>
                <BackgroundGradient style={{ width: `${barWidth}%` }} />
                <ViewCount>
                    <span>{item.viewCount.toLocaleString()}</span>
                    <p>{viewNoun}</p>
                </ViewCount>
                {!isMobile && canViewInsights && (
                    <Insight contentType={contentType} item={item} user={user} dateRange={dateRange} />
                )}
            </InfoContainer>
        </Row>
    )
}

export default LeaderBoardRow
