import * as colors from '@giphy/colors'
import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    // flex-grow: 1;
    padding: 0 13px 0 5px;
    align-items: center;
    height: 50px;

    background: ${colors.giphyDarkGrey};
    &:nth-child(2) {
        margin: 0px 10px;
    }

    label {
        font-family: interface;
        font-size: 18px;
        font-weight: bold;

        span {
            font-weight: normal;
            font-size: 14px;
            color: ${colors.giphyLightGrey};
        }
    }
    border-radius: 4px;
`

const NumberInputField = styled.input<{ value: string }>`
    background-color: ${colors.giphyDarkestGrey};
    width: 51px;
    margin: 0 10px 0 5px;
    height: 35px;
    color: ${({ value }) => (value.toString() === '0' ? `${colors.giphyLightCharcoal}` : `${colors.giphyWhite}`)};
    font-family: interface;
    font-weight: bold;
    font-size: 18px;
    border-radius: 4px;
`
const IncrementDecrementControls = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.giphyBlue};
    font-size: 11px;
    left: 43px;
    div:nth-child(1) {
        transform: scaleY(-1);
    }
    position: absolute;
`

const Arrow = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`

type Props = {
    numberVal: any
    onChange: (val: string | number, whichType: string) => void
    contentType: string
    children: any
}

const NumberInput = ({ numberVal, onChange, children, contentType }: Props) => {
    return (
        <Container>
            <NumberInputField
                value={numberVal}
                onChange={(e) => onChange(e.target.value, contentType)}
                type="text"
                pattern="[0-9]*"
            />
            <IncrementDecrementControls>
                <Arrow onClick={() => onChange(numberVal + 1, contentType)} className="ss-dropdown" />
                <Arrow onClick={() => onChange(numberVal - 1, contentType)} className="ss-dropdown" />
            </IncrementDecrementControls>
            {children}
        </Container>
    )
}

export default NumberInput
