import styled from 'styled-components'
import { Content, Emoji, Title, Copy, SupportLink } from './common-styles'
import { useEffect, useState } from 'react'

const Tagging = styled.div`
    position: absolute;
    left: 25.17%;
    right: 25.17%;
    top: 51.93%;
    bottom: 26.07%;
    height: 210px;
    background: linear-gradient(232.84deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3.19565px 23.9674px;
    border-radius: 7.98912px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
`

const TagsHeader = styled.h1`
    height: 24px;

    font-family: 'InterFace';
    font-style: normal;
    font-weight: 700;
    font-size: 17.5761px;
    line-height: 24px;
    /* identical to box height, or 136% */

    display: flex;
    align-items: center;

    /* White */

    color: #ffffff;
`

const TagsInput = styled.input`
    flex-direction: column;
    align-items: flex-start;
    width: 480px;
    height: 35.98px;
    left: 0px;
    margin-top: 20px;
    top: 41.54px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(33, 37, 41, 0.06) 0px 1.59782px 1.59782px, rgba(33, 37, 41, 0.08) 0px 0px 0.798912px;
    border-radius: 3.19565px;
`

const FeaturedTag = styled.span`
    padding: 7.98912px 11.9837px;
    width: 93.97px;
    height: 33.98px;
    background: rgb(33, 33, 33);
    border-radius: 35.1521px;
    font-family: InterFace;
    font-style: italic;
    font-weight: 700;
    font-size: 11.9837px;
    line-height: 18px;
    text-align: center;
`

const FeaturedTagWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 4px;
`

const TagIcon = styled.i`
    margin-top: 2px;
    margin-right: 10px;
`

const LeftImage = styled.img`
    position: absolute;
    left: 5.87%;
    right: 76.72%;
    top: 52.3%;
    bottom: 29.53%;
    transform: rotate(-15deg);
    z-index: 3;
    @media screen and (max-device-width: 1400px) {
        width: 138px;
    }
`
const RightImage = styled.img`
    position: absolute;
    left: 80.61%;
    right: 5.97%;
    top: 55.8%;
    bottom: 31.05%;
    transform: rotate(15.01deg);
    z-index: 3;
    @media screen and (max-device-width: 1400px) {
        width: 138px;
    }
`
const Yay = styled(LeftImage)`
    left: 68.87%;
    right: 18.9%;
    top: 61.91%;
    bottom: 27.9%;
    width: 200px;
    height: 100px;
    z-index: 4;
`
const Excited = styled(Yay)`
    top: 49.91%;
    bottom: 27.9%;
    transform: rotate(5.01deg);
`
const Crushing = styled(LeftImage)`
    left: 15.87%;
    right: 76.72%;
    top: 64.3%;
    bottom: 29.53%;
    width: 200px;
    height: 100px;
    z-index: 4;
`
const Omg = styled(Crushing)`
    top: 51.3%;
    transform: rotate(5.01deg);
`

export const TaggingScreen = () => {
    const tagsMock = ['mind blown', 'wow', 'excited', 'woohoo', 'happy dance']
    const [animatedOpacity, setAnimatedOpacity] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setAnimatedOpacity(100)
        }, 200)
    }, [])

    return (
        <>
            <Emoji src="https://media2.giphy.com/media/VDNDX5BhKKz0YsJkl0/200w.gif" />
            <Content style={{ opacity: animatedOpacity }}>
                <Title>Get your content discovered</Title>
                <Copy>
                    Tags are how users find your content on GIPHY and everywhere we&#39;re integrated. You should add
                    5-10 accurate, unique and relevant tags so people can find your content at the right moment.
                </Copy>
                <SupportLink
                    href="https://support.giphy.com/hc/en-us/articles/4405176151834-Content-Tagging-Guidelines"
                    target="_blank"
                >
                    Read more about tagging best practices
                </SupportLink>
            </Content>
            <LeftImage src="https://media2.giphy.com/media/xUA7b6vHi778bTElqg/200w.gif" />
            <Yay src="/static/img/upgraded_onboarding/yay.gif" />
            <Excited src="/static/img/upgraded_onboarding/excited.gif" />
            <Crushing src="/static/img/upgraded_onboarding/crushing.gif" />
            <Omg src="/static/img/upgraded_onboarding/omg.gif" />
            <RightImage src="https://media2.giphy.com/media/dt0Oep0PKarQG7cQLp/200w.gif" />
            <Tagging>
                <TagsHeader>
                    <TagIcon className="ss-tag" />
                    Add Tags
                </TagsHeader>
                <TagsInput type="text" placeholder="Enter tags" disabled />
                <FeaturedTagWrapper>
                    {tagsMock.map((tag) => <FeaturedTag>#{tag}</FeaturedTag>) as any}
                </FeaturedTagWrapper>
            </Tagging>
        </>
    )
}
