import styled from 'styled-components'
import { Container, Message } from './sc'

const Emoji = styled.img`
    height: 300px;
    width: 300px;
    margin-top: 30px;
`

const Error503 = () => {
    return (
        <>
            <Container>
                <Emoji src={'https://media.giphy.com/media/3ohhwlYW4evn0r0BXO/giphy.gif'} />
                <Message>
                    <h4>Oops! It looks like this page is currently undergoing maintenance.</h4>
                    <h4>
                        For urgent requests please <a href="https://support.giphy.com">contact GIPHY support</a>.
                    </h4>
                </Message>
            </Container>
        </>
    )
}

export default Error503
