import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import { Component } from 'react'
import { autobind } from 'core-decorators'
import { sample, isEqual } from 'lodash'
import css from './screensaver.css'

const MIN_SIZE = 100,
    MAX_SIZE = 400,
    MAX_SPEED = 10

/**
 * Channel Screensaver - Item: Controls an individual
 * falling item in the channel root page screensaver.
 * This view will update the item's position, randomize
 * values, reset values, etc.
 *
 * @class ChannelScreensaverItem
 * @param {Object} bounds Window bounds (x, y)
 * @param {Array} images List of image urls to randomly pick from.
 * @param {Boolean} [isHiding] Indicates whether the items are
 * already on screen and should hide.
 */
export default class ScreensaverItem extends Component {
    static propTypes = {
        bounds: PropTypes.object.isRequired,
        images: PropTypes.array.isRequired,
        isHiding: PropTypes.bool,
    }

    static defaultProps = {
        isHiding: false,
    }

    state = {
        x: 0,
        y: 0,
        image: '',
        size: 0,
        speed: 0,
        index: 0,
    }

    /**
     * Updates the item's position on screen.
     * If the item is offscreen, it's position will be reset to
     * the top and the size/speed/image/etc. will be randomized.
     */
    @autobind
    updatePosition() {
        const { bounds } = this.props
        const { y, speed } = this.state

        if (y > bounds.y) {
            this.resetItem(true)
        } else {
            this.setState({ y: y + speed })
        }

        this.animationId = window.requestAnimationFrame(this.updatePosition)
    }

    /**
     * Randomizes the item's size, iamge, speed, and position.
     *
     * @param {Boolean} isUpdate Indicates whether the item was
     * already on screen or not. If so, the item will move right
     * to the top of the screen so there is no wait.
     */
    resetItem(isUpdate) {
        const { bounds, images } = this.props
        const size = Math.round(MIN_SIZE + (MAX_SIZE - MIN_SIZE) * Math.random())
        const scale = size / MAX_SIZE
        const index = Math.ceil((size / MAX_SIZE) * 10)

        this.setState({
            x: Math.round(bounds.x * Math.random()),
            y: isUpdate ? -size : Math.round(-bounds.y * Math.random()),
            image: sample(images),
            size: size,
            speed: scale * MAX_SPEED,
            index: index,
        })
    }

    /**
     * Clears the animation loop and restarts.
     */
    startAnimating() {
        this.stopAnimating()
        this.updatePosition()
    }

    /**
     * Clears the animation loop and stops the item from moving.
     */
    stopAnimating() {
        if (this.animationId) {
            window.cancelAnimationFrame(this.animationId)
            this.animationId = null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.y !== this.state.y) {
            return true
        }
        if (nextState.image !== this.state.image) {
            return true
        }
        if (nextProps.isHiding !== this.props.isHiding) {
            return true
        }
        if (!isEqual(nextProps.bounds, this.props.bounds)) {
            return true
        }
        return false
    }

    componentWillReceiveProps(nextProps) {
        const { bounds, isHiding } = this.props
        if (nextProps.isHiding !== isHiding) {
            const { y, speed, size } = this.state
            this.stopAnimating()

            if (y + size > 0) {
                this.setState({ y: y + bounds.y + speed * 100 })
            }
        }
    }

    componentWillMount() {
        this.resetItem()
    }

    componentDidMount() {
        this.startAnimating()
    }

    componentWillUnmount() {
        this.stopAnimating()
    }

    render() {
        const { isHiding } = this.props
        const { x, y, size, index, image } = this.state
        const transform = 'translate(' + x + 'px, ' + y + 'px)'
        const styles = {
            height: size,
            width: size,
            zIndex: index,
            WebkitTransform: transform,
            msTransform: transform,
            transform: transform,
        }
        return (
            <div className={isHiding ? css.screensaverItemHiding : css.screensaverItem} style={styles}>
                <div
                    className={css.screensaverItemImage}
                    style={{ backgroundImage: 'url(' + image + ')' }}
                    data-index={index}
                />
            </div>
        )
    }
}
