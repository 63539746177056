export const samsung: any = {
    type: 'video',
    id: 'R2eABVP8tN8Qv0BSAq',
    index_id: 136243886,
    url: 'https://giphy.com/clips/samsungmobile-samsung-R2eABVP8tN8Qv0BSAq',
    slug: 'samsungmobile-samsung-R2eABVP8tN8Qv0BSAq',
    embed_url: 'https://giphy.com/embed/R2eABVP8tN8Qv0BSAq/video',
    username: 'samsungmobile',
    title: 'Boop!',
    rating: 'g',
    content_url: '',
    source_tld: '',
    source_post_url: '',
    is_hidden: false,
    is_removed: false,
    is_community: false,
    is_anonymous: false,
    is_featured: false,
    is_realtime: false,
    is_sticker: false,
    import_datetime: '2021-03-14 01:21:58',
    trending_datetime: '2021-03-14 01:24:13',
    images: {
        original: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy.gif',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy.mp4',
            frames: '31',
            hash: 'b0a2b40a73786c2fa2beffe3616bdefe',
        },
        downsized: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy.gif',
        },
        downsized_large: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy.gif',
        },
        downsized_medium: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy.gif',
        },
        downsized_small: {},
        downsized_still: {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy_s.gif',
        },
        fixed_height: {
            height: '200',
            width: '356',
            size: '1402691',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200.gif',
        },
        fixed_height_downsampled: {
            height: '200',
            width: '356',
            size: '245722',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200_d.gif',
        },
        fixed_height_small: {},
        fixed_height_small_still: {},
        fixed_height_still: {
            height: '200',
            width: '356',
            size: '43707',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200_s.gif',
        },
        fixed_width: {
            height: '113',
            width: '200',
            size: '462506',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200w.gif',
            webp_size: '137616',
            webp: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200w.webp',
        },
        fixed_width_downsampled: {
            height: '113',
            width: '200',
            size: '112924',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200w_d.gif',
        },
        fixed_width_small: {},
        fixed_width_small_still: {},
        fixed_width_still: {
            height: '113',
            width: '200',
            size: '19128',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200w_s.gif',
        },
        looping: {},
        original_still: {
            height: '270',
            width: '480',
            size: '85104',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy_s.gif',
        },
        original_mp4: {
            height: '270',
            width: '480',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy.mp4',
        },
        preview: {},
        preview_gif: {},
        source: {
            height: '1080',
            width: '1920',
            size: '3083126',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/source.mp4',
        },
        '480w_still': {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/480w_s.jpg',
        },
    },
    user: {
        id: 4397736,
        avatar_url: 'https://media2.giphy.com/avatars/samsungmobile/EswWPLYcrOUM.jpg',
        banner_image: 'https://media2.giphy.com/headers/samsungmobile/qyuQBXnzjSKO.gif',
        banner_url: 'https://media2.giphy.com/headers/samsungmobile/qyuQBXnzjSKO.gif',
        profile_url: 'https://giphy.com/samsungmobile/',
        username: 'samsungmobile',
        display_name: 'Samsung Mobile',
        description: '',
        user_type: 'partner',
        instagram_url: '',
        website_url: '',
        is_public: true,
        is_staff: false,
        is_superuser: false,
        is_verified: true,
    },
    has_attribution: false,
    video: {
        hls_manifest_url: '',
        dash_manifest_url: '',
        assets: {
            '360p': {
                url: 'https://media1.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy360p.mp4',
                height: '360',
                width: '640',
            },
            source: {
                url: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/source.mp4',
                height: '1080',
                width: '1920',
            },
        },
        previews: {
            fixed_height: {
                url: 'https://media2.giphy.com/media/R2eABVP8tN8Qv0BSAq/200h.gif',
                mp4: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200h.mp4',
                webp: 'https://media1.giphy.com/media/R2eABVP8tN8Qv0BSAq/200h.webp',
                height: '200',
                width: '356',
            },
            fixed_width: {
                url: 'https://media4.giphy.com/media/R2eABVP8tN8Qv0BSAq/200w.gif',
                mp4: 'https://media3.giphy.com/media/R2eABVP8tN8Qv0BSAq/200w.mp4',
                webp: 'https://media4.giphy.com/media/R2eABVP8tN8Qv0BSAq/200w.webp',
                height: '112',
                width: '200',
            },
        },
        captions: {
            en: {
                srt: 'https://staging.media.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy_en.srt',
                vtt: 'https://staging.media.giphy.com/media/R2eABVP8tN8Qv0BSAq/giphy_en.vtt',
            },
        },
        native: 'en',
    },
    tags: [],
    path: '/clips/samsungmobile-samsung-R2eABVP8tN8Qv0BSAq',
}

export const hbo: any = {
    type: 'video',
    id: 'LBGQJRdZEQehdWYkrr',
    index_id: 126699793,
    url: 'https://giphy.com/clips/hbo-episode-2-the-white-lotus-hite-LBGQJRdZEQehdWYkrr',
    slug: 'hbo-episode-2-the-white-lotus-hite-LBGQJRdZEQehdWYkrr',
    embed_url: 'https://giphy.com/embed/LBGQJRdZEQehdWYkrr/video',
    username: 'hbo',
    title: 'Oh My Gosh!',
    rating: 'g',
    content_url: '',
    source_tld: '',
    source_post_url: '',
    is_hidden: false,
    is_removed: false,
    is_community: false,
    is_anonymous: false,
    is_featured: false,
    is_realtime: false,
    is_sticker: false,
    import_datetime: '2021-03-14 01:21:58',
    trending_datetime: '2021-03-14 01:24:13',
    images: {
        original: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy.gif',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy.mp4',
            frames: '31',
            hash: 'b0a2b40a73786c2fa2beffe3616bdefe',
        },
        downsized: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy.gif',
        },
        downsized_large: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy.gif',
        },
        downsized_medium: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy.gif',
        },
        downsized_small: {},
        downsized_still: {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy_s.gif',
        },
        fixed_height: {
            height: '200',
            width: '356',
            size: '1402691',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200.gif',
        },
        fixed_height_downsampled: {
            height: '200',
            width: '356',
            size: '245722',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200_d.gif',
        },
        fixed_height_small: {},
        fixed_height_small_still: {},
        fixed_height_still: {
            height: '200',
            width: '356',
            size: '43707',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200_s.gif',
        },
        fixed_width: {
            height: '113',
            width: '200',
            size: '462506',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200w.gif',
            webp_size: '137616',
            webp: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200w.webp',
        },
        fixed_width_downsampled: {
            height: '113',
            width: '200',
            size: '112924',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200w_d.gif',
        },
        fixed_width_small: {},
        fixed_width_small_still: {},
        fixed_width_still: {
            height: '113',
            width: '200',
            size: '19128',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200w_s.gif',
        },
        looping: {},
        original_still: {
            height: '270',
            width: '480',
            size: '85104',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy_s.gif',
        },
        original_mp4: {
            height: '270',
            width: '480',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy.mp4',
        },
        preview: {},
        preview_gif: {},
        source: {
            height: '1080',
            width: '1920',
            size: '3083126',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/source.mp4',
        },
        '480w_still': {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/480w_s.jpg',
        },
    },
    user: {
        id: 7666,
        avatar_url: 'https://media2.giphy.com/avatars/hbo/dh6SAufTPTWW.gif',
        banner_image: 'https://media2.giphy.com/headers/avatars/hbo/Uova6eUTYz6e.gif',
        banner_url: 'https://media2.giphy.com/headers/avatars/hbo/Uova6eUTYz6e.gif',
        profile_url: 'https://giphy.com/hbo/',
        username: 'hbo',
        display_name: 'HBO',
        description: '',
        user_type: 'partner',
        instagram_url: '',
        website_url: '',
        is_public: true,
        is_staff: false,
        is_superuser: false,
        is_verified: true,
    },
    has_attribution: false,
    video: {
        hls_manifest_url: '',
        dash_manifest_url: '',
        assets: {
            '360p': {
                url: 'https://media1.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy360p.mp4',
                height: '360',
                width: '640',
            },
            source: {
                url: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/source.mp4',
                height: '1080',
                width: '1920',
            },
        },
        previews: {
            fixed_height: {
                url: 'https://media2.giphy.com/media/LBGQJRdZEQehdWYkrr/200h.gif',
                mp4: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200h.mp4',
                webp: 'https://media1.giphy.com/media/LBGQJRdZEQehdWYkrr/200h.webp',
                height: '200',
                width: '356',
            },
            fixed_width: {
                url: 'https://media4.giphy.com/media/LBGQJRdZEQehdWYkrr/200w.gif',
                mp4: 'https://media3.giphy.com/media/LBGQJRdZEQehdWYkrr/200w.mp4',
                webp: 'https://media4.giphy.com/media/LBGQJRdZEQehdWYkrr/200w.webp',
                height: '112',
                width: '200',
            },
        },
        captions: {
            en: {
                srt: 'https://staging.media.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy_en.srt',
                vtt: 'https://staging.media.giphy.com/media/LBGQJRdZEQehdWYkrr/giphy_en.vtt',
            },
        },
        native: 'en',
    },
    tags: [],
    path: '/clips/hbo-episode-2-the-white-lotus-hite-LBGQJRdZEQehdWYkrr',
}

export const ambarbecutie: any = {
    type: 'video',
    id: 'O4EfyLd7yIjxQuCjH6',
    index_id: 141355988,
    url: 'https://giphy.com/clips/horror-scream-terror-O4EfyLd7yIjxQuCjH6',
    slug: 'horror-scream-terror-O4EfyLd7yIjxQuCjH6',
    embed_url: 'https://giphy.com/embed/O4EfyLd7yIjxQuCjH6/video',
    username: 'ambarbecutie',
    title: 'TERROR SCREAM',
    rating: 'g',
    content_url: '',
    source_tld: '',
    source_post_url: '',
    is_hidden: false,
    is_removed: false,
    is_community: false,
    is_anonymous: false,
    is_featured: false,
    is_realtime: false,
    is_sticker: false,
    import_datetime: '2021-03-14 01:21:58',
    trending_datetime: '2021-03-14 01:24:13',
    images: {
        original: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy.gif',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy.mp4',
            frames: '31',
            hash: 'b0a2b40a73786c2fa2beffe3616bdefe',
        },
        downsized: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy.gif',
        },
        downsized_large: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy.gif',
        },
        downsized_medium: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy.gif',
        },
        downsized_small: {},
        downsized_still: {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy_s.gif',
        },
        fixed_height: {
            height: '200',
            width: '356',
            size: '1402691',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200.gif',
        },
        fixed_height_downsampled: {
            height: '200',
            width: '356',
            size: '245722',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200_d.gif',
        },
        fixed_height_small: {},
        fixed_height_small_still: {},
        fixed_height_still: {
            height: '200',
            width: '356',
            size: '43707',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200_s.gif',
        },
        fixed_width: {
            height: '113',
            width: '200',
            size: '462506',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200w.gif',
            webp_size: '137616',
            webp: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200w.webp',
        },
        fixed_width_downsampled: {
            height: '113',
            width: '200',
            size: '112924',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200w_d.gif',
        },
        fixed_width_small: {},
        fixed_width_small_still: {},
        fixed_width_still: {
            height: '113',
            width: '200',
            size: '19128',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200w_s.gif',
        },
        looping: {},
        original_still: {
            height: '270',
            width: '480',
            size: '85104',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy_s.gif',
        },
        original_mp4: {
            height: '270',
            width: '480',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy.mp4',
        },
        preview: {},
        preview_gif: {},
        source: {
            height: '1080',
            width: '1920',
            size: '3083126',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/source.mp4',
        },
        '480w_still': {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/480w_s.jpg',
        },
    },
    user: {
        id: 6352304,
        avatar_url: 'https://media2.giphy.com/avatars/ambarbecutie/rOQxtS3qowMe.gif',
        banner_image: '',
        banner_url: '',
        profile_url: 'https://giphy.com/ambarbecutie/',
        username: 'ambarbecutie',
        display_name: 'ambarbecutie',
        description: '',
        user_type: 'artist',
        instagram_url: '',
        website_url: '',
        is_public: true,
        is_staff: false,
        is_superuser: false,
        is_verified: true,
    },
    has_attribution: false,
    video: {
        hls_manifest_url: '',
        dash_manifest_url: '',
        assets: {
            '360p': {
                url: 'https://media1.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy360p.mp4',
                height: '360',
                width: '640',
            },
            source: {
                url: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/source.mp4',
                height: '1080',
                width: '1920',
            },
        },
        previews: {
            fixed_height: {
                url: 'https://media2.giphy.com/media/O4EfyLd7yIjxQuCjH6/200h.gif',
                mp4: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200h.mp4',
                webp: 'https://media1.giphy.com/media/O4EfyLd7yIjxQuCjH6/200h.webp',
                height: '200',
                width: '356',
            },
            fixed_width: {
                url: 'https://media4.giphy.com/media/O4EfyLd7yIjxQuCjH6/200w.gif',
                mp4: 'https://media3.giphy.com/media/O4EfyLd7yIjxQuCjH6/200w.mp4',
                webp: 'https://media4.giphy.com/media/O4EfyLd7yIjxQuCjH6/200w.webp',
                height: '112',
                width: '200',
            },
        },
        captions: {
            en: {
                srt: 'https://staging.media.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy_en.srt',
                vtt: 'https://staging.media.giphy.com/media/O4EfyLd7yIjxQuCjH6/giphy_en.vtt',
            },
        },
        native: 'en',
    },
    tags: [],
    path: '/clips/horror-scream-terror-O4EfyLd7yIjxQuCjH6',
}

export const richiebrowntown: any = {
    type: 'video',
    id: 'pWWZ2PGlJyWF0FIHPJ',
    index_id: 136751297,
    url: 'https://giphy.com/clips/oh-richiebrown-prettywow-pWWZ2PGlJyWF0FIHPJ',
    slug: 'horror-scream-terror-pWWZ2PGlJyWF0FIHPJ',
    embed_url: 'https://giphy.com/embed/pWWZ2PGlJyWF0FIHPJ/video',
    username: 'richiebrowntown',
    title: 'a collection of wows',
    rating: 'g',
    content_url: '',
    source_tld: '',
    source_post_url: '',
    is_hidden: false,
    is_removed: false,
    is_community: false,
    is_anonymous: false,
    is_featured: false,
    is_realtime: false,
    is_sticker: false,
    import_datetime: '2021-03-14 01:21:58',
    trending_datetime: '2021-03-14 01:24:13',
    images: {
        original: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy.gif',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy.mp4',
            frames: '31',
            hash: 'b0a2b40a73786c2fa2beffe3616bdefe',
        },
        downsized: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy.gif',
        },
        downsized_large: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy.gif',
        },
        downsized_medium: {
            height: '270',
            width: '480',
            size: '2448443',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy.gif',
        },
        downsized_small: {},
        downsized_still: {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy_s.gif',
        },
        fixed_height: {
            height: '200',
            width: '356',
            size: '1402691',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200.gif',
        },
        fixed_height_downsampled: {
            height: '200',
            width: '356',
            size: '245722',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200_d.gif',
        },
        fixed_height_small: {},
        fixed_height_small_still: {},
        fixed_height_still: {
            height: '200',
            width: '356',
            size: '43707',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200_s.gif',
        },
        fixed_width: {
            height: '113',
            width: '200',
            size: '462506',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200w.gif',
            webp_size: '137616',
            webp: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200w.webp',
        },
        fixed_width_downsampled: {
            height: '113',
            width: '200',
            size: '112924',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200w_d.gif',
        },
        fixed_width_small: {},
        fixed_width_small_still: {},
        fixed_width_still: {
            height: '113',
            width: '200',
            size: '19128',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200w_s.gif',
        },
        looping: {},
        original_still: {
            height: '270',
            width: '480',
            size: '85104',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy_s.gif',
        },
        original_mp4: {
            height: '270',
            width: '480',
            mp4_size: '142773',
            mp4: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy.mp4',
        },
        preview: {},
        preview_gif: {},
        source: {
            height: '1080',
            width: '1920',
            size: '3083126',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/source.mp4',
        },
        '480w_still': {
            height: '270',
            width: '480',
            size: '1458957',
            url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/480w_s.jpg',
        },
    },
    user: {
        id: 1224462,
        avatar_url: 'https://media2.giphy.com/avatars/richiebrowntown/1InZi800gWj8.jpg',
        banner_image: '',
        banner_url: '',
        profile_url: 'https://giphy.com/richiebrowntown/',
        username: 'richiebrowntown',
        display_name: 'Richie Brown',
        description: '',
        user_type: 'artist',
        instagram_url: '',
        website_url: '',
        is_public: true,
        is_staff: false,
        is_superuser: false,
        is_verified: true,
    },
    has_attribution: false,
    video: {
        hls_manifest_url: '',
        dash_manifest_url: '',
        assets: {
            '360p': {
                url: 'https://media1.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy360p.mp4',
                height: '360',
                width: '640',
            },
            source: {
                url: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/source.mp4',
                height: '1080',
                width: '1920',
            },
        },
        previews: {
            fixed_height: {
                url: 'https://media2.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200h.gif',
                mp4: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200h.mp4',
                webp: 'https://media1.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200h.webp',
                height: '200',
                width: '356',
            },
            fixed_width: {
                url: 'https://media4.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200w.gif',
                mp4: 'https://media3.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200w.mp4',
                webp: 'https://media4.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/200w.webp',
                height: '112',
                width: '200',
            },
        },
        captions: {
            en: {
                srt: 'https://staging.media.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy_en.srt',
                vtt: 'https://staging.media.giphy.com/media/pWWZ2PGlJyWF0FIHPJ/giphy_en.vtt',
            },
        },
        native: 'en',
    },
    tags: [],
    path: '/clips/horror-scream-terror-pWWZ2PGlJyWF0FIHPJ',
}
