exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);

// module
exports.push([module.id, "._1n7u0O26sWo5Q6wdVn6YYb {\n    display: flex;\n    position: absolute;\n    z-index: 1;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(0,0,0,.5);\n    left: 0;\n    top: 0;\n}\n\n._2TxsiH3_7cFkKKrcqYV21N {\n    color: " + require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyRed"] + ";\n    font-size: 28px;\n }", ""]);

// exports
exports.locals = {
	"colors": "\"shared/css/colors.css\"",
	"giphyRed": "" + require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyRed"] + "",
	"privateOverlay": "_1n7u0O26sWo5Q6wdVn6YYb",
	"privateIcon": "_2TxsiH3_7cFkKKrcqYV21N ss-icon ss-lock"
};