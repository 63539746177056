import { giphyIndigo } from '@giphy/colors'
import { useParams } from 'react-router'
import CSRFToken from 'shared/components/form/csrftoken-hoc'
import { Label } from 'shared/components/form/fields'
import { CTAButton as BrandCTAButton, Title } from 'shared/components/form/form-components'
import Input from 'shared/components/form/ui/input'
import styled from 'styled-components'
import { FormContainer, Textarea } from '../../../shared/components/settings/sc'

const CTAButton = styled(BrandCTAButton)`
    margin-top: 24px;
    width: 100%;
`

// the other `input` fields have a global style :/,
// but nothing for textarea, so here it is
export const ContentIDInput = styled(Input)`
    margin: 0;
`

const appealReasonInputProps = {
    as: 'textarea',
    name: 'request_reason',
    placeholder: 'Let us know why you belive the content does not violate our terms of service.',
}

type FormParams = {
    id: string
}

const TakedownAppealForm = ({ csrftoken }) => {
    const { id } = useParams<FormParams>()
    const contentIDInputProps = {
        name: 'gif_id',
        disabled: true,
        value: id,
    }

    return (
        <FormContainer>
            <form action="" method="POST">
                {csrftoken && <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />}
                <Title>Content Takedown Appeal</Title>
                <Label label="Content ID" />
                <ContentIDInput inputProps={contentIDInputProps} />
                <Label label="Appeal Reason" />
                <Textarea inputProps={appealReasonInputProps} />
                <CTAButton color={giphyIndigo}>Submit</CTAButton>
            </form>
        </FormContainer>
    )
}

export default CSRFToken(TakedownAppealForm)
