import { giphyRed } from '@giphy/colors'
import styled from 'styled-components'

const ErrorStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    p {
        font-weight: 600;
        font-size: 18px;
        margin-top: 20px;
        text-align: center;
    }

    span {
        color: ${giphyRed};
        line-height: 25px;
    }

    img {
        display: block;
        margin: auto;
    }
`

type Props = {
    isError: boolean
}

const ErrorState = ({ isError }: Props) => {
    return (
        <ErrorStateContainer>
            {isError ? (
                <div>
                    <img src="/static/img/dashboard/sparseGraySmiley.png" />
                    <p>
                        <span>Oops!</span>
                        <br />
                        Something went wrong fetching your analytics
                    </p>
                </div>
            ) : (
                <div>
                    <img src="/static/img/dashboard/sparseGraySmiley.png" />
                    <p>
                        No GIFs Yet <br /> <a href="/create/gifmaker">Create</a> your first GIF to start seeing metrics
                    </p>
                </div>
            )}
        </ErrorStateContainer>
    )
}

export default ErrorState
