import { Fragment, useContext, useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import { Loader } from 'shared/components/preloader-animation'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import DashboardUserContext from '../../../site/dashboard/context'
import AnalyticsInfoTooltip from '../../../site/user-dashboard-analytics/analytics-info-tooltip'
import LeaderBoardGraph from './leader-board-graph'
import * as api from './networking'

const LeaderboardContainer = styled.div`
    margin-top: 50px;

    h1 {
        font-size: 24px;
        font-family: NexaBlack;
        margin-bottom: 10px;
        text-align: left;

        ${mobile.css`
            font-size: 18px;
        `}
    }
`

const LeaderboardTitleContainer = styled.h1`
    position: relative;
    display: flex;
`

const LeaderboardIconComponent = styled(AnalyticsInfoTooltip)`
    margin-top: 1px;
`

type Props = {
    onboardingActive?: boolean
    currentStep?: number
    contentType: string
    dateRange: any[]
    datePreset: 'ALL_TIME' | 'CUSTOM' | 'WEEK' | 'MONTH'
    onLoad?: () => void
    onCSVDownload?: (label: string) => void
    onCSVError?: (err: Error) => void
    onCSVModalToggle?: (isOpen: boolean) => void
}

const LeaderBoard = ({
    onboardingActive,
    currentStep,
    contentType,
    dateRange,
    datePreset,
    onLoad,
    onCSVDownload,
    onCSVError,
    onCSVModalToggle,
}: Props) => {
    const { user } = useContext(DashboardUserContext)
    const isMobile = useMedia(mobile.query)
    const [isFetching, setFetching] = useState(false)
    const [data, setData] = useState<api.LeaderboardItem[]>([])
    const [csv, setCsv] = useState('')
    const [csvCtr, setCsvCtr] = useState('')

    // If the date range or content types change, refresh the leaderboard.
    useEffect(() => {
        getLeaderboard()
    }, [dateRange, contentType])

    const getLeaderboard = async () => {
        const { username } = user
        const descendingOrder = ({ viewCount: viewCountA }, { viewCount: viewCountB }) => viewCountB - viewCountA
        setFetching(true)
        let data = await api.getLeaderboard(contentType, dateRange, username)
        data.sort(descendingOrder)
        setData(data)
        setCsv(api.getCsvLink(contentType, dateRange, username))
        setCsvCtr(api.getCsvCtrLink(contentType, dateRange, username))
        setFetching(false)
        onLoad?.()
    }

    let tooltip = (
        <Fragment>
            A <strong>view</strong> means your clip appeared in a set of results.
            <strong> Clip Starts</strong> are the amount of times an actual video was started.
        </Fragment>
    )
    return (
        <LeaderboardContainer>
            <LeaderboardTitleContainer>
                Top {contentType}
                {!isMobile && contentType === 'Clips' && <LeaderboardIconComponent tooltip={tooltip} />}
            </LeaderboardTitleContainer>
            {isFetching ? (
                <Loader />
            ) : (
                <LeaderBoardGraph
                    onboardingActive={onboardingActive}
                    currentStep={currentStep}
                    data={data}
                    csv={csv}
                    csvCtr={csvCtr}
                    contentType={contentType}
                    dateRange={dateRange}
                    datePreset={datePreset}
                    onCSVDownload={onCSVDownload}
                    onCSVError={onCSVError}
                    onCSVModalToggle={onCSVModalToggle}
                />
            )}
        </LeaderboardContainer>
    )
}

export default LeaderBoard
