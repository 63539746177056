import * as colors from '@giphy/colors'
import { useState } from 'react'
import { Button } from 'shared/components/buttons'
import styled from 'styled-components'
import DownloadAnimation from './download-animation'

const DownloadButton = styled(Button)`
    margin-right: auto;
    height: 44px;
    width: 248px;
    color: ${colors.giphyWhite};
    background-color: ${colors.giphyDarkGrey};
    border-radius: 4px;
    position: relative;

    &:hover:enabled {
        color: ${colors.giphyBlue};
    }

    &:disabled {
        background-color: ${colors.giphyGreen};
        color: ${colors.giphyBlack};
        opacity: 1;
    }

    &:last-child {
        margin-right: 0;
    }

    i {
        margin-right: 5px;
        top: 3px;
        position: relative;
    }

    span {
        position: relative;
    }
`

type Props = {
    clickCallback: () => void
    title: string
}

const AnimatedDownloadButton = ({ clickCallback, title }: Props) => {
    const [isDownLoading, setIsDownloadLoading] = useState(false)

    const handleClick = async () => {
        setIsDownloadLoading(true)
        try {
            await clickCallback()
        } finally {
            setIsDownloadLoading(false)
        }
    }

    return (
        <DownloadButton disabled={isDownLoading} onClick={() => handleClick()}>
            {isDownLoading && <DownloadAnimation />}
            <i className={'ss-download'} />
            <span>{title}</span>
        </DownloadButton>
    )
}

export default AnimatedDownloadButton
