import { giphyDarkestGrey, giphyLightGrey, giphyRed } from '@giphy/react-giphy-brand/src/css/colors'
import { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { deleteStory } from 'shared/api'
import UserContext from 'shared/contexts/user'
import styled from 'styled-components'
import Button from 'ui/src/components/controls/button'
import { fontFamily } from 'ui/src/constants'
import { Modal } from '../modal'
import { StoryEditItemBase } from './edit-dropdown'

const Container = styled.div`
    background-color: ${giphyDarkestGrey};
    max-width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: ${fontFamily.title};
    gap: 20px;
    h1 {
        font-size: 20px;
    }
`
const Buttons = styled.div`
    display: flex;
    gap: 6px;
    color: white;
    justify-content: center;
`
type Props = { id: string }

const DeleteStory = ({ id }: Props) => {
    const [showModal, setShowModal] = useState(false)
    const { user } = useContext(UserContext)
    return (
        <>
            <StoryEditItemBase
                onClick={() => {
                    setShowModal(true)
                }}
            >
                Delete Story
            </StoryEditItemBase>
            {showModal &&
                createPortal(
                    <Modal>
                        <Container>
                            <h1>Are you sure you want to delete?</h1>
                            <Buttons>
                                <Button
                                    color={giphyRed}
                                    textColor="white"
                                    size="large"
                                    onClick={async () => {
                                        await deleteStory(id, user.userToken)
                                        location.reload()
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    size="large"
                                    color={giphyLightGrey}
                                    textColor="white"
                                    onClick={() => {
                                        setShowModal(false)
                                    }}
                                >
                                    No
                                </Button>
                            </Buttons>
                        </Container>
                    </Modal>,
                    document.body
                )}
        </>
    )
}
export default DeleteStory
