import { useContext, useState, useEffect } from 'react'
import UserContext from 'shared/contexts/user'
import UploadClipsCTAComponent from 'shared/components/upload-clips-cta'
import withLocationState from 'shared/components/with-location-state'
import styled from 'styled-components'
import AnalyticsDisplay, { DimmableArea } from '../user-dashboard-analytics/app'
import AnalyticsSideNav from '../user-dashboard-analytics/analytics-sidenav'
import { fontCss } from '../user/components/container'
import DashboardUserContext from './context'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import DashboardBanner from './dashboard-banner'
import { useMedia } from 'react-use'
import { mobile } from 'shared/util/media-query'

const AccountManagementColumn = styled(AnalyticsSideNav)<{ isOnboarding?: boolean }>`
    ${fontCss};
    margin-right: 20px;
    opacity: ${(props) => (props.isOnboarding == true ? '0.2' : '1.0')};
`
const DashboardColumn = styled.div`
    width: 770px;
`

const Container = styled.div`
    width: 1040px;
    display: flex;
    flex-wrap: wrap;
`

const UploadClipsCTA = styled(UploadClipsCTAComponent)`
    margin-bottom: 40px;
`

const DashboardPage = ({ dashboardUser, hasClips = false }) => {
    const { user } = useContext(UserContext)
    const isMobile = useMedia(mobile.query)
    const [onboardingActive, setOnboardingActive] = useState<boolean>(false)
    const [hasSeenDashboardOnboarding] = useLocalStorage<boolean>('has-seen-dashboard-onboarding', false)
    const showUploadClipsCTA = !hasClips && dashboardUser.channel_id === user.channelId // only show clips cta on logged in user's dashboard

    useEffect(() => {
        // determine if user is onboarding.
        if (!hasSeenDashboardOnboarding) {
            setOnboardingActive(true)
        }
    }, [])

    return (
        <DashboardUserContext.Provider value={{ user: dashboardUser }}>
            <div>
                <DashboardBanner />
                <Container>
                    {!isMobile && (
                        <DimmableArea onboardingActive={onboardingActive} step={0} currentStep={1}>
                            <AccountManagementColumn isOnboarding={onboardingActive} user={dashboardUser} />
                        </DimmableArea>
                    )}

                    <DashboardColumn>
                        <AnalyticsDisplay setOnboardingActive={setOnboardingActive} isOnboarding={onboardingActive}>
                            {showUploadClipsCTA && <UploadClipsCTA gaCategory="dashboard" />}
                        </AnalyticsDisplay>
                    </DashboardColumn>
                </Container>
            </div>
        </DashboardUserContext.Provider>
    )
}

export default withLocationState(['dashboard_user', 'has_clips'])(DashboardPage)
