import { giphyGreen } from '@giphy/colors'
import { Button } from 'shared/components/buttons'
import EmptyStatePlaceholder from 'shared/components/empty-state-placeholder'
import styled from 'styled-components'

const PlusIcon = styled.i`
    color: ${giphyGreen};
    display: inline-block;
    font-size: 36px;
    margin-bottom: 8px;
`

const Buttons = styled.div`
    display: flex;
    justify-content: center;
    padding: 16px 0;
    width: 100%;
`

const Link = styled(Button)`
    margin-right: 8px;
    max-width: 180px;
    width: 100%;

    &:last-child {
        margin-right: 0;
    }
`

type Props = {
    height: number
    width: number
    isMobile?: boolean
}

const NoUploads = ({ height, width, isMobile }: Props) => (
    <EmptyStatePlaceholder height={height} width={width}>
        <PlusIcon className="ss-plus" />
        <div>You haven&apos;t uploaded anything!</div>
        <Buttons>
            {isMobile ? (
                <Link as="a" href="/create/gifmaker">
                    Upload
                </Link>
            ) : (
                <>
                    <Link as="a" href="/upload">
                        Upload
                    </Link>
                    <Link as="a" href="/create">
                        Create
                    </Link>
                </>
            )}
        </Buttons>
    </EmptyStatePlaceholder>
)

export default NoUploads
