import Breadcrumbs from 'shared/components/breadcrumbs/breadcrumbs'
import ChannelListLiveHeader from 'shared/components/channel-live-logo/live-header'
import { UsernameBadge } from 'shared/components/users'
import { removeWords } from 'shared/util/string'
import css from './header.css'
const IMAGE_TYPES = ['gif', 'gifs', 'sticker', 'stickers']

export const Title = ({
    user,
    breadcrumbs,
    displayName,
    title,
    searchQuery,
    isTagView,
    url,
    isChild,
    isLive,
    shortDisplayName,
}) => {
    let titleStyle = isChild || searchQuery ? css.title : css.mainTitle
    return (
        <div className={css.titleContainer}>
            {breadcrumbs.length || searchQuery ? (
                <div className={css.breadcrumbs}>
                    <Breadcrumbs
                        breadcrumbs={breadcrumbs}
                        url={url}
                        displayName={displayName}
                        shortDisplayName={shortDisplayName}
                    />
                </div>
            ) : null}
            {isLive ? <ChannelListLiveHeader isMobile={false} /> : null}
            {title ? (
                <div className={css.titleContainer}>
                    <h1 className={titleStyle}>
                        {searchQuery && isTagView ? <span>Trending</span> : null}
                        {searchQuery && isTagView ? (
                            <span className={css.query}> {removeWords(searchQuery, IMAGE_TYPES)} </span>
                        ) : null}
                        <span>{title}</span>
                        {searchQuery && !isTagView ? <span className={css.query}> {searchQuery} </span> : null}
                    </h1>
                    <UsernameBadge className={css.usernameBadge} user={user} />
                </div>
            ) : null}
        </div>
    )
}
