import { useContext, useState } from 'react'
import { useChannelWithNormalGifFetch } from 'shared/api/channels'
import ChannelPageContext from 'shared/contexts/channel-page'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { getSearchTitle } from 'shared/util/search'
import styled from 'styled-components'
import { Description } from './components/description'
import HeaderCollectionButtons from './components/header-collection-buttons'
import { Title } from './components/title'
const HeaderSC = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: top;
`

const Header = ({ overrideTitle }: { overrideTitle?: string }) => {
    const [title, setTitle] = useState('')
    const { channel, channelType, isTagView, searchQuery } = useContext(ChannelPageContext)
    const { display_name, url, user, id, description, is_live, short_display_name, parent, content_type } = channel
    const isChild = !!parent
    let fetchOptions
    if (channelType === 'tagged_channel' || channelType === 'search_channel') {
        fetchOptions = { term: searchQuery, isFeaturedTag: isTagView }
    }
    const fetchGifs = useChannelWithNormalGifFetch(id, fetchOptions)
    useAsyncEffect(async () => {
        if (searchQuery) {
            const {
                pagination: { total_count },
            } = await fetchGifs(0)
            setTitle(getSearchTitle(total_count, content_type, isTagView) || '')
        } else {
            setTitle(display_name || user.username)
        }
    }, [channel.id, searchQuery, display_name, user?.username])

    const ancestors = channel.ancestors || []

    if (!title) {
        return null
    }
    return (
        <div>
            <HeaderSC>
                <Title
                    user={user}
                    breadcrumbs={ancestors}
                    displayName={display_name}
                    shortDisplayName={short_display_name}
                    title={overrideTitle || title}
                    searchQuery={searchQuery}
                    isTagView={isTagView}
                    url={url}
                    isChild={isChild}
                    isLive={is_live}
                />
                <HeaderCollectionButtons />
            </HeaderSC>
            {isChild ? <Description description={description} /> : null}
        </div>
    )
}

export default Header
