import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { WEB_API_KEY } from 'shared/api'
import Grid from 'shared/components/grid'
import locationState from 'shared/components/with-location-state'
import { Android, Apple } from 'site/apps-landing/components/app/app-links'
import Video from './background-video'
import styled from 'styled-components'

const BackgroundVideo = styled(Video)`
    height: 1015px;
    opacity: 0.23;
    position: fixed;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 50px;
    color: ${giphyWhite};
    font-family: interface;
    font-weight: bold;
    font-size: 26px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
`

const Thumbnail = styled.img`
    height: 174px;
    width: 174px;
    margin: 30px 0 27px 0;
    border-radius: 50%;
    border: 5px solid ${giphyWhite};
    background: rgba(0, 0, 0, 0.27);
`

const Subtitle = styled.div`
    width: 153px;
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${giphyLightGrey};
    font-size: 16px;
    font-weight: normal;
    margin: 5px 0 35px 0;
    div {
        padding-top: 4px;
    }
`

const AppLinks = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 25px 0 45px 0;
`

const Arrow = styled.div`
    font-size: 18px;
    margin-bottom: 20px;
`

const landingPageFetch = new GiphyFetch(WEB_API_KEY)

type Props = {
    title: string
    thumbnail: string
    iosUrl: string
    androidUrl: string
}

const ArSceneLandingPage = ({ title, thumbnail, iosUrl, androidUrl }: Props) => {
    const fetchGifs = (offset: number) => landingPageFetch.trending({ offset })
    return (
        <>
            <BackgroundVideo />
            <Container>
                <Thumbnail src={thumbnail} />
                {title}
                <Subtitle>
                    <div className="ss-camera" /> GIPHY Camera Filter
                </Subtitle>
                Download the GIPHY app to try this camera filter
                <AppLinks>
                    <Apple href={iosUrl} target="_blank" />
                    <Android href={androidUrl} target="_blank" />
                </AppLinks>
                <Arrow className="ss-icon ss-navigatedown" />
                <Grid columns={4} fetchGifs={fetchGifs} />
            </Container>
        </>
    )
}

export default locationState(['title', 'thumbnail', 'iosUrl', 'androidUrl'])(ArSceneLandingPage)
