import { giphyLightestGrey, giphyWhite } from '@giphy/colors'
import * as React from 'react'
import { SetStateAction } from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
    width: auto;
`
const ScrollCarousel = styled.div`
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 22px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    height: 44px;
    background: #2e2e2e;
    ::-webkit-scrollbar {
        display: none;
    }
`

const Pill = styled.div<{ gradient?: string | null }>`
    height: 100%;
    width: 100px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 17px;
    font-weight: bold;
    padding: 0 15px;
    color: ${giphyLightestGrey};
    cursor: pointer;
    background: #2e2e2e;
    ${({ gradient }) =>
        !!gradient &&
        css`
            background: ${gradient};
            color: ${giphyWhite};
            span {
                filter: drop-shadow(3px 1px 5px rgba(0, 0, 0, 0.25));
            }
        `};
`

type Props = {
    allOptions: any[]
    currentValue: string
    setValue: React.Dispatch<SetStateAction<number>>
}

const PillsSlider = ({ allOptions, currentValue, setValue }: Props) => (
    <Container>
        <ScrollCarousel>
            {allOptions.map((value, key) => (
                <Pill
                    key={key}
                    onClick={() => setValue(key)}
                    gradient={value.name === currentValue ? value.gradient : null}
                >
                    <span>{value.name}</span>
                </Pill>
            ))}
        </ScrollCarousel>
    </Container>
)

export default PillsSlider
