import { giphyLightGrey } from '@giphy/colors'
import { grid6 } from 'shared/util/grid'
import styled from 'styled-components'

const Copy = styled.p`
    color: ${giphyLightGrey};
    margin: 0 0 30px;
    width: ${grid6};
`

type Props = {
    description?: string
}

export const Description = ({ description }: Props) => {
    return description ? <Copy>{description}</Copy> : null
}
