import { addDays, differenceInDays, endOfMonth, format, isSameDay, startOfMonth, subDays } from 'date-fns'
import { CalEvent } from 'shared/api/calendar'

export type MappedDay = {
    singleDayEvents: CalEvent[]
    multiDayEvents: CalEvent[]
    ongoingEvents: CalEvent[]
    allDayEvents: CalEvent[]
    daysWithEvents: Set<number>
}

const getAllDaysInRange = (start: string, end: string, eventsCache: Map<string, MappedDay>): Map<string, MappedDay> => {
    let allDayEventsInMonth: CalEvent[] = []
    let daysWithEvents: Set<number> = new Set()

    for (let day = startOfMonth(subDays(start, 10)); day <= endOfMonth(addDays(end, 10)); day = addDays(day, 1)) {
        const currentDay = new Date(day)
        if (currentDay.getDate() === 1) {
            allDayEventsInMonth = []
            daysWithEvents = new Set()
        }
        if (eventsCache.has(getDateKey(currentDay))) continue
        eventsCache.set(getDateKey(currentDay), {
            singleDayEvents: [],
            multiDayEvents: [],
            ongoingEvents: [],
            allDayEvents: allDayEventsInMonth,
            daysWithEvents,
        })
    }

    return eventsCache
}

export const getDateKey = (date: Date) => {
    const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
    return format(dtDateOnly, 'YYYY-MM-DD')
}

const isEventExist = (event: CalEvent, events: CalEvent[]) => {
    return events.find((e) => e.id === event.id)
}

const addEventToList = (
    event: CalEvent,
    eventList: CalEvent[],
    day: Date,
    daysWithEvents: Set<number> | null = null
) => {
    if (!isEventExist(event, eventList)) {
        eventList.push(event)
        daysWithEvents && daysWithEvents.add(day.getDate())
    }
}

export const streamlineCalEvents = (
    events: CalEvent[],
    start: string,
    end: string,
    eventsCache: Map<string, MappedDay>
) => {
    const daysStreamline = getAllDaysInRange(start, end, eventsCache)

    events.forEach((event) => {
        if (event.status !== 'confirmed') return
        const startDt = new Date(event.start.date)
        const endDt = new Date(event.end.date)

        const startDay = new Date(startDt.valueOf() + startDt.getTimezoneOffset() * 60 * 1000)
        const endDay = subDays(new Date(endDt.valueOf() + endDt.getTimezoneOffset() * 60 * 1000), 1)
        const currentDay = daysStreamline.get(getDateKey(startDay))
        if (!currentDay) return

        const diffDays = differenceInDays(endDay, startDay)

        if (diffDays === 0) {
            addEventToList(event, currentDay.singleDayEvents, startDay, currentDay.daysWithEvents)
        } else if (diffDays <= 2) {
            for (let day = startDay; day <= endDay; day = addDays(day, 1)) {
                const cachedDay = daysStreamline.get(getDateKey(day))
                if (!cachedDay) return
                event.end.date = format(endDay, 'YYYY-MM-DD')
                addEventToList(event, cachedDay.multiDayEvents, day, cachedDay.daysWithEvents)
            }
        } else if (diffDays > 2) {
            for (let day = startDay; day <= endDay; day = addDays(day, 1)) {
                const cachedDay = daysStreamline.get(getDateKey(day))
                if (!cachedDay) return
                event.end.date = format(endDay, 'YYYY-MM-DD')
                addEventToList(event, cachedDay.ongoingEvents, day)
            }
        } else if (
            isSameDay(startOfMonth(startDay), startDay) &&
            (isSameDay(endDay, endOfMonth(endDay)) || isSameDay(endDay, startOfMonth(addDays(endDay, 1))))
        ) {
            addEventToList(event, currentDay.allDayEvents, startDay)
        }
    })

    return daysStreamline
}
