import { ICategory } from '@giphy/js-fetch-api'
import { useState } from 'react'
import { useRouteMatch } from 'react-router'
import { webFetch } from 'shared/api'
import useAsyncEffect from 'shared/hooks/use-async-effect'

const useCategories = () => {
    const {
        params: { subcategory },
    } = useRouteMatch<{ subcategory: string }>()
    const [categories, setCategories] = useState<ICategory[]>([])
    const [subcategories, setSubcategories] = useState<ICategory[]>([])
    useAsyncEffect(async () => {
        const categories = await webFetch.categories()
        setCategories(categories.data)
    }, [])
    useAsyncEffect(async () => {
        if (subcategory) {
            const subcategories = await webFetch.subcategories(subcategory)
            setSubcategories(subcategories.data)
        }
    }, [])
    // if we're on a subcatgory page we have a subcategory name+encoded in the pathname
    const items = subcategory ? subcategories : categories
    const title = subcategory ? categories.find((c) => c.name_encoded === subcategory)?.name : `Categories`

    return { title, subcategory, categories, items }
}

export default useCategories
