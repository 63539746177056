import { Fragment, useContext, useEffect, useState } from 'react'
import {
    addDays,
    addMonths,
    addWeeks,
    differenceInWeeks,
    endOfMonth,
    endOfWeek,
    format,
    isBefore,
    isSameDay,
    isSameMonth,
    startOfMonth,
    startOfWeek,
    subMonths,
} from 'date-fns'

import {
    AnimatedContainer,
    CalArrow,
    Container,
    CurrentMonth,
    CurrentYear,
    Day,
    DatesContainer,
    DaysHeader,
    WeekContainer,
    WeekNames,
} from './styles'
import UserContext from 'shared/contexts/user'
import { trackGA4ContentCalendarEvent } from './ga4'

type Props = {
    selectedDate: Date
    activeDate: Date
    daysWithEvents: Set<number> | undefined
    setSelectedDate: (date: Date) => void
    setActiveDate: (date: Date) => void
}

export const lastCalDate = new Date(2023, 11, 1)

const DaysBuilder = ({ selectedDate, activeDate, daysWithEvents, setSelectedDate, setActiveDate }: Props) => {
    const { user } = useContext(UserContext)
    const [animationStatus, setAnimationStatus] = useState('fadeIn')
    const [proxyDate, setProxyDate] = useState(new Date())
    const [isLastMonth, setIsLastMonth] = useState(false)

    useEffect(() => {
        setIsLastMonth(isSameMonth(activeDate, lastCalDate))
    }, [activeDate])

    useEffect(() => {
        let timeout
        if (animationStatus === 'fadeOut') {
            timeout = setTimeout(() => {
                setActiveDate(proxyDate)
                setAnimationStatus('fadeIn')
            }, 250)
        }
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationStatus])

    const nextMonth = () => {
        trackGA4ContentCalendarEvent('next_month', user)
        const newActiveDate = addMonths(activeDate, 1)
        setAnimationStatus('fadeOut')
        setProxyDate(newActiveDate)
    }

    const prevMonth = () => {
        if (isLastMonth) return

        trackGA4ContentCalendarEvent('previous_month', user)
        const newActiveDate = subMonths(activeDate, 1)
        setAnimationStatus('fadeOut')
        setProxyDate(newActiveDate)
    }

    const DateSwitcher = () => {
        return (
            <DaysHeader>
                <CalArrow className="ss-icon ss-navigateleft" onClick={prevMonth} disabled={isLastMonth} />
                <CurrentMonth>{format(activeDate, 'MMMM')}</CurrentMonth>
                <CurrentYear>{format(activeDate, 'YYYY')}</CurrentYear>
                <CalArrow className="ss-icon ss-navigateright" onClick={nextMonth} />
            </DaysHeader>
        )
    }

    const WeekdaysNames = () => {
        const weekStartDate = startOfWeek(activeDate)

        const weekDays = Array.from({ length: 7 }, (_, day) => (
            <WeekNames key={day}>{format(addDays(weekStartDate, day), 'dd').charAt(0)}</WeekNames>
        ))

        return <WeekContainer>{weekDays}</WeekContainer>
    }

    const datesForWeek = (date: Date, selectedDate: Date, activeDate: Date) => {
        const markedDays = daysWithEvents ? Array.from(daysWithEvents) : []

        const week = Array.from({ length: 7 }, (_, day) => {
            const currentDate = addDays(date, day)
            const isInactive = !isSameMonth(currentDate, activeDate)
            const isSelected = isSameDay(currentDate, selectedDate)
            const isHasEvent = markedDays.includes(parseInt(format(currentDate, 'D')))

            return (
                <Day
                    key={`${currentDate.toString()}${isHasEvent}`}
                    isInactive={isInactive}
                    isSelected={isSelected}
                    isHasEvent={isHasEvent}
                    onClick={() => {
                        if (isBefore(currentDate, lastCalDate)) return
                        trackGA4ContentCalendarEvent('day', user)
                        setSelectedDate(currentDate)
                    }}
                >
                    {format(currentDate, 'D')}
                </Day>
            )
        })

        return <Fragment key={format(date, 'YYYY-MM-DD')}>{week}</Fragment>
    }

    const Dates = () => {
        const startOfTheSelectedMonth = startOfMonth(activeDate)
        const endOfTheSelectedMonth = endOfMonth(activeDate)
        const startDate = startOfWeek(startOfTheSelectedMonth)
        const endDate = endOfWeek(endOfTheSelectedMonth)

        const allWeeks = Array.from({ length: differenceInWeeks(endDate, startDate) + 1 }, (_, i) =>
            datesForWeek(addWeeks(startDate, i), selectedDate, activeDate)
        )

        return <DatesContainer>{allWeeks}</DatesContainer>
    }

    return (
        <Container>
            <DateSwitcher />
            <WeekdaysNames />
            <AnimatedContainer fadeStatus={animationStatus}>
                <Dates />
            </AnimatedContainer>
        </Container>
    )
}

export default DaysBuilder
