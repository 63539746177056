import styled from 'styled-components'

export const ColoredStrip = styled.div`
    background: linear-gradient(-135deg, rgb(97, 87, 255) 0%, rgb(0, 255, 153) 100%);
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
`

export const CloseButton = styled.span`
    width: 18px;
    height: 18px;
    position: absolute;
    top: 25px;
    right: 25px;
    background-size: 100%;
    cursor: pointer;
    background-image: url('/static/img/thick-close-btn.png');
`
