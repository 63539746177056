import * as colors from '@giphy/colors'
import appendQuery from 'append-query'
import { SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'shared/components/buttons'
import Fields, { FIELDS, FieldUpdate, Label } from 'shared/components/form/fields'
import BlockEnterSubmit from 'shared/components/form/inputs/block-enter-submit'
import Input from 'shared/components/form/ui/input'
import UserContext from 'shared/contexts/user'
import { useSaveForm } from 'shared/hooks/use-save-form'
import { AckResponseErrorHandler, SaveHandler } from 'shared/types/settings'
import { POST } from 'shared/util/fetch-methods'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import { IProfilePowerUser } from 'types'
import { trackGA4SettingsEvent } from './ga4'
import { ButtonGroup, ButtonGroups, CTAButton, FieldSection, FooterTip, FormContainer, Header, Textarea } from './sc'
import SelectList from '../form/ui/select-list'
import { getIndustriesByGroup } from 'shared/api/industries'

const publicPrivateButtons = [
    { label: 'Public', val: true },
    { label: 'Private', val: false },
]
const yesIsFalseNoIsTrue = [
    { label: 'Yes', val: false },
    { label: 'No', val: true },
]

const availableButton = [
    { label: 'Available', val: true },
    { label: 'Not Available', val: false },
]

type Props = {
    csrftoken?: string
    user: IProfilePowerUser
    className?: string
    saveHandler: SaveHandler
    ackResponseError: AckResponseErrorHandler
    responseErrors: object
}

const aboutInputProps = {
    as: 'textarea',
    name: 'about_bio',
    placeholder:
        'Enter a short description about yourself. This could be your tagline, slogan, or anything else you like!',
}

const ButtonGroupsWithFooter = styled(ButtonGroups)`
    margin-bottom: 0;
`

const EmbedContainer = styled.div`
    width: 100%;
    iframe {
        width: 100%;
        height: 432px;
        margin-bottom: 8px;
        background: black;

        ${mobile.css`
            height: 200px;
        `}
    }
`

const AuthenticatedInstagram = styled.div`
    width: 100%;
    display: flex;
`

const InstagramInput = styled(Input)`
    flex-grow: 1;
    margin: 0;
    width: auto;
`

const LinkInstagram = styled(Button)`
    flex-grow: 1;
    vertical-align: top;
    background: rgb(220, 86, 154);
    background: linear-gradient(58deg, rgba(220, 86, 154, 1) 0%, rgba(160, 56, 243, 1) 100%);
`

const UnlinkInstagram = styled(Button)`
    flex-grow: 1;
    vertical-align: top;
`

const InstagramText = styled.div`
    display: inline-block;
    background-image: url('/static/img/instagram-text.svg');
    width: 68px;
    height: 22px;
    vertical-align: middle;
`

const IndustryInput = styled(Fields)`
    flex-grow: 1;
    margin: 0;
    width: 230px;
    height: 30px;
`

const IndustryRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 5px 0px 30px;
    gap: 16px;
    width: 100%;

    ${mobile.css`
        flex-direction: column;
    `}
`

type HistoryState = {
    showFreelance: boolean
    title: string
}

// use ' ' for blank labels
const socialLabels: FieldUpdate[] = [
    [FIELDS.facebook, { label: 'Social Links' }],
    ...[FIELDS.tumblr, FIELDS.twitter, FIELDS.instagram, FIELDS.tiktok, FIELDS.youtube].map(
        (key: string): FieldUpdate => [key, { label: ' ' }]
    ),
]

const ChannelSettingsForm = ({ csrftoken, user, className, saveHandler, ackResponseError, responseErrors }: Props) => {
    const isFocusedRef = useRef<boolean>(false)

    const {
        user: { permissions, isStaff },
    } = useContext(UserContext)

    const onSave = (err?: Error) => {
        saveHandler(err)
        trackGA4SettingsEvent('channel', err ? 'error' : 'success')
    }

    const onError = (field: string) => {
        ackResponseError(field)
    }

    const onFocus = () => {
        if (isFocusedRef.current) return

        trackGA4SettingsEvent('channel', 'open')
        isFocusedRef.current = true
    }

    const onBlur = (e: SyntheticEvent<HTMLFormElement, FocusEvent>) => {
        if (!e.nativeEvent.relatedTarget && isFocusedRef.current) {
            trackGA4SettingsEvent('channel', 'cancel')
            isFocusedRef.current = false
        }
    }

    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false)
    const [form1Valid, setForm1Valid] = useState<boolean>(false)
    const [form2Valid, setForm2Valid] = useState<boolean>(false)
    const [isFreelance, setIsFreelance] = useState<boolean | undefined>(user.is_freelance)
    const [otherIndustryLabel, setOtherIndustryLabel] = useState<string | undefined>(undefined)
    const [industryOptions, setIndustryOptions] = useState<string[]>([])
    const [isOtherIndustry, setIsOtherIndustry] = useState<boolean>(false)
    const [selectedIndustry, setSelectedIndustry] = useState<string>('')

    const { formRef, save, isSaving, formSaveId } = useSaveForm(user.id, onSave)
    useEffect(() => setSubmitEnabled(form1Valid && form2Valid), [form1Valid, form2Valid])
    const { user_type, is_verified, is_community } = user
    const isBrandOrArtist = user_type === 'partner' || user_type === 'artist'
    const isVerifiedCreator = is_verified && is_community
    useEffect(() => {
        if (isBrandOrArtist || is_community) {
            const industryType = is_community ? 'creator' : user_type
            getIndustriesByGroup(industryType).then(({ data: { industries, other_industry_id } }) => {
                const otherIndustryLabel = industries.find((i) => i.id === other_industry_id)?.label || ''
                const industryOptions = industries.map((i) => i.label)
                setIndustryOptions(industryOptions)
                setOtherIndustryLabel(otherIndustryLabel)
            })
        }
    }, [user_type, is_community])
    const showForHire = user_type === 'artist' || isStaff || isVerifiedCreator
    const { is_public, display_view_counts, about_bio } = user || {}
    const { block_external_indexing, attribution_gif, suppress_chrome } = user || {}
    const { instagram, authenticated_instagram } = user
    let embedUrl
    if (attribution_gif) {
        embedUrl = attribution_gif.embed_url
    }
    let customFieldProps: FieldUpdate[] = []
    if (!isBrandOrArtist) {
        customFieldProps = [[FIELDS.primarySite, { optional: true }]]
    }
    const freelanceRef = useRef<HTMLDivElement>(null)
    const {
        location: { state },
    } = useHistory<HistoryState>()

    useEffect(() => {
        if (state.showFreelance && freelanceRef.current) {
            freelanceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        }
    }, [state.showFreelance])
    useEffect(() => {
        if (user?.industry) {
            industryChange(user.industry?.label)
            if (otherIndustryLabel) {
                setIsOtherIndustry(user.industry.label === otherIndustryLabel)
            }
        }
    }, [user?.industry, otherIndustryLabel])
    const industryLabel = useMemo(() => {
        const industryType = is_community ? 'creator' : user_type
        return industryType === 'partner' ? 'Industry' : 'Category'
    }, [user_type, is_community])
    const dimButton = !submitEnabled || isSaving

    const socialFields = [FIELDS.facebook, FIELDS.tumblr, FIELDS.twitter, FIELDS.tiktok, FIELDS.youtube]
    // If user does not have access to the new Instagram auth, show the old social field.
    if (!permissions.auth_instagram) {
        socialFields.push(FIELDS.instagram)
    }

    const handleFreelanceToggle = () => {
        setIsFreelance(!isFreelance)
    }

    const linkInstagramClicked = () => {
        window.location.href = '/login/instagram/'
    }

    const unlinkInstagramClicked = () => {
        fetch('/disconnect/instagram/', POST()).then(() => location.reload())
    }

    const industryChange = (userIndustry) => {
        setForm2Valid(true)
        setIsOtherIndustry(userIndustry === otherIndustryLabel)
        setSelectedIndustry(userIndustry)
    }

    return (
        <form
            className={className}
            ref={formRef}
            onFocus={onFocus}
            onBlur={onBlur}
            onSubmit={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
        >
            <FormContainer>
                {csrftoken && <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />}
                <Header>Channel Settings</Header>
                <ButtonGroups>
                    <Label label="Channel Privacy" />
                    <ButtonGroup
                        groupName="is_public"
                        buttons={publicPrivateButtons}
                        defaultSelectedValue={is_public}
                    />
                </ButtonGroups>
                <ButtonGroups>
                    <Label label="About" />
                    <Textarea inputProps={aboutInputProps} initialValue={about_bio} />
                </ButtonGroups>

                {permissions.auth_instagram && (
                    <ButtonGroups>
                        <Label label="Instagram" />
                        <AuthenticatedInstagram>
                            {authenticated_instagram ? (
                                <>
                                    <InstagramInput
                                        inputProps={{
                                            value: `@${authenticated_instagram}`,
                                            readOnly: true,
                                        }}
                                    />
                                    <UnlinkInstagram secondary onClick={unlinkInstagramClicked}>
                                        Unlink Account
                                    </UnlinkInstagram>
                                </>
                            ) : instagram ? (
                                <>
                                    <InstagramInput
                                        inputProps={{
                                            value: `@${instagram}`,
                                            readOnly: true,
                                        }}
                                    />
                                    <LinkInstagram onClick={linkInstagramClicked}>
                                        Connect Your <InstagramText />
                                    </LinkInstagram>
                                </>
                            ) : (
                                <LinkInstagram onClick={linkInstagramClicked}>
                                    Connect Your <InstagramText />
                                </LinkInstagram>
                            )}
                        </AuthenticatedInstagram>
                    </ButtonGroups>
                )}

                <Fields
                    user={user}
                    onValidChange={setForm1Valid}
                    showFields={[FIELDS.primarySite]}
                    showLabel
                    noTooltip
                    noOptional
                    customFieldProps={customFieldProps}
                    ackResponseError={onError}
                    responseErrors={responseErrors}
                    aggroErrorMessages={true}
                />
                <FieldSection
                    user={user}
                    onValidChange={setForm2Valid}
                    showFields={socialFields}
                    showLabel
                    noTooltip
                    noOptional
                    customFieldProps={socialLabels}
                    ackResponseError={onError}
                    responseErrors={responseErrors}
                    aggroErrorMessages={true}
                />
                {showForHire && (
                    <div ref={freelanceRef}>
                        <ButtonGroupsWithFooter>
                            <Label label="Available for Work?" isNew labelWidth={70} />
                            <ButtonGroup
                                groupName="is_freelance"
                                buttons={availableButton}
                                defaultSelectedValue={isFreelance}
                                onClick={() => handleFreelanceToggle()}
                            />
                        </ButtonGroupsWithFooter>
                        <FooterTip>
                            Make yourself available to allow verified Brands, Businesses, and Artists to get in touch
                            with you from your GIPHY Channel.
                        </FooterTip>
                    </div>
                )}
                {isBrandOrArtist || is_community ? (
                    <>
                        <ButtonGroupsWithFooter>
                            <Label label={industryLabel} />
                            <IndustryRow>
                                <input type="hidden" name="industry_label" value={selectedIndustry} />
                                <SelectList
                                    onChange={industryChange}
                                    options={industryOptions}
                                    placeholder={`Choose an ${industryLabel}`}
                                    theme={'light'}
                                    width={!isOtherIndustry ? '100%' : '240px'}
                                    height="40px"
                                    allowMultiple={false}
                                    active={[user?.industry?.label || '']}
                                />
                                {isOtherIndustry && (
                                    <IndustryInput
                                        isHalf
                                        noTooltip
                                        noLabel
                                        noOptional
                                        onValidChange={setForm2Valid}
                                        customFieldProps={[
                                            [
                                                FIELDS.otherIndustry,
                                                {
                                                    defaultValue: user?.industry?.user_provided_industry || '',
                                                    placeholder: `Please specify your work ${industryLabel.toLowerCase()}`,
                                                    label: `Other ${industryLabel.toLowerCase()}`,
                                                },
                                            ],
                                        ]}
                                        showFields={[FIELDS.otherIndustry]}
                                        user={user}
                                        responseErrors={responseErrors}
                                        ackResponseError={onError}
                                        aggroErrorMessages={true}
                                    />
                                )}
                            </IndustryRow>
                        </ButtonGroupsWithFooter>
                        <ButtonGroupsWithFooter>
                            <Label label="Display GIF View Count" />
                            <ButtonGroup
                                groupName="display_view_counts"
                                buttons={publicPrivateButtons}
                                defaultSelectedValue={display_view_counts}
                            />
                        </ButtonGroupsWithFooter>
                        <FooterTip>Your GIF View Counts are private. Only you can see them.</FooterTip>
                        <ButtonGroups>
                            <Label label="Find Me in Google" />
                            <ButtonGroup
                                groupName="block_external_indexing"
                                buttons={yesIsFalseNoIsTrue}
                                defaultSelectedValue={block_external_indexing}
                            />
                        </ButtonGroups>
                        <ButtonGroups>
                            <Label label="Embed Attribution" />
                            <EmbedContainer>
                                {embedUrl && <iframe src={appendQuery(embedUrl, { formSaveId })} />}
                                <ButtonGroup
                                    groupName="suppress_chrome"
                                    buttons={yesIsFalseNoIsTrue}
                                    defaultSelectedValue={suppress_chrome}
                                />
                            </EmbedContainer>
                        </ButtonGroups>
                    </>
                ) : null}
                <BlockEnterSubmit />
                <CTAButton onClick={save} color={dimButton ? colors.giphyDarkGrey : colors.primaryCTA}>
                    {isSaving ? `Saving...` : `Save`}
                </CTAButton>
            </FormContainer>
        </form>
    )
}

export default ChannelSettingsForm
