import { connect } from 'react-redux'
import { compose } from 'recompose'
import SharedBanner from 'shared/components/form/settings/banner'
import SideNav from 'shared/components/form/settings/side-nav'
import DotsLoader from 'shared/components/loaders/dots'
import { STATUS_STYLE } from 'shared/components/message/flash-message'
import SettingsForm from 'shared/components/settings'
import withLocationState from 'shared/components/with-location-state'
import useDocumentTitle from 'shared/hooks/use-document-title'
import { useUserProfile } from 'shared/hooks/use-user-profile'
import { message } from 'shared/redux/message'
import styled from 'styled-components'
import { fontCss } from './components/container'
import FormErrors from './components/form-errors'

const gutter = 17

const Container = styled.div`
    width: 1040px;
    display: flex;
    ${fontCss};
    flex-wrap: wrap;
`

const Banner = styled(SharedBanner)`
    margin-bottom: ${gutter}px;
`

const Nav = styled(SideNav)`
    margin-right: ${gutter}px;
`

const Settings = ({ title, message }) => {
    useDocumentTitle(title)
    const user = useUserProfile(() => message('Error fetching settings', STATUS_STYLE.SUCCESS))
    return user ? (
        <Container>
            <Banner user={user} />
            <Nav user={user} />
            <SettingsForm user={user} />
            <FormErrors />
        </Container>
    ) : (
        <DotsLoader isAbsolute />
    )
}

export default compose(withLocationState(['title']), connect(undefined, { message }))(Settings)
