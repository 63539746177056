import { giphyCharcoal, giphyGreen, giphyLightGrey, giphyRed } from '@giphy/colors'
import { useState } from 'react'
import { fetchUserAnalytics } from 'shared/api'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { mobile } from 'shared/util/media-query'
import roundWithSuffix from 'shared/util/round-with-suffix'
import styled from 'styled-components'
import ChannelStatistics from './components/channel-statistics'

const GrayLine = styled.span`
    height: 44px;
    border-left: solid 1px ${giphyCharcoal};
    margin-left: 14px;
    margin-right: 14px;
`

const ChannelAnalyticsContainer = styled.div`
    padding-top: 15px;
    justify-content: flex-start;
    padding-bottom: 10px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    ${mobile.css`
        justify-content: flex-start;
        width: 100%;
        display: flex;
        margin-bottom: 6px;
    `}
`

const LockedContentText = styled.div`
    display: flex;
    align-items: baseline;
    color: ${giphyCharcoal};
    font-size: 14px;
    flex: 100%;
    margin-left: -5px;
    .ss-lock {
        font-size: 11px;
        margin-left: 4px;
        height: 13px;
        padding-right: 10px;
        color: ${giphyRed};
    }
`

const DashboardLink = styled.a`
    margin-top: 15px;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    align-items: baseline;
    .ss-barchart {
        color: ${giphyGreen};
        font-size: 11px;
        margin-right: 10px;
    }
    &:hover {
        color: ${giphyLightGrey};
    }
`

type Props = {
    isCompact: boolean | undefined
    userId: number
    showDashboardLink: boolean
}

const ChannelAnalyticsOverview = ({ isCompact, userId, showDashboardLink }: Props) => {
    const [uploadCount, setUploadCount] = useState<number>(0)
    const [viewCount, setViewCount] = useState<number>(0)
    const [isPublic, setIsPublic] = useState<boolean>(false)

    useAsyncEffect(async () => {
        const { uploadCount, viewCount, isPublic } = await fetchUserAnalytics(userId)
        setUploadCount(uploadCount)
        setViewCount(viewCount)
        setIsPublic(isPublic)
    }, [])

    const viewCountRounded = roundWithSuffix(viewCount)
    const uploadCountRounded = roundWithSuffix(uploadCount)

    if (!uploadCount || uploadCount <= 0) return null
    return (
        <ChannelAnalyticsContainer>
            <ChannelStatistics isPublic={isPublic} stat={uploadCountRounded} title={'GIF Uploads'} />
            {!isCompact && <GrayLine />}
            <ChannelStatistics isPublic={isPublic} stat={viewCountRounded} title={'GIF Views'} />
            {!isPublic && (
                <LockedContentText>
                    <div className="ss-lock" />
                    <p>Your metrics are private</p>
                </LockedContentText>
            )}
            {showDashboardLink && (
                <DashboardLink href={'/dashboard'}>
                    <div className={'ss-barchart'} />
                    Visit Your Dashboard
                </DashboardLink>
            )}
        </ChannelAnalyticsContainer>
    )
}

export default ChannelAnalyticsOverview
