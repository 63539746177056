import { useRef, useState } from 'react'
import { editUserById } from 'shared/api'
import { SaveHandler } from 'shared/types/settings'
import useAsyncEffect from './use-async-effect'

export function useSaveForm(id, cb: SaveHandler) {
    const formRef = useRef<HTMLFormElement>(null)
    const [isSaving, setSave] = useState<boolean>(false)
    const [formSaveId, setFormSaveId] = useState<number>(0)
    const [response, setResponse] = useState<{ pending_email?: string }>({})

    useAsyncEffect(async () => {
        if (formRef && isSaving) {
            const formData = new FormData(formRef.current!)

            const email = formData.get('email') as string
            if (!email) {
                formData.delete('email') // this field can be ommitted in some cases, but never blank
            }

            const birthdate = formData.get('birthdate') as string
            if (birthdate) {
                const [m, d, y] = birthdate.split('/')
                formData.set('birthdate', `${y}-${m}-${d}`)
            }
            const aboutBio = formData.get('about_bio') as string
            if (aboutBio) {
                formData.set('about_bio', aboutBio.replace(/<[^>]*>?/gm, ''))
            }
            try {
                const results = await editUserById(id, formData)
                setResponse(results)
                setFormSaveId(formSaveId + 1)
                cb()
            } catch (error) {
                setResponse({})
                console.error(error)
                cb(error)
            }
            setSave(false)
        }
    }, [isSaving])
    return { formRef, save: () => setSave(true), isSaving, formSaveId, response }
}
