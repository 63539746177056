import { lazy, Suspense } from 'react'

const Story = lazy(() => import(/* webpackChunkName: "desktopStory" */ './story'))

const StoryRoute = ({
    match: {
        params: { slug },
    },
}) => (
    <Suspense fallback={null}>
        <Story slug={slug} />
    </Suspense>
)

export default StoryRoute
